<template>
  <div class="dashboard">
    <v-row class="mx-2">
      <v-col cols="8">
        <v-spacer />
      </v-col>
      <v-col cols="4">
        <VDateRangePicker
          v-model="filter.date"
          :ranges="filter.dateRanges"
          dark
        />
      </v-col>
    </v-row>

    <v-row class="mx-2">
      <v-col cols="12">
        <SpotPlannedAchievedByDairy
          :period="filter.date"
          :color="colors.graph8"
          :smooth="false"
          height="353px"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <SilosAndTotalByRawMaterial
          height="250px"
          :period="filter.date"
        />
      </v-col>

      <v-col
        cols="8"
      >
        <CourtyardAverageTimeChart
          :period="filter.date"
          :color="colors.graph7"
          :graph-color="colors.line7"
          height="188px"
        />
      </v-col>

      <v-col
        cols="4"
      >
        <DescartedCollectionsChart
          :period="filter.date"
          :color="colors.graph3"
          :graph-color="colors.line3"
          height="210px"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <UnloadsByDayChart
          :period="filter.date"
          :color="colors.graph7"
          :graph-color="colors.line7"
          height="188px"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <AnalysisAverageTimeChart
          :period="filter.date"
          :color="colors.graph7"
          :graph-color="colors.line7"
          height="188px"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <FreighterVolumeDiffChart
          :period="filter.date"
          :color="colors.graph7"
          :graph-color="colors.line7"
          height="188px"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <RawMaterialChart
          :period="filter.date"
          :color="colors.graph7"
          :graph-color="colors.line7"
          height="188px"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

import SilosAndTotalByRawMaterial from "@/Domains/BI/Views/Platform/Components/SilosAndTotalByRawMaterial.vue";
import CourtyardAverageTimeChart from "@/Domains/BI/Views/Platform/Components/CourtyardAverageTimeChart.vue";
import UnloadsByDayChart from "@/Domains/BI/Views/Platform/Components/UnloadsByDayChart.vue";
import AnalysisAverageTimeChart from "@/Domains/BI/Views/Platform/Components/AnalysisAverageTimeChart.vue";
import FreighterVolumeDiffChart from "@/Domains/BI/Views/Platform/Components/FreighterVolumeDiffChart.vue";
import RawMaterialChart from "@/Domains/BI/Views/Platform/Components/RawMaterialChart.vue";

import moment from "moment";
import DescartedCollectionsChart from "@/Domains/BI/Views/General/Components/DescartedCollectionsChart.vue";
import SpotPlannedAchievedByDairy from "@/Domains/BI/Views/Platform/Components/SpotPlannedAchievedByDairy.vue";

export default {
  components: {
    SpotPlannedAchievedByDairy,
    RawMaterialChart,
    VDateRangePicker,
    SilosAndTotalByRawMaterial,
    CourtyardAverageTimeChart,
    UnloadsByDayChart,
    AnalysisAverageTimeChart,
    FreighterVolumeDiffChart,
    DescartedCollectionsChart,
  },

  data() {
    return {
      filter: {
        dateRanges: {
          'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
          'Mês anterior': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
          'Este ano': [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
        },
        date: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      },
      colors: {},
    }
  },

  created() {
    this.setColorsPalette();
  },

  methods: {
    setColorsPalette() {
      const transparentGraph = 'rgba(0, 0, 0, 0.4)';
      const lightBlue = 'rgba(38, 198, 218, 0.7)';
      const purple = 'rgba(171, 71, 188, 0.7)';
      const yellow = 'rgba(255, 255, 0, 0.7)';
      const red = 'rgba(229, 57, 53, 0.7)';
      const teal = 'rgba(29, 233, 182, 0.7)';
      this.colors = {
        graph1: transparentGraph,
        line1: lightBlue,
        graph2: transparentGraph,
        line2: purple,
        graph3: transparentGraph,
        line3: yellow,
        graph4: transparentGraph,
        line4: red,
        graph5: transparentGraph,
        line5: teal,
        graph6: transparentGraph,
        graph7: transparentGraph,
        line7: lightBlue,
        graph8: transparentGraph,
        report1: transparentGraph,
        temperature1: transparentGraph,
      };
    },
  }
};
</script>
