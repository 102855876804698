<template>
  <data-table
    name="Itinerário - Coletas"
    :headers="[
      { text: 'Produtor', value: 'producer.name' },
      { text: 'Quantidade', value: 'vol' },
      { text: 'Tanques', value: 'tanks' },
      { text: 'Temperatura', value: 'temp' },
      { text: 'Data Coleta', value: 'collectedAt' },
      { text: 'Hora Chegada', value: 'collectStartedAt' },
      { text: 'Hora Saída', value: 'collectEndedAt' },
      { text: 'Data/Hora Efetivação', value: 'unloadedAt' },
      { text: 'Logs', value: 'logs', width: 50, sortable: false },
      { align: 'end', text: '', altText: 'Ações', value: 'actions', width: 60, sortable: false },
    ]"
    :items="filteredItems"
    item-key="id"
    class="elevation-1"
    disable-filtering
    disable-pagination
    hide-default-footer
    @click:row="$event => $emit('editCollect', $event)"
  >
    <template #[`header.actions`]="{}">
      <slot name="actions" />
    </template>

    <template #[`item.producer.name`]="{ value, item }">
      {{ item.producer.code }} {{ value }}

      <v-tooltip
        v-if="item.condominium.length>0"
        top
        color="#222"
      >
        <template #activator="{ on }">
          <v-icon
            color="rgba(255, 255, 255, 0.7)"
            v-on="on"
          >
            groups
          </v-icon>
        </template>
        <div class="text-left d-flex flex-column">
          <div
            v-for="cond in item.condominium "
            :key="cond.id_pessoa"
          >
            {{ cond.codigo_laticinio }} {{ cond.nome }} : {{ cond.volume }} L
          </div>
        </div>
      </v-tooltip>
    </template>

    <template #[`item.collectedAt`]="{ value }">
      {{ dateFormat(value, 'DD/MM/YYYY') }}
    </template>

    <template #[`item.vol`]="{ value }">
      {{ value }}
    </template>

    <template #[`item.logs`]="{ item }">
      <v-btn
        v-if="(item.originalVol && item.originalVol != item.vol) || item.logs.length > 0"
        icon
        @click.stop="$emit('showCollectLog', item)"
      >
        <v-icon>
          info
        </v-icon>
      </v-btn>
    </template>

    <template #[`item.tanks`]="{ value }">
      <template v-for="tank in value">
        <v-chip
          v-if="tank.vol > 0"
          :key="tank.key"
          class="mt-1"
          small
          color="blue"
          text-color="white"
        >
          {{ tank.label }}
        </v-chip>
      </template>
    </template>

    <template #[`item.unloadedAt`]="{ value }">
      {{ dateFormat(value, 'DD/MM/YYYY HH:mm') }}
    </template>

    <template #[`item.actions`]="{ item }">
      <v-menu>
        <template #activator="{ on }">
          <v-btn
            dark
            icon
            v-on="on"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$emit('editCollect', item)">
            <v-list-item-icon>
              <v-icon>edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Editar
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('deleteCollect', item)">
            <v-list-item-icon>
              <v-icon>delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Excluir
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </data-table>
</template>

<script>
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

export default {
  props: {
    collects: Array,
    search: String,
  },

  computed: {
    filteredItems() {
      if (!this.search) {
        return this.collects;
      }

      const search = this.search?.toUpperCase()?.trim();

      return this.collects.filter(item => {
        return JSON.stringify(Object.values(item)).toUpperCase().includes(search);
      });
    }
  },

  methods: {
    dateFormat: (value, format) => isEmpty(value) ? '' : moment(value).format(format)
  }
}
</script>
