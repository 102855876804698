<template>
  <v-container
    class="schedule"
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
        />
      </v-col>
      <v-col>
        <person-autocomplete-filter
          v-model="filter.producer"
          type="PRODUCER"
          label="Produtor"
          dark
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet height="64">
          <v-toolbar
            flat
            light
          >
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Hoje
            </v-btn>
            <v-btn
              fab
              text
              small
              @click="prev"
            >
              <v-icon small>
                chevron_left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              @click="next"
            >
              <v-icon small>
                chevron_right
              </v-icon>
            </v-btn>
            <v-toolbar-title
              v-if="$refs.calendar"
              color="grey darken-2"
              class="calendar-title"
            >
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn-toggle
              v-model="type"
              mandatory
            >
              <v-btn
                outlined
                value="month"
              >
                Mês
              </v-btn>
              <v-btn
                outlined
                value="week"
              >
                Semana
              </v-btn>
              <v-btn
                outlined
                value="day"
              >
                Dia
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="filteredEvents"
            :event-color="getEventColor"
            :type="type"
            light
            @click:more="viewDay"
            @click:date="viewDay"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousedown:day="startTime"
            @mousemove:time="mouseMove"
            @mousemove:day="mouseMove"
            @mouseup:time="endDrag"
            @mouseup:day="endDrag"
            @mouseleave.native="cancelDrag"
          >
            <template v-slot:day="{ date }">
              <div
                v-if="!!totalEventsByDay[date]"
                class="map-btn"
              >
                <v-btn
                  icon
                  @click.prevent="openMap(date)"
                >
                  <v-icon small>
                    map
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:day-header="{ date }">
              <div
                v-if="!!totalEventsByDay[date]"
                class="map-btn"
              >
                <v-btn
                  icon
                  @click.prevent="openMap(date)"
                >
                  <v-icon small>
                    map
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:event="{ event, timed, eventSummary }">
              <div
                class="v-event-draggable"
                v-html="eventSummary()"
              />
              <div
                v-if="timed"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              />
            </template>
          </v-calendar>

          <event-viewer-dialog
            ref="eventViewer"
            v-model="selectedEvent"
            :show="selectedOpen && !saving"
            :editing="currentlyEditing === selectedEvent.idx"
            :technicians="technicians"
            @click:edit="editEvent"
            @click:delete="deleteEvent"
            @click:close="closeEvent"
            @click:cancel="cancelEdit"
            @click:save="saveEvent"
          />
        </v-sheet>
      </v-col>
    </v-row>
    <v-overlay
      :value="loading"
    >
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
    <v-overlay
      :value="saving"
    >
      <v-card-text>
        Salvando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
    <confirm ref="confirm" />

    <schedule-map-dialog
      v-model="map.show"
      :events="map.events"
      :date="map.date"
    />
  </v-container>
</template>

<style lang="scss">
.schedule {
  .calendar-title {
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .v-event-draggable {
    padding-left: 6px;
  }

  .v-event-timed {
    user-select: none;
    -webkit-user-select: none;
  }

  .v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    cursor: ns-resize;

    &::after {
      display: none;
      position: absolute;
      left: 50%;
      height: 4px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      width: 16px;
      margin-left: -8px;
      opacity: 0.8;
      content: '';
    }

    &:hover::after {
      display: block;
    }
  }

  .map-btn {
    position: absolute;
    bottom:0;
    right:0;
    z-index:1;
  }
  .v-btn-toggle {
    &:not(.v-btn-toggle--dense) {
      .v-btn.v-btn.v-size--default {
        height: 36px !important;
        min-height: 0;
        min-width: 48px;
      }
    }
  }
  .v-btn--outlined {
      border-color: rgba(0, 0, 0, 0.12) !important;
  }
}
</style>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import EventViewerDialog from "@/Domains/Visits/Schedule/Components/EventViewerDialog.vue";
import ScheduleMapDialog from "@/Domains/Visits/Schedule/Components/ScheduleMapDialog.vue";
import Confirm from "@/Support/Components/Confirm.vue";

import moment from "moment-timezone";

export default {
  name: "schedule",

  components: {
    PersonAutocompleteFilter,
    EventViewerDialog,
    ScheduleMapDialog,
    Confirm,
  },

  filters: {
    dateFormat: function(value, opts) {
      return moment(value).format(opts);
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      technicians: [],
      technicianColor: {},
      filter: {
        technician: {
          id: "",
          description: ""
        },
        producer: {
          id: "",
          description: ""
        },
      },
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
      },
      selectedEvent: {},
      selectedOpen: false,
      currentlyEditing: null,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'red', 'pink', 'light-blue', 'teal', 'light-green', 'lime'],
      showscheduleVisitTime: false,
      showEndTime: false,
      totalEventsByDay: {},

      onEndDrag: false,
      dragEvent: null,
      dragStart: null,
      startTimeOriginal: null,
      endTimeOriginal: null,
      isBottomDrag: false,

      map: {
        show: false,
        date: new Date(),
        events: [],
      }
    };
  },

  computed: {
    hasfilters() {
      return !!this.filter.technician.id || !!this.filter.producer.id
    },
    filteredEvents() {
      if (!this.hasfilters) {
        return this.events;
      }

      const filterTech = event => (!this.filter.technician.id || this.filter.technician.id == event.person.id);
      const filterProducer = event => (!this.filter.producer.id || this.filter.producer.id == event.producer.id);

      return this.events.filter(event => filterTech(event) && filterProducer(event));
    },
  },

  async created() {
    await this.loadTechnicians();
    await this.loadSchedules();
  },

  mounted () {
    this.$refs.calendar.checkChange()
  },

  methods: {

    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },

    getEventColor(event) {
      return event.color || "grey darken-1";
    },

    setToday() {
      this.focus = '';
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    /**
     * Método responsável por carregar os técnicos
     * @returns {Promise<void>}
     */
    async loadTechnicians() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaTecnicos`);

        const totalColors = this.colors.length;

        /**
         * Generate colors for each technician
         */
        let technicianColor = {};
        data.forEach((technician, idx) => {
          technicianColor[technician.id_pessoa] = this.colors[idx % totalColors];
        });

        this.technicianColor = technicianColor;

        this.technicians = data
          .map(technician => ({
            id: technician.id_pessoa,
            name: technician.nome
          }));

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
        console.log(err);
      }
    },

    /**
     * Recupera as mensagens do servidor
     * @returns {Promise<void>}
     */
    async loadSchedules() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/agenda/listaAgendamentos`);

        let events = data.map((schedule, idx) => {
          const date = moment(schedule.start).format("YYYY-MM-DD");
          this.updateEventsByDay(date, 1);
          return {
            ...schedule,
            idx: idx,
            start: new Date(schedule.start),
            end: new Date(schedule.end),
            color: this.technicianColor[schedule.person.id] || 'grey darken-1',
          }
        });

        this.events = events;
      } catch (err) {
        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Altera o calendário para exibir os dados do dia selecionado
     */
    onClickDay(day) {
      if (this.hasfilters) {
        this.$snotify.info("Limpe os filtros para adicionar um agendamento", "Atenção");
        return;
      }
      if (this.selectedOpen || this.map.show) {
        return;
      }
      const start = new Date(`${day.date}T07:00:00`);
      const end = new Date(`${day.date}T17:00:00`);

      let newIdx = this.events.length;
      let event = {
        idx: newIdx,
        id: null,
        name: '',
        start: start,
        end: end,
        color: 'grey darken-1',
        timed: true,
        category: 'VISITA',
        type: [],
        person: {
          id: "",
          name: ""
        },
        producers: [],
        producer: {
          id: "",
          name: ""
        },
      };
      this.events.push(event);

      this.currentlyEditing = newIdx;
      this.openEvent(event);
    },

    editEvent() {
      if (this.hasfilters) {
        this.$snotify.info("Limpe os filtros para editar o agendamento", "Atenção");
        return;
      }
      this.currentlyEditing = this.selectedEvent.idx;
    },

    /**
     * Remove um agendamento
     * @returns {Promise<void>}
     */
    async deleteEvent() {
      let ev = this.selectedEvent;
      if (!(await this.$refs.confirm.open('Remover agendamento', 'Tem certeza que deseja remover este agendamento?', { color: 'red' }))) {
        return;
      }
      this.saving = true;
      try {
        /**
         * Não tem ID, portanto não foi salvo no backend
         * vamos apenas remover
         */
        if (ev.id == null) {
          this.currentlyEditing = null;
          this.events.splice(ev.idx, 1);
          this.selectedOpen = false;
          return;
        }
        const date = moment(ev.start).format("YYYY-MM-DD");

        await this.$axios.post(`/agenda/excluiAgendamento`, { id: ev.id });

        /**
         * Remove o agendamento e fecha o dialog
         */
        this.currentlyEditing = null;
        this.events.splice(ev.idx, 1);
        this.selectedOpen = false;
        this.updateEventsByDay(date, -1);

        this.$snotify.success("Agendamento excluido!", "Sucesso");

      } catch (e) {
        this.$snotify.error("Ocorreu um erro ao excluir o agendamento!", "Atenção");
        console.warn(e);
      } finally {
        this.saving = false;
      }
    },

    /**
     * Salva um agendamento
     * @returns {Promise<void>}
     */
    async saveEvent() {
      this.saving = true;
      try {
        // Valida o formulário
        if (!this.$refs.eventViewer.validateForm()) {
          return;
        }

        /**
         * Usando destructor para evitar o data biding
         */
        let selectedEvent = { ...this.selectedEvent };

        let form = {
          ...selectedEvent,
          color: this.technicianColor[selectedEvent.person.id] || 'grey darken-1',
          start: moment(selectedEvent.start).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(selectedEvent.end).format("YYYY-MM-DD HH:mm:ss"),
        };

        const idx = this.currentlyEditing;

        /**
         * Se não tiver id então é um cadastro novo
         */
        if (selectedEvent.id == null) {
          /**
           * Em novos eventos é possível selecionar multiplos produtores
           * Mas vamos replicar esses eventos para o técnico poder
           * ajustar o horário de cada visita individualmente no app
           */
          let forms = selectedEvent.producers.map(producer => ({
            ...form,
            producer,
          }));

          let dataList = await Promise.all(forms.map(async form => {
            return await this.$axios.post(`/agenda/salvaAgendamento`, form);
          }));

          /**
           * Atualiza os ids dos eventos retornados pelo backend
           */
          dataList.forEach(({ data }, i) => {
            forms[i].id = data.id;
            forms[i].start = new Date(forms[i].start);
            forms[i].end = new Date(forms[i].end);

            const date = moment(forms[i].start).format("YYYY-MM-DD");
            this.updateEventsByDay(date, 1);

          })

          /**
           * Adiciona os eventos no calendário
           */
          this.events.splice(idx, 1, ...forms);
          this.selectedEvent = {};
          this.selectedOpen = false;
        }
        else {
          await this.$axios.post(`/agenda/atualizaAgendamento`, form);

          /**
           * Atualiza o evento no calendário
           */
          this.events.splice(idx, 1, selectedEvent);
          this.selectedEvent = selectedEvent;
        }

        /**
         * Fecha a edição
         */
        this.currentlyEditing = null;

        this.$snotify.success("Agendamento efetuado!", "Sucesso");

      } catch (e) {
        this.$snotify.error("Ocorreu um erro ao salvar o agendamento!", "Atenção");
        console.warn(e);
      } finally {
        this.saving = false;
      }
    },

    /**
     * Apenas fecha o dialog que estava exibindo os detalhes do agendamento
     */
    closeEvent() {
      this.selectedOpen = false;
    },

    /**
     * Cancela a edição do evento no dialog
     */
    cancelEdit() {
      const idx = this.currentlyEditing;
      /**
       * Verifica se é uma inserção
       */
      if (idx != null && this.events[idx]) {
        let id = this.events[idx].id;
        /**
         * Se for nulo, não foi salvo no banco, apenas remove o evento.
         */
        if (id == null) {
          /**
           * this is not working properly: [this.events.splice(idx, 1);]
           * then let's use a workaround
           */
          let events = [...this.events];
          events.splice(idx, 1);

          this.events = [];
          this.$nextTick(() => {
            this.events = events;
            this.selectedOpen = false;
          });
        }
        else {
          /**
           * Desfaz as alterações
           */
          this.selectedEvent = { ...this.events[idx] };
        }
        this.$nextTick(() => {
          this.currentlyEditing = null;
        });
      }
    },

    /**
     * Abre um dialog com os detalhes do agendamento
     */
    showEvent(event) {
      if (event.id == null) {
        return;
      }
      this.openEvent(event);
      this.currentlyEditing = null;
    },

    openEvent(event) {
      const open = () => {
        /**
         * Usando destructor para evitar o data biding
         */
        this.selectedEvent = { ...event };
        this.selectedOpen = true;
      }

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
    },

    startDrag({ event }) {
      if (event) {
        this.startTimeOriginal = event.start;
        this.endTimeOriginal = event.end;
        this.dragEvent = event;
        this.dragTime = null;
      }
    },

    async endDrag() {
      this.onEndDrag = true;
      if (this.dragEvent) {
        /**
         * Visualizar evento
         */
        if (this.dragEvent.start == this.startTimeOriginal && this.dragEvent.end == this.endTimeOriginal) {
          this.showEvent(this.dragEvent);
        }
        /**
         * Fim do drag'n drop
         */
        else {
          if (await this.$refs.confirm.open(
            'Confirmar alteração?',
            `<b>${this.dragEvent.name}</b><br>
              <b>Início:</b> ${moment(this.dragEvent.start).format('DD/MM/YYYY HH:mm')}<br>
              <b>Término:</b> ${moment(this.dragEvent.end).format('DD/MM/YYYY HH:mm')}`,
            { color: 'blue' }
          )
          ) {
            let form = {
              ...this.dragEvent,
              start: moment(this.dragEvent.start).format("YYYY-MM-DD HH:mm:ss"),
              end: moment(this.dragEvent.end).format("YYYY-MM-DD HH:mm:ss"),
            };
            /**
             * Atualiza as novas datas/horários do evento
             */
            await this.$axios.post(`/agenda/atualizaAgendamento`, form);
          }
          else {
            this.dragEvent.start = this.startTimeOriginal;
            this.dragEvent.end = this.endTimeOriginal;
          }
        }
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.endTimeOriginal = null;
      this.startTimeOriginal = null;
      this.onEndDrag = false;
      this.isBottomDrag = false;
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      /**
       * Arraste do evento inteiro
       */
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      }
      /**
       * Adicionar novo evento
       */
      else {
        const type = this.type;
        /**
         * Previne que exiba o dialog de criação de eventos
         * caso seja alterado o tipo de visualização do calendário
         */
        setTimeout(() => {
          if (type == this.type) {
            this.onClickDay(tms);
          }
        }, 300);
      }
    },
    extendBottom(event) {
      this.isBottomDrag = true;
      this.dragEvent = event;
      this.startTimeOriginal = event.start;
      this.endTimeOriginal = event.end;
    },
    mouseMove(tms) {
      /**
       * Verifica se saiu devido à estar no dialog de confirmação das alterações
       */
      if (this.onEndDrag) {
        return;
      }

      if (this.dragEvent) {
        const mouse = this.toTime(tms);
        /**
         * Arraste do evento inteiro
         */
        if (!this.isBottomDrag) {
          if (this.dragTime !== null) {
            const start = this.dragEvent.start;
            const end = this.dragEvent.end;
            const duration = end - start;
            const newStartTime = mouse - this.dragTime;
            const newStart = this.roundTime(newStartTime);
            const newEnd = newStart + duration;

            this.dragEvent.start = new Date(newStart);
            this.dragEvent.end = new Date(newEnd);
          }
        }
        /**
         * Arraste apenas do tempo final do evento
         */
        else {
          if (this.startTimeOriginal !== null) {
            const mouseRounded = this.roundTime(mouse, false);
            const min = Math.min(mouseRounded, this.startTimeOriginal);
            const max = Math.max(mouseRounded, this.startTimeOriginal);

            this.dragEvent.start = new Date(min);
            this.dragEvent.end = new Date(max);
          }
        }
      }
    },
    /**
     * Método chamado quando o mouse sai do calendário
     */
    async cancelDrag() {
      /**
       * Verifica se saiu devido à estar no dialog de confirmação das alterações
       */
      if (this.onEndDrag) {
        return;
      }

      if (this.isBottomDrag) {
        if (this.endTimeOriginal) {
          this.dragEvent.end = this.endTimeOriginal;
        }
      }
      else {
        await this.endDrag();
      }

      this.startTimeOriginal = null;
      this.endTimeOriginal = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },

    updateEventsByDay(date, incr) {
      if (!Number.isInteger(incr)) return;

      if (!(date in this.totalEventsByDay)) {
        this.totalEventsByDay[date] = 0;
      }

      this.totalEventsByDay[date] += incr;
    },

    openMap(date) {
      this.map.events = this.events.filter(ev => moment(ev.start).format("YYYY-MM-DD") == date);
      this.map.date = new Date(date);
      this.map.show = this.map.events.length > 0;
    },
  },
};
</script>
