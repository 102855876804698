<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="project-pmls"
  >
    <v-flex xs12>
      <v-row>
        <v-flex>
          <v-tabs
            v-model="tab"
            dark
            centered
            background-color="transparent"
            class="transparent"
            @change="onTabChange"
          >
            <v-tab href="#grafico">
              Gráfico de Meta
            </v-tab>
            <v-tab href="#visitados">
              Visitados
            </v-tab>
            <v-tab href="#nao_visitados">
              Não Visitados
            </v-tab>

            <v-tab-item value="grafico">
              <grafico
                ref="grafico"
                aba="grafico"
                :filter="filter"
              />
            </v-tab-item>

            <v-tab-item value="visitados">
              <reports-visitados
                ref="visitados"
                aba="visitados"
                :filter="filter"
              />
            </v-tab-item>

            <v-tab-item value="nao_visitados">
              <reports-nao-visitados
                ref="nao_visitados"
                aba="nao_visitados"
                :filter="filter"
              />
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-row>
    </v-flex>
  </v-container>
</template>

<style lang="scss" scoped>
.project-pmls {

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    } 
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import Grafico from '@/Domains/Projects/PMLS/Components/Grafico.vue';
import ReportsVisitados from '@/Domains/Projects/PMLS/Components/ReportsVisitados.vue';
import ReportsNaoVisitados from '@/Domains/Projects/PMLS/Components/ReportsNaoVisitados.vue';
import moment from "moment-timezone";
import _ from "lodash";

export default {
  //name: "project-pmls",

  components: {
    ReportsVisitados,
    ReportsNaoVisitados,
    Grafico
  },

  props: {
    aba: String,
    filter: Object
  },

  data() {
    return {
      loading: false,

      tab: null,
    };
  },

  methods: {
    onTabChange() {
      if (this.tab == 'grafico' && this.$refs.grafico) {
        setTimeout(() => {
          this.$refs.grafico.loadVisits();
        }, 1000);
      }

      if (this.tab == 'visitados' && this.$refs.visitados) {
        this.$refs.visitados.loadVisits();
      }

      if (this.tab == "nao_visitados" && this.$refs.nao_visitados) {
        this.$refs.nao_visitados.loadVisits();
      }
    },

    navigateBack() {
      this.$store.commit("setCurrentMenu", this.parent.name);
      this.$store.commit("setCurrentMenuBackground", this.parent.background);
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>