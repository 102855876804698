<template>
  <v-row>
    <v-col
      v-if="showUpload"
      cols="12"
      class="text-center"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-row>
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <v-btn
                    outlined
                    class="mb-5"
                    @click="showFileType"
                  >
                    <v-icon>attach_file</v-icon> Adicionar arquivos
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <file-upload
        v-model="showUploadDialog"
        extensions="pdf,xml,gif,jpe,jpeg,jpg,tiff,png,webp,bmp"
        accept="application/pdf,text/xml,image/*"
        :multiselect="false"
        @insertFiles="onSelectFiles"
      />

      <v-dialog
        v-model="showTypeDialog"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            Tipo de anexo
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-btn-toggle
                  v-model="selectedType"
                  @change="showFileUpload()"
                >
                  <v-btn
                    v-for="(item, key) of types"
                    :key="key"
                    :value="key"
                  >
                    <span class="hidden-sm-and-down">{{ item.title }}</span>

                    <v-icon
                      right
                      color="primary"
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="secondary"
              outlined
              @click="closeFileType()"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <v-col
      v-else-if="attachments.length === 0"
      cols="12"
      class="text-center"
    >
      <v-card>
        <v-card-text>Não há anexos disponíveis</v-card-text>
      </v-card>
    </v-col>

    <v-col
      v-for="(attachment, idx) in attachments"
      :key="attachment.url"
      cols="12"
      class="d-flex child-flex"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              xs8
              md8
              class="text-left"
              align-self="center"
            >
              <v-icon
                medium
                class="mr-2"
                style="padding-bottom: 5px; padding-left: 5px"
                color="#141518"
              >
                {{ getIcon(attachment) }}
              </v-icon>
              <span class="font-weight-medium">{{ getTypeName(attachment) }}</span>
            </v-col>
            <v-col
              xs2
              md2
              class="text-right"
              align-self="center"
            >
              <v-icon
                v-if="isImageType(attachment)"
                class="ml-4"
                color="secondary"
                @click="print(attachment.url || attachment.uri)"
              >
                print
              </v-icon>
              <v-icon
                class="ml-4"
                color="info"
                @click="open(attachment.url || attachment.uri)"
              >
                open_in_new
              </v-icon>
              <v-icon
                v-if="showUpload"
                class="ml-4"
                color="red"
                @click="onRemoveFile(idx)"
              >
                delete
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FileUpload from "@/Support/Components/FileUpload.vue";

import get from 'lodash/get'

export default {
  components: {
    FileUpload,
  },

  props: {
    attachments: {
      type: Array,
      default: () => []
    },
    showUpload: Boolean,
  },

  data() {
    return {
      showUploadDialog: false,
      showTypeDialog: false,
      selectedType: null,

      types: {
        ANALISE_QUALIDADE: {
          title: 'Análise de qualidade',
          icon: 'biotech',
        },
        DADOS_PESSOAIS: {
          title: 'Documentos pessoais',
          icon: 'assignment_ind',
        },
        BANCO: {
          title: 'Banco',
          icon: 'account_balance_wallet',
        },
      }
    }
  },

  methods: {
    /**
     * Abre a url em uma nova aba
     * @param url
     */
    open(url) {
      window.open(url);
    },

    /**
     * Abre uma nova aba e imprime
     * @param url
     */
    print(url) {
      const pwa = window.open("_new");
      pwa.document.open();
      pwa.document.write(`<html>
          <head>
            <script>
              function printOnLoad(){ setTimeout(() => { window.print(); window.close(); }, 10); }
            </scri` + `pt>
          </head>
          <body onload='printOnLoad()'>
            <img src='${url}' />
          </body>
        </html>`);
      pwa.document.close();
    },

    getTypeName(file) {
      if (!file.tipo) {
        return file.name;
      }

      const item = this.types[file.tipo];
      return item ? item.title : file.tipo;
    },

    getIcon(file) {
      if (!file.tipo) {
        return 'attach_file';
      }

      const item = this.types[file.tipo];
      return item ? item.icon : 'attach_file';
    },

    isImageType(file) {
      let type = file.type;

      if (!type) {
        const extension = (file.url || file.uri)
          .split(".").pop()
          .split("?").shift();

        type = `/${extension}`;
      }

      return (/\/(gif|jpe?g|jpg|tiff?|png|webp|bmp)$/i).test(type);
    },

    showFileType() {
      this.showTypeDialog = true;
      this.selectedType = null;
    },

    showFileUpload() {
      this.showTypeDialog = false;
      this.showUploadDialog = true;
    },

    closeFileType() {
      this.showTypeDialog = false
    },

    onSelectFiles(attachments) {
      for (let file of attachments) {
        if (!this.attachments.find(o => o.url == file.url)) {
          this.attachments.push({
            tipo: this.selectedType,
            url: file.url,
          });
        }
      }
    },

    async onRemoveFile(idx) {
      const fileUrl = this.attachments[idx].url;

      if (!(fileUrl.includes('googleapis.com') && fileUrl.includes('produtores'))) {
        this.attachments.splice(idx, 1);
        return;
      }

      if (!(await this.$root.$confirm('Remover anexo', 'Tem certeza que deseja excluir este anexo?', { color: 'red' }))) {
        return;
      }

      this.$root.$progressBar.loading();
      try {
        await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
        this.attachments.splice(idx, 1);

        this.$emit('fileRemoved');
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao salvar');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
  }
}
</script>
