var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"text-xs-center":"","grid-list-lg":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"graph-card report-card",attrs:{"color":"transparent","dark":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-chart',{style:({width: '100%', height: `300px !important`}),attrs:{"type":"bar","options":_vm.visitGraph,"autoresize":""}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"name":"Meta de Visitas","headers":[
              { align: 'start', text: 'Mês', value: 'month' },
              { text: 'Produtores Visitados', value: 'produtores_visitados', align: 'center' },
              { text: 'Não Visitados', value: 'n_visitados', align: 'center' },
              { text: '% Visitados', value: 'percent', align: 'center' },
              { text: 'Meta', value: 'total_visita', align: 'center' },
              { text: '', value: 'status', align: 'end' },
              { align: 'end', text: '', value: 'actions', width: 60, sortable: true },
            ],"items":_vm.visitados,"item-key":"month","loading":_vm.loading,"items-per-page":-1,"hide-default-footer":"","dark":""},scopedSlots:_vm._u([{key:`item.month`,fn:function({value}){return [_vm._v(" "+_vm._s(_vm.formatMonth(value))+" ")]}},{key:`item.status`,fn:function({value}){return [(value=='OK')?_c('v-icon',{attrs:{"right":"","color":"green darken-1"}},[_vm._v(" verified ")]):_vm._e(),(value=='NO')?_c('v-icon',{attrs:{"right":"","color":"red darken-1"}},[_vm._v(" cancel ")]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.print(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("print")])],1),_c('v-list-item-title',[_vm._v(" Imprimir ")])],1)],1)],1)]}}],null,true)})],1)],1)],1)],1),_c('v-speed-dial',{staticClass:"mr-5",attrs:{"fixed":"","dark":"","bottom":"","right":"","open-on-hover":"","direction":"top","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-2","dark":"","large":"","fab":""}},[_c('v-icon',[_vm._v("menu")])],1)]},proxy:true}])},[_c('v-btn',{attrs:{"fab":"","dark":"","color":"green darken-1"},on:{"click":function($event){return _vm.exportExcel()}}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" backup_table ")])]}}])},[_vm._v(" Download (Excel) ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }