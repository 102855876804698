<template>
  <v-dialog
    v-model="isVisible"
    scrollable
    :max-width="400"
    persistent
  >
    <v-card>
      <v-card-title
        class="blue--text text-h5"
      >
        Recálculo de Preço do Litro
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-menu
              ref="menu"
              transition="scale-transition"
              offset-y
              content-class="menu-select"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="dateRefFilter"
                  label="Mês de Referência"
                  prepend-inner-icon="event"
                  readonly
                  filled
                  hide-details
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="form.dateRef"
                :max="maxDateRef"
                type="month"
                no-title
                scrollable
              />
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="caption">
                  Movimentações de Coletas (Entrada)
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-switch
                    v-model="form.collectMovements"
                    :label="form.collectMovements ? 'Ativo' : 'Inativo'"
                    hide-details
                    class="mt-0"
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="caption">
                  Movimentações de Saída
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-switch
                    v-model="form.outgoingMovements"
                    :label="form.outgoingMovements ? 'Ativo' : 'Inativo'"
                    hide-details
                    class="mt-0"
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <div>
          O recálculo das movimentações de entrada de coleta são feitas a partir do mês de referência de cotação.<br>
          O recálculo das saídas são feitas a partir das movimentações de entrada.
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          :disabled="!form.collectMovements && !form.outgoingMovements"
          @click="save"
        >
          Recalcular
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export default {

  data() {
    return {
      isVisible: false,

      form: {
        dateRef: moment().subtract(1, "month").format("YYYY-MM"),
      }
    }
  },

  computed: {
    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format("YYYY-MM");
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (isEmpty(this.form.dateRef)) {
        return null;
      }

      return moment(this.form.dateRef, "YYYY-MM").format("MM/YYYY");
    },
  },

  methods: {
    show() {
      this.isVisible = true;
      this.$refs.form && this.$refs.form.resetValidation();

      this.form = {
        dateRef: moment().subtract(1, "month").format("YYYY-MM")
      };
    },
    close() {
      this.isVisible = false;
    },
    async save() {
      try {
        this.$root.$progressBar.saving();

        const payload = {
          referencia: this.form.dateRef,
          movimentacoes_coleta: this.form.collectMovements,
          movimentacoes_saida: this.form.outgoingMovements,
        };

        const { data } = await this.$axios.post(`/industry/silo/recalculate-movement-prices`, payload);

        this.$snotify.success('', {
          html: `<div class="snotifyToast__title"><b>Sucesso</b></div>
            <div class="snotifyToast__body">
              ${data.coletas} movimentos de coletas recalculados!<br>
              ${data.saidas} movimentos de saída recalculados!
            </div>`
        });

        this.close();
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao recalcular');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    }
  }
}
</script>
