<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    color="transparent"
    :loading="loading"
    :style="{height: height}"
  >
    <v-card-title
      class="flex-grow-0"
      :style="{background: 'rgba(0, 0, 0, 0.4)'}"
    >
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Total Medidos e Vales</span>
      <v-spacer />
    </v-card-title>
    <v-row
      no-gutters
      class="flex-grow-1"
      :style="`overflow: hidden; height: 100%`"
    >
      <v-col
        cols="12"
        class="py-0 pr-0"
        :style="{background: 'rgba(0, 0, 0, 0.4)'}"
      >
        <v-card-text class="white--text text-left">
          <v-row>
            <v-col
              cols="3"
              class="d-flex flex-column py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="subtitle-2 font-weight-light">
                Coletado
              </span>
              <span
                class="headline text-truncate"
              >{{ formatNumber(totalColetado) }}</span>
            </v-col>

            <v-col
              cols="3"
              class="d-flex flex-column py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="subtitle-2 font-weight-light">
                Recebido
              </span>
              <span
                class="headline text-truncate"
              >{{ formatNumber(totalMedido) }}</span>
            </v-col>

            <v-col
              cols="3"
              class="d-flex flex-column py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="subtitle-2 font-weight-light">
                Descartado
              </span>
              <span
                class="headline text-truncate"
              >{{ formatNumber(totalDescartado) }}</span>
            </v-col>

            <v-col
              cols="3"
              class="d-flex flex-column py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="subtitle-2 font-weight-light">
                Diferença
              </span>
              <span
                class="headline text-truncate"
              >{{ formatNumber(totalDiferenca) }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {

  props: {
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    height: {
      type: String,
      default: '95px',
    },
  },

  data() {
    return {
      loading: false,
      totalColetado: 0,
      totalMedido: 0,
      totalDiferenca: 0,
      totalDescartado: 0,
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;
        let coletado = 0;
        let medido = 0;
        let descartado = 0;

        let [{ data: volumeColeta }, { data: volumeMedidoGeral }] = await Promise.all([
          this.$axios.post(
            `/coleta/volumeColeta`,
            { data_inicio: startDate, data_fim: endDate }
          ),
          await this.$axios.post(
            `/coleta/volumeMedidoGeral`,
            { data_inicio: startDate, data_fim: endDate }
          )
        ]);

        if (!_.isArray(volumeColeta)) {
          throw new Error(volumeColeta);

        } else if (!_.isArray(volumeMedidoGeral)) {
          throw new Error(volumeMedidoGeral);
        }
        _.forEach(volumeColeta, function(e) {
          coletado += e.total_volume != null ? parseInt(e.total_volume) : 0;
        });

        _.forEach(volumeMedidoGeral, function(e) {
          medido += e.peso_medido != null ? parseFloat(e.peso_medido) : 0;
          descartado += e.volume_descartado != null ? parseFloat(e.volume_descartado) : 0;
        });

        this.totalColetado = coletado;
        this.totalMedido = medido;
        this.totalDiferenca = (coletado - medido);
        this.totalDescartado = descartado;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os dados!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  }
}
</script>
