<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-layout
        row
        wrap
      >
        <v-flex>
          <v-text-field
            v-model="description"
            prepend-inner-icon="compare"
            dark
            class="rota-field"
            label="Nome da Rota"
            placeholder="Digite o nome da rota"
            filled
            background-color="rgba(0,0,0,.5)"
            hide-details
            :rules="[v => !!v || 'Campo obrigatório!']"
          />
        </v-flex>

        <v-flex
          sm2
        >
          <v-text-field
            v-model="routeCode"
            label="Código Rota"
            placeholder="Digite o código da rota"
            class="rota-field"
            :style="{ textAlign: 'center' }"
            background-color="rgba(0,0,0,.5)"
            dark
            filled
            hide-details
          />
        </v-flex>
      </v-layout>

      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          sm3
        >
          <route-types-form-item
            v-model="types"
            tab="aba-cadastro"
            :rules="[v => !!v && v.length > 0 || 'Campo obrigatório!']"
          />
        </v-flex>

        <v-flex
          xs12
          sm3
        >
          <route-equipments-form-item
            v-model="equipments"
            :vol="vol"
            :stats="stats"
            :types="types"
            :params="params"
          />
        </v-flex>

        <v-flex
          xs12
          sm3
        >
          <route-persons-form-item
            v-model="persons"
            :rules="[v => !!v && v.length > 0 || 'Campo obrigatório!']"
          />
        </v-flex>

        <v-flex
          xs12
          sm3
        >
          <route-regions-form-item v-model="regions" />
        </v-flex>
      </v-layout>

      <v-layout
        id="route-section"
        ref="route-section"
        row
        wrap
        :style="'background: ' + $route.meta.background"
        :class="['route-section', { 'pr-2': isFullscreen }]"
        @fullscreenchange="onFullscreenChange"
      >
        <!-- workaround para o modo fullscreen -->
        <v-overlay
          v-model="routeParamsDialog"
          absolute
          attach=".route-section"
        />

        <v-flex
          xs12
          sm12
        >
          <route-resume
            ref="routeResume"
            :producers="mapPersons"
            :stats.sync="stats"
            :params="params"
            :equipments="equipments"
            :vol="vol"
            :maps="maps"
            :last-itinerary="lastItinerary"
            editable
            @onParamsChange="onParamsChange"
          >
            <template slot="actions">
              <v-dialog
                v-model="routeParamsDialog"
                width="400"
                attach=".route-section"
                hide-overlay
                eager
                class="rota-parametros"
              >
                <template #activator="{ on: dialog }">
                  <v-tooltip
                    left
                    attach
                  >
                    <template #activator="{ on: tooltip }">
                      <v-btn
                        icon
                        dark
                        v-on="{ ...dialog, ...tooltip }"
                      >
                        <v-icon>settings</v-icon>
                      </v-btn>
                    </template>

                    <span>Parâmetros da rota</span>
                  </v-tooltip>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="text-h6">Parâmetros</span>
                  </v-card-title>

                  <v-divider />

                  <v-card-text class="pb-0">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <span>Tempo médio da coleta:</span>

                          <v-slider
                            v-model="params.collectTime"
                            prepend-icon="access_time"
                            thumb-label
                            class="pa-0"
                            min="5"
                            max="40"
                            @change="onParamsChange"
                          >
                            <template #thumb-label="{ value }">
                              {{ value }}m
                            </template>

                            <template #append>
                              <span class="text-overline">
                                {{ params.collectTime }}m
                              </span>
                            </template>
                          </v-slider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <span>Matéria Prima:</span>

                          <RawMaterialAutocomplete
                            v-model="params.rawMaterial"
                            label=" "
                            auto-select-raw-milk
                            only-parents
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="primary"
                      text
                      @click="routeParamsDialog = false"
                    >
                      Ok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-tooltip
                left
                attach
              >
                <template #activator="{ on: tooltip }">
                  <v-menu
                    left
                    attach
                  >
                    <template #activator="{ on: menu }">
                      <v-btn
                        dark
                        icon
                        v-on="{ ...menu, ...tooltip }"
                      >
                        <v-icon>map</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="onTraceRouteClick('OSRM')">
                        <v-list-item-icon>
                          <v-icon>route</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          OSRM
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="onTraceRouteClick('GMaps')">
                        <v-list-item-icon>
                          <v-icon>route</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          GMaps
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

                <span>Traçar a rota</span>
              </v-tooltip>
            </template>
          </route-resume>
        </v-flex>

        <v-flex
          xs12
          sm8
          md9
        >
          <v-card dark>
            <v-card-text class="pa-0">
              <route-map
                ref="map"
                :value="$data"
                :center="$store.state.settings.coordenadas"
                single-route
                custom-options
                draggable
                disable-fullscreen
                :height="isFullscreen ? 'calc(100vh - 145px)' : '500px'"
                @onPositionChange="onPositionChange"
                @onRouteTraced="onRouteTraced"
              >
                <template #controls>
                  <v-tooltip
                    v-if="hasLocationChanges"
                    attach
                    bottom
                  >
                    <template #activator="{ on }">
                      <div
                        class="map-custom-points px-0"
                        :style="{ left: 'unset', right: 95 + 'px' }"
                      >
                        <v-btn
                          icon
                          light
                          class="ma-0"
                          v-on="on"
                          @click="undoLocationChanges"
                        >
                          <v-icon :style="{ fontSize: 28 + 'px' }">
                            undo
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>

                    <span>Desfazer alterações</span>
                  </v-tooltip>

                  <v-tooltip
                    v-if="hasEditLocationAccess"
                    attach
                    bottom
                  >
                    <template #activator="{ on }">
                      <div
                        class="map-custom-points px-0"
                        :style="{ left: 'unset', right: 50 + 'px' }"
                      >
                        <v-btn
                          icon
                          light
                          class="ma-0"
                          v-on="on"
                          @click="toggleLocationEdit"
                        >
                          <v-icon :style="{ fontSize: 28 + 'px' }">
                            {{ !isEditingLocation ? 'edit' : 'close' }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>

                    <span>{{ !isEditingLocation ? 'Ativar edição' : 'Fechar edição' }}</span>
                  </v-tooltip>

                  <div
                    class="map-custom-points px-0"
                    :style="{ left: 'unset', right: 5 + 'px' }"
                  >
                    <v-btn
                      icon
                      light
                      class="ma-0"
                      @click="toggleFullScreen"
                    >
                      <v-icon :style="{ fontSize: 28 + 'px' }">
                        {{ !isFullscreen ? 'fullscreen' : 'fullscreen_exit' }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </route-map>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          sm4
          md3
        >
          <route-points-form-item
            :waypoints.sync="mapPersons"
            :route="$data"
            :show-add-location="isEditingLocation"
            :is-fullscreen="isFullscreen"
            @onAddWaypoint="onAddProducer"
            @onRemoveWaypoint="onRemoveProducer"
            @onWaypointPositionChange="onProducerPositionChange"
            @onAddLocation="onAddProducerLocation"
            @onShowLocation="onShowProducerLocation"
          />
        </v-flex>
      </v-layout>
    </v-form>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green"
            v-on="on"
            @click="saveRoute('PUBLISH')"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </template>

        <span>Salvar e publicar</span>
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            v-on="on"
            @click="saveRoute('SAVE')"
          >
            <v-icon>receipt_long</v-icon>
          </v-btn>
        </template>

        <span>Salvar sem publicar</span>
      </v-tooltip>

      <v-tooltip
        v-if="!duplicating && form.id"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue-grey lighten-1"
            v-on="on"
            @click="onRouteDuplicateClick"
          >
            <v-icon>content_copy</v-icon>
          </v-btn>
        </template>

        <span>Duplicar Rota</span>
      </v-tooltip>

      <v-tooltip
        v-if="!duplicating && form.id"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="purple lighten-1"
            v-on="on"
            @click="printRoute"
          >
            <v-icon>print</v-icon>
          </v-btn>
        </template>

        <span>Imprimir Rota</span>
      </v-tooltip>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";
import RoutePointsFormItem from "@/Domains/Routes/Components/Form/RoutePointsFormItem.vue";
import RouteResume from "@/Domains/Routes/Components/Form/RouteResume.vue";
import RouteService from "@/Domains/Routes/Services/RouteService.js";
import RouteTypesFormItem from "@/Domains/Routes/Components/Form/RouteTypesFormItem.vue";
import RouteEquipmentsFormItem from "@/Domains/Routes/Components/Form/RouteEquipmentsFormItem.vue";
import RoutePersonsFormItem from "@/Domains/Routes/Components/Form/RoutePersonsFormItem.vue";
import RouteRegionsFormItem from "@/Domains/Routes/Components/Form/RouteRegionsFormItem.vue";
import RawMaterialAutocomplete from "@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import { v4 as uuidv4 } from "uuid";

const service = new RouteService();

export default {
  components: {
    RouteRegionsFormItem,
    RoutePersonsFormItem,
    RouteEquipmentsFormItem,
    RouteTypesFormItem,
    RoutePointsFormItem,
    RouteMap,
    RouteResume,
    RawMaterialAutocomplete,
  },

  mixins: [ReportMixin],

  props: {
    form: Object,
    tab: String,
  },

  data() {
    return {
      // Loaders
      loading: false,

      // Dialogs
      routeParamsDialog: false,

      // Grava se o registro é de uma rota que está sendo duplicada
      duplicating: false,

      isFullscreen: false,

      // Dados do formulário
      id: null,
      description: "",
      routeCode: "",
      published: false,
      equipments: [],
      persons: [],
      types: [],
      regions: [],
      mapPersons: [],
      stats: {
        distance: 0,
        duration: 0,
      },
      params: {
        routeStartPosition: {},
        routeEndPosition: {},
        collectTime: 0,
        dieselCostPerLitre: 0,
        routePolyline: null,
        rawMaterial: null,
      },
      maps: {
        needsUpdate: false,
      },
      lastItinerary: {},

      // Mascara usada nos valores adicionáis do leite
      MilkCurrencyMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 2,
            signed: true,
          },
        },
      },
    };
  },

  computed: {
    /**
     * Produtores visíveis da lista
     */
    visiblePoints() {
      return this.mapPersons.filter(producer => !producer.remove && !producer.groupingId);
    },

    /**
     * Volume total de leite da rota (Em Litros)
     * O Volume é carregado no componente pai pois é usado em mais de um componente
     */
    vol() {
      return _.sumBy(this.mapPersons.filter(producer => !producer.remove), 'vol');
    },

    isEditingLocation() {
      return this.mapPersons.some(point => point.type === 'PESSOA' && point.draggable)
    },

    hasLocationChanges() {
      return this.mapPersons.some(point => !!point.originalLocation)
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasEditLocationAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "route-producer-location-edit" && o.tipo === "COMPONENTE");
    },
  },

  watch: {
    /**
     * As regiões na tela são geradas com base nos produtores inseridos
     */
    mapPersons() {
      const regions = this.mapPersons
        .filter(({ region, remove }) => region.id && !remove)
        .map(({ region }) => region);

      this.regions = _.uniqBy(regions, "id");
    },
  },

  mounted() {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0;
    });

    this.loadFormData();
  },

  methods: {
    /**
     * Load do formulário
     */
    async loadFormData() {
      try {
        // Só faz o load da rota quando for uma edição
        if (!this.form.id) {
          this.id = uuidv4();
          return;
        }

        this.loading = true;

        const route = await service
          .fromSystem(this.$store.state.settings.sistema)
          .getRoute(this.form.id);

        // Preenche o objeto com base na resposta da api tratada
        this.id = this.form.id;
        this.description = route.description;
        this.routeCode = route.routeCode;
        this.types = route.types;
        this.equipments = route.equipments;
        this.persons = route.persons;
        this.mapPersons = route.mapPersons;
        this.stats = route.stats;
        this.params = route.params;
        this.published = route.published;
        this.lastItinerary = route.lastItinerary;
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar a rota!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    /**
     * Cria/Edita uma rota
     */
    async saveRoute(action) {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        this.loading = true;

        this.published = action !== 'SAVE';

        await service
          .fromSystem(this.$store.state.settings.sistema)
          .saveRoute(this.$data);

        return this.onRouteSaved();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar a rota!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    /**
     * @event Object
     *
     * Evento acionado ao adicionar um produtor na rota
     */
    onAddProducer() {
      return this.clearMapRoute();
    },

    /**
     * @event Object
     *
     * Evento acionado ao remover um produtor da rota
     */
    onRemoveProducer() {
      return this.clearMapRoute();
    },

    /**
     * @event void
     *
     * Evento acionado ao finalizar o DnD do produtor na lista
     */
    onProducerPositionChange() {
      return this.clearMapRoute();
    },

    /**
     * @event void
     *
     * Evento acionado ao adicionar a localização do produtor na lista
     */
    onAddProducerLocation(producer) {
      const idx = this.mapPersons.findIndex(item => item.id === producer.id);

      const location = this.$refs.map.getCenterLocation();

      this.mapPersons.splice(idx, 1, {
        ...producer,
        originalLocation: producer.location,
        location,
        hasLocation: true
      })

      return this.clearMapRoute();
    },

    /**
     * @event Object
     *
     * Evento acionado quando ocorre alteração no form de parâmetros da rota
     */
    onParamsChange(event) {
      this.params = {
        ...this.params,
        ...event,
      };
    },

    /**
     * @event void
     *
     * Evento acionado ao clicar no botão para re-traçar a rota
     */
    onTraceRouteClick(option) {
      return this.$refs.map.trace(true, false, option);
    },

    /**
     * @event Object
     *
     * Evento acionado ao traçar a rota
     */
    onRouteTraced(event) {
      this.stats.duration = event.duration;
      this.stats.distance = event.distance;
      this.params.routePolyline = event.polyline;

      this.maps.needsUpdate = false;
    },

    onShowProducerLocation(producer) {
      const index = this.$refs.map.waypoints.findIndex(item => item.id === producer.id);
      this.$refs.map.openMarkerInfo(index);
    },

    /**
     * @event Object
     *
     * Evento acionado no Drag-and-drop das marcações do mapa (partida/chegada)
     */
    onPositionChange(event) {
      if (event.marker.endsWith("start")) {
        this.params.routeStartPosition = event.position;
      }

      if (event.marker.endsWith("end")) {
        this.params.routeEndPosition = event.position;
      }

      if (event.marker === "stopping_point") {
        this.mapPersons.push(service.createStoppingPoint(event.position));
      }
      else if (event.marker.endsWith("stopping_point")) {
        const id = event.marker.split('_')[2];
        const idx = this.mapPersons.findIndex(o => o.id === id);
        if (idx > -1) {
          this.mapPersons[idx].location = event.position;
        }
      }

      if (event.marker.endsWith("person")) {
        const id = event.marker.split('_')[2];
        const idx = this.mapPersons.findIndex(o => o.id === id);
        if (idx > -1) {
          const producer = this.mapPersons[idx];

          this.mapPersons.splice(idx, 1, {
            ...producer,
            originalLocation: producer.originalLocation || producer.location,
            location: event.position
          })
        }
      }

      return this.clearMapRoute();
    },

    clearMapRoute() {
      this.maps.needsUpdate = true;
      this.params.routePolyline = null;

      return this.$refs.map.clearRoute();
    },

    /**
     * @event void
     *
     * Evento acionado ao salvar a rota (Volta para a lista de rotas)
     */
    onRouteSaved() {
      return this.$emit("onRouteSaved");
    },

    /**
     * @event void
     *
     * This becomes important when the user doesn't use the button to exit
     * fullscreen but hits ESC on desktop, pushes a physical back button on
     * mobile etc.
     */
    onFullscreenChange() {
      const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || null;
      this.isFullscreen = !!fullscreenElement && fullscreenElement.id === 'route-section';
    },

    toggleFullScreen() {
      if (this.isFullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      else {
        const element = this.$refs['route-section'];
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullScreen) {
          element.msRequestFullScreen();
        }
      }
    },

    toggleLocationEdit() {
      const editing = this.isEditingLocation;

      this.mapPersons = this.mapPersons.map(point => {
        if (point.type === 'PESSOA') {
          point.draggable = !editing;
        }
        return point;
      })
    },

    undoLocationChanges() {
      this.mapPersons = this.mapPersons.map(point => {
        if (point.originalLocation) {
          point.location = point.originalLocation;
          point.hasLocation = point.location.lat && point.location.lng,
          delete point.originalLocation;
        }
        return point;
      })
    },

    /**
     * @event void
     *
     * Evento acionado ao clicar para duplicar uma rota
     */
    onRouteDuplicateClick() {
      this.loading = true;

      this.duplicating = true;
      this.id = null;
      this.description = `${this.description} - Cópia`;

      return setTimeout(() => this.loading = false, 500);
    },

    /**
     * @event void
     *
     * Evento acionado ao clicar para imprimir uma rota
     */
    async printRoute() {

      const { distance, duration, vol, literPerDistance, ccs, cbt, visibleProducers } = this.$refs.routeResume;

      const types = this.types
        .map(o => o.description.toUpperCase())
        .join(', ');

      const equipments = this.equipments
        .map(o => (o.plate || o.description).toUpperCase())
        .join(', ');

      const technicians = this.persons
        .filter(o => o.roleId != 6)
        .map(o => o.name.toUpperCase())
        .join(', ');

      const drivers = this.persons
        .filter(o => o.roleId == 6)
        .map(o => o.name.toUpperCase())
        .join(', ');

      const regions = this.regions
        .map(o => o.description.toUpperCase())
        .join(', ');

      const producers = this.visiblePoints.map((producer, idx) => {
        if (producer.type === "PARADA") {
          return {
            Código: (idx + 10).toString(36).toUpperCase(),
            Nome: producer.name,
            Cidade: '',
            Volume: '',
            CCS: '',
          }
        }
        return {
          Código: (idx + 10).toString(36).toUpperCase(),
          Nome: producer.name,
          Cidade: producer.address.city,
          Volume: `${this.formatNumber(producer.vol)} L`,
          CCS: this.formatNumber(producer.ccs),
          CPP: this.formatNumber(producer.cbt),
        }
      });

      const mapUrl = await this.$refs.map.getStaticMap("940x750");

      const pages = [];

      const title = `Rota ${this.description}`;

      const rightTitle = [
        {
          tag: 'div',
          class: 'row',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }
      ];

      const header = [{
        tag: 'div',
        class: 'row',
        children: [
          {
            tag: 'div',
            class: 'twelve columns',
            contents: `<b>Tipos:</b> ${types}`,
          },
          {
            tag: 'div',
            class: 'twelve columns',
            contents: `<b>Equipamentos:</b> ${equipments}`,
          },
          {
            tag: 'div',
            class: 'twelve columns',
            contents: `<b>Técnicos:</b> ${technicians}`,
          },
          {
            tag: 'div',
            class: 'twelve columns',
            contents: `<b>Motoristas:</b> ${drivers}`,
          },
          {
            tag: 'div',
            class: 'twelve columns',
            contents: `<b>Regiões:</b> ${regions}`,
          }
        ]
      }];

      const headers = Object.keys(producers[0])
        .map(key => ({
          key,
          label: key,
          class: 'text-left'
        }));

      const body = [
        {
          tag: 'div',
          class: 'spacer'
        },
        {
          tag: 'section',
          class: 'row flex-center border-outline',
          children: [
            {
              tag: 'div',
              contents: `<b>Distância:</b> ${this.formatNumber(distance)} Km`
            },
            {
              tag: 'div',
              contents: `<b>Duração:</b> ${duration}`
            },
            {
              tag: 'div',
              contents: `<b>Volume:</b> ${this.formatNumber(vol)} L`
            },
            {
              tag: 'div',
              contents: `<b>L/Km:</b> ${this.formatNumber(literPerDistance)}`
            },
            {
              tag: 'div',
              contents: `<b>CCS:</b> ${this.formatNumber(ccs)}`
            },
            {
              tag: 'div',
              contents: `<b>CPP:</b> ${this.formatNumber(cbt)}`
            },
            {
              tag: 'div',
              contents: `<b>Produtores:</b> ${this.formatNumber(visibleProducers.length)}`
            },
          ]
        },
        {
          tag: 'section',
          class: 'row',
          children: [{
            tag: 'datatable',
            headers,
            items: producers,
          }]
        },
      ];

      pages.push({ title, rightTitle, header, body });

      const bodyMap = [
        {
          tag: 'div',
          class: 'spacer'
        },
        {
          tag: 'section',
          class: 'text-center border-outline',
          children: [
            {
              tag: 'img',
              src: mapUrl,
              width: 940,
              height: 750,
              style: 'transform: rotate(270deg);margin: calc((940px - 750px)/2) calc((750px - 940px)/2)'
            }
          ]
        }
      ];

      pages.push({ title, rightTitle, header: [], body: bodyMap });

      await this.printPdf({ pages });
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },
};
</script>
