<template>
  <div>
    <v-row>
      <v-col>
        <emergency-items
          v-for="e in typeEmergency"
          :key="e.type"
          :type="e.type"
          :data="e"
          :filter="filter"
          :loading="loading"
        />
      </v-col>
    </v-row>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando emergenciais...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import EmergencyItems from "@/Domains/Projects/PQFL/Components/EmergencyItems.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "emergency-summary",

  components: {
    EmergencyItems,
  },
 
  mixins: [ReportMixin],

  props: {
    filter: Object,
  },

  data() {
    return {
      loading: false,
      //metas de PAE emergenciais
      typeEmergency: [],

      emergencyGoals: {
        CRITICAL: { status: 'Produtores Críticos', visits: 0, questionnaires: [], type: 'PERCENT' },
        PRIORITY: { status: 'Produtores Prioritários', visits: 0, questionnaires: [], type: 'UN' },
        PRIORITYCCS: { status: 'Produtores Prioritários CCS', visits: 0, questionnaires: [], type: 'UN' },
      },

    };
  },
  watch: {
    filter: {
      handler() {
        this.onLoad();
      },
      deep: true
    }
   
  },
   
  methods: {
    
    async onLoad() {
      if (this.filter.project) {
        this.typeEmergency = [];
        await this.loadPriorityCriticalCPPReport();
        return this.$emit("onLoadEmmergency", this.typeEmergency);
      }
    },

    async loadPriorityCriticalCPPReport() {

      this.loading = true;
      let itemsVisits = [];
      let headers = [];
      let items = [];
      
      try {
        let { data } = await this.$axios.post(
          `/relatorioIn/visitasCriticosPrioritarios`,
          {
            mes_ref: moment(this.filter.date.month).format('YYYY-MM'),
          }
        );

        if (!_.isEmpty(this.filter.project.emergency) ) {
          const { CRITICO, PRIORITARIO } = JSON.parse(this.filter.project.emergency);
          this.emergencyGoals = {
            CRITICAL: { status: 'Produtores Críticos', visits: CRITICO.visitas, questionnaires: CRITICO.questionarios, type: !_.isEmpty(CRITICO.tipo) ? CRITICO.tipo : "PERCENT" },
            PRIORITY: { status: 'Produtores Prioritários', visits: PRIORITARIO.visitas, questionnaires: PRIORITARIO.questionarios, type: !_.isEmpty(PRIORITARIO.tipo) ? PRIORITARIO.tipo : "UN" },
          };
          
        }
        //criticos
        if (this.emergencyGoals.CRITICAL.visits > 0 ) {
          itemsVisits = [];
          headers = [
            { text: "Mês", value: "month", width: 100 },
            { text: "Produtores", value: "producers", width: 120, align: "center" },
            { text: "IN 59", value: "producers_in59", width: 100, align: "center"  },
            { text: "IN 76/77", value: "producers_in76", width: 100, align: "center"  },
            { text: "Não Salvos", value: "producers_not_save", width: 120, align: "center"  },
            { text: "Visitados", value: "visits", width: 150, align: "center"  },
            { text: "% Visitados", value: "visitsPerc", width: 120 },
            { text: "Meta", value: "goalsPerc", width: 100 },
            { text: "", value: "icone", width: 30 },
          ];

          items = data.filter(o => !_.isEmpty(o.produtores_criticos)).map(o => {

            let quantidadeSalvo59 = 0;
            let quantidadeSalvo76 = 0;
            let quantidadeDesvinculado = 0;
            let totalVisitado = 0;

            o.produtores_criticos = o.produtores_criticos.map(p => {
              let cbt_trimestral = p.cbt_geometrica_trimestral_posterior;
              let menor_cbt = p.menor_cbt;
              let salvo = 'Não salvo';
              let visit = _.isEmpty(p.visitas) ? 0 : (p.visitas).length ;

              p.visitas.map(v => {
                itemsVisits.push(v.id_visita)
              })

              if ((p.status == 'DESVINCULADO' || p.status == 'SUSPENSO') && !_.isEmpty(p.data_desvinculacao) && !_.isEmpty(p.visitas)) {
                quantidadeDesvinculado++;
              }

              if (cbt_trimestral > 0  &&  cbt_trimestral <= 300) {
                //76/77
                salvo = '76/77';
                totalVisitado++;
                quantidadeSalvo76++;
              } else if (menor_cbt > 0 && menor_cbt <= 300 && visit > 0 ) {
                // 59
                salvo = '59';
                totalVisitado++;
                quantidadeSalvo59++;
              } else if (!_.isEmpty(p.visitas))  {
                totalVisitado++;
              }
              return {
                ...p,
                salvo: salvo
              }
            });
            
            let totalNaoSalvo = o.total_criticos - quantidadeSalvo59 - quantidadeSalvo76;
            let totalAtivoVisitado = totalVisitado - quantidadeDesvinculado;
            totalAtivoVisitado = (totalAtivoVisitado < 0) ? 0 : totalAtivoVisitado;
            let percentualVisitado = totalAtivoVisitado > 0 ? (totalAtivoVisitado / o.total_criticos )  * 100 : 0;
            
            let percentualMeta = (this.emergencyGoals.PRIORITY.type == "UN") ?  ( 100 * this.emergencyGoals.CRITICAL.visits / o.total_criticos ) : this.emergencyGoals.PRIORITY.visits ;
            
            let icone = 'cancel';

            if (this.emergencyGoals.CRITICAL.type == "UN"
              && (totalAtivoVisitado >= this.emergencyGoals.CRITICAL.visits
              || totalAtivoVisitado == o.total_criticos) ) {
              icone = 'verified';
            }
            else if (this.emergencyGoals.CRITICAL.type == "PERCENT"
              &&  (percentualVisitado == 100 || percentualVisitado > percentualMeta )) {
              icone = 'verified';
            }

            return {
              month: o.mes,
              producers: o.total_criticos,
              producers_not_save: totalNaoSalvo,
              producers_in59: quantidadeSalvo59,
              producers_in76: quantidadeSalvo76,
              visits: o.total_criticos_visitados,
              visitsPerc: Math.abs(percentualVisitado).toFixed(2),
              details: o.produtores_criticos,
              goals: (this.emergencyGoals.CRITICAL.type == "PERCENT") ? (o.total_criticos * this.emergencyGoals.CRITICAL.visits / 100) : this.emergencyGoals.CRITICAL.visits,
              goalsPerc: this.emergencyGoals.CRITICAL.visits + " " + (this.emergencyGoals.CRITICAL.type == "PERCENT" ? "%" : ""),
              type: this.emergencyGoals.CRITICAL.type,
              icone: icone,
            }
          })

          //carrega os criticos de CPP para enviar para componente que monta tabela
          this.typeEmergency.push({
            type: 'critical',
            headers: headers,
            items: items,
            itemsVisits: itemsVisits,
            goal: this.emergencyGoals.CRITICAL,
          });
          
        }

        //prioritarios
        if (this.emergencyGoals.PRIORITY.visits > 0 ) {
          itemsVisits = [];
          headers = [
            { text: "Mês", value: "month" },
            { text: "Produtores", value: "producers", width: 120, align: "center" },
            { text: "Visitados", value: "visits", width: 150, align: "center"  },
            { text: "% Visitados", value: "visitsPerc", width: 120 },
            { text: "Meta", value: "goalsPerc", width: 100 },
            { text: "", value: "icone", width: 30 },
          ];
          items = data.filter(o => !_.isEmpty(o.produtores_prioritarios)).map(o => {

            let percentualMeta = (this.emergencyGoals.PRIORITY.type == "UN") ?  ((100 * o.total_prioritarios_visitados) / this.emergencyGoals.PRIORITY.visits  ) : this.emergencyGoals.PRIORITY.visits ;
            let icone = 'cancel';

            if (this.emergencyGoals.PRIORITY.type == "UN"
              && (o.total_prioritarios_visitados >= this.emergencyGoals.PRIORITY.visits
              || o.total_prioritarios_visitados == o.total_prioritarios) ) {
              icone = 'verified';
            }
            else if (this.emergencyGoals.PRIORITY.type == "PERCENT"
              &&  (o.perc_prioritarios_visitados == 100 || o.perc_prioritarios_visitados > percentualMeta )) {
              icone = 'verified';
            }
            o.produtores_prioritarios.map(p => {
              p.visitas.map(v => {
                itemsVisits.push(v.id_visita)
              })
            });
            
            return {
              month: o.mes,
              producers: o.total_prioritarios,
              visits: o.total_prioritarios_visitados,
              visitsPerc: o.perc_prioritarios_visitados,
              details: o.produtores_prioritarios,
              goals: (this.emergencyGoals.PRIORITY.type == "PERCENT") ? (o.total_criticos * this.emergencyGoals.PRIORITY.visits / 100) : this.emergencyGoals.PRIORITY.visits,
              goalsPerc: this.emergencyGoals.PRIORITY.visits + " " + (this.emergencyGoals.PRIORITY.type == "PERCENT" ? "%" : ""),
              type: this.emergencyGoals.PRIORITY.type,
              icone: icone,
            }
          });

          //carrega os prioritarios de CPP para enviar para componente que monta tabela
          this.typeEmergency.push({
            type: 'priority',
            headers: headers,
            items: items,
            itemsVisits: itemsVisits,
            goal: this.emergencyGoals.PRIORITY,
          });
         
        }
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
        await this.loadPriorityCCSReport();
      }
    },

    async loadPriorityCCSReport() {
     
      this.loading = true;
      let itemsVisits = [];
      if (!_.isEmpty(this.filter.project.emergency) ) {
        const { PRIORITARIOCCS } = JSON.parse(this.filter.project.emergency);
        if (PRIORITARIOCCS) {
          this.emergencyGoals = {
            PRIORITYCCS: { status: 'Produtores Prioritários CCS', visits: PRIORITARIOCCS.visitas, questionnaires: PRIORITARIOCCS.questionarios, type: !_.isEmpty(PRIORITARIOCCS.tipo) ? PRIORITARIOCCS.tipo : "UN" },
          };
        }
        
      }
      
      try {
        if (this.emergencyGoals.PRIORITYCCS.visits > 0 ) {
          let { data } = await this.$axios.post(
            `/relatorioIn/visitasPrioritariosCCS`,
            {
              mes_ref: moment(this.filter.date.month).format('YYYY-MM'),
            }
          );
          
          //prioritarios
        
          let headers = [
            { text: "Mês", value: "month" },
            { text: "Produtores", value: "producers", width: 120, align: "center" },
            { text: "Visitados", value: "visits", width: 150, align: "center"  },
            { text: "% Visitados", value: "visitsPerc", width: 120 },
            { text: "Meta", value: "goalsPerc", width: 100 },
            { text: "", value: "icone", width: 30 },
          ];

          let items = data.map(o => {

            let percentualMeta = (this.emergencyGoals.PRIORITYCCS.type == "UN") ?  ((100 * o.total_prioritarios_visitados) / this.emergencyGoals.PRIORITYCCS.visits  ) : this.emergencyGoals.PRIORITYCCS.visits ;
            let icone = 'cancel';

            if (this.emergencyGoals.PRIORITYCCS.type == "UN"
              && (o.total_prioritarios_visitados >= this.emergencyGoals.PRIORITYCCS.visits
              || o.total_prioritarios_visitados == o.total_prioritarios) ) {
              icone = 'verified';
            }
            else if (this.emergencyGoals.PRIORITYCCS.type == "PERCENT"
              &&  (o.perc_prioritarios_visitados == 100 || o.perc_prioritarios_visitados > percentualMeta )) {
              icone = 'verified';
            }
            if (!_.isEmpty(o.produtores_prioritarios)) {
              o.produtores_prioritarios.map(p => {
                if (!_.isEmpty(p.visitas)) {
                  p.visitas.map(v => {
                    itemsVisits.push(v.id_visita)
                  })
                }
              });
            }
            
            return {
              month: o.mes,
              producers: o.total_prioritarios,
              visits: o.total_prioritarios_visitados,
              visitsPerc: o.perc_prioritarios_visitados,
              details: o.produtores_prioritarios,
              goals: (this.emergencyGoals.PRIORITYCCS.type == "PERCENT") ? (o.total_criticos * this.emergencyGoals.PRIORITYCCS.visits / 100) : this.emergencyGoals.PRIORITYCCS.visits,
              goalsPerc: this.emergencyGoals.PRIORITYCCS.visits + " " + (this.emergencyGoals.PRIORITYCCS.type == "PERCENT" ? "%" : ""),
              type: this.emergencyGoals.PRIORITYCCS.type,
              icone: icone,
            }
          })
          //carrega os prioritarios de CPP para enviar para componente que monta tabela
          this.typeEmergency.push({
            type: 'priority-ccs',
            headers: headers,
            items: items,
            itemsVisits: itemsVisits,
            goal: this.emergencyGoals.PRIORITYCCS,
          });
        }
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de PRIORITARIOS CCS", "Atenção");
      }
      finally {
        this.loading = false;
      }
    }
  },
};
</script>