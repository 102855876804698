<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <masked-text-field
        v-model="form.percentual_despesas"
        label="Percentual Despesas (Incentivo)"
        :mask="masks.percentual"
        unmask
        prepend-inner-icon="percent"
      />
    </v-col>
  </v-row>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

export default {

  components: {
    MaskedTextField,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      masks: {
        percentual: { mask: Number, min: 0, max: 100, scale: 4 }
      },
    }
  },

  computed: {
    form: {
      get() {
        return this.data;
      },

      set(newValue) {
        return this.$emit("update:data", newValue);
      },
    },
  },
}
</script>
