<template>
  <div class="mx-6 px-xl-16">
    <v-row>
      <v-col
        cols="12"
        class="pt-0 mb-4 pb-0 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Itinerário de Coletas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
          @change="onTabChange"
        >
          <v-tab href="#tab-itineraries">
            Itinerários
          </v-tab>

          <v-tab
            href="#tab-editing"
            :disabled="!editing.id"
          >
            Editar Itinerário
          </v-tab>

          <v-tab-item value="tab-itineraries">
            <list-itineraries-tab
              v-if="tab === 'tab-itineraries'"
              ref="itineraries"
              status="APPROVED"
              @onItineraryNew="onItineraryNew"
              @onItineraryEditing="onItineraryEditing"
              @onItineraryPrint="onItineraryPrint"
              @generateKml="onGenerateKml"
            />
          </v-tab-item>

          <v-tab-item value="tab-editing">
            <editing-itinerary-tab
              v-if="tab === 'tab-editing'"
              :itinerary="editing"
              type="coleta"
              status="APPROVED"
              :report-settings="reportSettings"
              @onItinerarySave="onItinerarySave"
              @onItineraryPrint="onItineraryPrint"
            />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <print-settings-dialog
      ref="print-settings"
      @print="print"
    />

    <v-overlay
      :value="loading"
      absolute
    >
      <v-card-text>
        Carregando...
        <v-progress-linear
          class="mb-0"
          color="white"
          indeterminate
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import ListItinerariesTab from "@/Domains/Itineraries/Views/ListItinerariesTab.vue";
import EditingItineraryTab from "@/Domains/Itineraries/Views/EditingItineraryTab.vue";
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";
import printJS from 'print-js';

export default {

  components: {
    EditingItineraryTab,
    ListItinerariesTab,
    PrintSettingsDialog,
  },

  data() {
    return {
      tab: 'tab-itineraries',
      editing: {},

      reportSettings: {},

      loading: false,
    };
  },

  created() {
    this.getReportSettings();
  },

  methods: {

    onTabChange() {
      this.editing = {};
    },

    onItineraryNew() {
      this.editing = {};
      this.tab = 'tab-editing'
    },

    onItineraryEditing(itinerary) {
      this.editing = itinerary;
      this.tab = 'tab-editing'
    },

    onItinerarySave() {
      this.editing = {};
      this.tab = 'tab-itineraries'
    },

    async getReportSettings() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/settings/reports`);

        this.reportSettings = data.find(item => item.relatorio === 'Itinerário Coletas')?.configuracao || {};

      } catch (e) {
        this.$snotify.error('Erro ao carregar configuração de relatório', 'Atenção');

      } finally {
        this.loading = false;
      }
    },

    onItineraryPrint(item) {
      this.$refs['print-settings'].show({
        item: {
          ...item,
          ids: item.ids || [item.id]
        },
        params: [
          { title: 'Dados da Descarga', key: 'mostra_dados_descarga', value: false },
          { title: 'Movimentações do Silo', key: 'mostra_movimentacoes_silo', value: false },
          { title: 'Justificativa de Itinerário', key: 'mostra_justificativa_itinerario', value: false },
          { title: 'Observações de Itinerário', key: 'mostra_observacao_itinerario', value: false },
          { title: 'Justificativa de Coletas', key: 'mostra_justificativa_coleta', value: false }
        ]
      });
    },

    async print({ item,  params }) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/itinerario/imprime`, {
          tipo: 'COLETA',
          ids: item.ids,
          params,
          data_ini: item.startDate,
          data_fim: item.endDate
        });

        if (data.codigo === 0) {
          throw data.mensagem;
        }

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir o itinerario!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async onGenerateKml(itinerary) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/itinerario/gerarKml`,
          { id_itinerario: itinerary.id },
          { responseType: 'blob' }
        );

        const newBlob = new Blob([data], { type: "application/xml" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = `Itinerário-${itinerary.code}.kml`;
        link.click();

        window.URL.revokeObjectURL(dataObject);
        link.remove();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao gerar o Kml!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
