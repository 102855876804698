<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <v-flex>
            <v-tabs
              v-model="tab"
              vertical
              dark
              background-color="transparent"
              class="transparent"
              @change="onTabChange"
            >
              <v-tab href="#reports_quality">
                Qualidade
              </v-tab>
              <v-tab href="#reports_visit">
                Visitas
              </v-tab>

              <v-tab-item value="reports_quality">
                <reports-quality-tab
                  ref="reports_quality"
                  v-model="reportsQuality"
                  aba="reports_quality"
                  :filter="filter"
                />
              </v-tab-item>

              <v-tab-item value="reports_visit">
                <reports-visit-tab
                  ref="reports_visit"
                  v-model="reportsVisit"
                  aba="reports_visit"
                  :filter="filter"
                />
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ReportsQualityTab from "@/Domains/Projects/PMLS/Components/ReportsQualityTab.vue";
import ReportsVisitTab from "@/Domains/Projects/PMLS/Components/ReportsVisitTab.vue";

export default {

  components: {
    ReportsQualityTab,
    ReportsVisitTab,
  },

  props: {
    value: String,
    aba: String,
    filter: Object,
  },

  data() {
    return {
      tab: "reports_quality",

      reportsVisit: [],

      reportsQuality: [],
      
    };
  },

  methods: {
    onTabChange() {
      if (this.tab == 'reports_quality' && this.$refs.reports_quality) {
        setTimeout(() => {
          console.log('Entrou Quality: ', this.tab);
          this.$refs.reports_quality.loadVisits();
        }, 1000);
      }
      
      if (this.tab == 'reports_visit' && this.$refs.reports_visit) {
        console.log('Entrou Visit: ', this.tab);
        this.$refs.reports_visit.loadVisits()
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>