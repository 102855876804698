<template>
  <div>
    <v-card
      dark
      class="v-tamanho unloads-list"
      color="transparent"
    >
      <v-card-title>
        <v-row justify="end">
          <v-col
            cols="12"
            md="4"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              @change="loadAnalysisList"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        name="Análise Periódica de Silo"
        :loading="loading"
        :headers="headers"
        :items="filteredAnalysisList"
        disable-pagination
        hide-default-footer
        mobile-breakpoint="1000"
        class="elevation-1 unloads-list-table"
        @click:row="showSiloAnalysisDialog"
      >
        <template #item.sampleTakenAt="{ item }">
          {{ item.sampleTakenAt | date('DD/MM/YYYY HH:mm') }}
        </template>

        <template #item.rawProduct.name="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ (value || '').toUpperCase() | truncate(15) }}
              </v-chip>
            </template>

            {{ value }}
          </v-tooltip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu
            v-if="hasExcludePeriodicSiloAnalysisAccess"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="showConfirmationDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <silo-periodic-analysis
      ref="siloPeriodicAnalysisDialog"
      @onAnalysisSave="onAnalysisSave"
    />

    <v-speed-dial
      fixed
      fab
      dark
      bottom
      right
      open-on-hover
      class="mr-2"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="hasCreatePeriodicSiloAnalysisAccess"
        color="blue darken-4"
        dark
        fab
        @click="showSiloAnalysisDialog"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Adicionar nova Análise
        </v-tooltip>
      </v-btn>

      <v-btn
        color="green darken-1"
        dark
        fab
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="confirmationDialog.show"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Atenção
        </v-card-title>
        <v-card-text>
          <v-form
            ref="confirmationDialogForm"
            lazy-validation
            @submit.prevent=""
          >
            <div v-html="confirmationDialog.content" />

            <v-text-field
              v-model.number="confirmationDialog.typedToken"
              :rules="[v => (v && v === confirmationDialog.confirmationToken) || 'Token de confirmação incorreto!']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialogs()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="excludeAnalysis()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<style lang="scss">
.unloads-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.unloads-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import _ from "lodash";
import moment from "moment";
import SiloPeriodicAnalysis from "@/Domains/Platform/PeriodicSiloAnalysis/Components/SiloPeriodicAnalysis.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {

  components: {
    SiloPeriodicAnalysis,
    VDateRangePicker,
  },

  filters: {

    date(value, format) {

      if (_.isEmpty(value)) {
        return '';
      }

      return moment(value).format(format);
    },

    truncate(value, length) {
      return _.truncate(value, { length });
    },

  },

  data() {
    return {
      // Loaders
      loading: false,

      // Dados brutos do backend
      list: [],

      // Filtros da tabela
      filters: {
        dateRange: [],
      },

      confirmationDialog: {
        show: false,
        analysis: {},
        content: '',
        confirmationToken: '',
      },

    };
  },

  computed: {

    headers() {
      return [
        {
          text: 'Retirada Amostra',
          value: 'sampleTakenAt',
          sort: (a, b) => moment(a).diff(moment(b)),
        },
        {
          text: 'Silo',
          value: 'silo.label',
          sort: (a, b) => parseInt(a.replace(/\D/g, '')) - parseInt(b.replace(/\D/g, '')),
        },
        {
          text: 'Matéria Prima',
          value: 'rawProduct.name',
        },
        {
          text: 'Func. Agitador',
          value: 'stirrer',
        },
        {
          text: 'Temperatura',
          value: 'analysis.temp',
        },
        {
          text: 'Acidez',
          value: 'analysis.acidity',
        },
        {
          text: 'Alizarol',
          value: 'analysis.alizarol',
        },
        {
          text: 'Crioscopia',
          value: 'analysis.cryoscopy',
        },
        {
          text: 'Gordura',
          value: 'analysis.fat',
        },
        {
          text: 'ESD',
          value: 'analysis.esd',
        },
        {
          text: 'Densidade',
          value: 'analysis.density',
        },
        {
          text: 'pH',
          value: 'analysis.ph',
        },
        {
          text: 'Proteína',
          value: 'analysis.protein',
        },
        {
          text: 'Redutase',
          value: 'analysis.reductase',
        },
        {
          text: '',
          value: 'actions',
          width: 50,
          sortable: false,
        },
      ];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    hasCreatePeriodicSiloAnalysisAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "periodic-silo-analysis-insert" && o.tipo === "COMPONENTE");
    },

    hasExcludePeriodicSiloAnalysisAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "periodic-silo-analysis-delete" && o.tipo === "COMPONENTE");
    },

    filteredAnalysisList() {
      return this.list;
    },

  },

  async mounted() {
    await this.loadAnalysisList();
  },

  methods: {

    async loadAnalysisList() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.post(
          `/silo/listaAnalisesPeriodicasSilo`,
          {
            data_inicio: moment(startDate).format("DD/MM/YYYY"),
            data_fim: moment(endDate).format("DD/MM/YYYY"),
          }
        );

        if (!_.isArray(data)) {
          throw 'Erro ao carregar análises';
        }

        this.list = data.map(analysis => {
          return {
            id: analysis.id_silo_analise_detalhe,
            sampleTakenAt: analysis.data_hora_retirada_amostra,
            rawProduct: {
              id: analysis.id_materia_prima,
              name: analysis.nome_materia_prima,
            },
            silo: {
              id: analysis.id_silo,
              label: analysis.descricao_silo,
            },
            stirrer: analysis.agitador === true ? 'Sim' : 'Não',
            analysis: {
              temp: parseFloat(analysis.temperatura) ? parseFloat(analysis.temperatura).toFixed(1) : '0.0',
              acidity: parseFloat(analysis.acidez) ? parseFloat(analysis.acidez).toFixed(2) : '0.0',
              alizarol: parseFloat(analysis.alizarol) ? parseFloat(analysis.alizarol).toFixed(0) : '0',
              cryoscopy: parseFloat(analysis.crioscopia) ? parseFloat(analysis.crioscopia).toFixed(3) : '-0.000',
              fat: parseFloat(analysis.gordura) ? parseFloat(analysis.gordura).toFixed(2) : '0.00',
              esd: parseFloat(analysis.esd) ? parseFloat(analysis.esd).toFixed(2) : '0.00',
              density: parseFloat(analysis.densidade) ? parseFloat(analysis.densidade).toFixed(1) : '0.0',
              ph: parseFloat(analysis.ph) ? parseFloat(analysis.ph).toFixed(2) : '0.00',
              protein: parseFloat(analysis.proteina) ? parseFloat(analysis.proteina).toFixed(2) : '0.00',
              reductase: parseFloat(analysis.redutase) ? parseFloat(analysis.redutase).toFixed(0) : '0',
            },
          }
        })
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as análises!",
          "Atenção"
        );

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    onAnalysisSave() {
      return this.loadAnalysisList();
    },

    async excludeAnalysis() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/silo/excluiDetalheAnaliseSilo`,
          {
            id_silo_analise_detalhe: this.confirmationDialog.analysis.id,
          }
        );

        if (!_.isObject(data)) {
          throw 'Erro ao excluir a análise';
        }

        if (data.codigo === 0) {
          throw 'Erro ao excluir a análise';
        }

        this.closeDialogs();

        return this.loadAnalysisList();
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir aa análise!",
          "Atenção"
        );

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    showSiloAnalysisDialog({ id }) {

      if (!_.isEmpty(id)) {
        return this.$refs.siloPeriodicAnalysisDialog.show({ id });
      }

      return this.$refs.siloPeriodicAnalysisDialog.show({
        silo: {
          id: null,
          label: null,
        },
        rawMaterial: {
          id: null,
          name: null,
        },
      });
    },

    showConfirmationDialog(analysis) {
      this.confirmationDialog.analysis = analysis;
      this.confirmationDialog.show = true;
      this.confirmationDialog.content = `
      Deseja realmente excluir esta análise?
        <br /> <br />
      Para continuar, por favor digite <b>EXCLUIR</b> no campo abaixo:
      `;
      this.confirmationDialog.confirmationToken = 'EXCLUIR';
    },

    closeDialogs() {
      this.confirmationDialog = {
        show: false,
        analysis: {},
        content: '',
        confirmationToken: '',
      };
    },

    exportExcel() {
      const filename = "analise_periodica_de_silos";

      this.$refs.report.exportExcel(null, filename);
    }

  },

};
</script>
