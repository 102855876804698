<template>
  <div>
    <v-card
      dark
      color="transparent"
      class="rota-container"
    >
      <v-card-title
        class="justify-center text-body-2"
        style="background: rgba(0,0,0,.5);"
      >
        <span>{{ type === 'CAPTACAO' ? 'Produtores' : 'Clientes' }}</span>
      </v-card-title>

      <v-card-text
        :class="['pa-0 text-left', { 'sidebar-fullscreen': isFullscreen }]"
      >
        <draggable
          v-bind="{
            animation: 200,
            disabled: false,
            ghostClass: 'ghost',
            delay: 100,
            delayOnTouchOnly: true,
          }"
          class="produtores-grid drag-area"
          :list="waypoints"
          :group="{
            name: 'waypoints',
          }"
          @change="onWaypointPositionChange"
        >
          <transition-group type="transition">
            <template>
              <v-hover
                v-for="(waypoint, index) in waypoints"
                :key="`${index}-${route.id}-${waypoint.id}`"
                v-slot:default="{ hover }"
                :class="{ remover: waypoint.remove }"
              >
                <v-list-item
                  v-show="!waypoint.groupingId"
                  dark
                  dense
                  class="list-item produtor-item"
                >
                  <v-list-item-avatar size="16">
                    {{ listIndex(waypoint) }}
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ waypoint.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="waypoint.description">
                      {{ waypoint.description }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-if="waypoint.details">
                      {{ waypoint.details }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-if="waypoint.status == 'PROSPECTADO'">
                      <span style="background-color: red; border-radius: 4px">
                        {{ waypoint.status }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-tooltip
                    v-if="waypoint.grouping && waypoint.grouping.length > 0"
                    left
                    attach
                    color="black"
                  >
                    <template #activator="{ on }">
                      <v-list-item-avatar
                        size="18"
                        v-on="on"
                      >
                        <v-icon>groups</v-icon>
                      </v-list-item-avatar>
                    </template>

                    <div>
                      <div
                        v-for="(producer, p) of waypoint.grouping"
                        :key="`${index}-${route.id}-${waypoint.id}-${p}`"
                        class="d-block"
                      >
                        {{ producer.codigo_laticinio }} - {{ producer.nome }}
                      </div>
                    </div>
                  </v-tooltip>

                  <template v-if="hover">
                    <v-tooltip
                      v-if="waypoint.type === 'PARADA'"
                      left
                      attach
                    >
                      <template #activator="{ on }">
                        <v-list-item-avatar size="18">
                          <v-btn
                            x-small
                            icon
                            dark
                            color="white"
                            v-on="on"
                            @click="onEditStoppingPoint(waypoint)"
                          >
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </v-list-item-avatar>
                      </template>

                      <span>Editar nome</span>
                    </v-tooltip>
                    <v-tooltip
                      v-if="waypoint.hasLocation && waypoint.type !== 'PARADA'"
                      left
                      attach
                    >
                      <template #activator="{ on }">
                        <v-list-item-avatar size="18">
                          <v-btn
                            x-small
                            icon
                            dark
                            color="white"
                            v-on="on"
                            @click="onShowLocation(waypoint)"
                          >
                            <v-icon>not_listed_location</v-icon>
                          </v-btn>
                        </v-list-item-avatar>
                      </template>

                      <span>Visualizar no mapa</span>
                    </v-tooltip>
                    <v-tooltip
                      v-if="!waypoint.hasLocation && showAddLocation"
                      left
                      attach
                    >
                      <template #activator="{ on }">
                        <v-list-item-avatar size="18">
                          <v-btn
                            x-small
                            icon
                            dark
                            color="white"
                            v-on="on"
                            @click="onAddLocation(waypoint)"
                          >
                            <v-icon>pin_drop</v-icon>
                          </v-btn>
                        </v-list-item-avatar>
                      </template>

                      <span>Adicionar localização</span>
                    </v-tooltip>
                    <v-list-item-avatar size="18">
                      <v-btn
                        x-small
                        icon
                        dark
                        color="white"
                        @click="onRemoveWaypoint(waypoint)"
                      >
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                  </template>
                  <template v-else>
                    <v-list-item-avatar size="18">
                      <v-btn
                        x-small
                        icon
                        dark
                        color="white"
                      >
                        <v-icon
                          v-if="!waypoint.hasLocation"
                          class="red--text"
                        >
                          gps_off
                        </v-icon>
                        <v-icon v-else>
                          place
                        </v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                  </template>
                </v-list-item>
              </v-hover>
            </template>
          </transition-group>
        </draggable>
      </v-card-text>
      <v-card-actions
        class="pa-0 point-selector"
      >
        <v-autocomplete
          v-model="selected"
          :loading="loading"
          :items="allWaypoints"
          dark
          class="not-rounded-top thin adicionar-produtor ma-0"
          item-text="nome"
          :label="`Adicionar ${ type === 'CAPTACAO' ? 'produtor' : 'cliente' }`"
          :filter="searchFilter"
          hide-details
          return-object
          solo
          background-color="rgba(0,0,0,.5)"
          flat
          dense
          attach
          :menu-props="{ top: true }"
          @input="onAddWaypoint"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.description }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ item.status }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-tooltip
              v-if="item.grouping && item.grouping.length > 0"
              left
              color="black"
            >
              <template #activator="{ on }">
                <v-list-item-avatar
                  size="18"
                  v-on="on"
                >
                  <v-icon>groups</v-icon>
                </v-list-item-avatar>
              </template>

              <div>
                <div
                  v-for="(producer, i) of item.grouping"
                  :key="i"
                  class="d-block"
                >
                  {{ producer.codigo_laticinio }} - {{ producer.nome }}
                </div>
              </div>
            </v-tooltip>
          </template>
        </v-autocomplete>
      </v-card-actions>
    </v-card>

    <!-- workaround para o modo fullscreen -->
    <v-overlay
      v-model="stoppingPoint.show"
      absolute
      attach
    />

    <v-dialog
      :value="stoppingPoint.show"
      :max-width="380"
      persistent
      attach
      hide-overlay
      @click:outside="closeStoppingPointDialog"
      @keydown.esc="closeStoppingPointDialog"
    >
      <v-card>
        <v-card-title class="text-h5">
          Editar Parada
        </v-card-title>
        <v-card-text
          class="pa-4"
        >
          <v-form
            ref="stoppingPointForm"
            lazy-validation
            @submit.prevent="agree"
          >
            <v-text-field
              v-model="stoppingPoint.name"
              label="Nome"
              filled
              :rules="[v => !!v || 'Informe um nome!']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            outlined
            @click.native="closeStoppingPointDialog"
          >
            CANCELAR
          </v-btn>
          <v-btn
            color="primary darken-1"
            outlined
            @click.native="saveStoppingPoint"
          >
            SALVAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.sidebar-fullscreen {
  overflow: auto;
  height: calc(100vh - 235px);
  .drag-area {
    height: 100%;
  }
}
.point-selector {
  position: relative;
}
</style>

<script>
import Draggable from "vuedraggable";
import RouteService from "@/Domains/Routes/Services/RouteService.js";

const service = new RouteService();

export default {
  components: {
    Draggable,
  },

  props: {
    type: {
      type: String,
      default: 'CAPTACAO',
      validator: (value) => ['CAPTACAO', 'COMERCIAL'].indexOf(value) !== -1
    },

    route: {
      type: Object,
      default: () => ({ id: null, description: null }),
    },

    waypoints: {
      type: Array,
    },

    allProducers: {
      type: Boolean,
      default: false
    },

    showAddLocation: Boolean,
    isFullscreen: Boolean
  },

  data() {
    return {
      // Loaders
      loading: false,

      // Model do select
      selected: null,

      // Lista de produtores/clientes
      allWaypoints: [],

      stoppingPoint: {
        show: false,
        id: null,
        name: '',
      }
    };
  },

  watch: {
    waypoints(newValue) {
      if (newValue) {
        let flag = false;

        this.waypoints.forEach((item) => {
          if (item.status == "PROSPECTADO") {
            flag = true;
          }
        });

        return this.$emit("onAddProducerProesct", flag);
      }
    }
  },

  async mounted() {
    await this.loadAllWaypoints();
  },

  methods: {
    /**
     * Carrega a lista completa de produtores
     * @todo Deve mudar para algo do tipo typeahead (buscando do servidor)
     */
    async loadAllWaypoints() {
      try {
        this.loading = false;

        if (this.type === 'CAPTACAO') {
          this.allWaypoints = await service.fromSystem(this.$store.state.settings.sistema).getAllProducers(this.allProducers);
        }
        else {
          this.allWaypoints = await service.fromSystem(this.$store.state.settings.sistema).getAllCustomers();
        }
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    /**
     * Filtro usado na busca
     */
    searchFilter(item, queryText) {
      let name = `${item.name} ${item.description} ${item.status}`.toUpperCase();

      if (item.grouping?.length > 0) {
        name += JSON.stringify(item.grouping).toUpperCase();
      }

      const searchText = queryText.toUpperCase();

      return name.includes(searchText);
    },

    /**
     * Recupera o indíce do ponto de parada na lista
     */
    listIndex(waypoint) {
      let index = this.waypoints
        .filter(item => !item.groupingId)
        .findIndex((item) => item.id === waypoint.id);

      return ++index;
    },

    /**
     * @event Object
     *
     * Evento acionado ao adicionar um ponto de parada na lista
     */
    onAddWaypoint(waypoint) {
      // primeiro verificamos se o ponto de parada já está na lista
      let alreadyAdded = this.waypoints.find((item) => item.id === waypoint.id);

      // se já estiver, ou está marcado para exclusão ou já está mostrando
      // de qualquer maneira vamos remover essa marcação
      if (alreadyAdded) {
        delete alreadyAdded.remove;

        return this.$nextTick(() => {
          this.selected = null;
        });
      }

      this.waypoints.push({
        ...waypoint,
        pending: true,
        hasLocation: waypoint.location.lat && waypoint.location.lng,
      });

      this.$emit("onAddWaypoint", waypoint);

      return this.$nextTick(() => {
        this.selected = null;
      });
    },

    /**
     * @event Object
     *
     * Evento acionado para remover um ponto de parada da lista
     */
    async onRemoveWaypoint(waypoint) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente remover <b>${waypoint.name}</b> da rota <b>${this.route.description}</b>?<br>`,
        { color: 'red', token: 'REMOVER', attach: this.isFullscreen ? this.$el : false }
      ))
      ) {
        return;
      }

      const index = this.waypoints.findIndex(item => item.id === waypoint.id);

      if (index < 0) {
        return;
      }

      if (waypoint.pending) {
        return this.waypoints.splice(index, 1);
      }

      this.$set(this.waypoints, index, {
        ...waypoint,
        remove: true,
      });

      return this.$emit("onRemoveWaypoint");
    },

    /**
     * @event Object
     *
     * Evento emitido apenas para notificar o componente pai que houve mudança no formulário
     */
    onWaypointPositionChange() {

      return this.$emit("onWaypointPositionChange");
    },

    /**
     * @event Object
     *
     * Evento acionado ao adicionar um ponto de parada na lista
     */
    onAddLocation(waypoint) {

      this.$emit("onAddLocation", waypoint);
    },

    /**
     * @event Object
     *
     * Evento emitido para editar um ponto de parada
     */
    onEditStoppingPoint(waypoint) {
      this.stoppingPoint.show = true;
      this.stoppingPoint.id = waypoint.id;
      this.stoppingPoint.name = waypoint.name;
    },

    /**
     * @event Object
     *
     * Evento emitido para visualizar uma localização no mapa
     */
    onShowLocation(waypoint) {

      this.$emit("onShowLocation", waypoint);
    },

    /**
     * @event Object
     *
     * Evento emitido ao salvar um ponto de parada
     */
    async saveStoppingPoint() {
      if (!await this.$refs.stoppingPointForm.validate()) {
        return;
      }

      const point = this.waypoints.find((item) => item.id === this.stoppingPoint.id);
      point.name = this.stoppingPoint.name;

      this.closeStoppingPointDialog()
    },

    closeStoppingPointDialog() {
      this.stoppingPoint.show = false;
      this.stoppingPoint.id = null;
      this.stoppingPoint.name = '';
    }
  },
};
</script>
