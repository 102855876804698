<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="630px"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card>
      <v-card-title class="text-h5">
        Transferência
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <div class="subtitle-1">
            {{ vehicle.plate }}
            <v-divider />
          </div>
          <v-row>
            <v-col
              v-for="tank in computedTanks"
              :key="tank.key"
            >
              <v-text-field
                :value="tank.availableVol"
                :label="tank.label"
                placeholder=" "
                :hint="`Capacidade: ${tank.capacity}`"
                persistent-hint
                readonly
                :rules="[v => !v || v >= 0 || 'Volume incorreto']"
              >
                <template #append>
                  <v-btn
                    icon
                    :color="tank.availableVol <= 0 ? 'grey lighten-1' : 'blue'"
                    @click="startTransfer(tank)"
                  >
                    <v-tooltip
                      top
                      :disabled="tank.availableVol <= 0"
                    >
                      <template #activator="{ on }">
                        <v-icon v-on="on">
                          opacity
                        </v-icon>
                      </template>

                      Clique para iniciar a transferência
                    </v-tooltip>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <div
          v-if="transferrings.length > 0"
          class="subtitle-1"
        >
          Transferindo
          <v-divider />
        </div>
        <transition-group name="scroll-y-transition">
          <v-form
            v-for="(transferring, idx) in transferrings"
            :key="transferring.id"
            ref="transferringForms"
            lazy-validation
            @submit.prevent="addTransfer(idx)"
          >
            <v-row class="mt-0">
              <v-col
                class="py-0 flex-grow-1"
              >
                <v-row>
                  <v-col
                    cols="4"
                    class="pb-0"
                  >
                    <masked-text-field
                      v-model="transferring.vol"
                      :label="transferring.origin.label"
                      :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                      return-object
                      filled
                      unmask
                      dense
                      :rules="[v => !!v && v >= 0 || 'Campo obrigatório']"
                    />
                  </v-col>
                  <v-col
                    cols="4"
                    class="pb-0"
                  >
                    <v-select
                      v-model="transferring.equipment"
                      label="Reboque"
                      :items="trailers"
                      item-value="id"
                      item-text="plate"
                      return-object
                      filled
                      dense
                      :disabled="trailers.length === 1"
                      :rules="[v => !!v || 'Campo obrigatório']"
                      @change="transferring.destination = null"
                    />
                  </v-col>
                  <v-col
                    cols="4"
                    class="pb-0"
                  >
                    <v-select
                      v-model="transferring.destination"
                      label="Tanque Destino"
                      :items="transferring.equipment ? transferring.equipment.tanks : []"
                      item-value="key"
                      item-text="label"
                      return-object
                      filled
                      dense
                      :rules="[v => !!v || 'Campo obrigatório']"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                class="flex-grow-0 align-self-center text-center px-0 pt-0 d-flex"
              >
                <v-btn
                  icon
                  color="green"
                  @click="addTransfer(idx)"
                >
                  <v-icon>done_all</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="red"
                  @click="cancelTransfer(idx)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </transition-group>

        <div class="subtitle-1">
          Transferências Realizadas
          <v-divider />
        </div>
        <v-tabs>
          <v-tab
            v-for="(transfer, id) in transfers"
            :key="id"
          >
            {{ transfer.equipment.plate }}
          </v-tab>

          <v-tab-item
            v-for="(transfer, id) in transfers"
            :key="id"
          >
            <v-card flat>
              <v-data-table
                :headers="headers"
                title="Transferências Realizadas"
                :items="transfer.transfers"
                disable-pagination
                disable-sort
                disable-filtering
                hide-default-footer
                class="transfers-table"
              >
                <template #[`item.origem`]="{ value }">
                  Tanque {{ value }}
                </template>
                <template #[`item.destino`]="{ value }">
                  Tanque {{ value }}
                </template>
                <template #[`item.volume`]="{ value }">
                  {{ value }} L
                </template>
                <template #[`item.data_hora`]="{ value }">
                  {{ dateFormat(value, 'DD/MM HH:mm') }}
                </template>
                <template #[`item.action`]="{ index }">
                  <div class="action">
                    <v-btn
                      icon
                      color="red lighten-1"
                      @click="deleteTransfer(id, index)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="grey darken-1"
          outlined
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="hasChanges"
          color="orange darken-1"
          outlined
          @click="discardChanges"
        >
          Desfazer alterações
        </v-btn>
        <v-btn
          color="blue"
          outlined
          :disabled="!hasChanges || transferrings.length > 0"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">

.transfers-table {
  table {
    tbody {
      .action {
        display: none;
      }
      tr:hover .action {
        display: block;
      }
    }
  }
}

</style>

<script>
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

import moment from "moment-timezone";
import isObject from "lodash/fp/isObject"
import cloneDeep from "lodash/fp/cloneDeep"
import isEqual from "lodash/fp/isEqual"
import { v4 as uuidv4 } from "uuid";

export default {

  components: {
    MaskedTextField,
  },

  data() {
    return {
      dialog: false,

      itineraryId: null,
      vehicle: {},
      transfers: [],
      tanks: [],
      trailers: [],
      // Usados para descartar as alterações
      originalTanks: [],
      originalTransfers: [],

      transferrings: [],

      headers: [
        { text: 'Origem', value: 'origem' },
        { text: 'Destino', value: 'destino' },
        { text: 'Volume', value: 'volume' },
        { text: 'Data', value: 'data_hora', width: 100 },
        { text: '', value: 'action', width: 35, cellClass: 'px-0', align: 'right' },
      ],
    }
  },

  computed: {
    hasChanges() {
      return this.transferrings.length > 0 || !isEqual(this.transfers, this.originalTransfers)
    },
    computedTanks() {
      return this.tanks.map(tank => ({
        ...tank,
        availableVol: tank.vol - this.transferrings
          .filter(transfer => transfer.origin.key === tank.key)
          .reduce((acc, transfer) => (acc + (parseInt(transfer.vol || 0))), 0)
      }))
    }
  },

  methods: {

    async show({ itineraryId, vehicle, tanks, transfers, trailers }) {
      this.itineraryId = itineraryId;
      this.vehicle = vehicle;
      this.originalTanks = tanks;
      this.tanks = cloneDeep(tanks);
      this.originalTransfers = transfers;
      this.transfers = cloneDeep(transfers);
      this.trailers = trailers;
      this.transferrings = [];
      this.dialog = true;
    },

    startTransfer(tank) {
      if (tank.availableVol <= 0) {
        return;
      }

      /**
       * Select first trailer if already exists transfer
       */
      let equipment = null;
      if (this.transfers.length > 0) {
        equipment = this.trailers.find(vehicle => vehicle.id === this.transfers[0].equipment.id);
      }

      console.log({ equipment })

      this.transferrings.push({
        id: this.transferrings.length + 1,
        origin: {
          key: tank.key,
          label: tank.label,
        },
        vol: tank.availableVol,
        equipment,
        destination: null
      })
    },

    async addTransfer(idx) {
      try {
        if (!await this.$refs.transferringForms[idx].validate() || !await this.$refs.form.validate()) {
          return;
        }
        const t = this.transferrings[idx];

        const transfer = {
          data_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
          origem: t.origin.key.replace('tanque', ''),
          destino: t.destination.key.replace('tanque', ''),
          latitude: null,
          longitude: null,
          volume: parseFloat(t.vol)
        };

        const trailerTransfer = this.transfers.find(({ equipment }) => equipment.id === t.equipment.id);

        if (trailerTransfer) {
          trailerTransfer.transfers.push(transfer);
        }
        else {
          this.transfers.push({
            id: uuidv4(),
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            equipment: {
              id: t.equipment.id,
              plate: t.equipment.plate,
            },
            equipmentOrigin: {
              id: this.vehicle.id,
              plate: this.vehicle.plate,
            },
            transfers: [transfer],
          })
        }

        const tankIdx = this.tanks.findIndex(tank => tank.key === t.origin.key);

        this.tanks.splice(tankIdx, 1, {
          ...this.tanks[tankIdx],
          vol: this.tanks[tankIdx].vol - transfer.volume
        });

        this.transferrings.splice(idx, 1);
      } catch (error) {
        this.$snotify.error(`Erro: ${error}`, "Atenção");
        console.error(error);
      }
    },

    cancelTransfer(idx) {
      this.transferrings.splice(idx, 1);
    },

    deleteTransfer(id, idx) {
      const transfer = this.transfers[id].transfers[idx];

      const tankIdx = this.tanks.findIndex(tank => tank.key === `tanque${transfer.origem}`);

      this.tanks.splice(tankIdx, 1, {
        ...this.tanks[tankIdx],
        vol: this.tanks[tankIdx].vol + transfer.volume
      });

      this.transfers[id].transfers.splice(idx, 1);

      if (this.transfers[id].transfers.length === 0) {
        this.transfers.splice(id, 1);
      }
    },

    discardChanges() {
      this.transfers = cloneDeep(this.originalTransfers);
      this.tanks = cloneDeep(this.originalTanks);
      this.transferrings = [];
      this.$refs.form.resetValidation();
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }
        this.$root.$progressBar.loading()

        const payload = {
          id_itinerario: this.itineraryId,
          transferencias: this.transfers.map(transfer => ({
            id: transfer.id,
            placa_origem: this.vehicle.plate,
            placa_destino: transfer.equipment.plate,
            id_equipamento_origem: this.vehicle.id,
            id_equipamento_destino: transfer.equipment.id,
            transferencias: transfer.transfers
          }))
        };

        const { data } = await this.$axios.post(
          `/itinerario/salvaTransferencias`,
          payload
        );

        if (!isObject(data) || !data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$emit('save');
        this.close();

      } catch (error) {
        this.$snotify.error(`Erro ao salvar transferências: ${error}`, "Atenção");
        console.error(error);
      } finally {
        this.$root.$progressBar.hide()
      }
    },

    close() {
      this.dialog = false;
    },

    dateFormat: (value, opts) => moment(value).format(opts),
  },
}
</script>
