<template>
  <v-dialog
    v-model="show"
    scrollable
    width="80%"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        <span class="text-h6">Entrada de Ordem de Produção nº {{ order.code }}</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div style="min-height:400px">
          <v-row
            no-gutters
            style="border-bottom: 1px #eee solid;"
          >
            <v-col class="pt-1 pb-0">
              <v-text-field
                :value="order.item?.description"
                :label="isRawMaterial ? 'Matéria Prima' : 'Produto'"
                disabled
              />
            </v-col>
            <v-col
              v-if="hasPlannedQuantityAccess"
              class="pt-1 pb-0"
            >
              <masked-text-field
                :value="order.quantity"
                label="Qtde. Planejada"
                unmask
                :mask="masks.float"
                suffix="LT"
                disabled
              />
            </v-col>
            <v-col class="pt-1 pb-0">
              <masked-text-field
                :value="order.accomplishedQuantity"
                label="Qtde. Realizada"
                unmask
                :mask="masks.float"
                :suffix="order.item?.measurement || 'LT'"
                disabled
              />
            </v-col>
            <v-col
              v-if="!isRawMaterial && canEdit"
              class="px-0 d-flex flex-column justify-center"
              style="max-width: 149px;"
            >
              <v-btn
                color="primary"
                outlined
                @click="addEntry()"
              >
                Efetuar entrada
              </v-btn>
            </v-col>
          </v-row>

          <template v-if="isRawMaterial && canEdit">
            <span class="text-h6 d-block mt-4">Silos</span>

            <v-divider class="mb-4" />

            <v-row>
              <v-col
                v-for="silo in availableSilos"
                :key="silo.label"
                style="max-width:220px"
              >
                <silo-card
                  :silo="silo"
                  :disabled="!canEdit"
                  @click="onSiloClick(silo)"
                />
              </v-col>
              <v-col
                v-if="availableSilos.length === 0"
                cols="12"
                class="text-center"
              >
                Nenhum silo disponível para efetuar entradas
              </v-col>
            </v-row>
          </template>

          <template v-if="order.lots.length > 0">
            <span class="text-h6 d-block mt-4">Lotes</span>

            <v-divider class="mb-4" />

            <v-data-table
              :headers="stocksHeaders"
              :items="order.lots"
              light
              :items-per-page="-1"
              disable-pagination
              hide-default-footer
            >
              <template #[`item.manufacturingDate`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>
              <template #[`item.expirationDate`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>
              <template #[`item.quantity`]="{ value }">
                {{ formatNumber(value) }}
              </template>
              <template #[`item.reservedQuantity`]="{ value }">
                {{ formatNumber(value) }}
              </template>
              <template #[`item.availableQuantity`]="{ value }">
                {{ formatNumber(value) }}
              </template>
              <template #[`item.lotStatus`]="{ value }">
                <v-chip
                  v-if="value"
                  small
                  dark
                  :color="getLotStatusColor(value)"
                >
                  {{ value }}
                </v-chip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  v-if="hasEditAccess"
                  icon
                  @click.stop="editStock(item)"
                >
                  <v-icon>
                    edit
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </template>

          <span class="text-h6 d-block mt-4">Registros</span>

          <v-divider class="mb-4" />

          <v-data-table
            :headers="headers"
            :items="records"
            disable-pagination
            disable-sort
            disable-filtering
            hide-default-footer
          >
            <template #[`item.date`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
            </template>
            <template #[`item.quantity`]="{ item }">
              <span
                :class="{
                  'green--text': item.operation === 'ENTRADA',
                  'red--text': item.operation === 'SAIDA',
                }"
              >
                {{ formatNumber(item.quantity) }} {{ item.measurement }}
              </span>
            </template>
            <template #[`item.transferredVol`]="{ value }">
              {{ formatNumber(value) }} L
            </template>
            <template #[`item.transferredAt`]="{ value }">
              <v-chip x-small>
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </v-chip>
            </template>
            <template #[`item.manufacturingDate`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.expirationDate`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.rawMaterial.description`]="{ value }">
              <v-chip
                v-if="value"
                x-small
              >
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.action`]="{ item }">
              <v-tooltip
                v-if="hasChargebackAccess"
                top
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click.stop="removeTransfer(item)"
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </template>
                Estornar
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="form.show"
      scrollable
      max-width="630px"
    >
      <v-card class="order-dialog">
        <v-card-title>
          Entrada de Ordem de Produção nº {{ order.code }}
          <v-spacer />
          <v-btn
            icon
            small
            depressed
            @click="form.show = false"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text
          class="pt-5"
          style="max-height: calc(100vh - 250px);"
        >
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-text-field
                  :value="order.item?.description"
                  label="Produto"
                  disabled
                />
              </v-col>
              <v-col
                v-if="hasPlannedQuantityAccess"
                cols="6"
                class="pt-0"
              >
                <masked-text-field
                  :value="order.quantity"
                  label="Quantidade Planejada"
                  :mask="masks.float"
                  unmask
                  :suffix="order.item?.measurement"
                  disabled
                />
              </v-col>
              <v-col
                cols="6"
                class="pt-0 d-flex"
              >
                <v-text-field
                  v-model="form.lotNumber"
                  label="Nº Lote"
                  prepend-inner-icon="subtitles"
                  :disabled="!!form.lotFormat || isRawMaterial"
                  class="flex-grow-1"
                  @change="setExpirationDate()"
                >
                  <template #append>
                    <v-tooltip
                      v-if="!form.lotNumber && order.item?.lotFormat"
                      top
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          @click="generateLotNumber()"
                        >
                          <v-icon small>
                            autorenew
                          </v-icon>
                        </v-btn>
                      </template>
                      Gerar Lote
                    </v-tooltip>
                  </template>
                </v-text-field>
                <masked-text-field
                  v-if="form.lotNumber && !isRawMaterial"
                  v-model="form.lotNumberSuffix"
                  prefix="-"
                  label="Variação Lote"
                  placeholder="Tina, Tanque, etc."
                  maxlength="2"
                  :mask="masks.integer"
                  unmask
                  class="flex-grow-0 ml-2"
                  style="max-width: 100px;"
                />
              </v-col>
              <v-col
                v-if="!isRawMaterial"
                cols="6"
                class="pt-0"
              >
                <date-text-field
                  v-model="form.manufacturingDate"
                  label="Fabricação"
                  manual
                  :disabled="!form.lotNumber"
                  @input="setExpirationDate()"
                />
              </v-col>
              <v-col
                v-if="!isRawMaterial"
                cols="6"
                class="pt-0"
              >
                <date-text-field
                  v-model="form.expirationDate"
                  label="Validade"
                  manual
                  max=""
                  :disabled="!form.lotNumber"
                  :rules="!!form.lotNumber ? [v => !!v || 'Campo obrigatório!'] : []"
                />
              </v-col>
              <v-col
                cols="6"
                class="pt-0"
              >
                <v-select
                  v-if="isRawMaterial"
                  v-model="form.siloId"
                  :items="availableSilos"
                  label="Silo"
                  item-value="id"
                  item-text="label"
                  placeholder=" "
                  disabled
                  prepend-inner-icon="panorama_vertical_select"
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
                <warehouse-select
                  v-else-if="form.show"
                  v-model="form.warehouseId"
                  label="Depósito"
                  auto-select-default
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col
                cols="6"
                class="pt-0"
              >
                <masked-text-field
                  v-model="form.quantity"
                  label="Quantidade"
                  :mask="masks.float"
                  unmask
                  inputmode="numeric"
                  :suffix="order.item?.measurement"
                  :rules="[
                    v => !!v || 'Campo obrigatório!',
                    validateQuantity()
                  ]"
                  validate-on-blur
                />
              </v-col>
              <v-col
                v-if="!isRawMaterial"
                cols="6"
                class="pt-0"
              >
                <v-select
                  v-model="form.lotStatus"
                  :items="[
                    { text: 'LIBERADO', value: 'LIBERADO' },
                    { text: 'RESTRITO', value: 'RESTRITO' },
                    { text: 'SUSPENSO', value: 'SUSPENSO' },
                  ]"
                  label="Status Lote"
                />
              </v-col>
              <v-col
                v-if="!isRawMaterial && order.item.shelfLifeQuantity"
                cols="6"
                class="pt-0"
              >
                <v-text-field
                  :value="order.item.shelfLifeQuantity"
                  label="Reserva Shelf-Life"
                  disabled
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="form.show = false"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <edit-stock-dialog
      ref="editStockDialog"
      hide-price
      @save="onEditStockSaved"
    />
  </v-dialog>
</template>

<script>
import EditStockDialog from '@/Domains/Registrations/Item/Components/EditStockDialog.vue';
import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import DateTextField from '@/Support/Components/DateTextField.vue';
import SiloCard from "@/Domains/Industry/ProductionOrder/Components/SiloCard.vue";

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';
import { useRangeValidator } from '@/Support/Composables/validator.js'

import get from 'lodash/get';
import moment from 'moment'

const { validateRule } = useRangeValidator();

export default {

  components: {
    EditStockDialog,
    WarehouseSelect,
    MaskedTextField,
    DateTextField,
    SiloCard,
  },

  props: {
    value: Boolean,
    orderId: String,
    step: String,
  },

  data() {
    return {
      order: {
        item: {},
        transfers: [],
        records: [],
        lots: [],
      },

      silos: [],

      form: {
        show: false,
      },

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
        integer: { mask: Number, min: 0, scale: 0, signed: false },
      },

      stocksHeaders: [
        { text: 'Lote', value: 'lotNumber' },
        { text: 'Fabricação', value: 'manufacturingDate', align: 'center' },
        { text: 'Validade', value: 'expirationDate', align: 'center' },
        { text: 'Qtde. Física', value: 'quantity', align: 'center' },
        { text: 'Qtde. Reservada (Shelf Life)', value: 'reservedQuantity', align: 'center' },
        { text: 'Qtde. Disponível', value: 'availableQuantity', align: 'center' },
        { text: 'Status Lote', value: 'lotStatus', align: 'center' },
        { text: '', value: 'actions', align: 'center' },
      ]
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    canEdit() {
      return ['PLANEJADO', 'LIBERADO'].includes(this.order.status)
    },

    isRawMaterial() {
      return this.order.item.isRawMaterial;
    },

    availableSilos() {
      if (!this.isRawMaterial) {
        return [];
      }

      return this.silos.filter(silo => !silo.rawProduct.id || this.order.item.id === silo.rawProduct.id);
    },

    headers() {
      if (this.isRawMaterial) {
        return [
          { text: 'Silo', value: 'silo.description' },
          { text: 'Entrada', value: 'transferredAt' },
          { text: 'Volume', value: 'transferredVol' },
          { text: 'Nº Lote', value: 'batchNumber' },
          { text: 'Matéria Prima', value: 'rawMaterial.description' },
          { text: '', value: 'action', width: 30, sortable: false },
        ];
      }

      return [
        { text: 'Produto', value: 'product.name' },
        { text: 'Data e Hora', value: 'date', align: 'center' },
        { text: 'Pessoa', value: 'person' },
        { text: 'Qtde.', value: 'quantity' },
        { text: 'Nº Lote', value: 'lotNumber' },
        { text: 'Fabricação', value: 'manufacturingDate' },
        { text: 'Validade', value: 'expirationDate' },
      ];
    },

    records() {
      if (this.isRawMaterial) {
        return this.order.transfers.filter(transfer => transfer.type === 'ENTRADA_PRODUCAO' && transfer.rawMaterial.id === this.order.item.id);
      }

      return this.order.records.filter(record => record.product.id === this.order.item.id);
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasEditAccess() {
      if (!this.canEdit) {
        return false
      }
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-edicao-apontamento' && o.tipo === 'COMPONENTE');
    },
    hasChargebackAccess() {
      if (!this.canEdit) {
        return false
      }
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-exclusao-apontamento' && o.tipo === 'COMPONENTE');
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.order = {
          item: {},
          transfers: [],
          records: [],
          lots: [],
        };
        this.loadOrder();
      }
    }
  },

  methods: {
    async loadOrder() {
      try {
        this.$root.$progressBar.loading();

        this.order = await api.show(this.orderId);

      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao carregar a ordem de produção');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
        this.loadSilos();
      }
    },

    async loadSilos() {
      if (!this.isRawMaterial) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/industry/silo`);

        this.silos = data
          .map(silo => ({
            id: silo.id_silo,
            label: silo.descricao,
            lotNumber: silo.numero_lote,
            vol: silo.volume_atual,
            capacity: silo.volume_total,
            volume: null,
            rawProduct: {
              id: silo.id_materia_prima,
              name: silo.nome_materia_prima,
            },
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

      } catch (e) {
        console.warn(e);

        const message = get(e, 'response.data.message', 'Erro ao carregar os silos');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onSiloClick(silo) {
      this.form = {
        show: true,
        warehouseId: null,
        siloId: silo.id,
        lotNumber: silo.lotNumber || null,
        lotFormat: null,
        manufacturingDate: moment().format('YYYY-MM-DD'),
        quantity: null,
        expirationDate: this.order.item.expirationDate && silo.lotNumber ? moment().add(this.order.item.expirationDate, 'days').format('YYYY-MM-DD') : null,
      };
      this.setExpirationDate();
      this.$refs.form && this.$refs.form.resetValidation();
    },

    addEntry() {
      this.form = {
        show: true,
        warehouseId: null,
        siloId: null,
        lotNumber: this.order.lotNumber,
        lotFormat: this.order.lotFormat,
        manufacturingDate: moment().format('YYYY-MM-DD'),
        quantity: null,
        expirationDate: null,
      };
      this.setExpirationDate();
      this.$refs.form && this.$refs.form.resetValidation();
    },

    setExpirationDate() {
      const { expirationDate } = this.order.item;

      if (!expirationDate) {
        return;
      }

      if (!this.form.lotNumber || !this.form.manufacturingDate) {
        this.form.expirationDate = null;
        return;
      }

      this.form.expirationDate = moment(this.form.manufacturingDate).add(expirationDate, 'days').format('YYYY-MM-DD');
    },

    close() {
      this.show = false;
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const payload = {
          id_deposito: this.form.warehouseId,
          id_silo: this.form.siloId,
          quantidade: this.form.quantity,
          numero_lote: this.form.lotNumber,
          numero_lote_sufixo: this.form.lotNumberSuffix,
          status_lote: this.form.lotStatus,
          data_fabricacao: this.form.manufacturingDate,
          data_validade: this.form.expirationDate,
          reserva_shelf_life: this.order.item.shelfLifeQuantity,
        };

        if (this.isRawMaterial) {
          payload.id_materia_prima = this.order.item.id;
          payload.nome_materia_prima = this.order.item.name;
        }

        await api.production(this.order.id, payload);

        this.$emit('save');
        this.$snotify.success('Produção efetuada com sucesso', 'Sucesso');
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao produzir');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
        this.form.show = false;
        this.loadOrder();
      }
    },

    async generateLotNumber() {
      try {
        this.$root.$progressBar.saving();

        const { data } = await api.generateLot(this.order.id);

        this.order.lotNumber = data.numero_lote;
        this.order.lotFormat = data.formato_lote;
        this.form.lotNumber = data.numero_lote;
        this.form.lotFormat = data.formato_lote;

        this.$snotify.success('Lote gerado com sucesso', 'Sucesso');
      } catch (e) {
        this.$snotify.error('Erro ao gerar lote', 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    editStock(stock) {
      this.$refs.editStockDialog.show({
        stock: { ...stock },
        product: {
          name: this.order.item?.name,
        },
      })
    },

    onEditStockSaved() {
      this.loadOrder();
      return this.$emit('save');
    },

    async removeTransfer(item) {
      if (!this.canEdit) {
        return;
      }

      if (!this.isRawMaterial) {
        return;
      }

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente estornar esta movimentação?<br><br>`, { color: 'red', token: 'ESTORNAR' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.post(`/industry/silo/chargeback-movement`, {
          id: item.movementId,
        });

        this.$snotify.success('Estorno efetuado com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao estornar!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    validateQuantity() {
      if (!this.order.tolerance?.max) {
        return true;
      }

      const value = this.form.quantity;
      const rule = {
        max: this.order.tolerance.max - this.order.accomplishedQuantity,
      };

      return validateRule(value, rule);
    },

    getLotStatusColor(status) {
      const colors = {
        'SUSPENSO': 'red',
        'RESTRITO': 'orange',
        'LIBERADO': 'green',
      }

      return colors[status] || 'grey';
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
  }

}
</script>
