<template>
  <div>
    <v-col
      cols="12"
      class="px-0 pt-0"
    >
      <card-title-settings>
        Parâmetros de Visita
      </card-title-settings>
    </v-col>
    <v-card
      dark
      outlined
      color="rgba(255, 255, 255, 0.08)"
    >
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-select
              v-model="velocimetro_itinerario_tecnico"
              :items="textRules"
              label="Foto do Odômetro"
              dense
            />
          </v-col>

          <v-col
            v-if="!isNewApp"
            cols="3"
          >
            <v-select
              v-model="mostra_coleta_amostras"
              :items="booleanRules"
              label="Habilitar lançamento de coleta de amostra"
              dense
            />
          </v-col>

          <v-col
            v-if="!isNewApp"
            cols="3"
          >
            <v-select
              v-model="mostra_recomendacoes_abertas"
              :items="booleanRules"
              label="Habilitar menu de recomendações"
              dense
            />
          </v-col>

          <v-col
            cols="3"
          >
            <v-select
              v-model="preencher_numero_amostra"
              :items="sampleCollectTextRules"
              label="Preencher número de amostra automaticamente"
              dense
            />
          </v-col>

          <v-col cols="3">
            <v-select
              v-model="mostra_edicao_localizacao_produtor"
              :items="booleanRules"
              label="Habilitar edição de localização do produtor"
              dense
            />
          </v-col>

          <v-col cols="3">
            <v-select
              v-model="lancamento_exame_obrigatorio"
              :items="booleanRules"
              label="Habilitar lançamento obrigatório de exames de prospectados"
              dense
            />
          </v-col>

          <v-col
            cols="3"
          >
            <v-select
              v-model="visita_com_qr_code"
              :items="qrCodeTextRules"
              label="Visita com QR code"
              dense
            />
          </v-col>

          <v-col cols="3">
            <v-select
              v-model="prazo_recomendacoes_obrigatorio"
              :items="booleanRules"
              label="Prazo para recomendações obrigatório"
              dense
            />
          </v-col>

          <v-col cols="3">
            <v-select
              v-model="foto_justificativa_visita"
              :items="booleanRules"
              label="Foto na justificativa obrigatório"
              dense
            />
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="tipo_visita_padrao"
              :items="tipo_visita_padrao_options"
              dense
              multiple
              label="Tipos de visitas padrões permitidas"
              append-icon
              chips
              deletable-chips
            />
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="tipo_visita_prospecao"
              :items="tipo_visita_prospecao_options"
              dense
              multiple
              label="Tipos de visitas de prospecções permitidas"
              append-icon
              chips
              deletable-chips
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="saveQualitySettings()"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações do Aplicativo</span>
    </v-tooltip>

    <v-overlay v-model="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

import _ from "lodash";
import VisitsService from "@/Domains/Visits/Services/VisitsService.js";

const service = new VisitsService();

export default {
  components: {
    CardTitleSettings
  },

  data() {
    return {

      loading: false,

      //TODO: Remover configuração [isNewApp] e parâmetros [mostra_coleta_amostras, mostra_recomendacoes_abertas] quando todos estiverem usando a versão >= 2.0.9
      isNewApp: false,

      tipo_visita_padrao: [],
      tipo_visita_prospecao: [],

      mostra_coleta_amostras: null,
      mostra_recomendacoes_abertas: null,
      preencher_numero_amostra: null,
      velocimetro_itinerario_tecnico: null,
      mostra_edicao_localizacao_produtor: null,
      lancamento_exame_obrigatorio: null,
      prazo_recomendacoes_obrigatorio: null,
      foto_justificativa_visita: null,
      visita_com_qr_code: null,

      textRules: [
        { text: 'Ativo', value: 'OBRIGATORIO' },
        { text: 'Inativo', value: 'NAO_PERMITIDO' },
      ],

      mostra_recomendacoes_abertas_options: [
        { text: 'Ativo', value: true },
        { text: 'Inativo', value: false },
      ],

      booleanRules: [
        { text: 'Ativo', value: true },
        { text: 'Inativo', value: false },
      ],

      qrCodeTextRules: [
        { text: 'Seleciona com QrCode ou Lista', value: 'PERMITIDO' },
        { text: 'Somente Lista', value: 'NAO_PERMITIDO' },
        { text: 'Somente QrCode', value: 'OBRIGATORIO' },
      ],

      sampleCollectTextRules: [
        { text: 'Auto-incremento', value: 'AUTOINCREMENTO' },
        { text: 'Código do Produtor', value: 'CODIGO_PRODUTOR' },
        { text: 'Inativo', value: null },
      ],

      tipo_visita_padrao_options: service.standardTypesFilter,
      tipo_visita_prospecao_options: service.prospectionTypesFilter,

    };
  },

  mounted() {
    this.loadQualitySettings();
  },

  methods: {
    /**
     * carregar configuracoes do APP
     */
    async loadQualitySettings() {
      try {
        const { data } = await this.$axios.post(`/configuracao/listaConfiguracoesLaticinio`);

        if (_.isEmpty(data)) {
          return false;
        }

        this.handleData(data);

      } catch (e) {
        console.error(e);
      }
    },

    handleData(data) {
      this.isNewApp = data.app_visita_com_submodulos;
      this.tipo_visita_padrao = data.tipos_visita_padrao;
      this.mostra_coleta_amostras = data.mostra_coleta_amostras;
      this.tipo_visita_prospecao = data.tipos_visita_prospeccao;
      this.mostra_recomendacoes_abertas = data.mostra_recomendacoes_abertas;
      this.velocimetro_itinerario_tecnico = data.velocimetro_itinerario_tecnico;
      this.preencher_numero_amostra = data.ativa_autoincremento_amostra_visita ? 'AUTOINCREMENTO' : (data.ativa_codigo_produtor_amostra_visita ? 'CODIGO_PRODUTOR' : null);
      this.mostra_edicao_localizacao_produtor = data.mostra_edicao_localizacao_produtor;
      this.lancamento_exame_obrigatorio = data.lancamento_exame_obrigatorio;
      this.prazo_recomendacoes_obrigatorio = data.prazo_recomendacoes_obrigatorio;
      this.foto_justificativa_visita = data.foto_justificativa_visita;
      this.visita_com_qr_code = data.visita_com_qr_code;
    },

    /**
     * salvar configuracoes de qualidade
     */
    async saveQualitySettings() {
      try {
        this.loading = true;

        const payLoad = {
          configuracaoQualidade: JSON.stringify([
            {
              mostra_coleta_amostras: this.isNewApp ? true : this.mostra_coleta_amostras,
              tipos_visita_padrao: this.tipo_visita_padrao,
              tipos_visita_prospeccao: this.tipo_visita_prospecao,
              mostra_recomendacoes_abertas: this.isNewApp ? true : this.mostra_recomendacoes_abertas,
              velocimetro_itinerario_tecnico: this.velocimetro_itinerario_tecnico,
              ativa_autoincremento_amostra_visita: this.preencher_numero_amostra === 'AUTOINCREMENTO',
              ativa_codigo_produtor_amostra_visita: this.preencher_numero_amostra === 'CODIGO_PRODUTOR',
              mostra_edicao_localizacao_produtor: this.mostra_edicao_localizacao_produtor,
              lancamento_exame_obrigatorio: this.lancamento_exame_obrigatorio,
              prazo_recomendacoes_obrigatorio: this.prazo_recomendacoes_obrigatorio,
              foto_justificativa_visita: this.foto_justificativa_visita,
              visita_com_qr_code: this.visita_com_qr_code,
            },
          ]),

        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          this.$qs.stringify(payLoad)
        );

        data = _.head(data);

        if (_.get(data, 'codigo') !== 1) {
          throw new Error(data);
        }

        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.warn(error);
      } finally {
        this.$store.dispatch('updateStoreSetting');
        this.loading = false;
      }
    },
  },
};
</script>
