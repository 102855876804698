
const analysisDictionary = {
  temperatura: 'temp',
  acidez: 'acidity',
  crioscopia: 'cryoscopy',
  porcentagem_agua: 'waterPercentage',
  volume_agua: 'waterLiters',
  densidade: 'density',
  esd: 'esd',
  est: 'est',
  gordura: 'fat',
  proteina: 'protein',
  ph: 'ph',
  sng: 'sng',
  alizarol: 'alizarol',
  antibiotico: 'antibiotic',
  betalactamico: 'betaLactams',
  tetraciclina: 'tetracycline',
  sulfonamida: 'sulphonamide',
  cloretos: 'chlorides',
  mastite: 'mastitis',
  soda: 'sodiumHydroxide',
  bicarbonato: 'bicarbonateOfSoda',
  formol: 'formol',
  peroxido: 'peroxide',
  cloro: 'chlorine',
  amido: 'starch',
  sacarose: 'sucrose',
  neutralizantes: 'neutralising',
  reconstituintes: 'restoratives',
  conservantes: 'conservatives',
  sensorial: 'sensorial',
  quinolonas: 'quinolones',
  aminoglicosideos: 'aminoglykosides',
  macrolideos: 'macrolides',
  anfenicois: 'anfenicois',
  fosfatase: 'alkalinePhosphatase',
  solidos_totais: 'totalSolids',
  brix: 'brix',
  alizarol_qualit: 'alizarolQualit',
  alcool: 'alcohol',
  lactose: 'lactose',
  grumos: 'clumps',
  coagulos: 'clots',
  redutase: 'reductase',
  alcalino: 'alkali',
  outros: 'others',
  peroxidase: 'peroxidase',
  solubilidade: 'solubilidade',
  base_seca: 'base_seca',
  neomicina: 'neomicina',
  numero_lacre: 'numero_lacre',
  cefalosporina: 'cefalosporina',
  outras_especies: 'otherSpecies',
  ureia: 'ureia',
};

export default analysisDictionary;
