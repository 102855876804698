<template>
  <div class="packing-weighing">
    <v-form ref="formRef">
      <v-row class="mt-2">
        <v-col
          cols="12"
          sm="6"
          class="py-0"
        >
          <v-select
            v-if="form.shippingId"
            v-model="form.item"
            :items="products"
            label="Produto *"
            prepend-inner-icon="inventory"
            item-value="id"
            item-text="description"
            return-object
            outlined
            :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
            @change="onShippingItemChange"
          />
          <item-autocomplete-filter
            v-else
            v-model="form.item"
            label="Produto *"
            prepend-inner-icon="inventory"
            return-object
            outlined
            show-other-measurements
            :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
            @change="onItemChange"
          />
        </v-col>
        <v-col
          class="py-0"
        >
          <v-autocomplete
            v-model="form.stock"
            label="Lote *"
            :items="stocks"
            :disabled="!form.item?.id"
            :rules="[v => (!!v && !!v.id) || 'Campo obrigatório!']"
            item-value="id"
            item-text="description"
            return-object
            outlined
            prepend-inner-icon="subtitles"
          >
            <template
              v-if="form.item?.id && !form.shippingId"
              #append-item
            >
              <v-divider class="mt-2" />
              <v-row no-gutters>
                <v-col>
                  <v-btn
                    block
                    outlined
                    @click="addLot"
                  >
                    Cadastrar Lote
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          class="py-0"
        >
          <masked-text-field
            v-model="form.quantity"
            label="Quantidade *"
            :mask="masks.integer"
            unmask
            outlined
            :suffix="form.item?.defaultMeasurement"
            :disabled="!!form.item?.measurementId"
            prepend-inner-icon="iso"
            :hint="form.shippingId && form.stock && (form.stock.quantity - form.stock.packingQuantity) > 0 ? `Quantidade disponível: ${form.stock.quantity - form.stock.packingQuantity}` : undefined"
            :rules="[
              v => !!v && form.quantity > 0 || 'Campo obrigatório!',
              validateShippingQuantity(),
            ]"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="5"
          sm
          class="py-0"
        >
          <span class="headline">PESO BRUTO</span>
          <masked-text-field
            ref="grossWeightRef"
            v-model="form.grossWeight"
            suffix="Kg"
            :mask="masks.float"
            unmask
            inputmode="numeric"
            solo
            x-large
            height="48"
            dark
            background-color="black"
            class="scale-input"
            :rules="[v => !!v && form.grossWeight > 0 || 'Informe o Peso Bruto!']"
            validate-on-blur
            @keyup.enter="save()"
          />
        </v-col>
        <v-col
          class="py-0"
        >
          <span class="headline">PESO TARA 1</span>
          <masked-text-field
            v-model="form.tareWeight1"
            suffix="Kg"
            :mask="masks.float"
            unmask
            inputmode="numeric"
            solo
            x-large
            height="48"
            dark
            background-color="grey"
            class="scale-input"
          />
        </v-col>
        <v-col
          class="py-0"
        >
          <span class="headline">PESO TARA 2</span>
          <masked-text-field
            v-model="form.tareWeight2"
            suffix="Kg"
            :mask="masks.float"
            unmask
            inputmode="numeric"
            solo
            x-large
            height="48"
            dark
            background-color="grey"
            class="scale-input"
          />
        </v-col>
        <v-col
          cols="5"
          sm
          class="py-0"
        >
          <span class="headline">PESO LÍQUIDO</span>
          <masked-text-field
            :value="parseFloat(form.grossWeight || 0) - parseFloat(form.tareWeight1 || 0) - parseFloat(form.tareWeight2 || 0)"
            suffix="Kg"
            :mask="masks.floatSigned"
            unmask
            inputmode="numeric"
            solo
            x-large
            height="48"
            dark
            :background-color="scale.color"
            readonly
            class="scale-input"
          />
        </v-col>
        <v-col
          v-if="!packingSettings.autoWeighing"
          class="py-0 d-flex align-center"
        >
          <v-btn
            color="blue"
            outlined
            text
            x-large
            block
            style="height: 48px;margin-top: 2px;"
            @click="save"
          >
            Adicionar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <edit-stock-dialog
      ref="editStockDialogRef"
      hide-price
      @save="loadStocks(form.item?.itemId)"
    />
  </div>
</template>

<style lang="scss">
.packing-weighing {
  .theme--light.v-label--is-disabled, .theme--light.v-input--is-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .scale-input input {
    font-size: 18px;
  }

  .scale-input.v-input--is-readonly input {
    cursor: default;
  }
}
</style>

<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'
import store from '@/Support/Resources/vuex.js'

import debounce from 'lodash/debounce'
import { v4 as uuidv4 } from 'uuid'

import ItemAutocompleteFilter from '@/Support/Components/Filters/ItemAutocompleteFilter.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import EditStockDialog from '@/Domains/Registrations/Item/Components/EditStockDialog.vue'

import { usePacking } from '@/Domains/Shipment/Composable/Packing.js'

import ZebraPrinterGenerator from '@/Domains/Shipment/Api/ZebraPrinterGenerator.js'

const { progressBar, notify } = useUtils()

const { proxy } = getCurrentInstance()

// eslint-disable-next-line no-undef
const props = defineProps({
  warehouseId: String,
  settingsId: {
    type: String,
    default: 'WEIGHING'
  },
  externalValidate: {
    type: Function,
    default: () => true
  },
  boxNumber: Number,
  palletNumber: [String, Number],
  loadNumber: Number,
  loadedAt: String,
  hasWeighingInputSelected: Boolean,
})

const { scaleId, packingSettings, printTag } = usePacking(props.settingsId)

// eslint-disable-next-line no-undef
const emit = defineEmits([
  'weighing',
  'created'
])

const formRef = ref()
const grossWeightRef = ref()

const masks = {
  float: { mask: Number, min: 0, scale: 3, thousandsSeparator: '.', radix: ',' },
  floatSigned: { mask: Number, scale: 3, thousandsSeparator: '.', radix: ',', signed: true },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

const form = reactive({
  id: null,
  item: null,
  stock: null,
  quantity: null,
  grossWeight: null,
  tareWeight1: null,
  tareWeight2: null,
  shippingId: null,
})

const stocks = ref([]);

const products = ref([]);

const init = (data = null) => {
  form.item = null
  form.stock = null
  form.quantity = 1
  form.grossWeight = null
  form.tareWeight1 = null
  form.tareWeight1 = null
  form.shippingId = null
  reset()

  if (data?.shippingId) {
    form.shippingId = data.shippingId

    products.value = data.pendingProducts

    if (data.pendingProducts.length === 1) {
      onShippingItemChange(data.pendingProducts[0])
    }
  }
}

const reset = () => {
  form.id = uuidv4()
  formRef.value?.resetValidation()
}

const loadStocks = async (itemId) => {
  try {
    const { data } = await axios.get(`/stock`, { params: {
      ids: [itemId],
      id_deposito: props.warehouseId,
    } });

    stocks.value = data
      .map(stock => ({
        id: stock.id_estoque,
        description: stock.numero_lote || 'PADRÃO',
        lotNumber: stock.numero_lote,
        quantity: stock.quantidade,
        availableQuantity: stock.quantidade,
        manufacturingDate: stock.data_fabricacao,
        expirationDate: stock.data_validade,
      }));

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar os estoques!', 'Atenção')
  }
}

const onItemChange = (item) => {
  form.grossWeight = item.grossWeight
  form.tareWeight1 = item.tareWeight1
  form.tareWeight2 = item.tareWeight2
  form.quantity = item.conversionFactor || 1
  stocks.value = []
  if (item?.itemId) {
    loadStocks(item.itemId)
  }
}

const onShippingItemChange = (item) => {
  form.item = item
  form.grossWeight = item.grossWeight
  form.tareWeight1 = item.tareWeight1
  form.tareWeight2 = item.tareWeight2
  form.quantity = item.conversionFactor || 1

  stocks.value = item.stocks
  if (stocks.value.length === 1) {
    form.stock = stocks.value[0]
  }
}

const save = async () => {
  try {
    const isExternalValidated = await props.externalValidate()
    const isValidated = await formRef.value?.validate()

    if (!isExternalValidated || !isValidated) {
      return
    }

    progressBar?.saving()

    const payload = {
      id: form.id,
      id_item: form.item.itemId,
      id_unidade_medida: form.item.measurementId,
      id_estoque: form.stock.id,
      quantidade: form.quantity,
      peso_tara_1: form.tareWeight1,
      peso_tara_2: form.tareWeight2,
      peso_bruto: form.grossWeight,
    };

    if (form.item.shippingItemId) {
      payload.id_entrega_item = form.item.shippingItemId
    }

    payload.gerar_sscc = packingSettings.value.generateSscc

    const { data } = await axios.post(`packing`, payload)

    const packing = {
      ...form,
      id: data.id,
      description: form.item?.name,
      measurement: form.item?.defaultMeasurement,
      lotNumber: form.stock?.lotNumber,
      barcode: data.barcode,
      sscc: data.sscc,
      createdAt: data.createdAt,
      tareWeight: parseFloat(form.tareWeight1 || 0) + parseFloat(form.tareWeight2 || 0),
      netWeight: parseFloat(form.grossWeight || 0) - parseFloat(form.tareWeight1 || 0) - parseFloat(form.tareWeight2 || 0),
      product: `${form.item?.code || ''} - ${form.item?.name} - ${form.stock?.lotNumber || 'SEM LOTE'}`,
      boxNumber: props.boxNumber,
    }

    emit('created', packing)

    if (packingSettings.value.autoPrinting) {
      print(packing)
    }

    if (form.item.shippingItemId) {
      form.stock.packingQuantity = form.stock.packingQuantity + form.quantity
    }

    reset()
    grossWeightRef.value?.focus()

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao salvar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const validateShippingQuantity = () => {
  if (!form.shippingId || !form.stock) {
    return true
  }

  if (form.quantity <= (form.stock.quantity - form.stock.packingQuantity)) {
    return true
  }

  return 'Quantidade indisponível'
}

const editStockDialogRef = ref()

const addLot = () => {
  editStockDialogRef.value.show({
    product: {
      id: form.item?.itemId,
      name: form.item?.name,
    },
  })
}

const scale = reactive({
  id: null,
  started: false,
  color: 'green lighten-1',
})

const websocket = computed(() => proxy.$socket)

watch(websocket, () => startWeighingScale())

watch(scaleId, () => startWeighingScale())

onMounted(() => startWeighingScale())

onUnmounted(() => stopWeighingScale())

const onWeighing = (weight) => {
  if (props.hasWeighingInputSelected) {
    return emit('weighing', weight)
  }

  form.grossWeight = weight

  scale.color = 'yellow darken-2'
  onWeighingFinished()
}

const onWeighingFinished = debounce(() => {
  scale.color = 'green lighten-1'
  grossWeightRef.value?.resetValidation()
  if (packingSettings.value.autoWeighing && form.grossWeight) {
    save()
  }
}, 1000)

const startWeighingScale = () => {
  const io = websocket.value
  const id = scaleId.value
  if (!io || !id) {
    return
  }

  if (scale.started && scale.id === id) {
    return
  }

  if (scale.started && scale.id && scale.id !== id) {
    console.log('Weighing scale changed')
    io.emit('leave', scale.id)
  }

  console.log(`Weighing scale started: ${id}`)

  io.emit('join', id)

  if (!scale.started) {
    io.on('weight', onWeighing);
  }

  scale.started = true
  scale.id = id
}

const stopWeighingScale = () => {
  scale.started = false
  const io = websocket.value
  const id = scale.id
  if (!io || !id) {
    return
  }

  console.log(`Weighing scale stopped: ${id}`)

  io.emit('leave', id)
  io.off('weight', onWeighing);
}

const print = (packing) => {
  const tagModel = packingSettings.value.tagModel

  if (tagModel === 'SYSTEM_10X5') {
    const rawData = ZebraPrinterGenerator.systemTag10x5({
      barcode: packing.barcode,
      grossWeight: packing.grossWeight,
      tareWeight: packing.tareWeight,
      netWeight: packing.netWeight,
      code: packing.item?.code
    })

    return printTag(rawData)
  }

  if (tagModel === 'SIF_10X15') {
    const dairy = store.state.settings?.laticinio || {}
    const general = store.state.settings?.gerais || {}

    const rawData = ZebraPrinterGenerator.industryTag10x15({
      dairyName: dairy.name,
      dairyDoc: dairy.doc,
      dairyCity: dairy.address?.city,
      dairyState: dairy.address?.state,
      dairyStreet: dairy.address?.street,
      dairyNumber: dairy.address?.number,

      grossWeight: packing.grossWeight,
      tareWeight1: packing.tareWeight1,
      tareWeight2: packing.tareWeight2,
      tareWeight: packing.tareWeight,
      netWeight: packing.netWeight,

      productName: packing.item.name,
      quantity: packing.quantity,
      expirationDate: packing.stock?.expirationDate,
      manufacturingDate: packing.stock?.manufacturingDate,
      lotNumber: packing.stock?.lotNumber,
      loadedAt: props.loadedAt || packing.createdAt,

      gtin: packing.item.defaultBarcode || packing.item.barcode,
      dun: packing.item.barcode !== packing.item.defaultBarcode ? packing.item.barcode : '',
      sscc: packing.sscc,
      sif: general.sif,

      boxNumber: packing.boxNumber,
      palletNumber: props.palletNumber,
      loadNumber: props.loadNumber,
    })

    return printTag(rawData)
  }
}

// eslint-disable-next-line no-undef
defineExpose({ init, save, print })
</script>
