<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="80%"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="shipment-dialog">
      <v-card-title>
        <span class="text-h6">Expedição</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <shipment-resume
          :shipment="form.shipment"
        />

        <v-form ref="formRef">
          <v-row>
            <v-col
              cols="4"
              md="3"
              class="pb-0"
            >
              <date-time-text-field
                v-model="form.shipment.operationTime"
                label="Data Operação"
                icon="access_time"
                input-format="YYYY-MM-DD HH:mm"
                manual
                filled
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="6"
              sm="2"
              lg="1"
              class="py-0"
            >
              <v-select
                v-model="form.groupBy"
                :items="['PRODUTO', 'PEDIDO']"
                label="Agrupar por"
                outlined
                x-large
              />
            </v-col>
            <v-col
              v-if="form.groupBy === 'PEDIDO'"
              cols="6"
              sm="2"
              lg="1"
              class="py-0"
            >
              <v-select
                v-model="form.shippingId"
                label="Expedir"
                :items="shippingsSelect"
                outlined
                x-large
              />
            </v-col>
            <v-col
              cols="8"
              sm="6"
              md="4"
              class="py-0"
            >
              <v-text-field
                ref="codeRef"
                v-model="form.code"
                label="Produto"
                placeholder="Código Item, Código Barras"
                persistent-placeholder
                icon="access_time"
                hint="Aperte 'enter' para confirmar"
                outlined
                x-large
                @keyup.enter="addItem"
              />
            </v-col>
            <v-col
              cols="4"
              sm="2"
              lg="1"
              class="py-0"
            >
              <masked-text-field
                v-model="form.quantity"
                label="Quantidade"
                placeholder="0"
                persistent-placeholder
                :mask="masks.float"
                unmask
                outlined
                x-large
                validate-on-blur
                @keyup.enter="addItem"
              />
            </v-col>
          </v-row>
          <div class="d-flex">
            <span class="flex-grow-1 text-h5 black--text">
              {{ form.groupBy === 'PRODUTO' ? 'Produtos' : 'Pedidos' }}
            </span>
          </div>
          <v-divider class="mb-4" />

          <v-data-table
            :headers="headers"
            :items="form.groupBy === 'PEDIDO' ? shippings : products"
            :group-by="form.groupBy === 'PEDIDO' ? 'shippingId' : undefined"
            hide-default-footer
            disable-pagination
            disable-sort
            disable-filtering
          >
            <template #[`group.header`]="{ group, headers, items }">
              <td class="text-start">
                {{ items[0].code }}
              </td>
              <td
                class="text-start"
                :colspan="headers.length - 2"
              >
                <b>{{ items[0].customer.name }}</b>
              </td>
              <td
                class="text-start"
              >
                <v-menu
                  v-if="form.groupBy === 'PEDIDO' && items.some(p => p.shipped)"
                  offset-y
                  transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="items.some(p => p.shipped)"
                      @click="chargebackShipping(group)"
                    >
                      <v-list-item-icon>
                        <v-icon>settings_backup_restore</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Estornar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </template>
            <template #[`item.quantity`]="{ value, item }">
              {{ formatNumber(value) }} {{ item.item.measurement }}
            </template>
            <template #[`item.shipped`]="{ item }">
              {{ formatNumber(item.shipped + item.remaining.shipped) }} {{ item.item.measurement }}
              <v-input
                v-show="false"
                :value="item.shipped + item.remaining.shipped"
                required
                :rules="[() => !item.remaining.shipped || (item.remaining.quantity == item.remaining.shipped) || 'Obrigatório!']"
              />
            </template>
            <template #[`item.progress`]="{ item }">
              <v-progress-circular
                :rotate="-90"
                :size="24"
                :width="12"
                :value="((item.shipped + item.remaining.shipped) / item.quantity) * 100"
                :color="((item.shipped + item.remaining.shipped) / item.quantity) < 1 ? 'orange' : 'green'"
                class="ma-1"
              />
            </template>
            <template #[`item.actions`]="{ item }">
              <v-menu
                v-if="form.groupBy === 'PEDIDO' && item.shipped"
                offset-y
                transition="scale-transition"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="item.shipped"
                    @click="chargebackShippingItem(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>settings_backup_restore</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Estornar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex-column">
        <v-progress-linear
          v-show="progress"
          v-model="progress"
          color="primary"
          rounded
          height="6"
          class="mb-6"
        />
        <div class="full-width d-flex flex-row">
          <v-btn
            color="secondary"
            outlined
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="save()"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>

    <item-lot-dialog
      v-model="lotDialog.show"
      type="SAIDA"
      :items="lotDialog.items"
      @save="onLotSaved"
    />
  </v-dialog>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import axios from '@/Support/Resources/axios-instance.js';
import { useUtils } from '@/Support/Composables/utils.js'
import api from '@/Domains/Shipment/Api/Shipment.js'

import ShipmentResume from '@/Domains/Shipment/Shipment/Components/ShipmentResume.vue'
import DateTimeTextField from '@/Support/Components/DateTimeTextField.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import ItemLotDialog from '@/Domains/Registrations/Item/Components/ItemLotDialog.vue'

const { progressBar, notify, confirm } = useUtils()

const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

// eslint-disable-next-line no-undef
const emit = defineEmits(['save', 'close'])

const dialog = ref(false)

const formRef = ref()

const form = reactive({
  shipment: {
    operationTime: null,
    shippings: [],
  },
  groupBy: 'PEDIDO',
  shippingId: null,
  code: '',
  quantity: 1,
})

const headers = [
  { text: 'Código', value: 'item.code', width: 50 },
  { text: 'Produto', value: 'item.description' },
  { text: 'Quantidade', value: 'quantity' },
  { text: 'Quantidade Expedida', value: 'shipped' },
  { text: '', value: 'progress', width: 60 },
  { text: '', value: 'actions', width: 30 },
]

const shippings = computed(() => {
  if (!form.shipment?.shippings) {
    return []
  }

  return form.shipment.shippings.flatMap((shipping) => {
    return shipping.products
      .map((product) => ({
        shippingId: shipping.id,
        code: shipping.code,
        customer: shipping.customer,
        ...product,
      }))
  })
})

const products = computed(() => {
  if (!form.shipment?.shippings) {
    return {}
  }

  const groupedProducts = form.shipment.shippings.reduce((acc, shipping) => {
    for (const product of shipping.products) {
      const group = product.item.group
      if (!(group in acc)) {
        acc[group] = {
          item: product.item,
          quantity: 0,
          shipped: 0,
          remaining: {
            quantity: 0,
            shipped: 0
          }
        }
      }

      acc[group].quantity += product.quantity
      acc[group].shipped += product.shipped
      acc[group].remaining.shipped += product.remaining.shipped
      acc[group].remaining.quantity += product.remaining.quantity
    }

    return acc
  }, {})

  return Object.values(groupedProducts)
})

const progress = computed(() => {
  const total = products.value.reduce((total, product) => total + product.quantity, 0)
  const shipped = products.value.reduce((total, product) => total + (product.shipped + product.remaining.shipped), 0)

  return (shipped / total) * 100
})

const show = async (id) => {
  dialog.value = true
  load(id)
}

const close = () => {
  dialog.value = false
  form.shipment = {
    operationTime: null,
    shippings: [],
  }
  form.groupBy = 'PEDIDO'
  form.shippingId = null
  form.code = ''
  form.quantity = 1
  emit('close')
}

const load = async (id) => {
  try {
    progressBar?.loading()

    form.shipment = await api.show(id)

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const save = async (showDialog = true) => {
  try {
    if (!await formRef.value?.validate()) {
      notify.warning('Não é permitido realizar apontamentos parciais', 'Atenção')
      return
    }

    const itemsWithLotControl = form.shipment.shippings
      .flatMap((shipment) => shipment.products.filter(product => (product.remaining.shipped - product.remaining.quantityLot) > 0 && product.item.requiresLot));

    if (showDialog && itemsWithLotControl.length > 0) {
      showLotDialog(itemsWithLotControl);
      return;
    }

    progressBar?.saving()

    const { shipment } = form

    const payload = {
      data_hora_descarga: shipment.operationTime,
      entregas: shipment.shippings.map(shipment => ({
        id: shipment.id,
        pallets: shipment.pendingPalletCodes,
        produtos: shipment.products
          .filter(p => p.remaining.shipped > 0)
          .map(p => ({
            id: p.id,
            id_item: p.item.id,
            quantidade: p.remaining.shipped * p.item.conversionFactor,
            lotes: p.remaining.lots,
            embalagens: p.remaining.packingCodes,
          })),
      })),
    }

    await api.update(shipment.id, payload)

    emit('save')
    close()
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao salvar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const lotDialog = reactive({
  show: false,
  items: [],
})

const showLotDialog = (items) => {
  lotDialog.show = true;
  lotDialog.items = items.map(p => ({
    id: p.id,
    id_item: p.item.id,
    nome: p.item.description,
    codigo: p.item.code,
    unidade: p.item.defaultMeasurement,
    lote_manual: p.item.manualLot,
    quantidade: (p.remaining.shipped - p.remaining.quantityLot) * p.item.conversionFactor,
  }));
}

const onLotSaved = (items) => {
  const productsById = items.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})

  for (const shipping of form.shipment.shippings) {
    for (const p of shipping.products) {
      if (productsById[p.id]) {
        p.remaining.lots.push(...productsById[p.id].lotes)
        p.remaining.quantityLot += productsById[p.id].quantidade / p.item.conversionFactor
      }
    }
  }

  save(false);
}

const codeRef = ref()

const shippingsSelect = computed(() => {
  if (form.groupBy !== 'PEDIDO' || !form.shipment.shippings) {
    return []
  }

  return [
    {
      text: 'SEQUENCIALMENTE',
      value: null,
    },
    ...form.shipment.shippings.map(shipment => ({
      text: shipment.code ? `${shipment.code} - ${shipment.customer.name}` : shipment.customer.name,
      value: shipment.id,
    })),
  ]
})

const addItem = () => {
  let quantity = parseFloat(form.quantity) || 0
  const code = form.code?.trim()
  if (code && quantity) {

    // Verifica se há produtos com código ou código de barras compatíveis
    const product = products.value.find(p => p.item?.code === code || p.item?.barcode === code)

    if (product) {
      let shippings = form.shipment.shippings

      // Se houver seleção de pedido, a expedição será feita no pedido selecionado
      if (form.shippingId) {
        shippings = shippings.filter(s => s.id === form.shippingId)

        // Verifica se o pedido selecionado possui o produto a ser expedido e a quantidade disponível
        if (!shippings[0].products.some(p => p.item.group === product.item.group && (p.remaining.quantity - p.remaining.shipped) >= quantity)) {
          notify.warning('Quantidade indisponível', 'Atenção')
          return
        }
      }

      // Se não houver filtro de pedido, a expedição será feita sequencialmente
      const availableQuantityTotal = product.remaining.quantity - product.remaining.shipped

      if (quantity <= availableQuantityTotal) {
        for (const shipping of shippings) {
          for (const p of shipping.products) {
            if (p.item.group === product.item.group) {
              const availableQuantity = p.remaining.quantity - p.remaining.shipped
              const usedQuantity = Math.min(availableQuantity, quantity)
              quantity -= usedQuantity
              p.remaining.shipped += usedQuantity
            }
          }
        }
      } else {
        notify.warning('Quantidade indisponível', 'Atenção')
      }
    } else {
      // Caso contrário, verifica se o código é de um pallet ou caixa previamente cadastrado
      findPacking(code)
    }
  }
  form.quantity = 1
  codeRef.value?.reset()
  codeRef.value?.focus()
}

const findPacking = async (code) => {
  try {
    // Antes de buscar no servidor, verifica se o código já não foi adicionado em uma embalagem ou pallet
    const alreadyAddedPacking = form.shipment.shippings.flatMap(s => s.products.flatMap(p => p.remaining.packingCodes)).includes(code)
    const alreadyAddedPallet = form.shipment.shippings.flatMap(s => s.pendingPalletCodes).includes(code)

    if (alreadyAddedPacking) {
      return notify.warning('Embalagem já adicionada', 'Atenção')
    }

    if (alreadyAddedPallet) {
      return notify.warning('Pallet já adicionado', 'Atenção')
    }

    progressBar.loading()

    const response = await api.findStorage({ code })

    if (!response) {
      notify.warning('Código não encontrado', 'Atenção')
    }

    const { type, data } = response

    if (type === 'PACKING') {
      if (!products.value.find(p => [data.id_unidade_medida, data.id_item].includes(p.item.group))) {
        return notify.warning('Código não encontrado', 'Atenção')
      }

      const product = getProduct(data.id_unidade_medida, data.quantidade, 'MEASUREMENT') || getProduct(data.id_item, data.quantidade)

      if (product) {
        applyStock(product, data)
      } else {
        notify.warning('Quantidade indisponível', 'Atenção')
      }
    }
    else if (type === 'PALLET') {
      const items = Object.values(data.estoques.reduce((acc, cur) => {
        if (!acc[cur.id_item]) {
          acc[cur.id_item] = {
            id: cur.id_item,
            quantity: 0
          }
        }

        acc[cur.id_item].quantity += cur.quantidade
        return acc
      }, {}))

      // Busca a primeira entrega que contém todos os produtos do pallet
      const compatibleShipping = form.shipment.shippings.find(shipping => {
        // Se houver seleção de pedido, a expedição será feita no pedido selecionado
        if (form.shippingId && form.shippingId !== shipping.id) {
          return false
        }

        for (const item of items) {
          const product = shipping.products.find(p => p.item.id === item.id)
          if (!product) {
            return false
          }

          const availableQuantity = (product.remaining.quantity - product.remaining.shipped) * product.item.conversionFactor
          if (item.quantity > availableQuantity * product.item.conversionFactor) {
            return false
          }
        }

        return true
      })

      if (!compatibleShipping) {
        return notify.warning('Quantidade indisponível', 'Atenção')
      }

      for (const stock of data.estoques) {
        const product = compatibleShipping.products.find(p => p.item.id === stock.id_item)
        if (product) {
          applyStock(product, stock)
        }
      }

      compatibleShipping.pendingPalletCodes.push(data.codigo_barras)
    }
    else if (type === 'ERROR') {
      return notify.warning(data, 'Atenção')
    }

  } catch (err) {
    console.warn(err)
    notify.error('Erro ao buscar embalagem', 'Atenção');
  } finally {
    progressBar.hide()
  }
}

const applyStock = (product, data) => {
  if (data.estoque.tipo === 'LOTE') {
    product.remaining.lots.push({
      numero: data.estoque.numero_lote,
      quantidade: data.quantidade
    })
  }

  product.remaining.packingCodes.push(data.codigo_barras)

  product.remaining.quantityLot += data.quantidade / product.item.conversionFactor
  product.remaining.shipped += data.quantidade / product.item.conversionFactor
}

const getProduct = (id, quantity, type = 'ITEM') => {
  if (!id) {
    return null
  }

  let shippings = form.shipment.shippings

  // Se houver seleção de pedido, a expedição será feita no pedido selecionado
  if (form.shippingId) {
    shippings = shippings.filter(s => s.id === form.shippingId)
  }

  for (const shipping of shippings) {
    for (const p of shipping.products) {
      const availableQuantity = p.remaining.quantity - p.remaining.shipped
      if (type === 'MEASUREMENT') {
        if (p.item.measurementId === id && (availableQuantity * p.item.conversionFactor) >= quantity) {
          return p
        }
      } else {
        if (p.item.id === id && availableQuantity >= quantity) {
          return p
        }
      }
    }
  }

  return null
}

const chargebackShipping = async (id) => {
  if (!(await confirm('Estornar itens expedidos?', 'Esta ação não poderá ser desfeita.', { color: 'red' }))) {
    return
  }

  try {
    progressBar?.loading()

    await axios.put(`/shipping/${id}/chargeback`);

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar!', 'Atenção')
  } finally {
    progressBar?.hide()

    load(form.shipment.id)
  }
}

const chargebackShippingItem = async ({ id }) => {
  if (!(await confirm('Estornar itens expedidos?', 'Esta ação não poderá ser desfeita.', { color: 'red' }))) {
    return
  }

  try {
    progressBar?.loading()

    await axios.put(`/shipping/chargeback-item`, { id });

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar!', 'Atenção')
  } finally {
    progressBar?.hide()

    load(form.shipment.id)
  }
}

// eslint-disable-next-line no-undef
defineExpose({ show, close })
</script>
