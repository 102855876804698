<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent="save()"
    >
      <v-row v-if="canEdit">
        <v-col
          cols="12"
          md="4"
          class="pt-2"
        >
          <warehouse-select
            v-model="warehouseId"
            label="Depósito"
            outlined
            dense
            hide-details
            auto-select-default
            :rules="[v => !!v || 'Campo obrigatório!']"
          />
        </v-col>
      </v-row>
      <v-row
        v-for="(data, idx) of items"
        :key="idx"
        style="border-bottom: 1px #eee solid;"
      >
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex flex-column justify-center"
          style="max-width: 49px;"
        >
          <v-icon>
            reorder
          </v-icon>
        </v-col>
        <v-col class="pt-1 pb-0">
          <v-text-field
            :value="data.item?.description"
            label="Item"
            disabled
          />
        </v-col>
        <v-col
          v-if="hasInputsAccess && !$vuetify.breakpoint.mobile"
          class="pt-1 pb-0"
          cols="6"
          sm="3"
          md
        >
          <masked-text-field
            v-model="data.quantity"
            label="Qtde. Base"
            persistent-hint
            unmask
            :mask="masks.float"
            :suffix="data.item?.measurement"
            disabled
          />
        </v-col>
        <v-col
          v-if="hasPlannedQuantityAccess"
          class="pt-1 pb-0"
          cols="6"
          sm="3"
          md
        >
          <masked-text-field
            :value="data.plannedQuantity"
            label="Qtde. Planejada"
            unmask
            :mask="masks.float"
            :suffix="data.item?.measurement"
            disabled
          />
        </v-col>
        <v-col
          v-if="hasInputsAccess"
          class="pt-1 pb-0"
          cols="6"
          sm="3"
          md
        >
          <masked-text-field
            v-model="data.accomplishedQuantity"
            label="Qtde. Realizada"
            persistent-hint
            unmask
            :mask="masks.float"
            :suffix="data.item?.measurement"
            disabled
          />
        </v-col>
        <v-col
          v-if="canEdit"
          class="pt-1 pb-0"
          cols="6"
          sm="3"
          md
        >
          <masked-text-field
            v-model="data.writeOffQuantity"
            label="Qtde. *"
            persistent-hint
            unmask
            :mask="masks.float"
            inputmode="numeric"
            :suffix="data.item?.measurement"
            :rules="[
              v => !!v || 'Campo obrigatório!',
              validateRange(data),
            ]"
            validate-on-blur
          />
        </v-col>
        <v-col
          v-if="canEdit"
          class="px-0 d-flex flex-column justify-center"
          style="max-width: 49px;"
        >
          <v-btn
            icon
            @click.stop="removeComponent(idx)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="canEdit && items.length === 0">
        <v-col
          cols="12"
          class="text-center"
          :class="{ 'has-error': hasError }"
        >
          Nenhum insumo disponível para efetuar baixas
        </v-col>
      </v-row>

      <item-lot-dialog
        v-model="itemLotDialog.show"
        type="SAIDA"
        :items="itemLotDialog.items"
        :warehouse-id="warehouseId"
        @save="onLotSaved"
      />
    </v-form>

    <span class="text-h6 d-block mt-4">Registros</span>

    <v-divider class="mb-4" />

    <v-data-table
      :headers="headers"
      :items="records"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
    >
      <template #[`item.date`]="{ value }">
        {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
      </template>
      <template #[`item.quantity`]="{ item }">
        {{ formatNumber(item.quantity) }} {{ item.measurement }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-tooltip
          v-if="hasChargebackAccess"
          top
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click.stop="chargeback(item)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
          Estornar
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
.has-error {
  animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  color: red;
}
</style>

<script>
import ItemLotDialog from '@/Domains/Registrations/Item/Components/ItemLotDialog.vue';
import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import { useRangeValidator } from '@/Support/Composables/validator.js'

import moment from 'moment';

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

const { validateRule } = useRangeValidator();

export default {

  components: {
    ItemLotDialog,
    WarehouseSelect,
    MaskedTextField,
  },

  props: {
    canEdit: Boolean,
    order: {
      type: Object,
      default: () => ({
        items: [],
        records: [],
      })
    },
    step: Object,
  },

  data() {
    return {
      warehouseId: null,
      items: [],

      itemLotDialog: {
        show: false,
        items: [],
      },

      headers: [
        { text: 'Produto', value: 'product.name' },
        { text: 'Data e Hora', value: 'date', align: 'center' },
        { text: 'Pessoa', value: 'person' },
        { text: 'Qtde.', value: 'quantity', align: 'center' },
        { text: 'Lote', value: 'lotNumber', align: 'center' },
        { text: '', value: 'action', width: 30, sortable: false },
      ],

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },

      hasError: false,
    }
  },

  computed: {
    records() {
      return this.order.records.filter(record => !!record.itemId);
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasInputsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-inputs-apontamento' && o.tipo === 'COMPONENTE');
    },
    hasChargebackAccess() {
      if (!this.canEdit) {
        return false
      }
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-exclusao-apontamento' && o.tipo === 'COMPONENTE');
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.items = this.order.items
        .filter(data => !data.item.isRawMaterial)
        .map(item => {
          const plannedQuantity = item.quantity * this.order.quantity;
          const writeOffQuantity = plannedQuantity - item.accomplishedQuantity;
          return {
            ...item,
            plannedQuantity,
            writeOffQuantity: (this.hasInputsAccess && writeOffQuantity > 0) ? writeOffQuantity : null,
          }
        });

      if (this.step) {
        this.items = this.items
          .filter(item => !item.step || item.step.id === this.step.id)
      }
    },

    removeComponent(idx) {
      this.items.splice(idx, 1);
    },

    async save(showLotDialog = true) {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.hasError = this.items.length === 0;

        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, 2000);

          return;
        }

        const itemsWithLotControl = this.items.filter(item => item.item.requiresLot);

        if (showLotDialog && itemsWithLotControl.length > 0) {
          this.showLotDialog(itemsWithLotControl);
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_deposito: this.warehouseId,
          itens: this.items.map((item) => ({
            id_ordem_producao_item: item.id || null,
            quantidade: item.writeOffQuantity,
            lotes: item.lots,
          })),
        };

        await api.storeWriteOff(payload);

        this.$snotify.success('Baixa efetuada com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        this.$snotify.error('Erro ao efetuar baixa', 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    showLotDialog(items) {
      this.itemLotDialog.show = true;
      this.itemLotDialog.items = items.map(item => ({
        id_item: item.itemId,
        nome: item.item.description,
        codigo: item.item.code,
        unidade: item.item.measurement,
        quantidade: item.writeOffQuantity,
        lote_manual: item.item?.manualLot,
      }));
    },

    onLotSaved(items) {
      for (let lot of items) {
        const idx = this.items.findIndex(item => item.itemId === lot.id_item);
        this.items[idx].lots = lot.lotes;
      }
      this.save(false);
    },

    async chargeback(item) {
      if (!this.canEdit) {
        return;
      }

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente estornar este apontamento?<br><br>`, { color: 'red', token: 'ESTORNAR' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.post(`/stock/chargeback`, {
          id: item.id,
        });

        this.$snotify.success('Estorno efetuado com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao estornar o apontamento!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.$emit('close');
    },

    validateRange(item) {
      const value = item.writeOffQuantity;
      const rule = item.range;

      return validateRule(value, rule);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
  }
}
</script>
