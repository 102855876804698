<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Notas Fiscais de Saída
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <invoice-list type="SAIDA" />
  </v-container>
</template>

<script>
import InvoiceList from "@/Domains/Fiscal/Components/InvoiceList.vue";

export default {
  name: "OutgoingInvoice",

  components: {
    InvoiceList,
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>
