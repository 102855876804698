<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Volume por Condomínio
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      centered
      dark
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#tab-volume">
        Volume
      </v-tab>

      <v-tab href="#tab-collect">
        Coletas
      </v-tab>

      <v-tab-item value="tab-volume">
        <GroupingList
          v-if="tab === 'tab-volume'"
          type="CONDOMINIUM"
          format="VOLUME"
        />
      </v-tab-item>

      <v-tab-item value="tab-collect">
        <GroupingList
          v-if="tab === 'tab-collect'"
          type="CONDOMINIUM"
          format="COLLECT"
        />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import GroupingList from "@/Domains/Collects/Reports/GroupingVolume/Components/GroupingList.vue";

export default {
  name: 'condominium-volume',

  components: {
    GroupingList
  },

  data() {
    return {
      tab: null
    };
  },
};
</script>
