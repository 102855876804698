<template>
  <div>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              label="Buscar"
              filled
              single-line
              hide-details
              dark
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        v-model="selected"
        dark
        background-color="transparent"
        :items="items"
        show-select
        single-expand
        show-expand
        item-key="name"
        :headers="headers"
        :search="filters.search"
        @click:row="editGrouping"
      >
        <template #[`item.data-table-expand`]="{ expand, isExpanded }">
          <v-tooltip bottom>
            <template #[`activator`]="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                text
                v-on="on"
                @click.stop="expand(!isExpanded)"
              >
                <v-icon>groups</v-icon>
              </v-btn>
            </template>
            Produtores
          </v-tooltip>
        </template>

        <template #[`expanded-item`]="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col class="pa-3">
                <v-data-table
                  dark
                  :items="item.producers"
                  hide-default-footer
                  disable-pagination
                  :headers="[
                    { text: 'Código', value: 'codigo_laticinio', width: 100 },
                    { text: 'Produtor', value: 'nome' },
                  ]"
                />
              </v-col>
            </v-row>
          </td>
        </template>

        <template #[`item.technical`]="{ item }">
          <v-chip
            v-for="technical of item.technical"
            :key="technical.id_usuario"
            small
          >
            {{ technical.nome_usuario }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                small
                @click="addGrouping(item.id)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasExcludeAccess"
                small
                @click="removeGrouping(item.id)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="deep-purple darken-1"
        @click="addGrouping()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Adicionar condominio
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selected.length"
        fab
        dark
        color="orange darken-4"
        @click="onPrintCollectForm"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              content_paste
            </v-icon>
          </template>
          Imprimir Formulário para Coletas
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <person-dialog
      v-if="personDialog.show"
      :type="type"
      :visible="personDialog.show"
      :link-producer="false"
      :producer-id="personDialog.id"
      @close="onClose"
      @onSave="onSave"
    />
  </div>
</template>

<script>
import printJS from 'print-js';
import _ from 'lodash';
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import PersonDialog from '@/Domains/Producer/Management/Components/Dialogs/InfoProducer.vue';

export default {
  components: {
    PersonDialog,
  },

  mixins: [ReportMixin],

  props: {
    type: {
      type: String,
      default: 'CONDOMINIUM',
      validator: (value) => ['CONDOMINIUM', 'COMMUNITY-TANK', 'FAMILY-GROUP'].includes(value)
    }
  },

  data() {
    return {
      filters: {
        search: null,
      },

      items: [],

      personDialog: {
        show: false,
        id: null,
      },

      selected: [],
    };
  },

  computed: {
    headers() {
      const headers = [
        { text: 'Código Laticínio', value: 'dairyCode', align: 'center' },
        { text: 'Nome', value: 'name' },
        { text: 'Município', value: 'municipality' },
        { text: 'Motorista/Técnico', value: 'technical' },
        { text: 'Qtd. Produtores', value: 'amountProducer', width: 150, align: 'center', cellClass: 'subtitle-2 yellow--text text-h5' },
      ];

      if (this.type === 'COMMUNITY-TANK') {
        headers.push(
          { text: 'Qtd. Resfriadores', value: 'amountCoolers', width: 150, align: 'center' },
          { text: 'Capacidade total (L)', value: 'totalCapacity', width: 150, align: 'center' }
        );
      }

      headers.push({ text: '', altText: 'Opções', value: 'actions', width: 50, align: 'center' });

      return headers;
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasExcludeAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'exclude-condominium' && o.tipo === 'COMPONENTE');
    },
  },

  mounted() {
    this.loadGrouping();
  },

  methods: {
    async loadGrouping() {
      try {
        this.$root.$progressBar.loading();

        const types = {
          'CONDOMINIUM': 'CONDOMINIO',
          'COMMUNITY-TANK': 'TANQUE',
          'FAMILY-GROUP': 'FAMILIAR',
        };

        const { data } = await this.$axios.get(`/pessoa/agrupamento`, { params: {
          tipo: types[this.type]
        } });

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data.map((item) => {
          const producers = JSON.parse(item.condominio || item.tanque_comunitario || '[]');
          const coolers = JSON.parse(item.resfriadores_tanque_comunitario || '[]');
          return {
            id: item.id_pessoa,
            dairyCode: item.codigo_laticinio,
            name: item.nome,
            producers,
            amountProducer: producers.length,
            amountCoolers: coolers.length,
            totalCapacity: _.sumBy(coolers, (capacity) => parseFloat(capacity) || 0),
            municipality: item.end_cidade,
            technical: JSON.parse(item.pessoa_acesso || '[]')
          }
        });
      } catch (err) {
        console.warn(err)
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    addGrouping(id = null) {
      this.personDialog = { show: true, id };
    },

    editGrouping(item) {
      this.addGrouping(item.id);
    },

    async removeGrouping(id) {
      if (!(await this.$root.$confirm('Excluir cadastro', 'Tem certeza que deseja excluir este cadastro?', { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.delete(`/pessoa/agrupamento/${id}`);

        if (!data.codigo) {
          throw data.mensagem || data;
        }

        this.$snotify.success('Cadastro excluído com sucesso', 'Sucesso');
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Erro ao excluir cadastro', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
        this.loadGrouping();
      }
    },

    onClose() {
      this.personDialog = { show: false, id: null };
    },

    onSave() {
      this.onClose();

      this.loadGrouping();
    },

    getReportTitle() {
      const types = {
        'CONDOMINIUM': 'Condomínios',
        'COMMUNITY-TANK': 'Tanques Comunitários',
        'FAMILY-GROUP': 'Grupos Familiar',
      };

      return types[this.type];
    },

    exportExcel() {
      var htmlString = '<table>';
      let style = [];
      let header = [];
      let count = 0;

      this.items.forEach(function(item) {

        var technicalnames = item.technical.map(function(technical) {
          return technical.nome_usuario;
        });

        var listTechnicalnames = technicalnames.join(', ');

        header[count] = { hpx: 60 };

        count += 1;
        htmlString += '<tr">';
        htmlString += '<td colspan="2" style="font-weight: bold;">Grupo: ' + item.name + ' <br/> Município: '+ item.municipality +' <br/> Técnicos: ' +  listTechnicalnames + '</td>';
        htmlString += '</tr>';

        style[`A${count}`] = { font: {bold: 'true'}, alignment: { horizontal: 'left', vertical: 'center', wrapText: true} };
        count += 1;
        style[`A${count}`] = { font: {bold: 'true'}};
        style[`B${count}`] = { font: {bold: 'true'}};
        htmlString += '<tr>';
        htmlString += '<td style="font-weight: bold;">Código</td>';
        htmlString += '<td style="font-weight: bold;">Produtor</td>';
        htmlString += '</tr>';
        item.producers.forEach(function(producer) {
          count += 1;

          htmlString += '<tr>';
          htmlString += '<td>';
          htmlString += producer.codigo_laticinio;
          htmlString += '</td>';
          htmlString += '<td>';
          htmlString += producer.nome;
          htmlString += '</td>';
          htmlString += '</tr>';
        });
        count += 1;
        htmlString += '<tr><td></td><td></td></tr>';
      });

      htmlString += '</table>';

      var tempContainer = document.createElement("div");
      tempContainer.innerHTML = htmlString;

      var ws = this.XLSX.utils.table_to_sheet(tempContainer);

      ws['!cols'] = [
        { wch: 10 },
        { wch: 50 }
      ];

      for (const key of Object.keys(header)) {
        ws['!rows'][key] = header[key];
      }

      for (const key of Object.keys(style)) {
        ws[`${key}`].s = style[key];
      }

      var wb = this.XLSX.utils.book_new();
      const filename = this.getReportTitle();
      this.XLSX.utils.book_append_sheet(wb, ws, _.truncate(filename, { length: 31 }));
      this.XLSX.writeFile(wb, `${filename}.xlsx`);
    },

    async onPrintCollectForm() {
      try {
        this.$root.$progressBar.loading();

        if (this.selected.length === 0) {
          return;
        }

        const { data } = await this.$axios.post(`/produtores/impressaoAgrupamento`, {
          ids: this.selected.map(item => item.id),
        });

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao imprimir!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

  },
};
</script>
