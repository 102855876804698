<template>
  <v-dialog
    v-model="show"
    width="600"
    max-width="800"
  >
    <v-card>
      <v-card-title>Cadastro de Amostra</v-card-title>

      <v-card-text>
        <v-form
          ref="form"
        >
          <person-autocomplete-filter
            v-model="form.producer"
            label="Produtor *"
            type="PRODUCER"
            :filled="false"
            :hide-details="false"
            show-groupings
            show-bonus-producers
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

          <date-text-field
            v-model="form.date"
            label="Data da amostra *"
            prepend-inner-icon="event"
            no-title
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

          <v-select
            v-if="!type"
            v-model="form.type"
            :items="['CCS', 'CPP', 'GORDURA', 'CRIOSCOPIA', 'ACIDEZ', 'PRESENÇA DE SANGUE', 'CLORETOS']"
            label="Tipo"
            prepend-inner-icon="biotech"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

          <v-text-field
            v-model="form.sampleNumber"
            type="number"
            label="Número da amostra"
            prepend-inner-icon="add"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

          <v-text-field
            v-model="form.obs"
            label="Observações"
            prepend-inner-icon="edit"
          />

          <v-autocomplete
            v-model="form.markType"
            label="Marca"
            chips
            prepend-inner-icon="label_important"
            :items="markTypes"
          />

          <v-text-field
            v-if="form.markType == 'OUTROS'"
            v-model="form.otherMarkType"
            label="Outros Marca"
            prepend-inner-icon="add"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

          <v-autocomplete
            v-model="form.antibioticType"
            label="Grupo de Antibiótico"
            chips
            prepend-inner-icon="report"
            :items="antibioticTypes"
          />

          <v-text-field
            v-if="form.antibioticType == 'OUTROS'"
            v-model="form.otherAntibioticType"
            label="Outros Antibióticos"
            prepend-inner-icon="add"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

            <v-row>
              <v-col
                v-for="(attachment, idx) in form.attachments"
                :key="idx"
              >
                <file-viewer
                  :value="attachment"
                  @removeFile="onRemoveFile(idx)"
                />
              </v-col>
              <v-col
                v-if="form.attachments.length < 3"
                class="py-0 text-center align-self-center flex-column justify-center"
              >
                <v-btn
                  outlined
                  class="mb-5"
                  @click="showFileUpload = !showFileUpload"
                >
                  <v-icon>attach_file</v-icon>
                  <span class="ml-2">Adicionar</span>
                </v-btn>
              </v-col>
            </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          text
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-btn
          class="white--text"
          color="blue"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <file-upload
      v-model="showFileUpload"
      extensions="pdf,xml,gif,jpe,jpeg,jpg,tiff,png,webp,bmp"
      accept="application/pdf,text/xml,image/*"
      :multiselect="false"
      @insertFiles="onSelectFiles"
    />
  </v-dialog>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import DateTextField from "@/Support/Components/DateTextField.vue";
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";

import moment from "moment-timezone";

export default {
  components: {
    PersonAutocompleteFilter,
    DateTextField,
    FileUpload,
    FileViewer
  },

  props: {
    value: Boolean,
    type: String,
  },

  data() {
    return {
      showFileUpload: false,
      form: {
        attachments: []
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    antibioticTypes() {
      let types = this.$store.state.settings.gerais.antibiotico_principio_ativo || [];
      types.push('OUTROS');
      return types;
    },
    markTypes() {
      let types = this.$store.state.settings.gerais.marcas_antibiotico_amostra || [];
      types.push('OUTROS');
      return types;
    },
  },

  methods: {

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_produtor: this.form.producer.id,
          nome_produtor: this.form.producer.name,
          observacao: this.form.obs,
          numero_amostra: this.form.sampleNumber,
          marca_comercial: this.form.markType == "OUTROS" ? `OUTROS - ${this.form.otherMarkType}` : this.form.markType,
          principio_ativo: this.form.antibioticType == "OUTROS" ? `OUTROS - ${this.form.otherAntibioticType}` : this.form.antibioticType,
          anexos: this.form.attachments,
          data: moment(this.form.date).format('YYYY-MM-DD'),
          tipo: this.type || this.form.type,
        };

        const { data } = await this.$axios.post(`/coleta/insereColetaNormativa`, payload);

        this.$emit('save', data.id);
        this.close();
        this.$snotify.success("Amostra salva com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.form = {
        attachments: []
      };
      this.$refs.form && this.$refs.form.resetValidation();
      this.show = false;
    },

    onSelectFiles(files) {
      for (let file of files) {
        if (!this.form.attachments.find(o => o.name == file.name)) {
          this.form.attachments.push(file);
        }
      }
    },

    async onRemoveFile(idx) {
      try {
        const fileUrl = this.form.attachments[idx].url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('exames')) {
          if (!(await this.$root.$confirm('Remover anexo', 'Tem certeza que deseja excluir este anexo?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.attachments.splice(idx, 1);
          await this.save();
        }
        else {
          this.form.attachments.splice(idx, 1);
        }
      } catch (err) {
        console.warn(err);
      }
    },
  }
}
</script>
