<template>
  <div>
    <div>
      <v-row>
        <v-col
          cols="4"
          class="mb-0"
        >
          <warehouse-select
            v-model="warehouseId"
            label="Depósito"
            outlined
            dense
            hide-details
            auto-select-default
            @change="loadData"
          />
        </v-col>
        <v-spacer />
        <v-col class="text-right">
          <v-btn
            color="blue"
            outlined
            @click="addStockEntry"
          >
            Incluir lançamento
          </v-btn>
        </v-col>
      </v-row>

      <v-tabs
        v-model="tab"
      >
        <v-tab href="#records-tab">
          Lançamentos
        </v-tab>
        <v-tab
          v-if="stocks.items?.length > 0"
          href="#stocks-tab"
        >
          Lotes e validades
        </v-tab>
        <v-tab
          v-if="reservations.items?.length > 0"
          href="#reservations-tab"
        >
          Reservas
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item value="records-tab">
            <v-data-table
              :headers="records.headers"
              :items="records.items"
              :loading="loading"
              light
              disable-sort
            >
              <template #[`item.operation`]="{ value }">
                <v-icon
                  v-if="value == 'ENTRADA'"
                  color="green accent-3"
                >
                  south_west
                </v-icon>
                <v-icon
                  v-else
                  color="red accent-3"
                >
                  north_east
                </v-icon>
              </template>
              <template #[`item.date`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
              </template>
              <template #[`item.price`]="{ value }">
                {{ formatCurrency(value || 0) }}
              </template>
              <template #[`item.quantity`]="{ value }">
                <span
                  :class="{
                    'red--text text--accent-4' : value < 0,
                    'green--text text--accent-4' : value > 0
                  }"
                >
                  {{ formatNumber(value) }}
                </span>
              </template>
              <template #[`item.stock`]="{ value }">
                {{ formatNumber(value) }}
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="stocks-tab">
            <v-data-table
              :headers="stocks.headers"
              :items="stocks.items"
              :loading="loading"
              light
              :items-per-page="-1"
              disable-pagination
              hide-default-footer
              @click:row="showStockDetail"
            >
              <template #[`item.lotNumber`]="{ value }">
                <span :class="{ 'red--text' : !value }">
                  {{ value || 'SEM LOTE' }}
                </span>
              </template>
              <template #[`item.manufacturingDate`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>
              <template #[`item.expirationDate`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>
              <template #[`item.quantity`]="{ value }">
                <span :class="{ 'red--text' : value < 0 }">
                  {{ formatNumber(value) }}
                </span>
              </template>
              <template #[`item.price`]="{ value }">
                {{ formatCurrency(value || 0) }}
              </template>
              <template #[`item.lotStatus`]="{ value }">
                <v-chip
                  v-if="value"
                  small
                  dark
                  :color="getLotStatusColor(value)"
                >
                  {{ value }}
                </v-chip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  icon
                  @click.stop="editStock(item)"
                >
                  <v-icon>
                    edit
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="reservations-tab">
            <v-data-table
              :headers="reservations.headers"
              :items="reservations.items"
              :loading="loading"
              light
              :items-per-page="-1"
              disable-pagination
              hide-default-footer
            >
              <template #[`item.date`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>
              <template #[`item.quantity`]="{ value }">
                <span :class="{ 'red--text' : value < 0 }">
                  {{ formatNumber(value) }}
                </span>
              </template>
              <template #[`item.origin`]="{ value }">
                <v-chip
                  v-if="value.name"
                  small
                  class="mr-2"
                >
                  {{ value.name }}
                </v-chip>
                {{ value.code }}
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <stock-detail-dialog
      ref="stockDetailDialog"
    />

    <stock-entry-dialog
      ref="stockEntryDialog"
      @save="onStockEntrySaved"
    />

    <edit-stock-dialog
      ref="editStockDialog"
      @save="onEditStockSaved"
    />
  </div>
</template>

<script>
import StockEntryDialog from '@/Domains/Registrations/Item/Components/StockEntryDialog.vue';
import StockDetailDialog from '@/Domains/Registrations/Item/Components/StockDetailDialog.vue';
import EditStockDialog from '@/Domains/Registrations/Item/Components/EditStockDialog.vue';
import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue';

import moment from 'moment';
import { get } from 'lodash';

export default {

  components: {
    StockEntryDialog,
    StockDetailDialog,
    EditStockDialog,
    WarehouseSelect,
  },

  props: {
    itemData: {
      type: Object,
      default: () => ({})
    },
  },

  data() {
    return {
      loading: false,

      tab: null,

      records: {
        headers: [
          { text: '', value: 'operation' },
          { text: 'Tipo', value: 'type' },
          { text: 'Data e Hora', value: 'date', align: 'center' },
          { text: 'Pessoa', value: 'person' },
          { text: 'Quantidade', value: 'quantity', align: 'center' },
          { text: 'Estoque', value: 'stock', align: 'center' },
          { text: 'Preço unitário', value: 'price', align: 'center' },
          { text: 'Lote', value: 'lotNumber', align: 'center' },
          { text: 'Observações', value: 'notes' },
        ],
        items: [],
      },

      stocks: {
        headers: [
          { text: 'Lote', value: 'lotNumber' },
          { text: 'Fabricação', value: 'manufacturingDate', align: 'center' },
          { text: 'Validade', value: 'expirationDate', align: 'center' },
          { text: 'Fornecedor', value: 'supplierName', align: 'center' },
          { text: 'Estoque Físico', value: 'quantity', align: 'center' },
          { text: 'Estoque Reservado', value: 'reservedQuantity', align: 'center' },
          { text: 'Estoque Disponível', value: 'availableQuantity', align: 'center' },
          { text: 'Preço unitário', value: 'price', align: 'center' },
          { text: 'Status Lote', value: 'lotStatus', align: 'center' },
          { text: '', value: 'actions', align: 'center' },
        ],
        items: [],
      },

      reservations: {
        headers: [
          { text: 'Lote', value: 'lotNumber' },
          { text: 'Data e Hora', value: 'date', align: 'center' },
          { text: 'Pessoa', value: 'person' },
          { text: 'Quantidade', value: 'quantity', align: 'center' },
          { text: 'Origem', value: 'origin', align: 'center' },
          { text: 'Tipo', value: 'type', align: 'center' },
        ],
        items: [],
      },

      warehouses: [],
      warehouseId: null,

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },
    }
  },

  mounted() {
    if (this.itemData.id) {
      this.loadData();
    }
  },

  methods: {
    loadData() {
      this.loadHistory();
      this.loadStocks();
    },

    async loadHistory() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/stock/history`, { params: {
          id_item: this.itemData.id,
          id_deposito: this.warehouseId
        } });

        this.records.items = data.map(o => ({
          date: o.data_hora_cadastro,
          operation: o.entrada_saida == 1 ? 'ENTRADA' : 'SAIDA',
          type: this.getItemType(o),
          quantity: parseFloat(o.quantidade) * (o.entrada_saida == 2 ? -1 : 1),
          stock: parseFloat(o.estoque),
          price: parseFloat(o.valor),
          orderId: o.id_movimento,
          person: o.nome_pessoa_registro,
          lotNumber: o.numero_lote,
          lotStock: parseFloat(o.estoque_lote),
          notes: o.observacao,
        }))
      } catch (error) {
        const message = get(error, 'response.data.message', 'Erro ao carregar depósitos');
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadStocks() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/stock`, { params: {
          ids: [this.itemData.id],
          id_deposito: this.warehouseId,
          exibir_reservas: 1,
        } });

        this.stocks.items = data.map(o => ({
          id: o.id_estoque,
          lotNumber: o.numero_lote,
          lotStatus: o.status_lote,
          manufacturingDate: o.data_fabricacao,
          expirationDate: o.data_validade,
          supplierName: o.nome_fornecedor,
          quantity: o.quantidade,
          reservedQuantity: o.reservado,
          availableQuantity: o.quantidade - o.reservado,
          price: o.valor,
        }));

        this.reservations.items = data.flatMap(stock => {
          return stock.reservations.map(reservation => ({
            id: reservation.id,
            stockId: reservation.id_estoque,
            date: reservation.data_hora_cadastro,
            person: reservation.nome_pessoa_registro,
            quantity: reservation.quantidade,
            type: reservation.tipo,
            lotNumber: stock.numero_lote,
            origin: reservation.id_origem ? {
              id: reservation.id_origem,
              name: reservation.origem,
              code: reservation.cod_origem,
            } : {},
          }))
        })
      } catch (error) {
        const message = get(error, 'response.data.message', 'Erro ao carregar depósitos');
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    getItemType(item) {
      if (item.estorno) {
        return 'ESTORNO';
      }

      if (item.id_movimento_item) {
        return item.entrada_saida == 2 ? 'VENDA' : 'ESTORNO VENDA';
      }

      if (item.id_nota_fiscal) {
        return item.entrada_saida == 2 ? 'SAÍDA NF' : 'ENTRADA NF';
      }

      if (item.id_ordem_producao || item.id_ordem_producao_item || item.id_ordem_producao_subproduto) {
        return item.entrada_saida == 2 ? 'SAÍDA OP' : 'ENTRADA OP';
      }

      if (item.id_entrega_item) {
        return item.entrada_saida == 2 ? 'SAÍDA EXPEDIÇÃO' : 'ENTRADA EXPEDIÇÃO';
      }

      return 'ACERTO';
    },

    addStockEntry() {
      this.$refs.stockEntryDialog.show({
        warehouseId: this.warehouseId,
        product: this.itemData,
      });
    },

    onStockEntrySaved() {
      this.loadData();
    },

    showStockDetail(stock) {
      this.$refs.stockDetailDialog.show({
        stock: { ...stock },
        records: this.records.items.filter(item => item.lotNumber === stock.lotNumber),
      });
    },

    editStock(stock) {
      this.$refs.editStockDialog.show({
        stock: { ...stock },
        product: this.itemData,
      })
    },

    onEditStockSaved() {
      this.loadData();
    },

    getLotStatusColor(status) {
      const colors = {
        'SUSPENSO': 'red',
        'RESTRITO': 'orange',
        'LIBERADO': 'green',
      }

      return colors[status] || 'grey';
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => !value ? '-' : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
  },
}
</script>
