<template>
  <div class="mx-6 px-16">
    <v-flex xs12>
      <v-card
        color="transparent"
        dark
        class="mt-5"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              lg="2"
            >
              <v-date-range-picker
                v-model="filters.date"
                dark
                @change="onDateFilter"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="2"
            >
              <vehicle-autocomplete-filter
                v-model="filters.vehicle"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="2"
            >
              <v-autocomplete
                v-model="filters.fleetType"
                :items="['Frota Própria', 'Terceiro']"
                prepend-inner-icon="reorder"
                label="Tipo de frota"
                hide-details
                filled
                clearable
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="2"
            >
              <routes-autocomplete-filter
                v-model="filters.routes"
                label="Rota"
                dark
                multiple
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
                filled
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :search="filters.search"
          :loading="loading"
          class="elevation-1"
          :items-per-page="-1"
          item-key="nome_prestador"
          show-expand
          single-expand
          hide-default-footer
        >
          <template #[`item.volume`]="{value}">
            <span>{{ formatNumber(value) }} L</span>
          </template>
          <template #[`item.distancia`]="{value}">
            <span>{{ formatNumber(value) }} Km</span>
          </template>
          <template #[`item.distancia_rota`]="{value}">
            <span>{{ formatNumber(value) }} Km</span>
          </template>
          <template #[`item.total_km_gps`]="{value}">
            <span>{{ formatNumber(value) }} Km</span>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0 text-center"
            >
              <v-data-table
                :headers="details.headers"
                :items="item.coletas"
                :items-per-page="-1"
                dense
                hide-default-footer
                class="elevation-1 ma-3"
              >
                <template #[`item.data`]="{value}">
                  <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
                </template>
                <template #[`item.volume`]="{value}">
                  <span>{{ formatNumber(value) }} L</span>
                </template>
                <template #[`item.distancia`]="{value}">
                  <span>{{ formatNumber(value) }} Km</span>
                </template>
                <template #[`item.distancia_rota`]="{value}">
                  <span>{{ formatNumber(value) }} Km</span>
                </template>
                <template #[`item.total_km_gps`]="{value}">
                  <span>{{ formatNumber(value) }} Km</span>
                </template>
                <template #[`item.taxa_ocupacao`]="{value}">
                  <span>{{ formatNumber(value) }} %</span>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <v-card-title>
          <v-spacer />
        </v-card-title>
      </v-card>
    </v-flex>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import VehicleAutocompleteFilter from "@/Support/Components/Filters/VehicleAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    VDateRangePicker,
    VehicleAutocompleteFilter,
    RoutesAutocompleteFilter,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filters: {
        date: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        vehicle: {},
        routes: [],
        fleetType: null,
        search: ''
      },

      headers: [
        { text: "Prestador", value: "nome_prestador" },
        { text: "Volume (L)", value: "volume", width: 180 },
        { text: "Distância (Odômetro)", value: "distancia", width: 180 },
        { text: "Distância (Rota)", value: "distancia_rota", width: 180 },
        { text: "GPS KM", value: "total_km_gps", width: 180 },
      ],

      items: [],

      details: {
        headers: [
          { text: "Rota", value: "nome_rota" },
          { text: "Motorista", value: "motorista" },
          { text: "Placa", value: "placa" },
          { text: "Data", value: "data" },
          { text: "Volume", value: "volume" },
          { text: "Distância (Odômetro)", value: "distancia" },
          { text: "Distância (Rota)", value: "distancia_rota" },
          { text: "GPS KM", value: "total_km_gps" },
          { text: "Taxa de ocupação", value: "taxa_ocupacao" },
        ],
      },
    };
  },

  computed: {
    filteredItems() {
      if (_.isEmpty(this.filters.vehicle) && _.isEmpty(this.filters.fleetType) && _.isEmpty(this.filters.routes))
        return this.items;

      const routes = this.filters.routes.map(({ id }) => id);

      return this.items.filter(item => {
        const filterVehicle = _.isEmpty(this.filters.vehicle) || item.coletas.some(({ placa }) => placa == this.filters.vehicle.plate);
        const filterType = _.isEmpty(this.filters.fleetType) || (this.filters.fleetType === 'Frota Própria' ? item.nome_prestador === 'Frota Própria' :  item.nome_prestador !== 'Frota Própria');
        const filterRoute = _.isEmpty(this.filters.routes) || item.coletas.some(({ id_rota }) => routes.includes(id_rota));
        
        return filterVehicle && filterType && filterRoute;
      }).map(item => {
        let coletas = item.coletas;
        let acc = {};

        /**
         * Se o filtro for de placa ou rota, recalcula os totalizadores do Freteiro
         */
        if (!_.isEmpty(this.filters.vehicle) || !_.isEmpty(this.filters.routes)) {
          coletas = item.coletas.filter(({ placa, id_rota }) => {
            const filterVehicle = _.isEmpty(this.filters.vehicle) || placa == this.filters.vehicle.plate;
            const filterRoute = _.isEmpty(this.filters.routes) || routes.includes(id_rota);

            return filterVehicle && filterRoute;
          });

          acc = coletas.reduce((acc, cur) => ({
            distancia: acc.distancia + parseFloat(cur.distancia || 0),
            distancia_rota: acc.distancia_rota + parseFloat(cur.distancia_rota || 0),
            volume: acc.volume + parseFloat(cur.volume || 0),volume: acc.volume + parseFloat(cur.volume || 0),
            total_km_gps: acc.total_km_gps + parseFloat(cur.total_km_gps || 0),
          }), { distancia: 0, distancia_rota: 0, volume: 0, total_km_gps: 0 });
        }

        return {
          ...item,
          ...acc,
          coletas
        }
      });
    }
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    onDateFilter() {
      this.loadReport();
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.filters.date;

        let { data } = await this.$axios.post(
          `/relatorios/volumeRotasPrestador`,
          {
            data_inicio: startDate,
            data_fim: endDate,
          }
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = _(data)
          .groupBy('nome')
          .map((items, freteiro) => {
            return {
              nome_prestador: freteiro != "null" ? freteiro : "Frota Própria",
              ...items.reduce((acc, cur) => ({
                distancia: acc.distancia + parseFloat(cur.distancia || 0),
                distancia_rota: acc.distancia_rota + parseFloat(cur.distancia_rota || 0),
                volume: acc.volume + parseFloat(cur.volume || 0),
                total_km_gps: acc.total_km_gps + parseFloat(cur.total_km_gps || 0),
              }), { distancia: 0, distancia_rota: 0, volume: 0, total_km_gps: 0 }),
              coletas: items
            }
          }).value();

      } catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de frota", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    getCollectsReportJson(collects) {
      return collects.map((item) => ({
        'Rota': item.nome_rota,
        'Motorista': item.motorista,
        'Placa': item.placa,
        'Data': this.formatDate(item.data, "DD/MM/YYYY"),
        'Volume (L)': this.formatNumber(item.volume),
        'Distância (Odômetro)': this.formatNumber(item.distancia),
        'Distância (Rota)': this.formatNumber(item.distancia_rota),
        'GPS KM': this.formatNumber(item.total_km_gps),
        'Taxa de ocupação': this.formatNumber(item.taxa_ocupacao) + ' %',
      }));
    },

    async print() {
      const [startDate, endDate] = this.filters.date;
      const title = `VOLUME / FROTA - ${moment(startDate).format('DD/MM/YY').toUpperCase()} - ${moment(endDate).format('DD/MM/YY').toUpperCase()}`;

      const json = this.filteredItems;

      if (json.length === 0) {
        return;
      }

      let body = [];

      const report = json.map(item => ({
        title: item.nome_prestador,
        totals: {
          '': '<b>Total:</b>',
          'Volume (L)': this.formatNumber(item.volume),
          'Distância (Odômetro)': this.formatNumber(item.distancia),
          'Distância (Rota)': this.formatNumber(item.distancia_rota),
          'GPS KM': this.formatNumber(item.total_km_gps),
        },
        items: this.getCollectsReportJson(item.coletas)
      }))

      const headers = Object.keys(report[0].items[0])
        .map(header => ({
          key: header,
          label: header,
          class: 'text-left'
        }));

      const headersTotals = Object.keys(report[0].totals)
        .map(header => ({
          key: header,
          label: header,
          class: 'text-left'
        }));

      report
        .forEach(({ title, items, totals }) => {
          body.push({
            tag: 'section',
            class: 'row',
            children: [{
              tag: 'datatable',
              title,
              headers,
              items,
            }]
          });
        
          body.push({
            tag: 'section',
            class: 'row',
            children: [
              {
                tag: 'div',
                class: 'six columns',
                contents: '&nbsp;'
              },
              {
                tag: 'div',
                class: 'six columns',
                children: [{
                  tag: 'datatable',
                  headers: headersTotals,
                  items: [totals],
                }]
              },
            ]
          });
        });

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.printPdf({ pages: [{ title, header, body }] });
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
