<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          class="graph-card report-card"
          color="transparent"
          dark
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-chart
                  type="bar"
                  :options="visitGraph"
                  :style="{width: '100%', height: `300px !important`}"
                  autoresize
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-data-table
              name="Meta de Visitas"
              :headers="[
                { align: 'start', text: 'Mês', value: 'month' },
                { text: 'Produtores Visitados', value: 'produtores_visitados', align: 'center' },
                { text: 'Não Visitados', value: 'n_visitados', align: 'center' },
                { text: '% Visitados', value: 'percent', align: 'center' },
                { text: 'Meta', value: 'total_visita', align: 'center' },
                { text: '', value: 'status', align: 'end' },
                { align: 'end', text: '', value: 'actions', width: 60, sortable: true },
              ]"
              :items="visitados"
              item-key="month"
              :loading="loading"
              class="elevation-1"
              :items-per-page="-1"
              hide-default-footer
              dark
            >
              <template #[`item.month`]="{value}">
                {{ formatMonth(value) }}
              </template>
              <template #[`item.status`]="{value}">
                <v-icon
                  v-if="value=='OK'"
                  right
                  color="green darken-1"
                >
                  verified
                </v-icon>
                <v-icon
                  v-if="value=='NO'"
                  right
                  color="red darken-1"
                >
                  cancel
                </v-icon>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-menu>
                  <template #activator="{ on }">
                    <v-btn
                      dark
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="print(item)">
                      <v-list-item-icon>
                        <v-icon>print</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Imprimir
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <!-- <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn> -->
    </v-speed-dial>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import _ from "lodash";
import XLSX from "xlsx-js-style";

export default {
  name: "grafico",
  
  mixins: [ReportMixin],

  props: {
    aba: String,
    filter: Object,
  },

  data() {
    return {
      loading: false,

      visitGoals: {},

      visitados: [],
    };
  },

  computed: {
    visitGraph() {
      let meta = this.visitados.map(x => x.total_visita) ? this.visitados.map(x => x.total_visita)[0] : '';
      let periodo = this.visitados.map(x => x.periodo)[0];

      return this.generateGraphOptions(this.visitados, 'Meta - ' + meta + ' visita ' + periodo);
    },
  },

  watch: {
    filter: {
      handler() {
        this.loadVisits();
      },
      deep: true
    }
  },

  mounted() {
    this.loadVisits();
  },

  methods: {
    async loadVisits() {
      if (this.filter.project) {
        if (this.aba == 'grafico') {
          await this.loadReportVisitados();
        }
      } else {
        this.visitados = [];
      }
    },

    async loadReportVisitados() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/graficos/visitaProdutor`,
          this.$qs.stringify({
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM'),
          })
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        if (!_.isEmpty(data)) {
          let total_visita  = 0;
          let minimo_visita = 0;
          let tempo_minimo  = 0;
          let periodo       = '';

          if (!_.isEmpty(this.filter.project.visit) ) {
            this.visitGoals = JSON.parse(this.filter.project.visit).map(q => {
              total_visita  = q.total_visita,
              minimo_visita = q.minimo_visita_produtor,
              tempo_minimo  = q.tempo_minimo,
              periodo       = q.periodo
            })
          }
          
          this.visitados = data.map(o => {
            let percentualVisita =  (100 * o.produtores) / o.total_produtores;

            return {
              produtores_visitados: o.produtores,
              total_produtores: o.total_produtores,
              month: o.mes,

              n_visitados: (o.total_produtores - o.produtores),

              total_visita: total_visita,
              minimo_visita: minimo_visita,
              tempo_minimo: tempo_minimo,
              periodo: periodo,

              percent: this.formatNumber(percentualVisita) + ' %',
              status: (o.produtores >= total_visita) ? 'OK' : 'NO',
              details: JSON.parse(o.details),
            }
          });
        }

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as visitas!", "Atenção");

        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    getReportJson() {
      return this.visitados.map(item => ({
        "Mês": item.month,
        "Produtores Visitados": item.produtores_visitados,
        "Não Visitados": item.n_visitados,
        "% Visitados": item.percent,
        "Meta": item.total_visita
      }));
    },

    exportExcel() {
      const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.visitados));
      const workbook = XLSX.utils.book_new();
      const filename = 'Meta de Visitas';
      XLSX.utils.book_append_sheet(workbook, data, _.truncate(filename, { length: 31 }));
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    async print(item) {
      try {
        this.loading = true;

        if (_.isEmpty(item)) {
          return;
        }

        let ids = [];

        ids = item.details.map(x => x.id_pessoa);
        let month = this.visitados.filter( x => x.month === item.month)[0].month;

        const { data } = await this.$axios.post(`/produtores/imprime`,
          {
            month,
            ids,
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM'),
          }
        );

        let fileName = `Meta de Visitas - .pdf`;

        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const newBlob = new Blob([bytes], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);

          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(data);
        link.remove();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
      //return this.printFromJson(this.getReportJson(), 'Meta de Visitas');
    },

    generateGraphOptions(data, title) {
      return {
        color: ['rgba(229, 57, 53, 0.7)',  'rgba(38, 198, 218, 0.7)', '#ffff00'],
        title: {
          text: `${title}`,
          textStyle: { color: '#ddd' },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.month)),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          },
        },
        series: [
          {
            name: 'Visitados',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => Math.abs(o.produtores_visitados).toFixed(2)),
          },
          {
            name: 'Não Visitados',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1
              }
            },
            data: data.map(o =>  Math.abs(o.n_visitados).toFixed(2)),
          },
          {
            name: 'Meta',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1
              }
            },
            data: data.map(o =>  Math.abs(o.total_visita).toFixed(2)),
          }
        ]
      };
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>