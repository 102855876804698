<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          RASTREABILIDADE DOS SILOS
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-card
      color="transparent diff-list"
      dark
      style="margin-top:20px"
    >
      <v-card-title>
        <v-row>
          <v-col
            class="pa-1"
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              placeholder="Busca"
              hide-details
              filled
              dark
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          />

          <v-col
            cols="12"
            md="4"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              @change="loadSilos"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        name="Rastreabilidade dos Silos"
        :headers="headers"
        :loading="loading"
        :items="list"
        :search="filters.search"
        class="diff-list-table"
        dark
      >
        <template v-slot:item.temp="{ value }">
          {{ value }}
        </template>

        <template v-slot:item.silo="{ item }">
          {{ item.silo.description }}
        </template>
      </data-table>

      <div
        class="text-caption text-left ml-4"
        style="margin-top: -58px; height: 58px; line-height: 58px;"
      >
        Total de
        <v-chip
          class="mx-2"
          small
        >
          {{ list.length }}
        </v-chip>registros
      </div>
    </v-card>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>

    <v-speed-dial
      bottom
      class="mr-5"
      dark
      direction="top"
      fixed
      open-on-hover
      right
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          fab
          large
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        color="green darken-1"
        dark
        fab
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import moment from "moment";
import _ from "lodash";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {
  components: {
    VDateRangePicker,
  },

  data() {
    return {
      // Loaders
      loading: false,

      filters: {
        search: "",
        dateRange: [],

      },

      list: [],
    };
  },

  computed: {

    headers() {
      return [
        {
          text: 'Data/Hora',
          value: 'unloadedAt',
          align: 'start',
          sort: (a, b) => moment(a).diff(moment(b)),
        },
        {
          text: 'Rota',
          value: 'route.description',
        },
        {
          text: 'Motorista',
          value: 'driver.name'
        },
        {
          text: 'Silo',
          value: 'silo.description',
          sort: (a, b) => parseInt(a.index.replace(/\D/g, '')) - parseInt(b.index.replace(/\D/g, '')),
        },
        {
          text: 'Temp',
          value: 'temp'
        },
        {
          text: 'Acidez',
          value: 'acidity',
        },
        {
          text: 'Aliz',
          value: 'alizarol',
        },
        {
          text: 'Criosc',
          value: 'cryoscopy',
        },
        {
          text: 'Gord',
          value: 'fat',
        },
        {
          text: 'ESD',
          value: 'esd',
        },
        {
          text: 'Dens',
          value: 'density',
        },
        {
          text: 'Prot',
          value: 'protein',
        },
        {
          text: 'Fraudes',
          value: 'frauds',
        },
        {
          text: 'Analistas',
          value: 'analysts',
        },
      ];
    }

  },

  mounted() {
    this.loadSilos();
  },

  methods: {

    async loadSilos() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.post(
          `/silo/listaRotasMedias`,
          {
            data_inicio: moment(startDate).format("DD/MM/YYYY"),
            data_fim: moment(endDate).format("DD/MM/YYYY"),
          }
        );

        if (!_.isArray(data)) {
          throw 'Dados inválidos';
        }

        this.list = data.map(row => {

          let analysts = !_.isEmpty(row.analistas) ? JSON.parse(row.analistas) : [];
          analysts = analysts.map(analyst => analyst.nome_pessoa).join(' | ');

          return {
            unloadedAt: !_.isEmpty(row.data_hora_entrega) ? moment(row.data_hora_entrega).format('DD/MM/YYYY HH:mm') : '',
            silo: {
              id: row.id_silo,
              index: parseInt(row.descricao_silo.replace(/\D/g, '')),
              description: row.descricao_silo,
            },
            route: {
              description: row.nome_rota,
            },
            driver: {
              name: row.nome_motorista,
            },
            temp: parseFloat(row.temperatura) ? parseFloat(row.temperatura).toFixed(1) : '0.0',
            acidity: parseFloat(row.acidez) ? parseFloat(row.acidez).toFixed(2) : '0.0',
            alizarol: parseFloat(row.alizarol) ? parseFloat(row.alizarol).toFixed(0) : '0',
            cryoscopy: parseFloat(row.crioscopia) ? parseFloat(row.crioscopia).toFixed(3) : '-0.000',
            fat: parseFloat(row.gordura) ? parseFloat(row.gordura).toFixed(2) : '0.00',
            esd: parseFloat(row.esd) ? parseFloat(row.esd).toFixed(2) : '0.00',
            density: parseFloat(row.densidade) ? parseFloat(row.densidade).toFixed(1) : '0.0',
            protein: parseFloat(row.proteina) ? parseFloat(row.proteina).toFixed(2) : '0.00',
            frauds: row.fraudes,
            analysts,
          }
        });
      } catch (error) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o relatório!",
          "Atenção"
        );

        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      const filename = "rastreabilidade_dos_silos";

      this.$refs.report.exportExcel(null, filename);
    }
  },

}
</script>
