<template>
  <v-dialog v-model="show" scrollable :max-width="1200" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6">Configurações</span>
        <v-spacer />
        <v-btn icon small depressed @click="close()">
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation @submit.prevent="save()">
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="form.settingType"
                  label="Tipo de configuração *"
                  :items="types"
                  required
                  :rules="[v => !!v || 'Campo obrigatório!']"
                  @change="changeSettingType"
                />
              </v-col>
              <v-col
                v-if="form.settingType == 'ESTADO' || form.settingType == 'CIDADE' || form.settingType == 'LOCALIDADE'"
                cols="6"
              >
                <states-select
                  v-model="form.state"
                  prepend-inner-icon="villa"
                  label="Estado *"
                  required
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col v-if="(form.settingType == 'CIDADE' || form.settingType == 'LOCALIDADE')" cols="6">
                <cities-select
                  v-model="form.city"
                  :uf="form.state"
                  prepend-inner-icon="domain"
                  label="Cidade *"
                  required
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col v-if="(form.settingType == 'LOCALIDADE' || form.init)" cols="6">
                <locality-select
                  v-model="form.locality"
                  :uf="form.state"
                  :city="form.city"
                  label="Localidade *"
                  prepend-inner-icon="domain"
                  :style="form.init ? 'display: none' : ''"
                  persistent-hint
                  isConfig
                  multiple
                  todas
                  chip
                  autocomplete="new"
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-5" />
            <v-row>
              <v-col cols="3">
                <masked-text-field
                  v-model="date.startBrucelose"
                  label="Início intervalo brucelose *"
                  prepend-inner-icon="today"
                  placeholder="dd/mm/aaaa"
                  :mask="{
                    mask: Date,
                    pattern: 'd/m/00000',
                    lazy: true,
                  }"
                  :rules="[v => {
                    if(!v) {
                      return 'Campo obrigatório!';
                    } else if(v.trim().length < 10) {
                      return 'Data inválida!';
                    } else {
                      return true;
                    }
                  }]"
                />
              </v-col>
              <v-col cols="3">
                <masked-text-field
                  v-model="date.endBrucelose"
                  label="Fim intervalo brucelose *"
                  prepend-inner-icon="today"
                  placeholder="dd/mm/aaaa"
                  :mask="{
                    mask: Date,
                    pattern: 'd/m/00000',
                    lazy: true,
                  }"
                  :rules="[v => {
                    if(!v) {
                      return 'Campo obrigatório!';
                    } else if(v.trim().length < 10) {
                      return 'Data inválida!';
                    } else {
                      return true;
                    }
                  }]"
                />
              </v-col>
              <v-col cols="3">
                <masked-text-field
                  v-model="date.startTuberculose"
                  label="Início intervalo tuberculose *"
                  prepend-inner-icon="today"
                  placeholder="dd/mm/aaaa"
                  :mask="{
                    mask: Date,
                    pattern: 'd/m/00000',
                    lazy: true,
                  }"
                  :rules="[v => {
                    if(!v) {
                      return 'Campo obrigatório!';
                    } else if(v.trim().length < 10) {
                      return 'Data inválida!';
                    } else {
                      return true;
                    }
                  }]"
                />
              </v-col>
              <v-col cols="3">
                <masked-text-field
                  v-model="date.endTuberculose"
                  label="Fim intervalo tuberculose *"
                  prepend-inner-icon="today"
                  placeholder="dd/mm/aaaa"
                  :mask="{
                    mask: Date,
                    pattern: 'd/m/00000',
                    lazy: true,
                  }"
                  :rules="[v => {
                    if(!v) {
                      return 'Campo obrigatório!';
                    } else if(v.trim().length < 10) {
                      return 'Data inválida!';
                    } else {
                      return true;
                    }
                  }]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="green"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StatesSelect from "@/Support/Components/StatesSelect.vue";
import CitiesSelect from "@/Support/Components/CitiesSelect.vue";
import LocalitySelect from "@/Domains/Projects/HealthExamination/Components/LocalitySelect.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

import moment from "moment/moment";

export default {
  components: {
    CitiesSelect,
    StatesSelect,
    LocalitySelect,
    MaskedTextField
  },

  props: {
    value: Boolean,
    editId: String,
  },

  data() {
    return {
      date: {
        startBrucelose: null,
        endBrucelose: null,
        startTuberculose: null,
        endTuberculose: null,
      },

      types: [
        { value: "ESTADO", text: "Estado" },
        { value: "CIDADE", text: "Cidade" },
        { value: "LOCALIDADE", text: "Localidade" },
      ],

      form: [],
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          init: false,
          settingType: "ESTADO",
          state: null,
          city: null,
          locality: null,
        };

        this.date = {
          startBrucelose: null,
          endBrucelose: null,
          startTuberculose: null,
          endTuberculose: null,
        };

        if (this.editId) {
          this.form.init = true;
          this.loadNormativeSetting();
        }
      }
    },
  },

  methods: {
    async loadNormativeSetting() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam-normative-settings/${this.editId}`);

        this.form = {
          settingType: data.tipo_configuracao,
          state: data.estado,
          city: data.cidade,
          locality: data.localidades
        };

        this.date = {
          startBrucelose: data.intervalo_brucelose_inicio ? moment(data.intervalo_brucelose_inicio, "YYYY-MM-DD").format("DD-MM-YYYY") : null,
          endBrucelose: data.intervalo_brucelose_fim ? moment(data.intervalo_brucelose_fim, "YYYY-MM-DD").format("DD-MM-YYYY") : null,
          startTuberculose: data.intervalo_tuberculose_inicio ? moment(data.intervalo_tuberculose_inicio, "YYYY-MM-DD").format("DD-MM-YYYY") : null,
          endTuberculose: data.intervalo_tuberculose_fim ? moment(data.intervalo_tuberculose_fim, "YYYY-MM-DD").format("DD-MM-YYYY") : null,
        };
      } catch (err) {
        console.warn(err);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
        this.form.init = false;
      }
    },

    async save() {
      try {
        if (!(await this.$refs.form.validate())) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          tipo_configuracao: this.form.settingType,
          estado: this.form.state,
          cidade: this.form.city,
          localidades: this.form.locality,
          intervalo_brucelose_inicio: this.date.startBrucelose ? moment(this.date.startBrucelose, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
          intervalo_brucelose_fim: this.date.endBrucelose ? moment(this.date.endBrucelose, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
          intervalo_tuberculose_inicio: this.date.startTuberculose ? moment(this.date.startTuberculose, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
          intervalo_tuberculose_fim: this.date.endTuberculose ? moment(this.date.endTuberculose, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);
        const editId = this.editId || response.id;

        this.$emit("save", editId);
        this.$snotify.success("Registro salvo com sucesso", "Sucesso");
        this.close();
      } catch (e) {
        this.$snotify.error("Erro ao salvar registro", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/projects/health-exam-normative-settings`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/projects/health-exam-normative-settings/${this.editId}`, payload);
      return data;
    },

    changeSettingType() {
      this.form.state = null;
      this.form.city = null;
      this.form.locality = null;
    },

    close() {
      this.show = false;
    },
  },
};
</script>
