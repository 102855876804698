<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#new-notification-tab">
          Nova Mensagem
        </v-tab>

        <v-tab href="#notifications-tab">
          Mensagens
        </v-tab>

        <v-tab href="#notification-param-tab">
          Parametrizar Notificações
        </v-tab>

        <v-tab-item value="new-notification-tab">
          <new-notification-tab
            v-if="tab === 'new-notification-tab'"
          />
        </v-tab-item>

        <v-tab-item value="notifications-tab">
          <notifications-tab
            v-if="tab === 'notifications-tab'"
          />
        </v-tab-item>

          <v-tab-item value="notification-param-tab">
            <notification-param-tab
              v-if="tab === 'notification-param-tab'"
            />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import NewNotificationTab from "@/Domains/Notifications/Views/NewNotificationTab.vue";
import NotificationsTab from "@/Domains/Notifications/Views/NotificationsTab.vue";
import NotificationParamTab from "@/Domains/Notifications/Views/NotificationParamTab.vue";

export default {
  name: "notifications",

  components: {
    NewNotificationTab,
    NotificationsTab,
    NotificationParamTab,
  },

  data() {
    return {
      tab: 'new-notification-tab',
    };
  },
};
</script>
