import { computed, getCurrentInstance } from 'vue'
import axios from '@/Support/Resources/axios-instance.js'
import store from '@/Support/Resources/vuex.js'

export function usePacking(settingsName) {

  const { proxy } = getCurrentInstance()

  const websocket = computed(() => proxy.$socket)

  const settingsId = `PACKING-${settingsName}`

  const packingSettings = computed(() => {
    const settings = store.state.settings?.reports?.[settingsId] || {}

    return {
      computerId: settings.id,
      tagModel: settings.modelo_etiqueta,
      palletTagModel: settings.modelo_etiqueta_pallet,
      generateSscc: settings.gerar_sscc || false,
      autoWeighing: settings.pesagem_automatica || false,
      autoPrinting: settings.impressao_automatica || false,
    }
  })

  const scaleId = computed(() => {
    const system = store.state.settings?.sistema
    const id = store.state.settings?.reports?.[settingsId]?.id

    if (!system || !id) {
      return null
    }

    return `${system}:${id}`
  })

  const savePackingSettings = async ({ computerId, tagModel, palletTagModel, generateSscc, autoWeighing, autoPrinting }) => {
    const payload = {
      id: computerId,
      modelo_etiqueta: tagModel,
      modelo_etiqueta_pallet: palletTagModel,
      gerar_sscc: generateSscc,
      pesagem_automatica: autoWeighing,
      impressao_automatica: autoPrinting,
    }

    const { data } = await axios.post(`/configuracao/salvaConfiguracaoRelatorio`, {
      relatorio: settingsId,
      configuracao: payload,
    })

    if (!data.codigo) {
      throw data
    }

    store.commit('updateReportSettings', { [settingsId]: payload })
  }

  const printTag = (rawData) => {
    const io = websocket.value
    const id = scaleId.value
    if (!io || !id) {
      return
    }

    const [system, computerName] = id.split(':')

    io.emit('print-tag', {
      system,
      computerName,
      rawData,
    })
  }

  return {
    packingSettings,
    savePackingSettings,
    scaleId,
    printTag,
  }
}
