<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-form
      ref="formSort"
      v-model="validForm"
      lazy-validation
    >
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title>
          <v-row>
            <v-col
              md="4"
            >
              <v-autocomplete
                v-model="filters.route"
                :items="routes"
                item-text="descricao"
                item-value="id_rota"
                prepend-inner-icon="icon-rota"
                label="Rotas"
                hide-details
                filled
                @change="loadAnalysis"
              />
            </v-col>
            <v-col
              md="4"
            >
              <v-select
                v-model="filters.group"
                :items="groups"
                label="Grupo"
                item-text="descricao"
                item-value="id_configuracao_grupo_analise"
                prepend-inner-icon="list"
                filled
                @change="loadAnalysis"
              />
            </v-col>

            <v-col
              md="4"
            >
              <date-range-picker
                v-model="filters.date.input"
                @change="onDateFilter"
              />
            </v-col>

            <v-col
              md="12"
            >
              <v-data-table
                dark
                :headers="headers"
                :loading="loading"
                :items="Analysis"
                class="diff-list-table"
                :items-per-page="-1"
                item-key="codigo_laticinio"
                show-expand
                single-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td
                    :colspan="headers.length"
                    class="pa-0"
                  >
                    <v-container>
                      <v-card
                        color="transparent"
                        class="table-detail-container"
                        dark
                      >
                        <v-row>
                          <v-col
                            v-if="item.temperatura"
                            md="2"
                          >
                            Temperatura: <br> {{ item.temperatura ? item.temperatura.toFixed(2):'' }}
                          </v-col>
                          <v-col
                            v-if="item.gordura"
                            md="2"
                          >
                            Acidez: <br> {{ item.acidez ? item.acidez.toFixed(2): '' }}
                          </v-col>
                          <v-col
                            v-if="item.acidez"
                            md="2"
                          >
                            Alizarol: <br> {{ item.alizarol ? item.alizarol.toFixed(2):'' }}
                          </v-col>
                          <v-col
                            v-if="item.alizarol"
                            md="2"
                          >
                            Crioscopia: <br> {{ item.crioscopia ?item.crioscopia.toFixed(2) : '' }}
                          </v-col>
                          <v-col
                            v-if="item.crioscopia"
                            md="2"
                          >
                            % água: <br> {{ item.porcentagem_agua ?item.porcentagem_agua.toFixed(2) :'' }}
                          </v-col>
                          <v-col
                            v-if="item.porcentagem_agua"
                            md="2"
                          >
                            Densidade: <br> {{ item.densidade ?item.densidade.toFixed(2) :'' }}
                          </v-col>
                          <v-col
                            v-if="item.esd"
                            md="2"
                          >
                            Esd: <br> {{ item.esd ?item.esd.toFixed(2):'' }}
                          </v-col>
                          <v-col
                            v-if="item.est"
                            md="2"
                          >
                            Est: <br> {{ item.est ?item.est.toFixed(2):'' }}
                          </v-col>
                          <v-col
                            v-if="item.gordura"
                            md="2"
                          >
                            Gordura: <br> {{ item.gordura ? item.gordura.toFixed(2) : '' }}
                          </v-col>
                          <v-col
                            v-if="item.proteina"
                            md="2"
                          >
                            Proteina: <br> {{ item.proteina ?item.proteina.toFixed(2) :'' }}
                          </v-col>
                          <v-col
                            v-if="item.ph"
                            md="2"
                          >
                            PH: <br> {{ item.ph ?item.ph.toFixed(2):'' }}
                          </v-col>
                          <v-col
                            v-if="item.antibiotico"
                            md="2"
                          >
                            Antibiotico: <br> {{ item.antibiotico ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.cloretos"
                            md="2"
                          >
                            Cloretos: <br> {{ item.cloretos ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.mastite"
                            md="2"
                          >
                            Mastite: <br> {{ item.mastite ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.soda"
                            md="2"
                          >
                            Soda: <br> {{ item.soda ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.formol"
                            md="2"
                          >
                            Formol: <br> {{ item.formol ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.peroxido"
                            md="2"
                          >
                            Peroxido: <br> {{ item.peroxido ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.cloro"
                            md="2"
                          >
                            Cloro/Hipoclorito: <br> {{ item.cloro ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.amido"
                            md="2"
                          >
                            Amido: <br> {{ item.amido ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.sacarose"
                            md="2"
                          >
                            Sacarose: <br> {{ item.sacarose ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.neutralizantes"
                            md="2"
                          >
                            Neutralizantes: <br> {{ item.neutralizantes ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.reconstituintes"
                            md="2"
                          >
                            Reconstituintes: <br> {{ item.reconstituintes ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.conservantes"
                            md="2"
                          >
                            Conservantes: <br> {{ item.conservantes ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.bicarbonato"
                            md="2"
                          >
                            Bicarbonato: <br> {{ item.bicarbonato ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.betalactamico"
                            md="2"
                          >
                            Betalactamico: <br> {{ item.betalactamico ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.tetraciclina"
                            md="2"
                          >
                            Tetraciclina: <br> {{ item.tetraciclina ? 'presente' : 'ausente' }}
                          </v-col>
                          <v-col
                            v-if="item.sulfonamida"
                            md="2"
                          >
                            Sulfonamida: <br> {{ item.sulfonamida ? 'presente' : 'ausente' }}
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-container>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="blue"
        @click="loadRoutes(); loadGroup(); loadAnalysis();"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>
          Recarregar
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Exportar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import XLSX from "xlsx-js-style";
import dateRangePicker from "@/Support/Components/DateRangePicker.vue";
import qs from "qs";

export default {
  components: {
    dateRangePicker,
  },
  filters: {

  },

  data() {
    return {

      validForm: true,

      loading: false,

      filters: {
        route: [],
        group: [],
        date: {
          input: "today",
          range: [],
        },
      },

      routes: [],

      groups: [],

      Analysis: [],

      headers: [
        {
          align: 'start',
          text: "codigo",
          value: "codigo_laticinio",
        },
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Rota",
          value: "rota",
        },
        {
          text: "Grupo",
          value: "grupo",
        },

        {
          text: "Data",
          value: "data",
        },

      ],

    };

  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.loadRoutes();
    this.loadGroup();
    this.loadAnalysis();
  },
  methods: {

    async loadRoutes() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/listaRotaTipoJson`,
          this.$qs.stringify({
            tipo: 3,
          }),
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.routes = data;

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadGroup() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/configuracao/listaConfiguracaoGrupoAnalise`);

        this.groups = data.map(group => {
          return {
            id_configuracao_grupo_analise: group.id_configuracao_grupo_analise,
            descricao: group.descricao,
          }
        });

        //se tiver so um grupo ja traz selecionado
        if (this.groups.length === 1) {
          this.filters.group = this.groups[0].id_configuracao_grupo_analise;
        }

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event;

      this.loadAnalysis();
    },

    async loadAnalysis() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/analiseSorteio/listaAnalises`,
          qs.stringify({
            data_inicio: _.head(this.filters.date.range) || moment().format('YYYY-MM-DD'),
            data_fim: _.last(this.filters.date.range) || moment().format('YYYY-MM-DD'),
            id_rota: this.filters.route,
            id_grupo: this.filters.group,

          })
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.handleAnalysis(data);

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao sortear!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    handleAnalysis(data) {
      this.Analysis = data.map(analysis => {

        let array = {
          codigo_laticinio: analysis.codigo_laticinio,
          nome: analysis.nome,
          rota: analysis.rota,
          grupo: analysis.grupo,
          data: analysis.data ? moment(analysis.data).format("DD/MM/YYYY") : '',
        };

        let itens = JSON.parse(analysis.itens);
        itens.map(item => {
          switch (item) {
            case 'temperatura'      : array["temperatura"] = parseFloat(analysis.temperatura); break;
            case 'acidez'           : array["acidez"] = parseFloat(analysis.acidez); break;
            case 'alizarol'         : array["alizarol"] = parseFloat(analysis.alizarol); break;
            case 'crioscopia'       : array["crioscopia"] = parseFloat(analysis.crioscopia); break;
            case 'porcentagem_agua' : array["porcentagem_agua"] = parseFloat(analysis.porcentagem_agua); break;
            case 'densidade'        : array["densidade"] = parseFloat(analysis.densidade); break;
            case 'esd'              : array["esd"] = parseFloat(analysis.esd); break;
            case 'est'              : array["est"] = parseFloat(analysis.est); break;
            case 'gordura'          : array["gordura"] = parseFloat(analysis.gordura_inicial); break;
            case 'proteina'         : array["proteina"] = parseFloat(analysis.proteina); break;
            case 'ph'               : array["ph"] = parseFloat(analysis.ph); break;
            case 'antibiotico'      : array["antibiotico"] = analysis.antibiotico; break;
            case 'cloretos'         : array["cloretos"] = (analysis.cloretos); break;
            case 'mastite'          : array["mastite"] = (analysis.mastite); break;
            case 'soda'             : array["soda"] = (analysis.soda); break;
            case 'formol'           : array["formol"] = (analysis.formol); break;
            case 'peroxido'         : array["peroxido"] = (analysis.peroxido); break;
            case 'cloro'            : array["cloro"] = (analysis.cloro); break;
            case 'amido'            : array["amido"] = (analysis.amido); break;
            case 'sacarose'         : array["sacarose"] = (analysis.sacarose); break;
            case 'neutralizantes'   : array["neutralizantes"] = (analysis.neutralizantes); break;
            case 'reconstituintes'  : array["reconstituintes"] = (analysis.reconstituintes); break;
            case 'conservantes'     : array["conservantes"] = (analysis.conservantes); break;
            case 'bicarbonato'      : array["bicarbonato"] = (analysis.bicarbonato); break;
            case 'betalactamico'    : array["betalactamico"] = (analysis.betalactamico); break;
            case 'tetraciclina'     : array["tetraciclina"] = (analysis.tetraciclina); break;
            case 'sulfonamida'      : array["sulfonamida"] = (analysis.sulfonamida); break;
            case 'alcool_etilico'   : array["alcool_etilico"] = parseFloat(analysis.alcool_etilico); break;
            case 'lactose'          : array["lactose"] = parseFloat(analysis.lactose); break;
            case 'redutase'   : array["redutase"] = parseFloat(analysis.redutase); break;
            case 'alcalino'   : array["alcalino"] = (analysis.alcalino); break;
          }

        });

        return array;
      });
    },

    exportExcel() {

      let data = XLSX.utils.json_to_sheet(this.Analysis.map((item) => {

        return {
          Codigo: _.isEmpty(item.codigo_laticinio) ? '--' : item.codigo_laticinio,
          Produtor: item.nome,
          Rota: _.isEmpty(item.rota) ? '--' : item.rota,
          Data: _.isEmpty(item.data) ? '' : item.data,
          temperatura: _.isEmpty(item.temperatura) ? '' : item.temperatura,
          acidez: _.isEmpty(item.acidez) ? '' : item.acidez,
          alizarol: _.isEmpty(item.alizarol) ? '' : item.alizarol,
          crioscopia: _.isEmpty(item.crioscopia) ? '' : item.crioscopia,
          porcentagem_agua: _.isEmpty(item.porcentagem_agua) ? '' : item.porcentagem_agua,
          densidade: _.isEmpty(item.densidade) ? '' : item.densidade,
          esd: _.isEmpty(item.esd) ? '' : item.esd,
          est: _.isEmpty(item.est) ? '' : item.est,
          gordura: _.isEmpty(item.gordura) ? '' : item.gordura,
          proteina: _.isEmpty(item.proteina) ? '' : item.proteina,
          ph: _.isEmpty(item.ph) ? '' : item.ph,
          antibiotico: item.antibiotico ? 'presente' : '',
          cloretos: item.cloretos ? 'presente' : '',
          mastite: (item.mastite) ? 'presente' : '',
          soda: item.soda ? 'presente' : '',
          formol: item.formol ? 'presente' : '',
          peroxido: item.peroxido ? 'presente' : '',
          cloro: item.cloro ? 'presente' : '',
          amido: item.amido ? 'presente' : '',
          sacarose: item.sacarose ? 'presente' : '',
          neutralizantes: item.neutralizantes ? 'presente' : '',
          reconstituintes: item.reconstituintes ? 'presente' : '',
          conservantes: item.conservantes ? 'presente' : '',
          bicarbonato: item.bicarbonato ? 'presente' : '',
          betalactamico: item.betalactamico ? 'presente' : '',
          tetraciclina: item.tetraciclina ? 'presente' : '',
          sulfonamida: item.sulfonamida ? 'presente' : '',
          alcool_etilico: _.isEmpty(item.alcool_etilico) ? '' : item.alcool_etilico,
          lactose: _.isEmpty(item.lactose) ? '' : item.lactose,
          redutase: _.isEmpty(item.redutase) ? '' : item.redutase,
          alcalino: item.alcalino ? 'presente' : '',

        };
      }));

      const workbook = XLSX.utils.book_new();

      const filename = "analises_periodicas";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

  },

}
</script>
