<template>
  <div>
    <v-dialog
      v-if="dialog.show"
      v-model="dialog.show"
      width="80%"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title
          class="pb-1"
        >
          Análise de Tanque
          <v-spacer />
          <v-btn
            icon
            small
            depressed
            @click="hide()"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <div
            class="unload-data-info"
          >
            <span
              class="overline"
              style="line-height: 15px !important; font-size: 10px !important;"
            >
              Dados da Descarga
            </span>

            <v-divider
              class="pb-1"
              style="margin-top: 0 !important; margin-bottom: 0 !important;"
            />
            <v-row>
              <v-col
                cols="3"
                md
                lg
                class="pb-1"
              >
                <v-select
                  v-model="unload.rawProductInput"
                  label="Matéria Prima"
                  :items="rawProducts"
                  item-value="id"
                  item-text="name"
                  hide-details
                  return-object
                  :disabled="!hasRawMaterialEditAccess || unload.transfers?.length > 0"
                  dense
                  style="max-width: 180px"
                />
              </v-col>
              <v-col
                cols="3"
                md
                lg
                class="pb-1"
              >
                <v-text-field
                  :value="unload.itinerary.code"
                  label="Itinerário"
                  hide-details
                  disabled
                  dense
                />
              </v-col>
              <v-col
                cols="3"
                md
                lg
                class="pb-1"
              >
                <v-text-field
                  :value="unload.route.description"
                  label="Origem/Destino/Rota"
                  hide-details
                  disabled
                  dense
                />
              </v-col>
              <v-col
                cols="3"
                md
                lg
                class="pb-1"
              >
                <v-text-field
                  :value="unload.vehicle.plate"
                  label="Placa"
                  hide-details
                  disabled
                  dense
                />
              </v-col>
              <v-col
                cols="3"
                md
                lg
                class="pb-1"
              >
                <v-text-field
                  :value="unload.driver.name"
                  label="Motorista"
                  hide-details
                  return-unmasked
                  disabled
                  dense
                />
              </v-col>
              <v-col
                v-if="settings.measurementParam === 'balanca'"
                class="pb-1"
              >
                <masked-input
                  v-model.number="unload.inletWeight"
                  label="Peso Entrada (Kg)"
                  :mask="NumberMask"
                  :max-length="8"
                  hide-details
                  return-unmasked
                  disabled
                  dense
                />
              </v-col>
              <v-col
                v-if="settings.measurementParam === 'balanca'"
                class="pb-1"
              >
                <masked-input
                  v-model.number="unload.outletWeight"
                  label="Peso Saída (Kg)"
                  :mask="NumberMask"
                  :max-length="8"
                  hide-details
                  return-unmasked
                  disabled
                  dense
                />
              </v-col>
              <v-col
                v-if="settings.measurementParam === 'balanca'"
                class="pb-1"
              >
                <masked-input
                  v-model="unload.netWeight"
                  label="Peso Liquido (Kg)"
                  :mask="NumberMask"
                  :max-length="8"
                  disabled
                  hide-details
                  return-unmasked
                  dense
                />
              </v-col>
              <v-col
                class="pb-1"
              >
                <masked-input
                  v-model="unload.vale"
                  label="Vale (L)"
                  :mask="NumberMask"
                  :max-length="8"
                  disabled
                  hide-details
                  return-unmasked
                  dense
                />
              </v-col>
              <v-col
                class="pb-1"
              >
                <masked-input
                  v-model="unload.measured"
                  label="Medidos (L)"
                  :mask="NumberMask"
                  return-unmasked
                  hide-details
                  disabled
                  dense
                />
              </v-col>
              <v-col
                class="pb-1"
              >
                <masked-input
                  v-model="unload.difference"
                  label="Diferença (L)"
                  :mask="NumberMask"
                  disabled
                  return-unmasked
                  hide-details
                  dense
                />
              </v-col>
              <v-col
                class="pb-1"
              >
                <masked-input
                  v-model="unload.discount"
                  label="Desconto (L)"
                  :mask="NumberMask"
                  disabled
                  return-unmasked
                  hide-details
                  dense
                />
              </v-col>
              <v-col
                v-if="unload.itinerary.type === 'spot' && hasSpotTankEditAccess"
                cols="1"
                class="py-1 d-flex justify-end"
              >
                <v-menu class="mb-2">
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="editSpotTanks()"
                    >
                      <v-list-item-icon>
                        <v-icon>local_shipping</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Editar tanques
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <v-divider
              class="mb-1"
              style="margin-top: 0 !important; margin-bottom: 0 !important;"
            />
          </div>

          <tanks-analysis
            ref="tanksAnalysis"
            :unload="unload"
            :tanks.sync="tanks"
            :loading.sync="loading"
            :editing="editing"
            :visible-analysis-params="visibleAnalysisParams"
            :type="unload.itinerary.type"
            :ignore-empty="ignoreEmpty"
            :ignore-errors="ignoreErrors"
            :auto-mark-out-of-range="autoMarkOutOfRange"
            @onTankUpdated="onTankUpdated"
            @onTanksAnalysisSave="onTanksAnalysisSave"
            @close="hide()"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-3"
            text
            @click="hide()"
          >
            CANCELAR
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            outlined
            :disabled="!tanksAnalysisEnable"
            @click="save()"
          >
            SALVAR
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay :value="loading">
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.unload-data-info {

  .v-input--is-disabled {

    .v-input__control {
      color: #1f1f1f !important;

      .v-input__slot::before {
        border: none !important;
      }

      .v-label {
        color: #1f1f1f !important;
      }

      input {
        color: #1f1f1f !important;
      }
    }
  }

  .v-select.v-input--is-disabled .v-select__selections {
    color: #1f1f1f !important;
  }

  .v-select .v-select__selection--disabled {
    color: #1f1f1f !important;
  }

}

</style>

<script>
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import TanksAnalysis from "@/Domains/Platform/Unload/Components/UnloadSteps/Steps/UnloadStep1.vue";

export default {

  components: {
    TanksAnalysis,
    MaskedInput,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },

    visibleAnalysisParams: {
      type: Array,
      default: () => ([]),
    },

    type: String,

    ignoreErrors: {
      type: Array,
      default: () => ([]),
    },

    ignoreEmpty: {
      type: Array,
      default: () => ([]),
    },

    rawProducts: {
      type: Array,
      default: () => ([]),
    },

    autoMarkOutOfRange: Boolean,
    tanksAnalysisEnable: Boolean,
  },

  data() {
    return {
      loading: false,

      // Dados referentes ao Dialog
      dialog: {
        show: false,
      },

      unload: {},

      // Mascara usada nos damais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            signed: true,
            scale: 2,
            max: 99000,
          },
        },
      },
    };
  },

  computed: {

    settings() {
      const settings = this.$store.state.settings.plataforma || {};

      return {
        measurementParam: settings.medicao_descarga_plataforma || 'balanca',
      };
    },

    tanks: {
      get() {
        return this.unload.tanks.filter(tank => tank.vol > 0);
      },
      set(tanks) {
        this.unload.tanks = tanks;
      },
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasSpotTankEditAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'platform-unload-spot-tank-edit' && o.tipo === 'COMPONENTE');
    },

    hasRawMaterialEditAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'platform-unload-raw-material-edit' && o.tipo === 'COMPONENTE');
    },

  },

  methods: {

    show(unload) {
      this.dialog.show = true;
      this.unload = unload;
    },

    save() {
      this.$refs.tanksAnalysis.save();
    },

    hide() {
      this.dialog.show = false;
      this.unload = {};

      return this.$emit('reload');
    },

    onTankUpdated(updatedTank) {
      this.unload.tanks = this.unload.tanks.map(tank => {

        if (tank.index === updatedTank.index) {
          return updatedTank;
        }

        return tank;
      });
    },

    onTanksDiscarded(discardedTanks) {
      this.hide();

      return this.$emit('onTanksDiscarded', discardedTanks);
    },

    onTanksAnalysisSave() {
      return this.$emit('onTanksAnalysisSave');
    },

    editSpotTanks() {
      this.$refs.tanksAnalysis.editSpotTanks()
    }

  },

}
</script>
