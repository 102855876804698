<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            Metas configuradas
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="history.items"
            dark
            class="elevation-1 report-table"
            @click:row="onSelectPlanning($event, true)"
          >
            <template #[`item.date`]="{ value, item }">
              <span :class="{['text-decoration-line-through']: !item.active}">
                {{ value | dateFormat('MM/YYYY') }}
              </span>
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="onSelectPlanning(item, false)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click.stop="openConfirmDialog(item.title, item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Deletar </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="openDuplicateDialog(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>library_add</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Duplicar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue darken-1 mr-5"
          v-on="on"
          @click="onNewPlanning"
        >
          <v-icon dark>
            add
          </v-icon>
        </v-btn>
      </template>

      <span>Novo planejamento</span>
    </v-tooltip>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
    <v-dialog
      v-model="confirmDeleteDialog"
      width="400"
    >
      <v-card>
        <v-card-title class="pa-3">
          <span
            class="subtitle-1"
          >
            Deseja realmente excluir meta ?
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="pa-1"
            color="blue-grey darken-1"
            dark
            text
            @click="confirmDeleteDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="pa-1"
            color="blue darken-2 "
            dark
            text
            @click="deleteGoal"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="duplicateDialog"
      width="400"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title class="pa-3">
            <span
              class="subtitle-1"
            >
              Informe o novo período e protocolo
            </span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  v-model="goalDuplicate.dateAtMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  content-class="menu-select"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="goalDuplicate.qualityValidityStart | dateFormat('MM/YYYY')"
                      label="Mês de início"
                      prepend-inner-icon="event"
                      :rules="[v => !!v || 'Campo obrigatório!']"
                      persistent-hint
                      filled
                      required
                      hide-selected
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="goalDuplicate.qualityValidityStart"
                    :max="goalDuplicate.qualityValidityEnd"
                    type="month"
                    no-title
                    scrollable
                    @input="goalDuplicate.dateAtMenu = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  v-model="goalDuplicate.dateAtMenuEnd"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  content-class="menu-select"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="goalDuplicate.qualityValidityEnd | dateFormat('MM/YYYY')"
                      label="Mês de fim"
                      prepend-inner-icon="event"
                      :rules="[v => !!v || 'Campo obrigatório!']"
                      persistent-hint
                      filled
                      required
                      hide-selected
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="goalDuplicate.qualityValidityEnd"
                    :min="goalDuplicate.qualityValidityStart"
                    type="month"
                    no-title
                    scrollable
                    @input="goalDuplicate.dateAtMenuEnd = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="goalDuplicate.protocol"
                  label="Protocolo"
                  type="text"
                  persistent-hint
                  filled
                  hide-selected
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="pa-1"
              color="blue-grey darken-1"
              dark
              text
              @click="duplicateDialog = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="pa-1"
              color="blue darken-2 "
              dark
              text
              @click="duplicateGoal"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

export default {
  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  props: {
    project: String,

    view: String,
  },

  data() {
    return {
      loading: false,

      history: {
        items: [],
      },

      confirmDeleteDialog: false,

      goalAction: {
        name: null,
        id: null,
        disabledEdition: false,
      },

      duplicateDialog: false,

      goalDuplicate: {
        id_duplicate: null,
        dateAtMenu: false,
        dateAtMenuEnd: false,
        qualityValidityStart: null,
        qualityValidityEnd: null,
        protocol: null,
      }
    };
  },
  computed: {
    headers() {
      let header = [];
      if (this.project == 'Dairy' || this.project == 'Dairy-Award') {
        header = [
          { text: 'Descrição', value: 'description' },
          { text: '', value: 'action', width: 30 },
        ];
      } else {
        header = [
          { text: 'Vigência', value: 'text', width: 200 },
          { text: 'Protocolo', value: 'protocol' },
          { text: '', value: 'action', width: 30 },
        ];
      }
      return header;
    },

  },

  created() {
    this.loadHistory();
  },

  methods: {
    async loadHistory() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: this.project,
            view: this.view,
          }
        );

        if (!_.isArray(data)) {
          throw "PHP error";
        }
        this.history.items = data.map(o => ({
          id: o.id_assistencia,
          text: "De " + moment(o.data).format('MM/YYYY') + " até " + moment(o.data_fim).format('MM/YYYY'),
          protocol: o.protocolo,
          active: o.ativo,
          selected: null,
          description: o.descricao,
        }));

      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false;
      }
    },

    onNewPlanning() {
      this.$emit('newPlanning');
    },

    onSelectPlanning({ id }, show) {
      this.$emit('selectPlanning', id, show);
    },

    openConfirmDialog(name, id) {
      this.goalAction = {
        name,
        id
      };

      this.confirmDeleteDialog = true;
    },

    openDuplicateDialog(id) {
      this.duplicateDialog = true;
      this.goalDuplicate.id_duplicate = id;
    },

    async duplicateGoal() {
      try {
        if (!await this.$refs.form.validate()) {
          this.$snotify.warning("Campos inválidos", "Atenção");
          return false;
        }

        this.$root.$progressBar.saving();
        if (!this.goalDuplicate.id_duplicate) {
          this.duplicateDialog = false;
          this.$snotify.error("Identificador da meta não encontrada!", "Atenção");
          throw "Identificador da meta não encontrada";
        }

        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/duplicaMeta`,
          this.$qs.stringify({
            id_referencia: this.goalDuplicate.id_duplicate,
            data: moment(this.goalDuplicate.qualityValidityStart).startOf("month").format('YYYY-MM-DD') || null,
            data_fim: moment(this.goalDuplicate.qualityValidityEnd).endOf("month").format('YYYY-MM-DD') || null,
            protocolo: this.goalDuplicate.protocol,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        if (!data.codigo) {
          this.$snotify.error("Oops, ocorreu um erro ao duplicar meta!", "Atenção");
        } else {
          this.$snotify.success("Sucesso, meta duplicada com sucesso !", "Sucesso");
        }

      } catch (e) {
        console.log(e);
      } finally {
        this.$root.$progressBar.hide();
        this.goalDuplicate.id_duplicate = null;
        this.goalDuplicate.qualityValidityStart = null;
        this.goalDuplicate.qualityValidityEnd = null;
        this.goalDuplicate.protocol = null;
        this.duplicateDialog = false;
        this.loadHistory();
      }
    },

    async deleteGoal() {
      try {
        if (!this.goalAction.id) {
          this.confirmDeleteDialog = false;
          throw "Identificador da meta não encontrada";
        }

        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/deletaMeta`,
          this.$qs.stringify({
            id_assistencia: this.goalAction.id,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        if (!data.codigo) {
          this.$snotify.error("Oops, ocorreu um erro ao excluir meta!", "Atenção");
        }

        this.confirmDeleteDialog = false;
        this.$snotify.success("Sucesso, meta excluida com sucesso !", "Sucesso");
        this.loadHistory();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
