<template>
  <div>
    <v-container
      class="silo"
      @click="onClick"
    >
      <v-row class="pa-0 ma-0 pb-3">
        <v-col
          cols="10"
          class="pa-0"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="text-overline text-truncate text-left mb-0"
                style="line-height: 18px !important;"
                v-on="on"
              >
                {{ label }}
              </div>
            </template>

            {{ label }}
          </v-tooltip>
          <p
            class="text-overline text-left mb-0"
            style="line-height: 18px !important;"
          >
            {{ silo.vol || 0 }}L / {{ silo.capacity || 0 }}L
          </p>
          <p
            class="text-overline text-left mb-0"
            style="min-height: 24px;"
          >
            <v-tooltip
              v-if="silo.rawProduct.id"
              top
            >
              <template #activator="{ on, attrs }">
                <v-chip
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ (silo.rawProduct.name || '').toUpperCase() | truncate(23) }}
                </v-chip>
              </template>

              {{ silo.rawProduct.name }}
            </v-tooltip>
          </p>
          <p
            v-if="validateSiloVol"
            class="text-left mb-0"
            style="min-height: 24px;"
          >
            <v-tooltip
              v-if="isTraceabilityEnabled && silo.milktime"
              top
            >
              <template #activator="{ on, attrs }">
                <v-chip
                  small
                  :color="getMilktimeColor(silo.milktime)"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="onSiloTraceability"
                >
                  {{ getMilktimeFormatted(silo.milktime) }}
                </v-chip>
              </template>

              {{ formatDate(silo.milktime, 'DD/MM/YYYY HH:mm') }}
            </v-tooltip>
          </p>
        </v-col>

        <v-col
          cols="2"
          class="pa-0 text-right"
        >
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                small
                v-on.stop="on"
              >
                <v-icon
                  dark
                  dense
                >
                  more_vert
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="isTransferActionsEnabled && hasEditSiloAccess"
                @click="onSiloProductionTransferring"
              >
                <v-list-item-avatar>
                  <v-icon>
                    get_app
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Produção</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="isTransferActionsEnabled && hasPermissionToSeeBetweenSilos"
                @click="onSiloToSiloTransferring"
              >
                <v-list-item-avatar>
                  <v-icon>
                    get_app
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Entre Silos</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasSiloCip"
                @click="onSiloClearing"
              >
                <v-list-item-avatar>
                  <v-icon>
                    published_with_changes
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>CIP</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="isTransferActionsEnabled && hasEditSiloAccess"
                @click="onUnitTransferring"
              >
                <v-list-item-avatar>
                  <v-icon>
                    multiple_stop
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Unidade</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="isTransferActionsEnabled && hasEditSiloAccess"
                @click="onSpotTransferring"
              >
                <v-list-item-avatar>
                  <v-icon>
                    business
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Spot</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasCreatePeriodicSiloAnalysisAccess"
                @click="onSiloPeriodicAnalysis"
              >
                <v-list-item-avatar>
                  <v-icon>
                    biotech
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Análise</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="isTraceabilityEnabled"
                @click="onSiloTraceability"
              >
                <v-list-item-avatar>
                  <v-icon>
                    query_stats
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Rastreabilidade</v-list-item-title>
              </v-list-item>

              <v-divider />

              <v-list-item
                v-if="hasEditSiloAccess"
                @click="onSiloEditing"
              >
                <v-list-item-avatar>
                  <v-icon>
                    create
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="silo.rawProduct.id && hasEditSiloAccess"
                @click="onSiloAnalysisStandard"
              >
                <v-list-item-avatar>
                  <v-icon>
                    biotech
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Padrões de Análise</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasDeleteSiloAccess"
                @click="onSiloExcluding"
              >
                <v-list-item-avatar>
                  <v-icon>
                    delete
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-progress-linear
        :value="utilization"
        color="grey"
        height="25"
      >
        <template #default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </v-container>
  </div>
</template>

<style lang="scss">
.silo {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
}

.silo:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.08);
}
</style>

<script>
import _ from "lodash";
import moment from 'moment';

export default {

  name: 'SiloInfo',

  filters: {

    truncate(value, length) {
      return _.truncate(value, { length });
    },

  },

  props: {
    label: {
      type: String,
    },
    silo: {
      type: Object,
      default: () => ({
        label: '',
        capacity: 0,
        vol: 0,
      }),
    },
  },

  data() {
    return {
    }
  },

  computed: {

    utilization() {
      if (!this.silo.vol || !this.silo.capacity) {
        return 0;
      }

      return  ((this.silo.vol / this.silo.capacity) * 100).toFixed(0) || 0;
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    hasCreatePeriodicSiloAnalysisAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'periodic-silo-analysis-insert' && o.tipo === 'COMPONENTE');
    },

    hasDeleteSiloAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-delete' && o.tipo === 'COMPONENTE');
    },

    hasEditSiloAccess() {
      return this.isAdmin || this.userResources.some(o => ['silo-insert-edit', 'silo-item-edit'].includes(o.recurso) && o.tipo === 'COMPONENTE');
    },

    validateSiloVol() {
      return this.$store.state.settings.gerais.validar_saldo_silo;
    },

    isTransferActionsEnabled() {
      return (!this.validateSiloVol || this.silo.vol > 0) && this.silo.rawProduct.id;
    },

    isTraceabilityEnabled() {
      return this.validateSiloVol && this.silo.vol > 0 && this.silo.rawProduct.id;
    },

    hasPermissionToSeeBetweenSilos() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "see-between-silo" && o.tipo === "COMPONENTE");
    },

    hasSiloCip() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-cip' && o.tipo === 'COMPONENTE');
    },
  },

  methods: {

    onSiloProductionTransferring() {
      return this.$emit('onSiloProductionTransferring', this.silo);
    },

    onSiloToSiloTransferring() {
      return this.$emit('onSiloToSiloTransferring', this.silo);
    },

    onSiloClearing() {
      return this.$emit('onSiloClearing', this.silo);
    },

    onUnitTransferring() {
      return this.$emit('onUnitTransferring', this.silo);
    },

    onSpotTransferring() {
      return this.$emit('onSpotTransferring', this.silo);
    },

    onSiloPeriodicAnalysis() {
      return this.$emit('onSiloPeriodicAnalysis', this.silo);
    },

    onSiloEditing() {
      return this.$emit('onSiloEditing', this.silo);
    },

    onSiloExcluding() {
      return this.$emit('onSiloExcluding', this.silo);
    },

    onSiloAnalysisStandard() {
      return this.$emit('onSiloAnalysisStandard', this.silo);
    },

    onSiloTraceability() {
      return this.$emit('onSiloTraceability', this.silo);
    },

    onClick() {
      return this.$emit('click', this.silo);
    },

    getMilktimeColor(value) {
      const hours = moment.duration(moment().diff(value)).asHours();
      if (hours > 48) {
        return 'red';
      }
      if (hours > 24) {
        return 'orange';
      }
      return 'green';
    },

    getMilktimeFormatted(value) {
      const hours = moment.duration(moment().diff(value)).asHours();
      if (hours > 48) {
        return '> 48 horas';
      }
      if (hours > 24) {
        return '> 24 horas';
      }
      return '< 24 horas';
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

  },

}
</script>
