<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col
        cols="12"
        class="text-xs-center"
      >
        <h2 class="menu-header white--text mt-0 mb-2">
          Fluxo de Movimentações do Silo
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="6"
                sm="3"
                md="2"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filters.range"
                  :ranges="dateRanges"
                  dark
                  :disabled="!!isFilteringLot"
                  @change="onFilter"
                />
              </v-col>
              <v-col
                cols="6"
                sm="3"
                md="2"
                class="pt-0"
              >
                <v-select
                  v-model="filters.silos"
                  :items="silos"
                  clearable
                  filled
                  item-value="id"
                  item-text="label"
                  hide-details
                  label="Silos"
                  multiple
                  prepend-inner-icon="panorama_vertical_select"
                  @change="onFilter"
                />
              </v-col>

              <v-col
                cols="6"
                sm="3"
                md="2"
                class="pt-0"
              >
                <raw-material-autocomplete
                  v-model="filters.rawMaterialId"
                  label="Matéria Prima"
                  only-parents
                  clearable
                  filled
                  hide-details
                  @change="onFilter"
                />
              </v-col>

              <v-col
                cols="6"
                sm="3"
                md="2"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.lotNumber"
                  label="Número do Lote"
                  prepend-inner-icon="search"
                  single-line
                  clearable
                  filled
                  hide-details
                  @change="onFilter"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                class="d-flex justify-space-between align-center pt-0"
              >
                <div
                  v-if="!isFilteringLot"
                  class="d-flex flex-column justify-end flex-grow-1 text-center"
                  style="border-left: 2px solid #fff"
                >
                  <span class="caption font-weight-light">
                    Volume anterior {{ formatDate(previousDate, 'DD/MM/YYYY') }}
                  </span>
                  <span
                    class="body-1 font-weight-bold text--accent-3"
                    :class="{'green--text': previousBalance > 0, 'red--text': previousBalance < 0}"
                  >
                    {{ formatNumber(previousBalance) }}
                  </span>
                </div>
                <div
                  class="d-flex flex-column justify-end flex-grow-1 text-center"
                  style="border-left: 2px solid #fff"
                >
                  <span class="caption font-weight-light">
                    Entradas
                    <v-icon
                      color="green accent-3"
                      small
                    >
                      south_west
                    </v-icon>
                  </span>
                  <span
                    class="body-1 font-weight-bold green--text text--accent-3"
                  >
                    {{ formatNumber(incoming) }}
                  </span>
                </div>
                <div
                  class="d-flex flex-column justify-end flex-grow-1 text-center"
                  style="border-left: 2px solid #fff;"
                >
                  <span class="caption font-weight-light">
                    Saídas
                    <v-icon
                      color="red accent-3"
                      small
                    >
                      north_east
                    </v-icon>
                  </span>
                  <span
                    class="body-1 font-weight-bold red--text"
                  >
                    {{ formatNumber(outcoming) }}
                  </span>
                </div>
                <div
                  class="d-flex flex-column justify-end flex-grow-1 text-center"
                  style="border-left: 2px solid #fff"
                >
                  <span class="caption font-weight-light">
                    <template v-if="!isFilteringLot">
                      Volume em {{ formatDate(filters.range[1], 'DD/MM/YYYY') }}
                    </template>
                    <template v-else>
                      Volume
                    </template>
                  </span>
                  <span
                    class="headline font-weight-bold text--accent-3"
                    :class="{'green--text': balance > 0, 'red--text': balance < 0}"
                  >
                    {{ formatNumber(balance) }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <data-table
            ref="report"
            :headers="headers"
            :items="items"
            :loading="loading"
            :group-by="!isFilteringLot ? 'lotNumber' : undefined"
            dark
            dense
            :items-per-page="20"
            :footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
          >
            <template #[`group.header`]="{ group, isOpen, toggle, items }">
              <td class="text-start">
                <v-btn
                  icon
                  @click="toggle"
                >
                  <v-icon>
                    {{ isOpen ? 'remove' : 'add' }}
                  </v-icon>
                </v-btn>
              </td>
              <td class="text-start">
                <span v-if="group">
                  Lote: <b>{{ group }}</b>
                </span>
              </td>
              <td
                class="text-start"
                :colspan="headers.length - 6"
              />
              <td class="text-start">
                Vol. anterior:
                <v-chip small>
                  <v-avatar left>
                    <v-icon
                      v-if="(lotsPreviousBalance[group] || {}).previousBalance < 0"
                      color="red"
                      v-text="'arrow_circle_up'"
                    />
                    <v-icon
                      v-else
                      color="green"
                      v-text="'arrow_circle_down'"
                    />
                  </v-avatar>

                  {{ formatNumber((lotsPreviousBalance[group] || {}).previousBalance) }}
                </v-chip>
              </td>
              <td class="text-start">
                Entradas:
                <v-chip small>
                  <v-avatar left>
                    <v-icon
                      color="green accent-3"
                      v-text="'south_west'"
                    />
                  </v-avatar>

                  {{ formatNumber((lotsPreviousBalance[group] || {}).incoming) }}
                </v-chip>
              </td>
              <td class="text-start">
                Saídas:
                <v-chip small>
                  <v-avatar left>
                    <v-icon
                      color="red accent-3"
                      v-text="'north_east'"
                    />
                  </v-avatar>

                  {{ formatNumber((lotsPreviousBalance[group] || {}).outcoming) }}
                </v-chip>
              </td>
              <td class="text-start">
                Volume:
                <v-chip small>
                  <v-avatar left>
                    <v-icon
                      v-if="(lotsPreviousBalance[group] || {}).balance < 0"
                      color="red"
                      v-text="'arrow_circle_up'"
                    />
                    <v-icon
                      v-else
                      color="green"
                      v-text="'arrow_circle_down'"
                    />
                  </v-avatar>

                  {{ formatNumber((lotsPreviousBalance[group] || {}).balance) }}
                </v-chip>
              </td>
            </template>
            <template #[`item.icon`]="{ item }">
              <v-icon
                v-if="item.type == 'ESTORNO'"
                color="orange accent-3"
              >
                undo
              </v-icon>
              <v-icon
                v-else-if="item.inOut === 'IN'"
                color="green accent-3"
              >
                south_west
              </v-icon>
              <v-icon
                v-else
                color="red accent-3"
              >
                north_east
              </v-icon>
            </template>

            <template #[`item.type`]="{ value }">
              <v-chip
                small
              >
                {{ getTypeName(value) }}
              </v-chip>
            </template>

            <template #[`item.transferredAt`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
            </template>

            <template #[`item.tank`]="{ value }">
              {{ getTankName(value) }}
            </template>

            <template #[`item.transferredVol`]="{ item }">
              <v-chip small>
                <v-avatar left>
                  <v-icon
                    :color="item.inOut === 'OUT' ? 'red' : 'green'"
                  >
                    {{ item.inOut === 'OUT' ? 'arrow_circle_up' : 'arrow_circle_down' }}
                  </v-icon>
                </v-avatar>

                <span v-if="item.transferredVol !== 0">
                  {{ formatNumber(item.transferredVol) }}
                </span>

                <span v-else>
                  ----
                </span>
              </v-chip>
            </template>

            <template #[`item.rawMaterial.description`]="{ value }">
              <v-chip
                v-if="value"
                small
              >
                {{ value }}
              </v-chip>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-speed-dial
      fixed
      fab
      dark
      bottom
      right
      open-on-hover
      class="mr-2"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue';
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import moment from 'moment';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

const TypesEnum = {
  ENTRADA_TANQUE: 'Entrada Coleta',
  ENTRADA_PRODUCAO: 'Entrada Produção',
  SAIDA_PRODUCAO: 'Saída Produção',
  AJUSTE: 'Ajuste',
  CIP: 'CIP',
  ENTRE_SILOS: 'Transferência Entre Silos',
  SAIDA_UNIDADE: 'Saída Unidade',
  ENTRADA_UNIDADE: 'Entrada Unidade',
  SAIDA_SPOT: 'Saída Spot',
  ENTRADA_SPOT: 'Entrada Spot',
  ESTORNO: 'Estorno',
}

export default {

  components: {
    RawMaterialAutocomplete,
    VDateRangePicker,
  },

  data() {
    return {
      loading: false,

      isFilteringLot: false,

      filters: {
        range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        silos: [],
        lotNumber: null,
        rawMaterialId: null,
      },

      silos: [],

      dateRanges: {
        'Hoje': [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        'Ontem': [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
        'Esta semana': [moment().isoWeekday(0).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        'Semana anterior': [moment().isoWeekday(0).subtract(7, 'days').format('YYYY-MM-DD'), moment().isoWeekday(0).subtract(1, 'days').format('YYYY-MM-DD')],
      },

      headers: [
        { text: '', value: 'icon' },
        { text: 'Tipo', value: 'type', formatter: value => this.getTypeName(value) },
        { text: 'OP/Origem/Destino/Rota', value: 'origin', align: 'center' },
        { text: 'Itinerário', value: 'code' },
        { text: 'Silo', value: 'silo.description' },
        { text: 'Movimentado Em', value: 'transferredAt', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
        { text: 'Volume', value: 'transferredVol' },
        { text: 'Tanque', value: 'tank', formatter: value => this.getTankName(value) },
        { text: 'Matéria Prima', value: 'rawMaterial.description' },
        { text: 'Nº Lote', value: 'lotNumber' },
        { text: 'Observação', value: 'obs' },
      ],

      items: [],

      previousBalance: 0,

      lotsPreviousBalance: {},
    };
  },

  computed: {
    incoming() {
      return this.items.filter(o => o.transferredVol > 0).reduce((acc, cur) => acc + cur.transferredVol, 0);
    },
    outcoming() {
      return this.items.filter(o => o.transferredVol < 0).reduce((acc, cur) => acc + cur.transferredVol, 0);
    },
    balance() {
      return parseFloat(this.previousBalance) + this.items.reduce((acc, cur) => acc + cur.transferredVol, 0);
    },
    previousDate() {
      return moment(this.filters.range[0]).subtract(1, 'days').format('YYYY-MM-DD')
    }
  },

  mounted() {
    this.loadSilos();
  },

  methods: {
    onFilter: debounce(function () {
      this.getHistory();
      this.isFilteringLot = !!this.filters.lotNumber;
    }, 500),

    getReportTitle() {
      if (this.isFilteringLot) {
        return `Fluxo Movimentações do Silo - ${this.isFilteringLot}`;
      }

      const [startDate, endDate] = this.filters.range;
      return `Fluxo Movimentações do Silo - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    async print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const title = this.getReportTitle();
      this.$refs.report.exportExcel(null, title);
    },

    async loadSilos() {
      try {
        const { data } = await this.$axios.post(`/silo/principal`);

        if (!data.silo) {
          throw 'PHP Error';
        }

        this.silos = data.silo
          .map(silo => ({
            index: (silo.descricao || '').replace(/\D/g, ''),
            id: silo.id_silo,
            label: silo.descricao,
            vol: parseInt(silo.volume_atual),
            capacity: parseInt(silo.volume_total),
          }))
          .sort((a, b) => a.index - b.index);
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao carregar os silos!', 'Atenção');
      }
    },

    async getHistory() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.range;

        const { data } =  await this.$axios.get(`/industry/silo/flow`, { params: {
          start_date: startDate,
          end_date: endDate,
          silos: this.filters.silos,
          lotNumber: this.filters.lotNumber,
          rawMaterialId: this.filters.rawMaterialId,
        } });

        this.previousBalance = data.saldo_anterior || 0;

        this.items = data.movimentos.map(history => ({
          id: history.id_silo_movimento,
          code: history.cod_itinerario || '',
          silo: {
            id: history.id_silo,
            description: history.nome_silo,
          },
          rawMaterial: {
            id: history.id_materia_prima,
            description: history.nome_materia_prima,
          },
          transferredVol: parseInt(history.volume),
          transferredAt: history.data,
          tank: history.tanque,
          obs: history.observacao || '',
          type: history.tipo,
          inOut: history.volume > 0 ? 'IN' : 'OUT',
          lotNumber: history.numero_lote,
          origin: history.origem || '',
        }))

        if (!this.isFilteringLot) {
          const group = groupBy(this.items, 'lotNumber');

          this.lotsPreviousBalance = mapValues(group, (items, lotNumber) => {
            const previousBalance = data.saldo_lotes[lotNumber] || 0;
            return {
              previousBalance: previousBalance,
              incoming: items.filter(o => o.transferredVol > 0).reduce((acc, cur) => acc + cur.transferredVol, 0),
              outcoming: items.filter(o => o.transferredVol < 0).reduce((acc, cur) => acc + cur.transferredVol, 0),
              balance: parseFloat(previousBalance) + items.reduce((acc, cur) => acc + cur.transferredVol, 0),
            }
          });
        } else {
          this.lotsPreviousBalance = {};
        }
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao carregar as movimentações!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getTankName: (value) => {
      const tanks = {
        tanque1: 'Tanque 1',
        tanque2: 'Tanque 2',
        tanque3: 'Tanque 3',
        tanque4: 'Tanque 4',
        tanque5: 'Tanque 5',
        tanque6: 'Tanque 6',
        tanque7: 'Tanque 7',
        tanque8: 'Tanque 8'
      }

      return tanks[value] || ''
    },

    getTypeName: (value) => TypesEnum[value] || value,
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },

};
</script>
