<template>
  <div>
    <v-row>
      <!-- <v-col
        cols="12"
        md="3"
        offset-md="9"
      >
        <month-range-picker
          v-model="filters.range"
          :ranges.sync="filters.ranges"
          :min="filters.min"
          :max="filters.max"
          dark
        />
      </v-col> -->
      <v-col
        md="11"
        class="pb-12"
      >
        <v-chart
          ref="chart"
          type="bar"
          :options="Options"
          :style="{width: '100%', height: `300px !important`}"
          autoresize
        />
      </v-col>
      <v-col
        v-if="goal.dados.length > 0 && (goal.acao === 'REDUZIR' || goal.acao === 'AUMENTAR')"
        md="1"
        class="pt-12"
      >
        <p>Médias </p>
        <p>Meta: {{ parseInt(goal.dados[0].goals) }} </p>
        <p>Inicial: {{ parseInt(goal.dados[0].media) }} </p>
        <p>
          Final: {{ parseInt(goal.dados[goal.dados.length-1].media) }}
          <v-icon
            right
            :color="goal.dados[goal.dados.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
          >
            verified
          </v-icon>
        </p>
      </v-col>
      <v-col
        v-else-if="goal.dados.length > 0 "
        md="1"
        class="pt-12"
      >
        <p>Médias </p>
        <p>Meta: {{ (goal.quantidade) }}  </p>
        <p>
          <v-icon
            right
            :color="goal.dados[goal.dados.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
          >
            verified
          </v-icon>
        </p>
      </v-col>
    </v-row>

    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando criticos e prioritarios...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";

export default {
  name: "quality-report",

  components: {
    MonthRangePicker,
  },

  props: {
    goal: Object,

    filter: Object,
  },

  data() {
    return {
      dateStart: '',
      dateEnd: '',
      filters: {
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [this.goal.data_inicio ? moment(this.goal.data_inicio).format('YYYY-MM') : moment().format('YYYY-MM'), this.goal.data_fim ? moment(this.goal.data_fim).format('YYYY-MM') : moment().format('YYYY-MM')],

        min: moment().subtract(5, 'years').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),
      },
      loading: false,

    };
  },

  computed: {
    Options() {
      return this.generateGraphOptions(this.goal.dados, this.goal.titulo);
    },

  },

  mounted() {

  },

  methods: {

    generateGraphOptions(data, title ) {
      if (_.isEmpty(data)) {
        return ;
      }

      let metaTexto = '';
      let metaValor = '';
      if (!this.goal.media) {
        metaTexto =   `${this.goal.acao} média ${this.goal.indicador} ${this.goal.quantidade}` ;
        metaValor =  ` ( Meta ${this.goal.indicador}: ${parseInt(data[0].goals)} )`;
      } else {
        metaTexto = `Adequar ${this.goal.quantidade} produtores com ${this.goal.indicador} ${this.goal.acao} de ${this.goal.media} `;
        // metaTexto += this.goal.dias_entrada > 0 ? ` com data de entrada maior que ${this.goal.dias_entrada} dias` : '';
      }

      let subtitle  =  metaTexto + metaValor;

      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(140, 23, 23, 1)', 'rgba(140, 23, 23, 0.5)'],
        title: {
          text: `${title}`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtext: `${subtitle}`,
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          padding: [0, 0, 30, 50],
        },
        legend: {
          // width: 800,
          // top: 100,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 200, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {
                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });

                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.data)),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          },
        },
        series: [
          (this.goal.acao === 'ABAIXO' || this.goal.acao === 'ACIMA') ?
            {
              name: `Total de Produtores`,
              type: 'bar',
              showSymbol: false,
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => Math.abs(o.acima + o.abaixo).toFixed(0)),
            }
            : {
              name: 'Média',
              type: 'bar',
              showSymbol: false,
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => Math.abs(o.media).toFixed(0)),
            },
          {
            name: 'Meta',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: false,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o =>  Math.abs(o.goals).toFixed(0)),
          },
          (this.goal.acao === 'ABAIXO' || this.goal.acao === 'ACIMA') ?
            {
              name: `Produtores Acima de ${this.goal.media}`,
              type: 'bar',
              barGap: '-100%',
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => Math.abs(o.acima).toFixed(0)),
            }
            : null,
          (this.goal.acao === 'ABAIXO' || this.goal.acao === 'ACIMA') ?
            {
              name: `Produtores Abaixo de ${this.goal.media}`,
              type: 'bar',
              barGap: '-100%',
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => Math.abs(o.abaixo).toFixed(0)),
            }
            : null,
        ]
      };
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
