import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist';

import settings from "./vuex.settings.js";
import maintenance from "./vuex.maintenance.js";
import socket from "./vuex.socket.js";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [
    'settings',
  ]
});

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    settings,
    maintenance,
    socket,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
export const useStore = () => store;
