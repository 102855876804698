<template>
  <v-row>
    <v-col
      offset="9"
      md="3"
    >
      <v-autocomplete
        v-model="filter.producer"
        :items="listProducer"
        :loading="loading"
        clearable
        dark
        filled
        label="Produtor"
        prepend-inner-icon="person_outline"
        item-text="name"
        item-value="id_produtor"
      />
    </v-col>
    <v-col
      md="12"
      cols="12"
    >
      <v-flex>
        <average-graphic
          v-for="q in quality"
          :key="q.indicador"
          :goal="q"
          :filter="filter"
          type-project="PMLS"
        />
      </v-flex>
    </v-col>
  </v-row>
</template>

<script>
import AverageGraphic from "@/Domains/Projects/PMLS/Components/AverageGraphic.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    AverageGraphic
  },

  props: {
    filter: Object,
  },

  data() {
    return {
      loading: false,
      quality:
        [
          { "indicador": "CPP" },
          { "indicador": "CCS" },
          { "indicador": "gordura" },
          { "indicador": "proteina" },
          { "indicador": "caseina" },
          { "indicador": "solidos_totais" },
          { "indicador": "esd" },
          { "indicador": "lactose" },
          { "indicador": "ureia" },
          { "indicador": "volume" },
        ],
      listProducer: [],
    };
  },
  async mounted() {
    await this.loadProducer();
  },

  methods: {

    async loadProducer() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/produtores/listaProdutorPMLS`,
          this.$qs.stringify({
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM'),
          })
        );

        this.listProducer = data.map((producer) => ({
          id_produtor: producer.id_produtor,
          name: producer.nome_produtor
        }));
        console.log(this.listProducer);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
