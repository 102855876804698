<template>
  <v-dialog
    v-model="show"
    width="650"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Aplicativo
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-stepper
        v-model="step"
        alt-labels
        class="elevation-0"
      >
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="step > 1"
          >
            Dados
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="2"
          >
            Laticínios
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="3"
          >
            Upload
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-text>
              <v-form
                ref="dataForm"
                lazy-validation
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <span class="caption">
                      Tipo *
                    </span>
                    <v-radio-group
                      v-model="form.type"
                      row
                      class="my-0"
                      :rules="[v => !!v || 'Campo obrigatório!']"
                    >
                      <v-radio
                        label="Release"
                        value="RELEASE"
                      />
                      <v-radio
                        label="Beta"
                        value="BETA"
                      />
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="6"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="form.version"
                      label="Versão *"
                      prepend-inner-icon="iso"
                      placeholder="1.0.000"
                      :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
                      :rules="[v => !!v || 'Campo obrigatório!']"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    class="py-0"
                  >
                    <masked-text-field
                      v-model="form.version_code"
                      label="Código da Versão *"
                      prepend-inner-icon="reorder"
                      placeholder="000"
                      :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                      :rules="[v => !!v || 'Campo obrigatório!']"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <v-textarea
                      v-model="form.release_notes"
                      label="Notas da versão"
                      placeholder=" "
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form
              ref="dairiesForm"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-treeview
                    v-model="form.dairies"
                    selectable
                    :items="dairies"
                    open-on-click
                  />
                </v-col>
                <v-col cols="12">
                  <v-input
                    :value="form.dairies.length"
                    required
                    :rules="[v => !!v || 'Selecione os laticínios']"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form
              ref="uploadForm"
              lazy-validation
            >
              <v-row>
                <v-col
                  v-if="form.file"
                  cols="12"
                >
                  <file-viewer
                    :value="form.file"
                    @removeFile="onRemoveFile()"
                  />
                </v-col>
                <v-col
                  v-else
                  cols="12"
                  class="py-0 text-center"
                >
                  <v-btn
                    outlined
                    @click="showFileUpload = !showFileUpload"
                  >
                    <v-icon>attach_file</v-icon>
                    <span class="ml-2">Adicionar</span>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-input
                    :value="form.file"
                    required
                    :rules="[v => !!v || 'Adicione o apk']"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-divider />
      
      <v-card-actions>
        <v-btn
          v-if="step == 1"
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-btn
          v-else
          outlined
          @click="step = step - 1"
        >
          <v-icon
            left
            dark
          >
            chevron_left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="step != 3"
          color="green"
          outlined
          @click="nextStep()"
        >
          Continuar
          <v-icon
            right
            dark
          >
            chevron_right
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>

    <file-upload
      v-model="showFileUpload"
      :multiselect="false"
      extensions="apk"
      accept="application/vnd.android.package-archive"
      :size="1024 * 1024 * 30"
      @insertFiles="onSelectFile"
    />
  </v-dialog>
</template>

<script>
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";

import _ from "lodash";

export default {

  components: {
    MaskedTextField,
    FileUpload,
    FileViewer,
  },

  props: {
    value: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      showFileUpload: false,
      step: 1,
      form: {
        dairies: [],
        file: null
      },
      steps: {
        1: 'dataForm',
        2: 'dairiesForm',
        3: 'uploadForm',
      },
      dairies: []
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.reset();

        if (this.editId) {
          this.load();
        }
      }
    }
  },

  created() {
    this.loadDairies()
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/quality/app/${this.editId}`);

        this.form = data;
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async loadDairies() {
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaLaticinio`);

        this.dairies = _.map(_.groupBy(data, 'system'), children => {
          const system = children[0].system;
          return {
            id: system,
            name: _.upperFirst(system),
            children: children.map(item => ({
              id: item.id_pessoa,
              name: item.nome,
            }))
          };
        });

      } catch (error) {
        this.$snotify.error("Erro ao carregar os laticínios", "Atenção");
        console.warn(error);
      }
    },

    async save() {
      try {
        if (!await this.validate()) {
          return;
        }
        
        this.saving = true;

        const data = this.editId ? await this.update(this.form) : await this.store(this.form);

        this.$snotify.success("Salvo com sucesso!", "Sucesso");
        this.show = false;
        this.$emit('save', data.id);
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/quality/app`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/quality/app/${this.editId}`, payload);
      return data;
    },

    cancel() {
      this.show = false;
    },

    async nextStep() {
      const form = this.steps[this.step];
      if (!await this.$refs[form].validate()) {
        return;
      }
      this.step++;
    },

    async validate() {
      for (let step of Object.keys(this.steps)) {
        const form = this.steps[step];
        if (!await this.$refs[form].validate()) {
          this.step = step;
          return false;
        }
      }
        
      return true;
    },

    reset() {
      for (let step of Object.keys(this.steps)) {
        const form = this.steps[step];
        this.$refs[form] && this.$refs[form].resetValidation();
      }
      this.step = 1
      this.form = {
        dairies: [],
        file: null
      };
    },
    
    onSelectFile([file]) {
      this.form.file = file;
      this.$refs.uploadForm.resetValidation();
    },

    async onRemoveFile() {
      try {
        const fileUrl = this.form.file.url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('apks')) {
          if (!(await this.$root.$confirm('Remover apk', 'Tem certeza que deseja excluir este apk?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.file = null;
          await this.save();
        }
        else {
          this.form.file = null;
        }
      } catch (err) {
        console.warn(err);
      }
    },
  }
}
</script>
