<template>
  <v-dialog
    v-if="model"
    v-model="model"
    persistent
    scrollable
    fullscreen
    dark
  >
    <v-card class="camera-dialog">
      <camera
        ref="camera"
        autoplay
        @started="onStarted"
        @error="onError"
        @flash="onFlash"
      >
        <div
          v-if="picture"
          class="picture-taken"
          :style="`background-image: url(${picture})`"
        />
        <v-btn
          icon
          small
          depressed
          class="ma-10"
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
        <v-row
          v-if="!hasCamera"
          justify="center"
          class="alert"
        >
          <v-alert
            dense
            light
            prominent
            colored-border
            border="left"
            elevation="2"
            type="warning"
            dismissible
            @input="close()"
          >
            <div class="text-h6">
              Atenção
            </div>
            Dispositivo não possui câmeras disponíveis
          </v-alert>
        </v-row>

        <v-row
          v-if="hasCamera"
          class="actions text-center"
        >
          <template v-if="!picture">
            <v-col>
              <v-btn
                fab
                large
                text
                @click="toggleFlash"
              >
                <v-icon
                  dark
                  large
                >
                  {{ flash === 'auto' ? 'flash_auto' : 'flash_off' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                fab
                x-large
                outlined
                @click="snapshot"
              >
                <v-icon
                  dark
                  x-large
                >
                  camera
                </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                fab
                large
                text
                @click="flipCamera"
              >
                <v-icon
                  dark
                  large
                  :style="rotate ? 'transform: rotate(-180deg)' : undefined"
                >
                  flip_camera_android
                </v-icon>
              </v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col>
              <v-btn
                fab
                large
                color="error"
                @click="cancel"
              >
                <v-icon
                  dark
                  x-large
                >
                  clear
                </v-icon>
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col>
              <v-btn
                fab
                large
                color="success"
                @click="select"
              >
                <v-icon
                  dark
                  x-large
                >
                  check
                </v-icon>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </camera>
    </v-card>
  </v-dialog>
</template>

<script setup>
// eslint-disable-next-line
import { ref, computed, defineProps, defineEmits, onMounted } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'
import { v4 as uuidv4 } from 'uuid'

const { progressBar, notify } = useUtils()

// eslint-disable-next-line
import Camera from './Camera.vue'

const props = defineProps({
  value: Boolean,
})

const emit = defineEmits([
  'input',
  'close',
  'capture',
])

const camera = ref()
const picture = ref()
const pictureBlob = ref()
const flash = ref('off')
const rotate = ref(false)
const hasCamera = ref(false)

const model = computed({
  get() {
    return props.value
  },
  set(newValue) {
    emit('input', newValue)
  }
})

const onStarted = async () => {
  hasCamera.value = true
}

const close = () => {
  pictureBlob.value = undefined
  picture.value = undefined
  model.value = false

  emit('close')
}

const flipCamera = () => {
  camera.value?.flipCamera()
  rotate.value = !rotate.value
}

const toggleFlash = () => {
  camera.value?.toggleFlash()
}

const onFlash = async (value) => {
  flash.value = value
}

const snapshot = async () => {
  const imageBlob = await camera.value?.snapshot()

  if (!imageBlob) {
    return
  }

  pictureBlob.value = imageBlob
  picture.value = URL.createObjectURL(imageBlob)
}

const cancel = () => {
  pictureBlob.value = undefined
  picture.value = undefined
}

const select = async () => {
  try {
    progressBar?.loading()

    const blobFile = pictureBlob.value
    const fileName = `${uuidv4()}.png`
    const file = new File([blobFile], fileName)

    const uploadFile = await upload(file)

    emit('capture', uploadFile)
    close()
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro salvar a imagem', 'Atenção')

  } finally {
    progressBar?.hide()
  }
}

const upload = async (file) => {
  const formData = new FormData()
  formData.append('file', file)

  const { data } = await axios.post('/arquivos/storageUpload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  return data.file
}

const onError = (error) => {
  notify.error(error, 'Atenção')
}
</script>

<style lang="scss">
.camera-dialog {
  .alert {
    position: relative;
    top: 50%;
  }
  .actions {
    position: fixed;
    bottom: 20px;
    width: 100%;
  }
  .picture-taken {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-size: contain;
    background-position: center;
    background-color: #2d2d2d;
  }
}
</style>
