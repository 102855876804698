<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="filter.project"
            :items="projectList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadRange"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="filter.project.protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-switch
            v-model="filter.prospected"
            label="Exibir prospectados"
            dark
            class="ma-1 pb-1"
            color="teal accent-3"
            hide-details
          />
        </v-col>
      </v-row>
      <questionnaire-details
        v-for="q in questionnairesGoal"
        :key="q.id_formulario"
        :questionnaire="q"
        :filter="filter"
        :project="project"
      />
    </v-flex>
  </v-container>
</template>

<script>
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import QuestionnaireDetails from "@/Domains/Projects/Questionnaires/Components/QuestionnaireDetails.vue"
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "quality-report",

  components: {
    MonthRangePicker,
    QuestionnaireDetails,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
    project: String,
    view: String,
  },

  data() {
    return {

      filter: {
        project: {
          start: null,
          end: null,
          protocol: '',
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        search: '',

        prospected: null,

      },

      projectList: [],

      questionnairesGoal: [],

    };
  },

  created() {
    this.loadProject();
  },

  methods: {

    async loadRange() {
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.max = moment(this.filter.project.dateEnd).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), moment().format('YYYY-MM')];

      this.questionnairesGoal = !_.isEmpty(this.questionnairesGoal) ? this.questionnairesGoal : JSON.parse(this.filter.project.questionario) ;

    },

    async loadProject() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: this.project ? this.project : "PQFL",
            view: this.view,
          }
        );

        this.projectList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            questionario: proj.questionario,
            protocol: proj.protocolo,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
