<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col md="3">
        <v-autocomplete
          v-model="route"
          :items="routes"
          clearable
          filled
          item-text="description"
          item-value="id"
          label="Rota"
          prepend-inner-icon="map"
          dark
          @change="onFilterChange"
        />
      </v-col>
      <v-col md="3">
        <v-autocomplete
          v-model="technician"
          :items="technicians"
          clearable
          filled
          item-text="name"
          item-value="id"
          label="Técnico"
          prepend-inner-icon="person"
          dark
          @change="onFilterChange"
        />
      </v-col>
      <v-col md="3">
        <v-autocomplete
          v-model="assinatura"
          :items="assinaturas"
          clearable
          filled
          label="Assinatura"
          prepend-inner-icon="label"
          dark
          @change="onFilterChange"
        />
      </v-col>
      <v-col md="3">
        <v-text-field
          v-model="search"
          clearable
          filled
          hide-details
          label="Pesquisar"
          prepend-inner-icon="search"
          single-line
          dark
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          class="graph-card report-card"
          color="transparent"
          dark
        >
          <v-card-text>
            <v-data-table
              name="Meta de Visitas"
              :headers="[
                { align: 'start', text: 'Mês', value: 'month' },
                { text: 'Produtores Visitados', value: 'produtores_visitados', align: 'center' },
                { text: 'Não Visitados', value: 'n_visitados', align: 'center' },
                { text: '% Visitados', value: 'percent', align: 'center' },
                { text: 'Meta', value: 'total_visita', align: 'center' },
                { text: '', value: 'status', align: 'end' }
              ]"
              :items="visitados"
              item-key="month"
              :loading="loading"
              class="elevation-1"
              :items-per-page="-1"
              hide-default-footer
              show-expand
              single-expand
              dark
            >
              <template #[`item.month`]="{value}">
                {{ formatMonth(value) }}
              </template>
              <template #[`item.status`]="{value}">
                <v-icon
                  v-if="value=='OK'"
                  right
                  color="green darken-1"
                >
                  verified
                </v-icon>
                <v-icon
                  v-if="value=='NO'"
                  right
                  color="red darken-1"
                >
                  cancel
                </v-icon>
              </template>
              <template #expanded-item="{headers, item}">
                <td
                  :colspan="headers.length"
                  class="pa-5 text-center"
                >
                  <v-data-table
                    :items="item.details"
                    :headers="[
                      { text: 'Código', value: 'codigo_laticinio', align: 'start' },
                      { text: 'Produtor', value: 'nome', align: 'center' },
                      { text: 'Rota Principal', value: 'descricao', align: 'center' },
                      { text: 'CCS', value: 'ccs', align: 'center' },
                      { text: 'CPP', value: 'cbt', align: 'center' },
                      { text: 'Visitas', value: 'qtd_visitas', align: 'center' },
                    ]"
                    :search="search"
                    show-expand
                    single-expand
                    :loading="loading"
                    class="elevation-1"
                    :items-per-page="-1"
                    hide-default-footer
                    item-key="codigo_laticinio"
                  >
                    <template #[`item.icone`]="{value}">
                      <v-icon
                        v-if="value=='OK'"
                        right
                        color="green darken-1"
                      >
                        verified
                      </v-icon>
                      <v-icon
                        v-if="value=='NO'"
                        right
                        color="red darken-1"
                      >
                        cancel
                      </v-icon>
                    </template>
                    <template #expanded-item="{ item } ">
                      <td
                        :colspan="headers.length"
                        class="pa-5 text-center"
                      >
                        <v-data-table
                          :headers="[
                            { text: 'Data', value: 'visit.date', align: 'start' },
                            { text: 'Técnico', value: 'technical.name', width: 300 ,sortable: true},
                            { text: 'Início', value: 'visit.startedAt', width: 100 ,sortable: true},
                            { text: 'Fim', value: 'visit.endedAt', width: 100 ,sortable: true},
                            { text: 'Duração', value: 'visit.duration', width: 100 ,sortable: true},
                            { text: 'Tempo Mínimo Duração', value: 'visit.tempo_minimo', width: 200 ,sortable: true, align: 'center',},
                            { align: 'end', text: 'Assinatura', value: 'signature', width: 110 ,sortable: true},
                            { align: 'end', text: '', value: 'actions', width: 90 ,sortable: true},
                          ]"
                          :items="visit_details.filter(x => x.mes === item.mes && x.id_produtor === item.id_pessoa)"
                          disable-pagination
                          hide-default-footer
                          item-key="visit.id_visita"
                          show-expand
                          single-expand
                          :loading="loading"
                        >
                          <template #expanded-item="{ headers, item }">
                            <td
                              :colspan="headers.length"
                              class="pa-0"
                            >
                              <v-container v-if="item.visit || item.openAdvises">
                                <v-card-text
                                  v-if="item.visit.pictures || item.visit.historical || item.openAdvises"
                                >
                                  <v-layout
                                    v-if="item.openRecommendations.length"
                                    row
                                  >
                                    <v-flex
                                      md12
                                      sm12
                                    >
                                      <v-card
                                        class="mt-3 technical-visit-card"
                                        dark
                                      >
                                        <v-card-title>
                                          <span class="text-subtitle-2">Recomendações Abertas</span>
                                        </v-card-title>

                                        <v-data-table
                                          :headers="[
                                            { align: 'start', text: 'Indicador', value: 'indicator' },
                                            { text: 'Causa', value: 'cause' },
                                            { text: 'Recomendação', value: 'advise' },
                                            { text: 'Status', value: 'status' },
                                          ]"
                                          :items="item.openRecommendations"
                                          class="technical-visit-table"
                                          disable-sort
                                          hide-default-footer
                                        >
                                          <template #[`item.recommendation`]="{ item }">
                                            {{ item.recommendation | uppercase }}
                                          </template>

                                          <template #[`item.cause`]="{ item }">
                                            {{ item.cause | uppercase }}
                                          </template>

                                          <template #[`item.indicator`]="{ item }">
                                            {{ item.indicator | uppercase }}
                                          </template>

                                          <template #[`item.status`]="{ item }">
                                            {{ item.status | uppercase }}
                                          </template>
                                        </v-data-table>
                                      </v-card>
                                    </v-flex>
                                  </v-layout>

                                  <v-layout
                                    v-if="item.visit.observations.length"
                                    row
                                  >
                                    <v-flex
                                      md12
                                      sm12
                                    >
                                      <v-card
                                        class="mt-3 technical-visit-card"
                                        dark
                                      >
                                        <v-card-title>
                                          <span class="text-subtitle-2">Observações</span>
                                        </v-card-title>

                                        <v-card-text
                                          class="pa-3 text-left"
                                          style="background: rgba(255, 255, 255, 0.15)"
                                        >
                                          {{ item.visit.observations }}
                                        </v-card-text>
                                      </v-card>
                                    </v-flex>
                                  </v-layout>

                                  <v-layout
                                    v-if="item.visit.pictures.length"
                                    row
                                  >
                                    <v-flex
                                      md12
                                      sm12
                                    >
                                      <v-card
                                        class="mt-3 technical-visit-card"
                                        dark
                                      >
                                        <v-card-title>
                                          <span class="text-subtitle-2">Fotos</span>
                                        </v-card-title>

                                        <v-card-text
                                          class="pt-6 pb-0"
                                          style="background: rgba(255, 255, 255, 0.15)"
                                        >
                                          <v-layout
                                            class="mb-0"
                                            row
                                            wrap
                                          >
                                            <template v-for="(image, index) in item.visit.pictures">
                                              <v-img
                                                :key="index"
                                                :lazy-src="image.src"
                                                class="mr-3 mb-3"
                                                max-width="100"
                                                style="cursor: zoom-in !important;"
                                                @click="openGallery(index)"
                                              />
                                            </template>
                                          </v-layout>

                                          <LightBox
                                            ref="lightbox"
                                            :media="item.visit.pictures"
                                            :show-light-box="false"
                                            show-caption
                                          />
                                        </v-card-text>
                                      </v-card>
                                    </v-flex>
                                  </v-layout>

                                  <v-layout
                                    v-if="item.visit.signature"
                                    row
                                  >
                                    <v-flex
                                      md12
                                      sm12
                                    >
                                      <v-card
                                        class="mt-3 technical-visit-card"
                                        dark
                                      >
                                        <v-card-title>
                                          <span class="text-subtitle-2">Assinatura do Produtor</span>
                                        </v-card-title>

                                        <v-card-text
                                          class="pa-2 pt-3 align-center justify-center"
                                          style="background: white;"
                                        >
                                          <img
                                            :src="item.visit.signature"
                                            alt="assinatura"
                                            width="30%"
                                          >
                                        </v-card-text>
                                      </v-card>
                                    </v-flex>
                                  </v-layout>
                                </v-card-text>

                                <v-card-text
                                  v-else
                                  style="height: 50px"
                                >
                                  <v-row class="justify-center align-center">
                                    Nenhuma visita encontrada
                                  </v-row>
                                </v-card-text>
                              </v-container>
                            </td>
                          </template>

                          <template #[`group.header`]="{ group, items, headers, isOpen, toggle }">
                            <td :colspan="headers.length">
                              <v-layout
                                class="pa-0 align-center"
                                row
                              >
                                <v-flex class="pa-0 text-left">
                                  <v-btn
                                    dark
                                    icon
                                    @click="toggle"
                                  >
                                    <v-icon v-if="isOpen">
                                      remove
                                    </v-icon>
                                    <v-icon v-else>
                                      add
                                    </v-icon>
                                  </v-btn>
                                  {{ group }}
                                </v-flex>
                                <v-flex class="pa-0 text-right">
                                  <v-chip
                                    x-small
                                    v-text="items.length"
                                  />
                                </v-flex>
                              </v-layout>
                            </td>
                          </template>

                          <template #[`item.signature`]="{ item }">
                            <v-btn
                              v-if="!item.visit.signature"
                              color="red lighten-2"
                              dark
                              icon
                            >
                              <v-icon>assignment_late</v-icon>
                            </v-btn>

                            <v-btn
                              v-else
                              color="green lighten-2"
                              dark
                              icon
                              @click="openSignatureDialog(item)"
                            >
                              <v-icon>assignment_turned_in</v-icon>
                            </v-btn>
                          </template>

                          <template #[`item.actions`]="{ item }">
                            <v-menu>
                              <template #activator="{ on }">
                                <v-btn
                                  dark
                                  icon
                                  v-on="on"
                                >
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item @click="showPrintDialog(item)">
                                  <v-list-item-icon>
                                    <v-icon>print</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Imprimir
                                  </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                  v-if="resourceDeleteVisits"
                                  @click="onVisitExcludeClick(item.visit.id_visita)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>delete</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Excluir
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </template>
                        </v-data-table>
                      </td>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          fab
          large
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        color="orange darken-1"
        dark
        fab
        @click="showPrintDialog()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        color="green darken-1"
        dark
        fab
        @click="toExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>

          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="signatureDialog.show"
      max-width="400"
      @close="closeDialogs"
    >
      <v-card>
        <v-card-title class="text-subtitle-2">
          Assinatura do Produtor
        </v-card-title>
        <v-divider />
        <v-card-text>
          <img
            :src="signatureDialog.signature"
            alt="assinatura"
            width="100%"
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmationDialog.show"
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ confirmationDialog.title }}
        </v-card-title>
        <v-card-text>
          <v-form
            ref="confirmationDialogForm"
            lazy-validation
            @submit.prevent=""
          >
            <div v-html="confirmationDialog.content" />

            <v-text-field
              v-model.number="confirmationDialog.typedToken"
              :rules="[v => (v && v === confirmationDialog.confirmationToken) || 'Token de confirmação incorreto!']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="closeDialogs()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="onConfirmationDialogSubmit()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="printDialog.show"
      max-width="600"
      persistent
      @close="closeDialogs"
    >
      <v-card>
        <v-card-title class="text-subtitle-2">
          Configuração de Impressão
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-0">
          <v-row
            dense
            no-gutters
            style="margin: 8px -4px;"
          >
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Faixa (Curva ABC)
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showABCCurve"
                        :label="printDialog.params.showABCCurve ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Resultados Qualidade
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showQualityResults"
                        :label="printDialog.params.showQualityResults ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Médias Qualidade
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showQualityAvg"
                        :label="printDialog.params.showQualityAvg ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>

          <v-row
            dense
            no-gutters
            style="margin: 8px -4px;"
          >
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Recomendações abertas
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showOpenedRecommendations"
                        :label="printDialog.params.showOpenedRecommendations ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Checklists
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showChecklists"
                        :label="printDialog.params.showChecklists ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Coletas de amostras
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showSampleCollects"
                        :label="printDialog.params.showSampleCollects ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="v-btt2 black--text pa-0"
            text
            color="red darken-1"
            @click="closeDialogs"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="print(printDialog.item)"
          >
            Imprimir
          </v-btn>
        </v-card-actions>

        <v-overlay
          :value="loading"
          absolute
        >
          <v-card-text>
            Carregando...
            <v-progress-linear
              class="mb-0"
              color="white"
              indeterminate
            />
          </v-card-text>
        </v-overlay>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import LightBox from 'vue-image-lightbox'
import XLSX from "xlsx-js-style";
import _ from "lodash";
//import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

export default {
  name: "visitados",

  filters: {
    uppercase(value) {
      return (value || "").toUpperCase();
    },
  },

  components: {
    LightBox,
    //RoutesAutocompleteFilter
  },

  mixins: [ReportMixin],

  props: {
    aba: String,
    filter: Object,
  },

  data() {
    return {
      loading: false,

      // Campo de busca para buscar pelo nome do produtor
      search: '',

      // Assinaturas
      assinatura: null,

      assinaturas: [
        { text: 'Com Assinatura', value: '1' },
        { text: 'Sem Assinatura', value: '2' },
      ],

      //filters: {
      // Rota filtrada para busca
      route: null,

      routes: [],
      //},

      // Técnico filtrado para a busca
      technician: null,

      technicians: [],

      items: [],

      visitGoals: {},

      visitados: [],

      // details: {
      //   headers: [],
      //   items: [],
      //   type: '',
      //   month: '',
      //   search: ''
      // },

      visit_details: [],

      signatureDialog: {
        show: false,
        signature: '',
      },

      // Dialogo de confirmação
      confirmationDialog: {
        show: false,
        callback: () => {
        },
        title: '',
        content: '',
        item: {},
        typedToken: '',
        confirmationToken: '',
      },

      printDialog: {
        show: false,
        item: {},
        params: {
          showABCCurve: true,
          showQualityResults: true,
          showQualityAvg: true,
          showOpenedRecommendations: true,
          showChecklists: true,
          showSampleCollects: true,
          showPriceNegotiation: true,
        },
      },

      resourceDeleteVisits: false,
    };
  },

  computed: {
    visitGraph() {
      return this.generateGraphOptions(this.visitados, ' ');
    },
  },

  watch: {
    filter: {
      handler() {
        this.loadVisits();
      },
      deep: true
    }
  },

  mounted() {
    this.loadVisits();
    this.loadPersonsFilter();
    this.loadRoutesFilter();

  },

  methods: {
    loadResourcesUser() {
      const recursosUsuario = this.$store.state.settings.recursosUsuario;

      const deleteVisits = recursosUsuario.find(recurso => recurso.recurso === "visits-delete" && recurso.tipo == "COMPONENTE");

      if (!_.isEmpty(deleteVisits)) {
        this.resourceDeleteVisits = true;
      }
    },

    async loadVisits() {
      if (this.filter.project) {
        if (this.aba == 'visitados') {
          await this.loadMetas();
        }
      } else {
        this.visitados = [];
      }
    },

    async loadMetas() {
      await this.loadVisitados();
      await this.loadVisitsList();
    },

    async loadVisitados() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/graficos/visitaProdutor`,
          this.$qs.stringify({
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM'),
            id_rota: this.route
          })
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        if (!_.isEmpty(data)) {
          let total_visita  = 0;
          let minimo_visita = 0;
          let tempo_minimo  = 0;

          if (!_.isEmpty(this.filter.project.visit) ) {
            this.visitGoals = JSON.parse(this.filter.project.visit).map(q => {
              total_visita  = q.total_visita,
              minimo_visita = q.minimo_visita_produtor,
              tempo_minimo  = q.tempo_minimo
            })
          }

          this.visitados = data.map(o => {
            let percentualVisita =  (100 * o.produtores) / o.total_produtores;

            return {
              produtores_visitados: o.produtores,
              total_produtores: o.total_produtores,
              month: o.mes,
              details: JSON.parse(o.details),

              n_visitados: (o.total_produtores - o.produtores),

              total_visita: total_visita,
              minimo_visita: minimo_visita,
              tempo_minimo: tempo_minimo,
              percent: this.formatNumber(percentualVisita) + ' %',
              status: (o.produtores >= total_visita) ? 'OK' : 'NO',
            }
          });
        }

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as visitas!", "Atenção");

        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    async loadRoutesFilter() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/rota/listaRotaResumidaJson`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.routes = data.map(route => {
          return {
            id: route.id_rota,
            description: route.descricao,
          }
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async loadVisitsList() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/visita/listaVisitas`,
          {
            projeto: 1,
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            id_pessoa: this.technician,
            assinatura: this.assinatura,
          }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        let total_visita  = 0;
        let minimo_visita = 0;
        let tempo_minimo  = 0;

        if (!_.isEmpty(this.filter.project.visit) ) {
          this.visitGoals = JSON.parse(this.filter.project.visit).map(q => {
            total_visita  = q.total_visita,
            minimo_visita = q.minimo_visita_produtor,
            tempo_minimo  = q.tempo_minimo  + ' Hrs'
          })
        }

        const visits = data.map(visit => {
          return {
            mes: moment(visit.data_visita).format('YYYY-MM'),
            id_produtor: visit.id_produtor,
            technical: {
              id: visit.id_tecnico,
              name: (visit.nome || '').toUpperCase(),
            },
            producer: {
              id_produtor: visit.id_produtor,
              nome_produtor: (visit.nome_produtor || '').toUpperCase(),
            },
            visit: {
              id_visita: visit.id_visita,
              date: moment(visit.data_visita).format('DD/MM/YYYY'),
              startedAt: moment(visit.hora_chegada, 'HH:mm:ss').format('HH:mm'),
              endedAt: moment(visit.hora_saida, 'HH:mm:ss').format('HH:mm'),
              duration: moment.utc(moment(visit.hora_saida, 'HH:mm:ss').diff(moment(visit.hora_chegada, 'HH:mm:ss'))).format('HH:mm'),
              observations: visit.observacao || '',
              signature: _.isEmpty(visit.assinatura) ? null : `data:image/png;base64,${visit.assinatura}`,

              total_visita: total_visita,
              minimo_visita: minimo_visita,
              tempo_minimo: tempo_minimo,

              pictures: _.map(data.fotos, (caption, src) => {
                return {
                  src,
                  thumb: src,
                  caption
                }
              }),
              historical: data.historico,
            },
            openRecommendations: visit.recomendacoes_abertas.map(recommendation => ({
              recommendation: recommendation.recomendacao,
              indicator: recommendation.indicador_afetado,
              cause: recommendation.causa,
              status: recommendation.status,
            }))
          }
        });

        this.visit_details = _.sortBy(visits, ({  date, startedAt }) => {
          return moment(`${date} ${startedAt}`, 'DD/MM/YYYY HH:mm').toDate();
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as visitas!", "Atenção");

        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    async loadPersonsFilter() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaTecnicos`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.technicians = data.map(x => {
          return {
            id: x.id_pessoa,
            name: x.nome,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // async onVisitDetails({ item, value }) {
    //   try {
    //     // Verifica se na visita selecionada já foi carregado os dados do servidor
    //     if (item.details) {
    //       return;
    //     }

    //     // Não faz nada quando fechar
    //     if (!value) {
    //       return;
    //     }

    //     this.onLoading = true;

    //     const { data } = await this.$axios.post(
    //       `/visita/detalhaVisita`,
    //       {
    //         id_visita: item.visit.id_visita,
    //       }
    //     );

    //     if (!_.isObject(data)) {
    //       throw new Error(data);
    //     }

    //     //const visitDetails = data || {};

    //     this.visit_details = this.visit_details.map(visit => {
    //       if (visit.visit.id_visita !== item.visit.id_visita) {
    //         return visit;
    //       }

    //       return {
    //         details: {
    //           visit: {
    //             pictures: _.map(data.fotos, (caption, src) => {
    //               return {
    //                 src,
    //                 thumb: src,
    //                 caption
    //               }
    //             }),
    //             historical: data.historico
    //           },
    //         },
    //         ...visit,
    //       };
    //     });

    //   } catch (err) {
    //     this.$snotify.error("Oops, ocorreu um erro ao carregar os detalhes da visita!", "Atenção");

    //     console.error(err);
    //   } finally {
    //     this.onLoading = false;
    //   }
    // },

    /**
     * Abre o lightbox com a imagem selecionada sendo exibida
     */
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },

    onFilterChange() {
      this.loadVisitados();
      this.loadVisitsList();
    },

    openSignatureDialog(item) {
      this.signatureDialog.signature = item.visit.signature;
      this.signatureDialog.show = true;
    },

    closeDialogs() {
      this.confirmationDialog.show = false;
      this.confirmationDialog.title = '';
      this.confirmationDialog.item = {};
      this.confirmationDialog.content = '';
      this.confirmationDialog.typedToken = '';
      this.confirmationDialog.confirmationToken = '';
      this.confirmationDialog.callback = () => {};

      this.printDialog.show = false;
      this.printDialog.item = {};
      this.printDialog.params = {
        showABCCurve: true,
        showQualityResults: true,
        showQualityAvg: true,
        showOpenedRecommendations: true,
        showChecklists: true,
        showSampleCollects: true,
        showPriceNegotiation: true,
      };

      this.signatureDialog.show = false;
    },

    onConfirmationDialogSubmit() {
      const valid = this.$refs.confirmationDialogForm.validate();

      if (!valid) {
        return;
      }

      return this.confirmationDialog.callback(this.confirmationDialog.item);
    },

    onVisitExcludeClick(id_visita) {
      if (this.resourceDeleteVisits) {
        this.confirmationDialog.show = true;
        this.confirmationDialog.title = 'Atenção';
        this.confirmationDialog.item.id = id_visita;
        this.confirmationDialog.content = `
          Deseja realmente excluir esta visita?
            <br />
          Para continuar, por favor digite <b>EXCLUIR</b> no campo abaixo:
          `;
        this.confirmationDialog.confirmationToken = 'EXCLUIR';
        this.confirmationDialog.callback = this.exclude;
      } else {
        this.$snotify.error("Usuário não tem permissão para excluir essa visita!", "Atenção");
      }
    },

    async exclude({ id_visita }) {
      try {
        this.loading = true;

        this.closeDialogs();

        const { data } = await this.$axios.post(
          `/visita/excluir`,
          this.$qs.stringify({
            id_visita: id_visita,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        const respose = data[0];

        if (respose.codigo !== 1) {
          throw data;
        }

        return this.loadVisitsList();
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao excluir a visita!", "Atenção");

        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    toExcel() {
      try {
        this.onLoading = true;

        let data_ini = _.isEmpty(this.filter.range) ? moment() : moment(_.first(this.filter.range));
        let data_fim = _.isEmpty(this.filter.range) ? moment() : moment(_.last(this.filter.range));
        console.log('this.visitados',this.visitados);
        if (this.this.visitados) {
          const reportData = this.visitados.map(({ producer, technical, visit }) => ({
            "Data Visita": visit.date,
            "Produtor": producer.name,
            "Técnico": technical.name,
            "Tipos": visit.types.map(visit => visit.text).join(', '),
            "Modelo": visit.model.text,
            "Duração": visit.duration,
            "Início": visit.startedAt,
            "Fim": visit.endedAt,
            "Observações": visit.observations,
          }));

          let data = XLSX.utils.json_to_sheet(reportData);
          const workbook = XLSX.utils.book_new();
          const filename = `Visita de ${data_ini.format('DD.MM')} - ${data_fim.format('DD.MM')}`;
          XLSX.utils.book_append_sheet(workbook, data, filename);
          XLSX.writeFile(workbook, `${filename}.xlsx`);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.onLoading = false;

        this.closeDialogs();
      }
    },

    showPrintDialog(item) {
      this.printDialog.show = true;
      this.printDialog.item = item;
      this.printDialog.params = {
        showABCCurve: true,
        showQualityResults: true,
        showQualityAvg: true,
        showOpenedRecommendations: true,
        showChecklists: true,
        showSampleCollects: true,
        showPriceNegotiation: true,
      };
    },

    async print(item) {
      try {
        this.loading = true;

        let dataIni = _.isEmpty(this.filter.range) ? moment() : moment(_.first(this.filter.range));
        let dataFim = _.isEmpty(this.filter.range) ? moment() : moment(_.last(this.filter.range));

        let ids = [];

        if (!item) {
          ids = this.visit_details.map(({ visit: { id_visita } }) => id_visita)
        } else {
          ids = [
            item.visit.id_visita,
          ];
        }

        if (_.isEmpty(ids)) {
          return;
        }

        const { data } = await this.$axios.post(`/visita/imprime`, {
          ids,
          params: {
            mostra_curva_abc: this.printDialog.params.showABCCurve,
            mostra_resultados_qualidade: this.printDialog.params.showQualityResults,
            mostra_medias_qualidade: this.printDialog.params.showQualityAvg,
            mostra_recomendacoes_abertas: this.printDialog.params.showOpenedRecommendations,
            mostra_checklist: this.printDialog.params.showChecklists,
            mostra_coleta_amostras: this.printDialog.params.showSampleCollects,
            mostra_negociacoes_preco: this.printDialog.params.showPriceNegotiation,
          }
        });

        let fileName = '';

        if (item) {
          fileName = `Visita - ${item.producer.name} - ${moment(item.visit.date, 'DD/MM/YYYY').format('DD.MM.YYYY')}.pdf`;
        } else {
          fileName = `Visitas - ${dataIni.format('DD.MM.YYYY')} - ${dataFim.format('DD.MM.YYYY')}.pdf`;
        }

        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const newBlob = new Blob([bytes], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);

          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(data);
        link.remove();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;

        this.closeDialogs();
      }
    },

    generateGraphOptions(data, title) {
      return {
        color: ['rgba(229, 57, 53, 0.7)',  'rgba(38, 198, 218, 0.7)', '#ffff00'],
        title: {
          text: `${title}`,
          textStyle: { color: '#ddd' },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.month)),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          },
        },
        series: [
          {
            name: 'Visitados',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => Math.abs(o.produtores_visitados).toFixed(2)),
          },
          {
            name: 'Não Visitados',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1
              }
            },
            data: data.map(o =>  Math.abs(o.n_visitados).toFixed(2)),
          },
          {
            name: 'Meta',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1
              }
            },
            data: data.map(o =>  Math.abs(o.total_visita).toFixed(2)),
          }
        ]
      };
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
