<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="orders"
  >
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent text-left"
      >
        <v-tab
          href="#tab-list"
        >
          Documentos
        </v-tab>

        <v-tab
          href="#tab-add"
        >
          Editar/Inserir Documento
        </v-tab>

        <v-tab-item value="tab-list">
          <document-list-tab
            v-if="tab === 'tab-list'"
            @add="onAddDocument"
            @show="onShowDocument"
          />
        </v-tab-item>

        <v-tab-item value="tab-add">
          <document-editing-tab
            v-if="tab === 'tab-add'"
            :edit-id.sync="editId"
            @save="onDocumentSaved"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import DocumentListTab from '@/Domains/Registrations/Documents/Views/DocumentListTab.vue'
import DocumentEditingTab from '@/Domains/Registrations/Documents/Views/DocumentEditingTab.vue'

export default {
  components: {
    DocumentListTab,
    DocumentEditingTab,
  },

  data() {
    return {
      tab: 'tab-list',
      editId: null
    };
  },

  methods: {
    onAddDocument() {
      this.editId = null;
      this.tab = "tab-add";
    },

    onShowDocument(id) {
      this.editId = id;
      this.tab = "tab-add";
    },

    onDocumentSaved() {
      this.tab = "tab-list";
    },
  },
};
</script>