<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="700px"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        <span class="text-h6">Adicionar Subproduto de Ordem de Produção nº {{ order.code }}</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
          class="mt-2"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="6"
              class="pt-0"
            >
              <v-text-field
                v-if="!!silo && !!silo.id"
                :value="form.silo.label"
                label="Silo *"
                disabled
              />
              <v-autocomplete
                v-else
                v-model="form.silo"
                label="Silo *"
                placeholder=" "
                :items="silos"
                :rules="[v => !!v || 'Campo obrigatório!']"
                item-value="id"
                item-text="label"
                return-object
                required
              />
            </v-col>
            <v-col
              v-if="form.silo && form.silo.rawProduct.id"
              cols="6"
              class="pt-0"
            >
              <v-text-field
                :value="form.silo.rawProduct.name"
                label="Matéria Prima"
                disabled
              />
            </v-col>
            <v-col
              v-else
              cols="6"
              class="pt-0"
            >
              <v-select
                v-model="form.rawProduct"
                :items="rawMaterials"
                label="Matéria Prima *"
                item-text="description"
                item-value="id"
                return-object
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="6"
              class="pt-0"
            >
              <masked-text-field
                v-model="form.volume"
                label="Volume *"
                prepend-inner-icon="iso"
                persistent-hint
                unmask
                inputmode="numeric"
                :mask="{ mask: Number, min: 0 }"
                :rules="volumeRules"
                validate-on-blur
              />
            </v-col>
            <v-col
              cols="6"
              class="pt-0"
            >
              <v-text-field
                v-model="form.obs"
                label="Observação"
                required
                autofocus
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import { useRangeValidator } from '@/Support/Composables/validator.js'

import IMask from 'imask';
import get from 'lodash/get'

const { validateRule } = useRangeValidator();

export default {

  components: {
    MaskedTextField,
  },

  props: {
    value: Boolean,
    order: {
      type: Object,
      default: () => ({})
    },
    silo: {
      type: Object,
      default: () => ({})
    },
    rawMaterials: {
      type: Array,
      default: () => ([])
    },
  },

  data() {
    return {
      silos: [],

      form: {},

      masks: {
        time: {
          mask: 'hh:mm',
          blocks: {
            hh: {
              mask: IMask.MaskedRange,
              placeholderChar: 'hh',
              from: 0,
              to: 23,
              maxLength: 2,
            },
            mm: {
              mask: IMask.MaskedRange,
              placeholderChar: 'mm',
              from: 0,
              to: 59,
              maxLength: 2,
            },
          },
        }
      }
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    volumeRules() {
      if (!this.silo) {
        return [v => !!v || 'Campo obrigatório!']
      }

      const availableVol = this.silo.capacity - this.silo.vol;

      return [
        v => !!v || 'Campo obrigatório!',
        v => v <= availableVol || 'Volume excede a capacidade no silo de destino',
        this.validateRange(),
      ]
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {};

        this.selectSilo();
      }
    }
  },

  methods: {

    async loadSilos() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/silo/principal`);

        this.silos = data.silo.map(silo => ({
          index: (silo.descricao || '').replace(/\D/g, ''),
          id: silo.id_silo,
          label: silo.descricao,
          batchNumber: silo.numero_lote,
          vol: parseInt(silo.volume_atual),
          capacity: parseInt(silo.volume_total),
          rawProduct: {
            id: silo.id_materia_prima,
            name: silo.nome_materia_prima,
          },
        })).sort((a, b) => a.index - b.index);
      } catch (e) {
        this.$snotify.error('Erro ao carregar silos', 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    selectSilo() {
      if (this.silo && this.silo.id) {
        this.form.silo = { ...this.silo };

        const byproduct = this.order.byproducts.find(item => item.itemId === this.silo.rawProduct.id);

        if (byproduct) {
          const availableVolume = byproduct.plannedQuantity - byproduct.accomplishedQuantity;

          if (availableVolume > 0) {
            this.form.volume = availableVolume;
          }
        }
      } else {
        this.loadSilos();
      }
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          tipo: 'ENTRADA_PRODUCAO',
          id_ordem_producao: this.order.id,
          id_silo: this.form.silo.id,
          id_materia_prima: this.form.silo.rawProduct.id || this.form?.rawProduct?.id,
          nome_materia_prima: this.form.silo.rawProduct.name || this.form?.rawProduct?.description,
          observacao: this.form.obs,
          volume: this.form.volume,
        };

        const { data } = await this.$axios.post(`/industry/silo/movement`, payload);

        this.$emit('save', data.id);
        this.$snotify.success('Movimento efetuado com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao cadastrar movimento');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.show = false;
    },

    validateRange() {
      const value = this.form.volume;
      const rule = this.form.rawProduct?.range;

      return validateRule(value, rule);
    },
  }

}
</script>
