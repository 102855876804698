<template>
  <div>
    <v-select
      v-model="selected"
      :items="selectItems"
      :prepend-inner-icon="prependInnerIcon"
      :label="label"
      :hide-details="hideDetails"
      :filled="filled"
      v-bind="$attrs"
      @change="onDateFilter"
    >
      <template #item="{ item }">
        <template v-if="selected === 'customRange' && item.value === 'customRange'">
          <span class="grow"> {{ startDateText }} — {{ endDateText }} </span>
          <v-btn
            icon
            light
            @click="refresh"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>

        <template v-else>
          {{ item.text }}
        </template>
      </template>

      <template #selection="{ item }">
        <span v-if="selected === 'customRange'">
          {{ startDateText }} — {{ endDateText }}
        </span>
        <span v-else>
          {{ item.text }}
        </span>
      </template>
    </v-select>

    <v-dialog
      :value="showDatePicker"
      persistent
      width="290"
    >
      <v-date-picker
        v-model="dateRange"
        range
        reactive
        type="month"
        :min="minMonth"
        :max="maxMonth"
        @change="onCustomDateRange"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  props: {
    value: {
      type: Array,
    },

    label: {
      type: String,
      default: 'Data',
    },

    prependInnerIcon: {
      type: String,
      default: 'today',
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    filled: {
      type: Boolean,
      default: true,
    },

    ranges: {
      type: Object,
      default() {
        return {
          'Ano anterior': [moment().subtract(1, "year").startOf("year").format("YYYY-MM"), moment().subtract(1, "year").endOf("year").format("YYYY-MM")],
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        }
      }
    },
    min: String,
    max: String,
  },

  data() {
    return {
      showDatePicker: false,
      selected: null,
      dateRange: [],
    };
  },

  computed: {
    selectItems() {
      let items = [];

      Object.keys(this.ranges).forEach(key => {
        items.push({ text: key, value: key })
      })

      items.push({ text: 'Definir data', value: 'customRange' });

      return items;
    },
    startDateText() {
      const startDate = _.first(this.dateRange);
      if (!startDate) {
        return;
      }

      return moment(startDate).format('MM/YYYY');
    },

    endDateText() {
      const endDate = _.last(this.dateRange)
      if (!endDate) {
        return;
      }

      return moment(endDate).format('MM/YYYY');
    },
    minMonth() {
      return moment(this.min).format("YYYY-MM");
    },
    maxMonth() {
      return moment(this.max).format("YYYY-MM");
    },

  },

  watch: {
    value() {
      if (this.value && this.value.length == 2) {
        this.dateRange = this.value;
        this.checkSelected();
      }
    },
  },

  mounted() {
    if (this.value && this.value.length == 2) {
      this.dateRange = this.value;
    }
    else {
      this.dateRange = Object.values(this.ranges)[0];
    }
    this.onCustomDateRange();
  },

  methods: {
    onDateFilter(selected) {
      if (selected === 'customRange') {
        this.showDatePicker = true;
        return;
      }

      this.dateRange = this.ranges[selected];

      this.$emit('input', this.dateRange);
      this.$emit('change', this.dateRange);
    },

    onCustomDateRange() {
      this.showDatePicker = false;

      this.checkSelected();

      this.$emit('input', this.dateRange);
      this.$emit('change', this.dateRange);
    },

    checkSelected() {
      const [startDate, endDate] = this.dateRange;
      
      this.selected = 'customRange';

      Object.keys(this.ranges).forEach(key => {
        const [start, end] = this.ranges[key];
        if (startDate == start && endDate == end) {
          this.selected = key;
        }
      })
    },

    refresh() {
      this.showDatePicker = true;
    },
  },
};
</script>
