<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container
      row
      wrap
    />

    <v-row
      xs12
      justify="center"
    >
      <v-col
        cols="12"
      >
        <h2 class="menu-header white--text">
          Período entre Coletas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-model="selectedTab"
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab>
        Período entre Coletas
      </v-tab>
      <v-tab>
        Período entre Coletas
      </v-tab>
        <v-tabs-items
          :value="selectedTab"
        >
          <v-tab-item>
            <reports-tab
              v-if="selectedTab === 0"
              :date-filter.sync="filters.date"
              :producers-filter.sync="filters.producers"
              :routes-filter.sync="filters.routes"
            />
          </v-tab-item>
          <v-tab-item>
            <banner-producer-tab
              v-if="selectedTab === 1"
              :date-filter.sync="filters.date"
              :producers-filter.sync="filters.producers"
              :routes-filter.sync="filters.routes"
            />
          </v-tab-item>
        </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import ReportsTab from "@/Domains/Collects/Reports/ProducerCollected/Views/ReportsTab.vue";
import BannerProducerTab from "@/Domains/Collects/Reports/ProducerCollected/Views/BannerProducerTab.vue";
import moment from "moment-timezone";

export default {
  components: {
    ReportsTab,
    BannerProducerTab
  },

  data () {
    return {
      filters: {
        date: [moment().startOf("day").format("YYYY-MM-DD"), moment().endOf("day").format("YYYY-MM-DD")],
        producers: [],
        routes: [],
      },
      selectedTab: 0,
    }
  },
}
</script>
