<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Grupos de Produtos
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
          </v-col>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          @click:row="showDialog"
        >

          <template #[`item.fields`]="{ value }">
            <v-row
              v-for="field of value"
              :key="field.id"
            >
              <v-col
                cols="4"
              >
                Campo:
                <v-chip
                  small
                  color="green darken-1"
                >
                  {{ field.description }}
                </v-chip>
              </v-col>
              <v-col
                cols="3"
              >
                Tipo:
                <v-chip
                  small
                  color="gray darken-1"
                >
                  {{ getTypeById(field.type) }}
                </v-chip>
                <v-chip
                  v-if="field.type === 'select'"
                  small
                  color="gray darken-2"
                >
                  {{ formatValues(field.values) }}
                </v-chip>
              </v-col>
              <v-col
                cols="4"
              >
                Regras:
                <v-chip
                  v-for="rule of field.rules"
                  :key="rule.id"
                  small
                  color="red darken-1"
                >
                  {{ rule.rule }}
                </v-chip>
              </v-col>
            </v-row>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  dark
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="hasEditItemAccess"
                  @click="showDialog(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      edit
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Editar
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="hasDeleteItemAccess"
                  @click="deleteItem(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      delete
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Excluir
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="hasCreateItemAccess"
        fab
        color="purple"
        @click="showDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Cadastrar Grupo
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <product-group-dialog
      v-model="edit"
      :edit-id.sync="editId"
      @save="loadItems"
    />
  </div>
</template>

<script>
import ProductGroupDialog from "@/Domains/Registrations/ProductGroup/ProductGroupDialog.vue";
import { isArray } from "lodash";
export default {

  components: {
    ProductGroupDialog,
  },

  data() {
    return {
      items: [],

      filters: {
        search: "",
      },

      typesList: [
        {
          id: 'number',
          type: 'Numérico'
        },
        {
          id: 'text',
          type: 'Texto'
        },
        {
          id: 'select',
          type: 'Seleção'
        },
        {
          id: 'date',
          type: 'Data'
        },
      ],

      edit: false,
      editId: null,
    };
  },

  computed: {
    headers() {
      return [
        { text: 'Grupo', value: 'group', align: 'start'},
        { text: 'Campos', value: 'fields', align: 'start' },
        { text: '', value: '', align: 'end' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
      ];
    },

    //Pega os dados de permissão do usúario.
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    //Se for admin ou ter o cargo Supervisor(Milkup) libera todos os acessos.
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    //Permissão para poder CRIAR uma Causa.
    hasCreateItemAccess() {
      return this.isAdmin || (this.userResources.some(o => (o.recurso === "product-group-create") && o.tipo === "COMPONENTE" ));
    },

    //Permissão para poder EDITAR uma Causa.
    hasEditItemAccess() {
      return this.isAdmin || (this.userResources.some(o => (o.recurso === "product-group-edit") && o.tipo === "COMPONENTE" ));
    },

    //Permissão para poder DELETAR uma Causa.
    hasDeleteItemAccess() {
      return this.isAdmin || (this.userResources.some(o => (o.recurso === "product-group-delete") && o.tipo === "COMPONENTE" ));
    },
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/product-group');

        this.items = data.map(item => {
          return {
            id: item.id_grupo_produto,
            group: item.grupo,
            fields: item.campos,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    showDialog({ id }) {
      if (!this.hasEditItemAccess) {
        return;
      }
      this.edit = true;
      this.editId = id;
    },

    async deleteItem(Item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir o Grupo ${Item.group} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`registrations/product-group/${Item.id}`);

        this.$snotify.success("Grupo excluído com sucesso", "Sucesso");
        return await this.loadItems();
      } catch (e) {
        this.$snotify.error("Erro ao excluir grupo", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getTypeById(id) {
      for (const type of this.typesList) {
        if (type.id === id) {
          return type.type;
        }
      }

      return ' - ';
    },

    formatValues(objectsArray) {

      if (!isArray(objectsArray)) {
        return;
      }

      return objectsArray.map(object => `${object.id}=${object.value}`).join(', ');
    },
  },
}
</script>
