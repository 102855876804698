<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="80%"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="shipment-dialog">
      <v-card-title>
        <span class="text-h6">Montagem da Carga</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <shipment-resume
          :shipment="form.shipment"
        />

        <v-form ref="formRef">
          <div class="d-flex">
            <span class="flex-grow-1 text-h5 mt-4 black--text">Produtos</span>
          </div>
          <v-divider class="mb-4" />
          <v-data-table
            :headers="headers"
            :items="products"
            group-by="shippingId"
            hide-default-footer
            disable-pagination
            disable-sort
            disable-filtering
            show-expand
            single-expand
          >
            <template #[`group.header`]="{ group, isOpen, toggle, headers, items }">
              <template v-if="group">
                <td
                  v-for="h in headers"
                  :key="h.value"
                  :class="h.align ? `text-${h.align}` : 'text-start'"
                >
                  <template v-if="h.value === 'item.code'">
                    {{ shippings[group].code }}
                  </template>
                  <template v-else-if="h.value === 'item.description'">
                    <b>{{ shippings[group].customer.name }}</b>
                  </template>
                  <template v-else-if="h.value === 'grossWeight'">
                    <v-chip
                      small
                      dark
                    >
                      {{ formatNumber(shippings[group].grossWeight) }}
                      <v-avatar
                        right
                        color="primary"
                      >
                        Kg
                      </v-avatar>
                    </v-chip>
                  </template>
                  <template v-else-if="h.value === 'netWeight'">
                    <v-chip
                      small
                      dark
                    >
                      {{ formatNumber(shippings[group].netWeight) }}
                      <v-avatar
                        right
                        color="primary"
                      >
                        Kg
                      </v-avatar>
                    </v-chip>
                  </template>
                  <template v-else-if="h.value === 'packings.length'">
                    <v-chip
                      color="teal"
                      small
                      text-color="white"
                    >
                      <v-avatar
                        left
                        class="teal darken-4"
                      >
                        {{ formatNumber(shippings[group].pallets.length) }}
                      </v-avatar>
                      Pallets
                    </v-chip>
                    <v-chip
                      color="blue"
                      small
                      text-color="white"
                    >
                      <v-avatar
                        left
                        class="blue darken-4"
                      >
                        {{ formatNumber(shippings[group].packingsQuantity) }}
                      </v-avatar>
                      Caixas
                    </v-chip>
                  </template>
                  <template v-else-if="h.value === 'actions'">
                    <v-menu
                      offset-y
                      transition="scale-transition"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          color="teal"
                          icon
                          small
                          v-on.stop="on"
                        >
                          <v-icon
                            dark
                            dense
                          >
                            pallet
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(pallet, index) in shippings[group].pallets"
                          :key="index"
                          @click="showPallet(shippings[group], items, pallet.id)"
                        >
                          <v-list-item-icon>
                            <v-icon>pallet</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ pallet.barcode }}</v-list-item-title>
                        </v-list-item>

                        <v-divider
                          v-if="shippings[group].pallets.length > 0 && shippings[group].hasPendingPallet"
                        />

                        <v-list-item
                          v-if="shippings[group].hasPendingPallet || items.some(product => !product.completed)"
                          @click="showPallet(shippings[group], items)"
                        >
                          <v-list-item-icon>
                            <v-icon>add</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Novo Pallet</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </td>
              </template>
            </template>
            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :items="item.packings"
                  :headers="packingHeaders"
                  hide-default-footer
                  disable-pagination
                  disable-sort
                  disable-filtering
                  class="my-4"
                >
                  <template #[`item.quantity`]="{ value, item }">
                    {{ formatNumber(value) }} {{ item.measurement }}
                  </template>
                  <template #[`item.grossWeight`]="{ value }">
                    {{ formatNumber(value) + ' Kg' }}
                  </template>
                  <template #[`item.tareWeight`]="{ value }">
                    {{ formatNumber(value) + ' Kg' }}
                  </template>
                  <template #[`item.netWeight`]="{ value }">
                    {{ formatNumber(value) + ' Kg' }}
                  </template>
                  <template #[`item.date`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY') }}
                  </template>
                </v-data-table>
              </td>
            </template>
            <template #[`item.quantity`]="{ value, item }">
              {{ formatNumber(value) }} {{ item.item.measurement }}
            </template>
            <template #[`item.grossWeight`]="{ value }">
              {{ formatNumber(value) + ' Kg' }}
            </template>
            <template #[`item.netWeight`]="{ value }">
              {{ formatNumber(value) + ' Kg' }}
            </template>
            <template #[`item.packings.length`]="{ value }">
              {{ formatNumber(value) }} Caixas
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip
                v-if="!item.completed"
                bottom
              >
                <template #activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    small
                    v-on="on"
                    @click="addPacking(item)"
                  >
                    <v-icon>scale</v-icon>
                  </v-btn>
                </template>
                Nova Embalagem/Pesagem
              </v-tooltip>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex-column">
        <div class="full-width d-flex flex-row">
          <v-btn
            color="secondary"
            outlined
            @click="close()"
          >
            Cancelar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>

    <PackingDialog
      ref="packingDialogRef"
      @close="load"
    />

    <PalletDialog
      ref="palletDialogRef"
      :loaded-at="form.shipment?.operationTime"
      :load-number="form.shipment?.itinerary?.code"
      @save="load"
    />
  </v-dialog>
</template>

<style lang="scss">
.shipment-dialog {
  .v-chip.v-size--small .v-avatar {
    height: 14px !important;
    min-height: 14px !important;
    min-width: 14px !important;
    width: unset !important;
    border-radius: 6px !important;
    padding: 3px;
  }
}
</style>

<script setup>
import { ref, reactive, computed } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import api from '@/Domains/Shipment/Api/Shipment.js'
import moment from 'moment'

import ShipmentResume from '@/Domains/Shipment/Shipment/Components/ShipmentResume.vue'
import PackingDialog from '@/Domains/Shipment/StorageControl/Components/PackingDialog.vue'
import PalletDialog from '@/Domains/Shipment/StorageControl/Components/PalletDialog.vue'

const { progressBar, notify } = useUtils()

const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)
const formatDate = (value, format) => !value ? '-' : moment(value).format(format)

const headers = [
  { text: 'Código', value: 'item.code', width: 50 },
  { text: 'Produto', value: 'item.description' },
  { text: 'Quantidade', value: 'quantity' },
  { text: 'Peso Bruto', value: 'grossWeight', mask: '#,##0.00' },
  { text: 'Peso Líquido', value: 'netWeight', mask: '#,##0.00' },
  { text: '', value: 'packings.length' },
  { text: '', value: 'actions', width: 60 },
]

const packingHeaders = [
  { text: 'Lote', value: 'lotNumber' },
  { text: 'Quantidade', value: 'quantity' },
  { text: 'Peso Bruto', value: 'grossWeight', mask: '#,##0.00' },
  { text: 'Peso Tara', value: 'tareWeight', mask: '#,##0.00' },
  { text: 'Peso Líquido', value: 'netWeight', mask: '#,##0.00' },
  { text: 'Data', value: 'date' },
  { text: 'Código de Barras', value: 'barcode' },
]

const products = computed(() => {
  if (!form.shipment?.shippings) {
    return []
  }

  return form.shipment.shippings.flatMap((shipping) => {
    return shipping.products
      .filter((product) => product.remaining.quantity == 0)
      .map((product) => {
        return {
          shippingId: shipping.id,
          ...product,
          grossWeight: product.packings.reduce((acc, cur) => acc + cur.grossWeight, 0),
          netWeight: product.packings.reduce((acc, cur) => acc + cur.netWeight, 0),
          completed: product.packings.reduce((acc, cur) => acc + cur.quantity, 0) >= product.realQuantity,
        }
      })
  })
})

const shippings = computed(() => {
  if (!form.shipment?.shippings) {
    return {}
  }

  return form.shipment.shippings.reduce((acc, shipping) => {
    acc[shipping.id] = {
      ...shipping,
      ...shipping.products.reduce((acc, cur) => {
        acc.packingsQuantity += cur.packings.length
        acc.grossWeight += cur.packings.reduce((acc, cur) => acc + cur.grossWeight, 0)
        acc.netWeight += cur.packings.reduce((acc, cur) => acc + cur.netWeight, 0)
        return acc
      }, {
        packingsQuantity: 0,
        grossWeight: shipping.pallets.reduce((acc, cur) => acc + cur.palletTare + cur.stretchTare, 0),
        netWeight: 0,
      }),
      hasPendingPallet: shipping.products.some((product) => product.packings.some((packing) => !packing.palletId)),
    }

    return acc
  }, {})
})

const dialog = ref(false)

const formRef = ref()

const form = reactive({
  id: null,
  shipment: {
    shippings: [],
  }
})

const show = (id) => {
  dialog.value = true
  formRef.value?.reset()
  form.id = id
  form.shipment = {
    shippings: [],
  }
  load()
}

const close = () => {
  dialog.value = false
}

const load = async () => {
  try {
    progressBar?.loading()

    form.shipment = await api.show(form.id)

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}
const packingDialogRef = ref()
const palletDialogRef = ref()

const addPacking = (product) => {
  packingDialogRef.value.show({
    shippingId: product.shippingId,
    pendingProducts: [
      {
        ...product.item,
        shippingItemId: product.id,
        quantity: product.quantity,
        realQuantity: product.realQuantity,
        stocks: product.stocks,
      }
    ]
  })
}

const showPallet = (shipping, groupedProducts, palletId = null) => {
  palletDialogRef.value.show({
    id: palletId,
    shippingId: shipping.id,
    pendingPackings: shipping.products
      .flatMap((product) => product.packings.filter((packing) => !packing.palletId)),
    pendingProducts: groupedProducts
      .filter((product) => !product.completed)
      .map((product) => ({
        ...product.item,
        shippingItemId: product.id,
        quantity: product.quantity,
        realQuantity: product.realQuantity,
        stocks: product.stocks,
      })),
  });
}

// eslint-disable-next-line no-undef
defineExpose({ show, close })
</script>
