<template>
  <v-dialog
    v-model="show"
    scrollable
    width="1200"
    persistent
  >
    <v-card class="invoice-dialog">
      <v-card-title>
        Nota Fiscal de {{ form.tipo === 0 ? 'Entrada' : 'Saída' }}
        <span
          v-if="form.status"
          class="body-1 ml-4 mt-1 blue-grey--text"
        >
          <v-chip
            small
            :color="getStatusColor(form.status)"
          />
          {{ form.status }}
        </span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-stepper
        v-model="step"
        alt-labels
        non-linear
        class="elevation-0"
      >
        <v-stepper-header>
          <v-stepper-step
            step="1"
            editable
            :rules="[() => formValidation.dados]"
          >
            Dados da Nota
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="2"
            editable
            :rules="[() => formValidation.cliente]"
          >
            {{ form.tipo === 0 ? 'Remetente' : 'Destinatário' }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="3"
            editable
            :rules="[() => formValidation.produtos]"
          >
            Produtos
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="4"
            editable
            :rules="[() => formValidation.pagamentos]"
          >
            Pagamentos
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="5"
            editable
            :rules="[() => formValidation.totais]"
          >
            Totais
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form
              ref="form-1"
              v-model="formValidation.dados"
              lazy-validation
              style="max-height: calc(100vh - 300px);"
              class="overflow-y-auto overflow-x-hidden"
              :disabled="disabled"
            >
              <v-row>
                <v-col
                  v-if="form.ambiente === 2 && form.tipo_emissao === 'PROPRIA'"
                  cols="12"
                  class="text-center"
                >
                  <v-alert
                    type="warning"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          Ambiente de Homologação
                        </div>
                        <div class="body-1">
                          Documento sem valor fiscal
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col
                  v-if="form.erros.length > 0 && !['PROCESSANDO', 'EMITIDA', 'CONTIGENCIA'].includes(form.status)"
                  cols="12"
                  class="text-center"
                >
                  <v-alert
                    type="error"
                    border="left"
                    colored-border
                    elevation="2"
                    class="mb-0"
                  >
                    <v-row
                      class="pa-0 align-center"
                    >
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          Erro no envio da NF-e
                        </div>
                        <div class="body-1">
                          <p
                            v-for="(erro, idx) in form.erros"
                            :key="idx"
                          >
                            {{ erro }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <v-select
                    v-model="form.tipo_emissao"
                    :items="[
                      { text: 'Emissão própria', value: 'PROPRIA' },
                      { text: 'Emitida por terceiros', value: 'TERCEIROS' },
                      { text: 'Importada via XML', value: 'XML' },
                    ]"
                    label="Tipo de Emissão *"
                    prepend-inner-icon="import_export"
                    disabled
                    :rules="[rules.required]"
                    @change="onEmissionChange"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.serie"
                    label="Série *"
                    :disabled="form.tipo_emissao === 'PROPRIA' && isPreconfiguredOperation"
                    prepend-inner-icon="subtitles"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.numero"
                    label="Número"
                    :disabled="form.tipo_emissao === 'PROPRIA'"
                    prepend-inner-icon="subtitles"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.chave"
                    label="Chave de Acesso"
                    :disabled="form.tipo_emissao === 'PROPRIA'"
                    prepend-inner-icon="subtitles"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <v-select
                    v-model="isPreconfiguredOperation"
                    :items="[
                      { text: 'Pré-configurada', value: true },
                      { text: 'Manual', value: false },
                    ]"
                    label="Tipo de Natureza"
                    prepend-inner-icon="notes"
                    :disabled="form.tipo_emissao !== 'PROPRIA'"
                    @change="onOperationTypeChange"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-if="isPreconfiguredOperation"
                    v-model="form.id_natureza_operacao"
                    :items="filteredOperations"
                    item-text="descricao"
                    item-value="id"
                    prepend-inner-icon="notes"
                    label="Natureza de Operação *"
                    :rules="[rules.required]"
                    @change="onOperationChange"
                  />
                  <v-text-field
                    v-else
                    v-model="form.natureza"
                    label="Natureza de Operação *"
                    prepend-inner-icon="notes"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="form.data_emissao"
                    label="Data da Emissão"
                    prepend-inner-icon="event"
                    placeholder="00/00/0000 00:00"
                    :mask="{ mask: '00/00/0000 00:00' }"
                    :rules="[ rules.dateTime ]"
                    :max-length="16"
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="form.data_movimentacao"
                    :label="form.tipo === 0 ? 'Data da Entrada' : 'Data da Saída'"
                    prepend-inner-icon="event"
                    placeholder="00/00/0000 00:00"
                    :mask="{ mask: '00/00/0000 00:00' }"
                    :rules="[ rules.dateTime ]"
                    :max-length="16"
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-select
                    v-model="form.finalidade"
                    :items="[
                      { text: 'NF-e Normal', value: 1 },
                      { text: 'NF-e Complementar', value: 2 },
                      { text: 'NF-e de Ajuste', value: 3 },
                      { text: 'Devolução/Retorno', value: 4 },
                    ]"
                    label="Finalidade *"
                    prepend-inner-icon="import_export"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-select
                    v-model="form.presenca"
                    :items="[
                      { text: 'Não se aplica', value: 0 },
                      { text: 'Operação presencial', value: 1 },
                      { text: 'Não presencial, internet', value: 2 },
                      { text: 'Não presencial, teleatendimento', value: 3 },
                      { text: 'NFC-e entrega em domicílio', value: 4 },
                      { text: 'Operação presencial, fora do estabelecimento', value: 5 },
                      { text: 'Não presencial, outros', value: 9 },
                    ]"
                    label="Indicador de Presença *"
                    prepend-inner-icon="my_location"
                    :rules="[v => v >= 0 || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-select
                    v-model="form.emitente.regime"
                    :items="[
                      { text: 'Simples Nacional', value: 1 },
                      { text: 'Simples Nacional - Excesso de Sublimite', value: 2 },
                      { text: 'Regime Normal', value: 3 },
                    ]"
                    label="Código de regime tributário *"
                    prepend-inner-icon="badge"
                    :disabled="form.tipo_emissao === 'PROPRIA'"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-select
                    v-model="form.consumidor"
                    :items="[
                      { text: 'Sim', value: 1 },
                      { text: 'Não', value: 0 },
                    ]"
                    label="Consumidor Final *"
                    prepend-inner-icon="person"
                    :disabled="isPreconfiguredOperation"
                    :rules="[v => v >= 0 || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-select
                    v-model="form.transporte.frete"
                    :items="[
                      { text: '0 - Contratação do Frete por conta do Remetente (CIF)', value: 0 },
                      { text: '1 - Contratação do Frete por conta do destinatário/remetente (FOB)', value: 1 },
                      { text: '2 - Contratação do Frete por conta de terceiros', value: 2 },
                      { text: '3 - Transporte próprio por conta do remetente', value: 3 },
                      { text: '4 - Transporte próprio por conta do destinatário', value: 4 },
                      { text: '9 - Sem Ocorrência de transporte', value: 9 },
                    ]"
                    label="Modalidade do frete *"
                    prepend-inner-icon="local_shipping"
                    :rules="[v => v >= 0 || 'Campo obrigatório!']"
                  />
                </v-col>
              </v-row>

              <div class="d-flex">
                <span class="flex-grow-1 text-h5 mt-4 black--text">Documentos referenciados</span>

                <div
                  v-if="!disabled"
                  class="d-flex justify-end"
                >
                  <v-btn
                    outlined
                    color="blue"
                    class="my-2 mr-2"
                    @click="addNfe()"
                  >
                    <v-icon>add</v-icon> Adicionar NF-e
                  </v-btn>
                  <v-btn
                    outlined
                    color="blue"
                    class="my-2"
                    @click="addNfp()"
                  >
                    <v-icon>add</v-icon> Adicionar NFP
                  </v-btn>
                </div>
              </div>
              <v-divider class="mb-4" />
              <v-row
                v-for="(item, idx) of form.notas_referenciadas"
                :key="'NFe-' + idx"
              >
                <v-col
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="form.notas_referenciadas[idx]"
                    label="Chave de acesso da NF-e referenciada"
                    prepend-inner-icon="subtitles"
                    :mask="masks.integer"
                    unmask
                    maxlength="44"
                  />
                </v-col>
                <v-col
                  v-if="!disabled"
                  class="px-0 d-flex flex-column justify-center"
                  style="max-width: 49px;"
                >
                  <v-btn
                    icon
                    @click.stop="removeNfe(idx)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider
                v-if="form.notas_referenciadas.length > 0 && form.notas_rurais_referenciadas.length > 0"
                class="my-4"
              />

              <v-row
                v-for="(item, idx) of form.notas_rurais_referenciadas"
                :key="'NFP-' + idx"
              >
                <v-col>
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                      class="pb-0"
                    >
                      <v-select
                        v-model="item.modelo"
                        :items="[
                          { text: '04 - NF de Produtor', value: '04' },
                          { text: '01 - NF (v2.0)', value: '01' },
                        ]"
                        label="Modelo do documento fiscal *"
                        prepend-inner-icon="local_shipping"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="pb-0"
                    >
                      <masked-text-field
                        v-model.trim="item.cpf_cnpj"
                        prepend-inner-icon="assignment_ind"
                        label="CPF/CNPJ do emitente *"
                        :rules="[rules.required]"
                        :mask="masks.cpfCnpjMask"
                        maxlength="18"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="item.ie"
                        label="IE do emitente"
                        prepend-inner-icon="subtitles"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="pb-0"
                    >
                      <states-select
                        v-model="item.uf"
                        prepend-inner-icon="villa"
                        label="UF do emitente"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="pb-0"
                    >
                      <masked-text-field
                        v-model="item.data"
                        label="Data da emissão *"
                        prepend-inner-icon="event"
                        placeholder="00/00/0000"
                        :mask="{ mask: '00/00/0000' }"
                        :max-length="10"
                        :rules="[rules.required, rules.date]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="pb-0"
                    >
                      <masked-text-field
                        v-model="item.serie"
                        label="Série do documento fiscal *"
                        prepend-inner-icon="subtitles"
                        :mask="masks.integer"
                        :rules="[rules.required]"
                        unmask
                        maxlength="3"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="pb-0"
                    >
                      <masked-text-field
                        v-model="item.numero"
                        label="Número do documento fiscal *"
                        prepend-inner-icon="subtitles"
                        :mask="masks.integer"
                        unmask
                        maxlength="9"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="!disabled"
                  class="px-0 d-flex flex-column justify-center"
                  style="max-width: 49px;"
                >
                  <v-btn
                    icon
                    @click.stop="removeNfp(idx)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  class="pa-0"
                >
                  <v-divider class="my-4" />
                </v-col>
              </v-row>
              <div class="d-flex">
                <span class="flex-grow-1 text-h5 mt-4 black--text">Dados adicionais</span>
              </div>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="form.adicionais"
                    label="Informações Adicionais"
                    placeholder="Informações complementares"
                    rows="2"
                    auto-grow
                    prepend-inner-icon="drive_file_rename_outline"
                    hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    :value="adicionaisSistema"
                    label="Informações Adicionais do Sistema"
                    placeholder="Informações complementares"
                    rows="2"
                    auto-grow
                    prepend-inner-icon="drive_file_rename_outline"
                    disabled
                    hide-details
                  />
                  <div class="my-2">
                    <span>
                      Esta informação é gerada automaticamente pelo sistema com base nas observações cadastradas nas Operações Fiscais.
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form
              ref="form-2"
              v-model="formValidation.cliente"
              lazy-validation
              style="max-height: calc(100vh - 300px);"
              class="overflow-y-auto overflow-x-hidden"
              :disabled="disabled"
            >
              <v-row v-if="form.tipo_emissao !== 'XML'">
                <v-col
                  cols="12"
                  md="6"
                  class="pb-0"
                >
                  <v-menu
                    v-model="showCustomerMenu"
                    transition="slide-y-transition"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="form.cliente.nome"
                        label="Nome *"
                        prepend-inner-icon="person"
                        :rules="[rules.required]"
                        append-icon="search"
                        v-on="on"
                        @click:append="searchPerson"
                        @keyup.enter="searchPerson"
                      />
                    </template>
                    <v-list
                      v-if="customers.length > 0"
                      style="max-height: 300px;"
                      class="overflow-y-auto overflow-x-hidden"
                    >
                      <v-list-item
                        v-for="(customer, idx) in customers"
                        :key="idx"
                        two-line
                        @click="onCustomerSelected(customer)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ customer.nome }}</v-list-item-title>
                          <v-list-item-subtitle>{{ customer.codigo_laticinio }} - {{ customer.cnpj_cpf }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model.trim="form.cliente.cpf_cnpj"
                    prepend-inner-icon="assignment_ind"
                    label="CPF/CNPJ *"
                    :rules="[rules.required]"
                    :mask="masks.cpfCnpjMask"
                    maxlength="18"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <v-select
                    v-model="form.cliente.indicador"
                    :items="[
                      { text: 'Contribuinte ICMS', value: 1 },
                      { text: 'Contribuinte isento de inscrição', value: 2 },
                      { text: 'Não Contribuinte', value: 9 },
                    ]"
                    label="Indicador IE *"
                    prepend-inner-icon="import_export"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.cliente.ie"
                    label="Inscrição Estadual"
                    prepend-inner-icon="subtitles"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <zip-code-input
                    v-model="form.cliente.cep"
                    prepend-inner-icon="subtitles"
                    label="CEP"
                    @address="onAddress"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.cliente.rua"
                    prepend-inner-icon="subtitles"
                    label="Endereço"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.cliente.numero"
                    prepend-inner-icon="subtitles"
                    label="Número"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <states-select
                    v-model="form.cliente.uf"
                    prepend-inner-icon="villa"
                    label="Estado"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <cities-select
                    v-model="form.cliente.cidade"
                    :uf="form.cliente.uf"
                    :code="form.cliente.codigo_cidade"
                    prepend-inner-icon="domain"
                    label="Cidade"
                    @change="onCity"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.cliente.bairro"
                    prepend-inner-icon="subtitles"
                    label="Bairro"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="form.cliente.telefone"
                    prepend-inner-icon="phone"
                    label="Telefone"
                    :mask="masks.phoneMask"
                    maxlength="15"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.cliente.email"
                    prepend-inner-icon="email"
                    label="E-maiI"
                  />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  cols="12"
                  md="8"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.emitente.razao_social"
                    label="Nome *"
                    prepend-inner-icon="person"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model.trim="form.emitente.cpf_cnpj"
                    prepend-inner-icon="assignment_ind"
                    label="CPF/CNPJ *"
                    :rules="[rules.required]"
                    :mask="masks.cpfCnpjMask"
                    maxlength="18"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.emitente.ie"
                    label="Inscrição Estadual"
                    prepend-inner-icon="subtitles"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <zip-code-input
                    v-model="form.emitente.cep"
                    prepend-inner-icon="subtitles"
                    label="CEP"
                    @address="onAddress"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.emitente.rua"
                    prepend-inner-icon="subtitles"
                    label="Endereço"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.emitente.numero"
                    prepend-inner-icon="subtitles"
                    label="Número"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <states-select
                    v-model="form.emitente.uf"
                    prepend-inner-icon="villa"
                    label="Estado"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <cities-select
                    v-model="form.emitente.cidade"
                    :uf="form.emitente.uf"
                    :code="form.emitente.codigo_cidade"
                    prepend-inner-icon="domain"
                    label="Cidade"
                    @change="onCity"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.emitente.bairro"
                    prepend-inner-icon="subtitles"
                    label="Bairro"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="form.emitente.telefone"
                    prepend-inner-icon="phone"
                    label="Telefone"
                    :mask="masks.phoneMask"
                    maxlength="15"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.emitente.email"
                    prepend-inner-icon="email"
                    label="E-maiI"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form
              ref="form-3"
              v-model="formValidation.produtos"
              lazy-validation
              style="max-height: calc(100vh - 300px);"
              class="overflow-y-auto overflow-x-hidden"
              :disabled="disabled"
            >
              <v-tabs
                v-model="tab"
              >
                <v-tab href="#tab-produtos">
                  Produtos
                </v-tab>
                <v-tab href="#tab-impostos">
                  Impostos
                </v-tab>

                <v-tab-item value="tab-produtos">
                  <v-data-table
                    :headers="[
                      { text: 'Nº', value: 'numero', sortable: false },
                      { text: 'Descrição', value: 'descricao', sortable: false },
                      { text: 'Código', value: 'codigo', sortable: false },
                      { text: 'Unidade de Medida', value: 'unidade_medida', sortable: false },
                      { text: 'Quantidade', value: 'quantidade', sortable: false },
                      { text: 'Preço Unitário', value: 'valor_unitario', sortable: false },
                      { text: 'Total', value: 'total', sortable: false },
                      { text: '', value: 'action', sortable: false, width: 70 },
                    ]"
                    :items="form.produtos"
                    hide-default-footer
                    disable-pagination
                  >
                    <template #[`item.numero`]="{ index }">
                      {{ index + 1 }}
                    </template>
                    <template #[`item.descricao`]="{ value, item }">
                      {{ value }}
                      <v-chip
                        v-if="!item.id && form.tipo_emissao !== 'PROPRIA'"
                        outlined
                        small
                        color="warning"
                        class="mb-0 ml-2"
                      >
                        NOVO
                      </v-chip>
                    </template>
                    <template #[`item.quantidade`]="{ value }">
                      {{ formatNumber(value) }}
                    </template>
                    <template #[`item.valor_unitario`]="{ value }">
                      {{ formatCurrency(value) }}
                    </template>
                    <template #[`item.total`]="{ item }">
                      {{ formatCurrency(item.quantidade * item.valor_unitario) }}
                    </template>
                    <template #[`item.action`]="{ index }">
                      <div class="d-flex">
                        <v-btn
                          icon
                          class="mr-2"
                          @click.stop="editProduct(index)"
                        >
                          <v-icon>{{ !disabled ? 'edit' : 'open_in_full' }}</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="!disabled"
                          icon
                          @click.stop="removeProduct(index)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-input
                        :value="form.produtos.length"
                        required
                        :rules="[v => !!v || 'Adicione um produto']"
                      />
                    </v-col>
                  </v-row>
                  <div
                    v-if="!disabled"
                    class="d-flex justify-end"
                  >
                    <v-btn
                      outlined
                      color="blue"
                      class="my-2 mr-2"
                      @click="addProduct()"
                    >
                      <v-icon>add</v-icon> Adicionar produto
                    </v-btn>
                  </div>
                </v-tab-item>

                <v-tab-item value="tab-impostos">
                  <v-data-table
                    :headers="[
                      { text: 'Nº', value: 'numero', sortable: false },
                      { text: 'Descrição', value: 'descricao', sortable: false },
                      { text: 'Código', value: 'codigo', sortable: false },
                      { text: 'CFOP', value: 'impostos.icms.cfop', sortable: false },
                      { text: 'ICMS', value: 'impostos.icms.tributacao', sortable: false },
                      { text: 'ICMS %', value: 'impostos.icms.aliquota', sortable: false },
                      { text: 'ICMS R$', value: 'impostos.icms.valor', sortable: false },
                      { text: 'IPI %', value: 'impostos.ipi.aliquota', sortable: false },
                      { text: 'IPI R$', value: 'impostos.ipi.valor', sortable: false },
                      { text: 'PIS R$', value: 'impostos.pis.valor', sortable: false },
                      { text: 'COFINS R$', value: 'impostos.cofins.valor', sortable: false },
                    ]"
                    :items="form.produtos"
                    hide-default-footer
                    disable-pagination
                  >
                    <template #[`item.numero`]="{ index }">
                      {{ index + 1 }}
                    </template>
                    <template #[`item.impostos.icms.aliquota`]="{ value }">
                      {{ formatNumber(value) }}
                    </template>
                    <template #[`item.impostos.icms.valor`]="{ value }">
                      {{ formatCurrency(value) }}
                    </template>
                    <template #[`item.impostos.ipi.aliquota`]="{ value }">
                      {{ formatNumber(value) }}
                    </template>
                    <template #[`item.impostos.ipi.valor`]="{ value }">
                      {{ formatCurrency(value) }}
                    </template>
                    <template #[`item.impostos.pis.valor`]="{ value }">
                      {{ formatCurrency(value) }}
                    </template>
                    <template #[`item.impostos.cofins.valor`]="{ value }">
                      {{ formatCurrency(value) }}
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-form
              ref="form-4"
              v-model="formValidation.pagamentos"
              lazy-validation
              style="max-height: calc(100vh - 300px);"
              class="overflow-y-auto overflow-x-hidden"
              :disabled="disabled"
            >
              <v-row
                v-for="(item, idx) of form.pagamentos"
                :key="idx"
              >
                <v-col class="py-0">
                  <v-row>
                    <v-col class="py-0">
                      <v-select
                        v-model="item.indicador"
                        :items="[
                          { text: 'Pagamento à vista', value: 0 },
                          { text: 'Pagamento à prazo', value: 1 },
                        ]"
                        label="Indicador da forma de pagamento *"
                        prepend-inner-icon="import_export"
                        :rules="[v => v >= 0 || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col class="py-0">
                      <v-select
                        v-model="item.forma"
                        :items="[
                          { text: 'Dinheiro', value: '01' },
                          { text: 'Cheque', value: '02' },
                          // { text: 'Cartão de Crédito', value: '03' },
                          // { text: 'Cartão de Débito', value: '04' },
                          // { text: 'Crédito Loja', value: '05' },
                          // { text: 'Vale Alimentação', value: '10' },
                          // { text: 'Vale Refeição', value: '11' },
                          // { text: 'Vale Presente', value: '12' },
                          // { text: 'Vale Combustível', value: '13' },
                          { text: 'Duplicata Mercantil', value: '14' },
                          { text: 'Boleto Bancario', value: '15' },
                          { text: 'Depósito Bancário', value: '16' },
                          { text: 'Pagamento Instantâneo (PIX)', value: '17' },
                          { text: 'Transferência bancária, Carteira Digital', value: '18' },
                          // { text: 'Programa de fidelidade, Cashback, Crédito Virtual', value: '19' },
                          // { text: 'Sem Pagamento', value: '90' },
                          // { text: 'Outros', value: '99' },
                        ]"
                        label="Forma de Pagamento *"
                        prepend-inner-icon="import_export"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col class="py-0">
                      <money-input
                        :value="totalNota"
                        label="Valor"
                        disabled
                      />
                    </v-col>
                    <v-col class="py-0">
                      <money-input
                        v-model="item.desconto"
                        label="Desconto"
                      />
                    </v-col>
                    <v-col
                      v-if="!!item.desconto_funrural"
                      class="py-0"
                    >
                      <money-input
                        :value="item.desconto_funrural"
                        label="Desconto Funrural"
                        disabled
                      />
                    </v-col>
                    <v-col class="py-0">
                      <money-input
                        :value="totalNota - (item.desconto || 0) - (item.desconto_funrural || 0)"
                        label="Total"
                        disabled
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <div
                  v-if="item.indicador === 1 && !disabled"
                  class="px-4 d-flex flex-column justify-center"
                >
                  <v-btn
                    outlined
                    color="blue"
                    class="my-2"
                    @click="addParcela(idx)"
                  >
                    <v-icon>add</v-icon> Adicionar parcela
                  </v-btn>
                </div>
                <template v-if="item.indicador === 1">
                  <v-col
                    v-for="(parc, i) of item.parcelas"
                    :key="`${idx}-${i}`"
                    cols="12"
                    class="py-0"
                  >
                    <v-row>
                      <v-col class="py-0">
                        <v-row>
                          <v-col
                            cols="4"
                            class="py-0"
                          >
                            <v-text-field
                              :value="String(i + 1).padStart(3, '0')"
                              label="Parcela"
                              prefix="#"
                              readonly
                              persistent-hint
                              :rules="[v => !!v || 'Campo obrigatório!']"
                            />
                          </v-col>
                          <v-col
                            cols="4"
                            class="py-0"
                          >
                            <masked-text-field
                              v-model="parc.vencimento"
                              label="Vencimento *"
                              prepend-inner-icon="event"
                              placeholder="00/00/0000"
                              :mask="{ mask: '00/00/0000' }"
                              :max-length="10"
                              :rules="[rules.required, rules.date]"
                            />
                          </v-col>
                          <v-col
                            cols="4"
                            class="py-0"
                          >
                            <money-input
                              v-model="parc.valor"
                              label="Valor *"
                              :rules="[rules.required]"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        v-if="!disabled"
                        class="px-0 d-flex flex-column justify-center"
                        style="max-width: 49px;"
                      >
                        <v-btn
                          icon
                          @click.stop="removeParcela(idx, i)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-form
              ref="form-5"
              v-model="formValidation.totais"
              lazy-validation
              style="max-height: calc(100vh - 300px);"
              class="overflow-y-auto overflow-x-hidden"
              :disabled="disabled || form.calculo_automatico"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.produtos"
                    label="Total Produtos"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.desconto"
                    label="Descontos"
                    prefix="R$"
                  />
                </v-col>
                <!-- TODO: Salvar valor do frete -->
                <v-col
                  v-if="false"
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.frete"
                    label="Valor do Frete"
                    prefix="R$"
                  />
                </v-col>
                <!-- TODO: Salvar valor do seguro -->
                <v-col
                  v-if="false"
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.seguro"
                    label="Valor do Seguro"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.despesas"
                    label="Despesas"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.tributos"
                    label="Valor aprox. imp."
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.pis"
                    label="Valor PIS"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.cofins"
                    label="Valor COFINS"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.base"
                    label="Base ICMS"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.icms"
                    label="Valor ICMS"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.base_st"
                    label="Base ICMS ST"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.icms_st"
                    label="Valor ICMS ST"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.fcp"
                    label="Total do FCP"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.fcp_st"
                    label="Total do FCP ST"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.fcp_retido_st"
                    label="Total do FCP ST Ret"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.ipi"
                    label="Valor IPI"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.funrural"
                    label="Valor Funrural"
                    prefix="R$"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="form.total.nota"
                    label="Total da Nota"
                    prefix="R$"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
        <small class="mx-6">* Campo obrigatório</small>
      </v-stepper>
      <v-card-actions>
        <v-btn
          v-if="step == 1"
          color="secondary"
          outlined
          @click="cancel()"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-else
          outlined
          @click="previousStep()"
        >
          <v-icon
            left
            dark
          >
            chevron_left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <template v-if="!disabled">
          <v-btn
            v-if="step < 5"
            color="green"
            outlined
            @click="nextStep"
          >
            Próximo
            <v-icon
              right
              dark
            >
              chevron_right
            </v-icon>
          </v-btn>
          <v-btn
            v-if="['XML', 'TERCEIROS'].includes(form.tipo_emissao)"
            outlined
            color="primary"
            @click="update()"
          >
            Salvar
          </v-btn>
          <v-btn
            v-else-if="!form.status || ['PENDENTE', 'REJEITADA'].includes(form.status)"
            outlined
            color="primary"
            @click="save()"
          >
            Salvar
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            v-if="canEdit"
            color="info"
            outlined
            @click="isEditing = true"
          >
            Editar
            <v-icon
              right
              dark
            >
              edit
            </v-icon>
          </v-btn>
          <v-btn
            v-if="form.status === 'PENDENTE'"
            color="primary"
            @click="authorize()"
          >
            Autorizar no SEFAZ
            <v-icon
              right
              dark
            >
              task_alt
            </v-icon>
          </v-btn>
          <v-btn
            v-if="['PROCESSANDO', 'EMITIDA', 'CONTIGENCIA'].includes(form.status)"
            color="info"
            @click="checkStatus()"
          >
            Consultar nota
            <v-icon
              right
              dark
            >
              info_outline
            </v-icon>
          </v-btn>
          <v-btn
            v-if="form.status === 'EMITIDA'"
            color="primary"
            @click="danfe()"
          >
            Imprimir DANFE
            <v-icon
              right
              dark
            >
              print
            </v-icon>
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>

    <product-dialog
      ref="productDialog"
      :tax-regime="regimeTributario"
      :interstate="operacaoInterestadual"
      :final-consumer="consumidorFinal"
      :customer-state="form.cliente.uf"
      :in-out="form.tipo === 0 ? 'ENTRADA' : 'SAIDA'"
      :issue-type="form.tipo_emissao"
      :disabled="disabled"
      :autocalculation="form.calculo_automatico"
      @save="onProductSaved"
    />

    <invoice-auth-dialog
      ref="authDialog"
      @response="onAuthResponse"
    />

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<style lang="scss">
.invoice-dialog {
  .theme--light.v-label--is-disabled, .theme--light.v-input--is-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .theme--light.v-select.v-input--is-disabled .v-select__selections {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .theme--light.v-select .v-select__selection--disabled {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .v-input__icon--append .theme--light.v-icon.v-icon.v-icon--disabled {
    color: transparent !important;
  }
}
</style>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import ZipCodeInput from "@/Support/Components/ZipCodeInput.vue";
import StatesSelect from "@/Support/Components/StatesSelect.vue";
import CitiesSelect from "@/Support/Components/CitiesSelect.vue";
import ProductDialog from "@/Domains/Fiscal/Components/ProductDialog.vue";
import InvoiceAuthDialog from "@/Domains/Fiscal/Components/InvoiceAuthDialog.vue";

import { getImpostoProduto } from '@/Domains/Settings/Views/Fiscal/Components/Operation/Services/FiscalOperationService.js';

import moment from 'moment';
import isArray from 'lodash/fp/isArray';
import get from 'lodash/get';

const DESTINO_INTERNA = 1;
const DESTINO_INTERESTADUAL = 2;

export default {

  components: {
    MaskedTextField,
    MoneyInput,
    ZipCodeInput,
    StatesSelect,
    CitiesSelect,
    ProductDialog,
    InvoiceAuthDialog,
  },

  props: {
    value: Boolean,
    editId: String,
    type: {
      type: String,
      default: 'ENTRADA',
      validator: (value) => ['ENTRADA', 'SAIDA'].indexOf(value) !== -1
    },
  },

  data() {
    return {
      loading: false,
      saving: false,

      step: 1,
      tab: null,

      form: {
        tipo_emissao: 'PROPRIA',
        calculo_automatico: true,
        emitente: {},
        notas_referenciadas: [],
        notas_rurais_referenciadas: [],
        transporte: {},
        cliente: {},
        produtos: [],
        pagamentos: [{
          indicador: 0,
          forma: '01',
          parcelas: []
        }],
        total: {},
        erros: [],
      },

      formValidation: {
        dados: true,
        cliente: true,
        produtos: true,
        pagamentos: true,
        totais: true,
      },

      showCustomerMenu: false,
      customers: [],

      issuerState: null,
      taxRegime: null,
      environment: null,

      isPreconfiguredOperation: true,
      operations: [],
      operation: null,

      isRecalculatePending: false,

      isEditing: true,

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        dateTime: v => this.isDateValid(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!',
        date: v => this.isDateValid(v, 'DD/MM/YYYY') || 'Data Inválida!',
      },

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
        integer: { mask: Number, min: 0, scale: 0, signed: false },
        percentual: { mask: Number, min: 0, max: 100, scale: 4 },

        phoneMask: {
          mask: "(00) 00000-0000",
        },

        cpfCnpjMask: {
          mask: [
            {
              mask: '000.000.000-00',
              type: 'CPF',
            },
            {
              mask: '00.000.000/0000-00',
              type: 'CNPJ',
            },
          ],
          dispatch: function(appended, dynamicMasked) {
            const number = (dynamicMasked.value + appended).replace(/\D/g, "");
            return dynamicMasked.compiledMasks.find((mask) => number.length > 11 ? mask.type === 'CNPJ' : mask.type === 'CPF');
          },
        },
      }
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    canEdit() {
      return !this.form.status || ['PENDENTE', 'REJEITADA'].includes(this.form.status) || this.form.tipo_emissao !== 'PROPRIA';
    },

    disabled() {
      return !this.canEdit || !this.isEditing
    },

    adicionaisSistema() {
      let adicionais = this.form.adicionais_sistema || ''

      const notaRural = this.form.notas_rurais_referenciadas[0] || {};
      const produtoDespesa = this.form.produtos.find(o => get(o, 'impostos.outros.percentual_despesas')) || {};
      const variaveis = {
        '[VALOR_BASE_ICMS]': this.formatNumber(this.form.total.base) || '',
        '[VALOR_BASE_ICMS_ST]': this.formatNumber(this.form.total.base_st) || '',
        '[VALOR_ICMS_ST]': this.formatNumber(this.form.total.icms_st) || '',
        '[PERC_OUTRAS_DESPESAS]': this.formatNumber(get(produtoDespesa, 'impostos.outros.percentual_despesas')) || '',
        '[VALOR_OUTRAS_DESPESAS]': this.formatNumber(this.form.total.outras_despesas) || '',
        '[DATA_EMISSAO]': this.form.data_emissao_real
          ? moment(this.form.data_emissao_real).format('DD/MM/YYYY')
          : (this.form.data_emissao ? this.form.data_emissao.split(' ')[0] : ''),
        '[SERIE_NOTA_PRODUTOR]': notaRural.serie || '',
        '[NUMERO_NOTA_PRODUTOR]': notaRural.numero || '',
        '[DATA_NOTA_PRODUTOR]': notaRural.data || '',
      };

      adicionais = Object.keys(variaveis)
        .reduce((acc, key) => acc.replace(key, variaveis[key]), adicionais)

      if (this.form.total.funrural) {
        adicionais += adicionais ? '\n' : '';
        adicionais += `Valor Funrural: R$ ${this.formatNumber(this.form.total.funrural, 2, ',', '')}.`;
      }

      return adicionais;
    },

    filteredOperations() {
      // Filtra a natureza de ENTRADA ou SAÍDA
      return this.operations.filter(item => item.tipo === this.form.tipo);
    },

    regimeTributario() {
      return this.form.emitente.regime === 3 ? 'NORMAL' : 'SIMPLES'
    },

    operacaoInterestadual() {
      return this.form.emitente.uf !== this.form.cliente.uf
    },

    consumidorFinal() {
      return this.form.consumidor === 1
    },

    totalNota() {
      return this.form.total.nota || this.form.produtos.reduce((acc, cur) => acc + (cur.quantidade * cur.valor_unitario), 0);
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.reset();

        if (this.editId) {
          this.load();
        }
      }
    }
  },

  created() {
    this.loadSettings();
    this.loadOperations();
  },

  methods: {
    previousStep() {
      this.step--;
    },

    async nextStep() {
      if (this.disabled) {
        this.step++;
        return;
      }

      if (!await this.$refs[`form-${this.step}`].validate()) {
        return;
      }

      if (this.isRecalculatePending) {
        this.recalculateTaxes();
      }

      this.step++;
    },

    async validateForm() {
      for (let i = 1; i <= 5; i++) {
        if (!await this.$refs[`form-${i}`].validate()) {
          this.step = i;
          return false;
        }
      }
      return true;
    },

    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/fiscal/invoice/${this.editId}`);

        this.isPreconfiguredOperation = !!data.id_natureza_operacao;

        if (this.isPreconfiguredOperation) {
          this.onOperationChange(data.id_natureza_operacao);
        }

        this.handleData(data);

      }
      catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os dados da nota!', 'Atenção');
      }
      finally {
        this.loading = false;
      }
    },

    handleData(data) {
      this.isEditing = false;
      this.form = {
        id: data.id,
        ...this.form,
        ...data.json,
        tipo_emissao: data.tipo_emissao,
        status: data.status,
        tipo: data.tipo,
        ambiente: data.ambiente,
        modelo: data.modelo,
        serie: data.serie,
        numero: data.numero,
        chave: data.chave,
        id_natureza_operacao: data.id_natureza_operacao,
        data_emissao: moment(data.data_emissao).format('DD/MM/YYYY HH:mm'),
        data_movimentacao: moment(data.json.data_movimentacao).format('DD/MM/YYYY HH:mm'),
        notas_rurais_referenciadas: (data.json.notas_rurais_referenciadas || [])
          .map(item => ({
            ...item,
            data: moment(item.data).format('DD/MM/YYYY'),
          })),
        produtos: (data.json.produtos || []).map(this.handleProduct),
        pagamentos: (data.json.pagamentos || []).map(this.handlePayment),
        erros: data.erros || [],
      };
    },

    handlePayment(pagamento) {
      return {
        ...pagamento,
        parcelas: (pagamento.parcelas || [])
          .map(parc => ({
            ...parc,
            vencimento: moment(parc.vencimento).format('DD/MM/YYYY'),
          })),
      };
    },

    handleProduct(produto) {
      if (produto.impostos && produto.cfop) {
        produto.impostos.icms.cfop = produto.cfop;
      }
      produto.lotes = (produto.lotes || [])
        .map(lote => ({
          ...lote,
          data_fabricacao: lote.data_fabricacao ? moment(lote.data_fabricacao).format('DD/MM/YYYY') : null,
          data_validade: lote.data_validade ? moment(lote.data_validade).format('DD/MM/YYYY') : null,
        }));
      return produto;
    },

    generatePayload() {
      return {
        ...this.form,
        destino: this.operacaoInterestadual ? DESTINO_INTERESTADUAL : DESTINO_INTERNA,
        data_emissao: this.form.data_emissao ? moment(this.form.data_emissao, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm') : null,
        data_movimentacao: this.form.data_movimentacao ? moment(this.form.data_movimentacao, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm') : null,
        notas_rurais_referenciadas: (this.form.notas_rurais_referenciadas || [])
          .map(item => ({
            ...item,
            data: item.data ? moment(item.data, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
          })),
        produtos: (this.form.produtos || [])
          .map(prod => ({
            ...prod,
            lotes: (prod.lotes || [])
              .map(lote => ({
                ...lote,
                data_fabricacao: lote.data_fabricacao ? moment(lote.data_fabricacao, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
                data_validade: lote.data_validade ? moment(lote.data_validade, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
              }))
          })),
        // É possível ter mais de uma forma de pagamento (EX: dinheiro e cartão), mas por enquanto estará disponível apenas uma
        pagamentos: (this.form.pagamentos || [])
          .map(pag => ({
            ...pag,
            valor: this.totalNota,
            parcelas: (pag.parcelas || [])
              .map(parc => ({
                ...parc,
                vencimento: parc.vencimento ? moment(parc.vencimento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
              }))
          })),
      };
    },

    async save() {
      if (!await this.validateForm()) {
        return;
      }
      this.saving = true;

      try {
        const payload = this.generatePayload();

        const { data } = await this.$axios.post(`/fiscal/invoice/issue`, payload);

        this.$emit('save', data.id);

        this.handleResponse(data.invoice);

      } catch (error) {
        const message = get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async update() {
      if (!await this.validateForm()) {
        return;
      }
      this.saving = true;

      try {
        const payload = this.generatePayload();

        const { data } = await this.$axios.put(`/fiscal/invoice/${this.editId}`, payload);

        this.$emit('save', data.id);

        this.handleResponse(data.invoice);

      } catch (error) {
        const message = get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async authorize() {
      this.$refs['authDialog'].show({
        id: this.form.id,
        env: this.form.ambiente,
        number: this.form.numero,
        customer: this.form.cliente.nome,
        key: this.form.chave,
      });
    },

    onAuthResponse(invoice) {
      this.step = invoice.status === 'REJEITADA' ? 1 : 5;

      this.handleData(invoice);
    },

    async checkStatus() {
      this.saving = true;

      try {
        const { data } = await this.$axios.post(`/fiscal/invoice/status`, {
          ids: [this.form.id]
        });

        const { result, invoice } = data.responses[0];

        if (!result.code) {
          throw result.message;
        }

        this.handleResponse(invoice);

      } catch (error) {
        const message = get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    handleResponse(invoice) {
      this.step = invoice.status === 'REJEITADA' ? 1 : 5;

      this.handleData(invoice);

      if (invoice.status === 'EMITIDA') {
        this.$snotify.success("Nota emitida com sucesso!", "Sucesso");
      } else if (invoice.status === 'PENDENTE' || invoice.status === 'REGISTRADA') {
        this.$snotify.success("Nota salva com sucesso!", "Sucesso");
      } else if (invoice.status === 'PROCESSANDO') {
        this.$snotify.success("Nota sendo processada, aguarde!", "Sucesso");
      } else {
        this.$snotify.warning("A nota possui erros que devem ser corrigidas.");
      }
    },

    async recalculateTaxes() {
      if (!this.form.calculo_automatico) {
        return;
      }

      // Só é possível recalcular se os dados principais da nota estiverem preenchidos
      if (!await this.$refs[`form-1`].validate()) {
        this.isRecalculatePending = true;
        return;
      }

      this.isRecalculatePending = false;

      this.loading = true;

      try {
        const payload = this.generatePayload();

        const { data } = await this.$axios.post(`/fiscal/invoice/calculate`, payload);

        this.form = {
          ...this.form,
          produtos: data.produtos.map(this.handleProduct),
          pagamentos: data.pagamentos.map(this.handlePayment),
          total: data.total
        };

      } catch (error) {
        const message = get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$emit('close');
      this.show = false;
    },

    reset() {
      this.$refs['form-1'] && this.$refs['form-1'].resetValidation();
      this.$refs['form-2'] && this.$refs['form-2'].resetValidation();
      this.$refs['form-3'] && this.$refs['form-3'].resetValidation();
      this.$refs['form-4'] && this.$refs['form-4'].resetValidation();
      this.$refs['form-5'] && this.$refs['form-5'].resetValidation();

      this.tab = null;
      this.step = 1;
      this.form = {
        tipo_emissao: 'PROPRIA',
        calculo_automatico: true,
        tipo: this.type === 'ENTRADA' ? 0 : 1,
        finalidade: 1, // 1 - NF-e Normal
        presenca: 0, // 0 - Não se aplica
        ambiente: this.environment,
        emitente: {
          uf: this.issuerState,
          regime: this.taxRegime,
        },
        notas_referenciadas: [],
        notas_rurais_referenciadas: [],
        transporte: {
          frete: 9, // 9 - Sem Ocorrência de transporte
        },
        cliente: {},
        produtos: [],
        pagamentos: [{
          indicador: 0, // Pagamento à vista
          forma: '01', // Dinheiro
          parcelas: []
        }],
        total: {},
        erros: [],
      };
      this.isPreconfiguredOperation = true;
      this.isEditing = true;
      this.operation = null;
    },

    addNfe() {
      this.form.notas_referenciadas.push('');
    },

    removeNfe(idx) {
      this.form.notas_referenciadas.splice(idx, 1);
    },

    addNfp() {
      this.form.notas_rurais_referenciadas.push({});
    },

    removeNfp(idx) {
      this.form.notas_rurais_referenciadas.splice(idx, 1);
    },

    async searchPerson() {
      try {
        const nome = this.form.cliente.nome;

        if (!nome) {
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaJson`, {
          nome
        });

        if (!isArray(data)) {
          throw data;
        }

        this.customers = data;
        this.showCustomerMenu = true;

      } catch (error) {
        console.error(error);

        this.$snotify.error('Oops, ocorreu um erro ao carregar os clientes!', 'Atenção');
      } finally {
        this.loading = false;
      }
    },

    onCustomerSelected(customer) {
      const ie = (customer.inscricao_estadual || '').trim()
      const taxas = JSON.parse(customer.taxas || '{}');
      this.form.cliente = {
        id: customer.id_pessoa,
        nome: customer.nome,
        cpf_cnpj: customer.cnpj_cpf,
        ie: ie,
        cep: customer.end_cep,
        uf: customer.end_estado,
        codigo_cidade: customer.end_cidade_codigo,
        cidade: customer.end_cidade,
        bairro: customer.end_bairro,
        rua: customer.end_rua,
        numero: customer.end_numero,
        email: customer.email,
        telefone: customer.telefone,
        aliquota_funrural: taxas.funrural ? (parseFloat(taxas.funrural) + parseFloat(taxas.rat || 0) + parseFloat(taxas.senar || 0)) : null,
      };

      if (ie.toUpperCase() === 'ISENTO') {
        this.form.cliente.indicador = 2; //Contribuinte isento de inscrição
      } else if (ie) {
        this.form.cliente.indicador = 1; //Contribuinte ICMS
      } else {
        this.form.cliente.indicador = 9; //Não Contribuinte
      }

      this.showCustomerMenu = false;
    },

    async onAddress(data) {
      this.form.cliente = {
        ...this.form.cliente,
        rua: data.logradouro,
        bairro: data.bairro,
        cidade: data.localidade,
        uf: data.uf,
        codigo_cidade: data.ibge
      }
    },

    onCity(data) {
      this.form.cliente  = {
        ...this.form.cliente,
        cidade: data.city || this.form.cliente.cidade,
        codigo_cidade: data.code || this.form.cliente.codigo_cidade,
      }
    },

    onEmissionChange(value) {
      if (value !== 'PROPRIA') {
        this.form.id_natureza_operacao = null;

        if (this.isPreconfiguredOperation) {
          this.isPreconfiguredOperation = false;
        }
      } else {
        this.form.serie = null;
        this.form.numero = null;
        this.form.chave = null;
        this.form.ambiente = this.environment;
        this.form.emitente.regime = this.taxRegime;
        this.form.emitente.uf = this.issuerState;
      }
    },

    onOperationTypeChange() {
      this.form.id_natureza_operacao = null;
    },

    onOperationChange(id) {
      if (id) {
        this.operation = this.operations.find(o => o.id === id);
        if (this.operation) {
          this.form.serie = this.form.serie || this.operation.serie;
          this.form.natureza = this.operation.descricao;
          this.form.consumidor = this.operation.consumidor_final ? 1 : 0;
          this.form.adicionais_sistema = this.operation.informacoes_adicionais;

          for (const produto of this.form.produtos) {
            produto.impostos = getImpostoProduto(this.getConfiguredTaxes(), this.form.cliente.uf, produto.id, produto.ncm);
          }
        }
      } else {
        this.natureza = null;
      }
    },

    getConfiguredTaxes() {
      if (this.operation) {
        return {
          icms: { ...this.operation.icms },
          ipi: { ...this.operation.ipi },
          pis: { ...this.operation.pis },
          cofins: { ...this.operation.cofins },
          funrural: {
            ...this.operation.funrural,
            // Preferencia de taxa funrural cadastrada no perfil do produtor
            aliquota: this.form.cliente.aliquota_funrural || this.operation.funrural.aliquota
          },
          outros: { ...this.operation.outros },
          icms_excecoes: this.operation.icms_excecoes || [],
          ipi_excecoes: this.operation.ipi_excecoes || [],
          pis_excecoes: this.operation.pis_excecoes || [],
          cofins_excecoes: this.operation.cofins_excecoes || [],
        };
      } else if (this.form.cliente.aliquota_funrural) {
        return {
          funrural: {
            descontar_do_total_faturado: false,
            aliquota: this.form.cliente.aliquota_funrural,
          }
        };
      }

      return null;
    },

    async loadSettings() {
      try {
        const { data } = await this.$axios.get(`/fiscal/settings`);

        this.environment = data.ambiente,
        this.taxRegime = data.regime_tributario;
        this.issuerState = data.empresa.end_estado;
        this.onEmissionChange(this.form.tipo_emissao);

      } catch (e) {
        console.error(e);
      }
    },

    async loadOperations() {
      try {
        const { data } = await this.$axios.get(`/fiscal/operation`);

        this.operations = data;

      } catch (e) {
        console.error(e);
        this.$snotify.error('Oops, ocorreu um erro ao carregar as naturezas de operações!', 'Atenção');
      }
    },

    addProduct() {
      const product = {
        idx: this.form.produtos.length,
        impostos: this.getConfiguredTaxes()
      };
      this.$refs['productDialog'].show(product, this.getConfiguredTaxes());
    },

    async removeProduct(idx) {
      if (!(await this.$root.$confirm('Remover produto', 'Tem certeza que deseja remover este item?', { color: 'red' }))) {
        return;
      }
      this.form.produtos.splice(idx, 1);
      this.recalculateTaxes();
    },

    editProduct(idx) {
      const product = this.form.produtos[idx];
      product.idx = idx;
      this.$refs['productDialog'].show(product, this.getConfiguredTaxes());
    },

    onProductSaved({ idx, ...product }) {
      this.form.produtos.splice(idx, 1, product);
      this.recalculateTaxes();
    },

    addParcela(idx) {
      this.form.pagamentos[idx].parcelas.push({})
      this.onChangeInstallments(idx);
    },

    removeParcela(idx, i) {
      this.form.pagamentos[idx].parcelas.splice(i, 1);
      this.onChangeInstallments(idx);
    },

    onChangeInstallments(idx) {
      const payment = this.form.pagamentos[idx];
      // Quantidade de parcelas
      const noInstallments = payment.parcelas.length;
      // Valor da conta
      const accountAmount = parseFloat(this.totalNota - (payment.desconto || 0) - (payment.desconto_funrural || 0));
      // Valor de cada parcela, com arredondamento para 2 casas decimais (para baixo)
      const installmentAmount = Math.floor((accountAmount / noInstallments) * 100) / 100;
      // Diferença em centavos das parcelas
      const rest = accountAmount - (installmentAmount * noInstallments);
      // Na última parcela deve ser adicionado a diferença dos centavos
      const lastAmount = +(installmentAmount + rest).toFixed(2);

      for (let i = 0; i < noInstallments; i++) {
        const amount = i < (noInstallments - 1) ? installmentAmount : lastAmount;
        payment.parcelas[i].valor =  amount;
      }
    },

    async danfe() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/fiscal/invoice/print-danfe`, { ids: [this.form.id] }, { responseType: 'blob' });

        const dataObject = window.URL.createObjectURL(data);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = `${this.form.chave}-nfe.pdf`;
        link.click();

        window.URL.revokeObjectURL(dataObject);
        link.remove();

      } catch (error) {
        const message = get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    isDateValid(value, format) {
      if (!value) {
        return true;
      }

      if (value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },

    getStatusColor(value) {
      const statusColors = {
        'PENDENTE': 'amber darken-1',
        'PROCESSANDO': 'blue',
        'REJEITADA': 'red',
        'EMITIDA': 'green',
        'CANCELADA': 'grey',
        'DENEGADA': 'red darken-1',
        'CONTIGENCIA': 'teal',
      };
      return statusColors[value];
    },

    formatNumber: (value) => value ? new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value) : '-',
    formatCurrency: (value) => value ? 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value) : '-',
  }
}
</script>
