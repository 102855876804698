var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"text-xs-center":"","grid-list-lg":""}},[_c('v-container',[_c('v-card',{staticClass:"v-tamanho collecton-city",attrs:{"color":"transparent","dark":""}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-date-range-picker',{staticClass:"pt-0 pb-7",attrs:{"label":"Data","dark":""},on:{"change":_vm.onFilter},model:{value:(_vm.localDateFilter),callback:function ($$v) {_vm.localDateFilter=$$v},expression:"localDateFilter"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('person-autocomplete-filter',{staticClass:"pt-0 pb-7",attrs:{"label":"Produtor","type":"PRODUCER","multiple":"","show-groupings":""},on:{"change":_vm.onFilter},model:{value:(_vm.localProducersFilter),callback:function ($$v) {_vm.localProducersFilter=$$v},expression:"localProducersFilter"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('routes-autocomplete-filter',{staticClass:"pt-0 pb-7",attrs:{"label":"Rota","dark":"","multiple":""},on:{"change":_vm.onFilter},model:{value:(_vm.localRoutesFilter),callback:function ($$v) {_vm.localRoutesFilter=$$v},expression:"localRoutesFilter"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('masked-input',{attrs:{"mask":{ mask: '00:00' },"max-length":5,"prepend-icon":"access_time","placeholder":"Filtrar Horas (00:00) Coletas acima de:"},on:{"change":_vm.onTimeFormat},model:{value:(_vm.filters.horas),callback:function ($$v) {_vm.$set(_vm.filters, "horas", $$v)},expression:"filters.horas"}})],1)],1),_c('data-table',{ref:"report",staticClass:"collecton-city-table",attrs:{"dark":"","items":_vm.relatorio,"search":_vm.filters.search,"headers":[
          { text:'Código', value:'codigo_produtor', align: 'start', width: '10%'},
          { text:'Produtor', value:'nome_produtor', width: '40%', align:'left'},
          { text:'Última Coleta (Hrs)', value:'ultima_coleta', width: '20%', align:'center'},
          { text:'Data Última Coleta', value:'data_ultima_coleta', width: '20%', align:'center'},
          { text:'Volume Coletado', value:'volume_coletado', width: '20%', align:'center'},
        ],"item-key":"id_produtor","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"pa-0 text-center",attrs:{"colspan":7}},[_c('data-table',{staticClass:"elevation-1 ma-3 text-center",attrs:{"headers":[
                { text:'Rota', value:'nome_rota', width: '20%', align:'center'},
                { text:'Data', value:'data', width: '20%', align:'center'},
                { text:'Coleta (Hrs)', value:'hora', width: '20%', align:'center'},
                { text:'Volume Coletado', value:'quantidade_coleta', width: '40%', align: 'center'},
              ],"items":item.details,"items-per-page":-1,"dense":"","hide-default-footer":""}})],1)]}}])})],1)],1),_c('v-speed-dial',{staticClass:"mr-5",attrs:{"fixed":"","dark":"","bottom":"","right":"","open-on-hover":"","direction":"top","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-2","dark":"","large":"","fab":""}},[_c('v-icon',[_vm._v("menu")])],1)]},proxy:true}])},[_c('v-btn',{attrs:{"fab":"","dark":"","color":"green darken-1"},on:{"click":function($event){return _vm.exportExcel()}}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" backup_table ")])]}}])},[_vm._v(" Download (Excel) ")])],1),_c('v-btn',{attrs:{"fab":"","dark":"","color":"orange darken-1"},on:{"click":_vm.print}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" print ")])]}}])},[_vm._v(" Imprimir ")])],1)],1),_c('v-overlay',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_vm._v(" Carregando ... ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }