<template>
  <v-dialog
    v-model="show"
    width="650"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">
          Inventário
        </span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <person-autocomplete-filter
                v-model="form.producer"
                label="Produtor *"
                type="PRODUCER"
                :filled="false"
                :hide-details="false"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.bezerras"
                label="Bezerras"
                placeholder="0 até 12 meses"
                prepend-inner-icon="icon-rebanho"
                :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.bezerros"
                label="Bezerros"
                placeholder="0 até 12 meses"
                prepend-inner-icon="icon-rebanho"
                :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.novilhas"
                label="Novilhas"
                placeholder="12 até 24 meses"
                prepend-inner-icon="icon-rebanho"
                :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.novilhos"
                label="Novilhos"
                placeholder="12 até 24 meses"
                prepend-inner-icon="icon-rebanho"
                :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.vacas"
                label="Vacas"
                placeholder="24 meses >"
                prepend-inner-icon="icon-rebanho"
                :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.boi_touros"
                label="Boi/Touros"
                placeholder="24 meses >"
                prepend-inner-icon="icon-rebanho"
                :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="green"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

export default {
  components: {
    PersonAutocompleteFilter,
    MaskedTextField,
  },

  props: {
    value: Boolean,
    editId: String,
  },

  data() {
    return {
      form: {},
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.form = {};

        if (this.editId) {
          this.loadInventory();
        }
      }
    },
  },

  methods: {

    async loadInventory() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/producer-inventory/${this.editId}`);

        this.form = {
          id: data.id,
          producer: data.producer ? {
            id: data.producer.id_pessoa,
            name: data.producer.nome,
          } : null,
          bezerros: data.bezerros,
          novilhos: data.novilhos,
          boi_touros: data.boi_touros,
          bezerras: data.bezerras,
          novilhas: data.novilhas,
          vacas: data.vacas
        };
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_produtor: this.form.producer.id,
          bezerros: this.form.bezerros,
          novilhos: this.form.novilhos,
          boi_touros: this.form.boi_touros,
          bezerras: this.form.bezerras,
          novilhas: this.form.novilhas,
          vacas: this.form.vacas
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;
        this.$emit('save', editId);
        this.close();

        this.$snotify.success("Registro salvo com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar registro", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/projects/producer-inventory`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/projects/producer-inventory/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    },
  },
};
</script>
