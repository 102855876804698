var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('card-title-settings',{staticClass:"mb-3"},[_vm._v(" Configuração da NF-e ")]),_c('v-card',{attrs:{"dark":"","outlined":"","color":"rgba(255, 255, 255, 0.08)"}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":[
                  { text: 'Produção', value: 1 },
                  { text: 'Homologação', value: 2 },
                ],"label":"Ambiente"},model:{value:(_vm.settings.environment),callback:function ($$v) {_vm.$set(_vm.settings, "environment", $$v)},expression:"settings.environment"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":[
                  { text: 'Simples Nacional', value: 1 },
                  { text: 'Simples Nacional - Excesso de Sublimite', value: 2 },
                  { text: 'Regime Normal', value: 3 },
                ],"label":"Regime Tributário"},model:{value:(_vm.settings.taxRegime),callback:function ($$v) {_vm.$set(_vm.settings, "taxRegime", $$v)},expression:"settings.taxRegime"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('card-title-settings',{staticClass:"mb-3"},[_vm._v(" Certificado Digital (A1) ")]),_c('v-card',{attrs:{"outlined":"","color":"rgba(255, 255, 255, 0.08)"}},[_c('v-card-text',[_c('v-row',[(_vm.settings.certificate)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('file-viewer',{attrs:{"value":_vm.settings.certificate,"preview-height":58},on:{"removeFile":function($event){return _vm.onRemoveFile()}}})],1):_c('v-col',{staticClass:"py-0 text-center",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mb-5 mt-5",attrs:{"outlined":"","dark":""},on:{"click":function($event){_vm.showFileUpload = !_vm.showFileUpload}}},[_c('v-icon',[_vm._v("attach_file")]),_c('span',{staticClass:"ml-2"},[_vm._v("Adicionar")])],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Senha *","type":"password","dark":""},model:{value:(_vm.settings.password),callback:function ($$v) {_vm.$set(_vm.settings, "password", $$v)},expression:"settings.password"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"dark":"","color":"transparent"}},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(" Controle de Numeração da NF-e "),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":_vm.addNumeration}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" add ")])],1)]}}])},[_c('span',[_vm._v("Incluir numeração")])])],1),_c('v-data-table',{attrs:{"dark":"","sort-by":'nome',"sort-desc":false,"items-per-page":15,"headers":[
            { text: 'Série', value: 'series' },
            { text: 'Próximo número', value: 'nextNumber' },
            { text: 'Ambiente', value: 'environment' },
            { text: 'Opções', value: 'actions', sortable: false, align: 'center' },
          ],"items":_vm.numerations},on:{"click:row":_vm.editNumeration},scopedSlots:_vm._u([{key:`item.environment`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.getEnvironment(value))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"small":""},on:{"click":function($event){return _vm.nfNumberingControl(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Excluir")])],1)],1)],1)]}}],null,true)})],1),_c('v-card',{staticClass:"mt-6",attrs:{"dark":"","color":"transparent"}},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(" Data de Pagamento em Faturas de Contra-Notas "),_c('v-spacer'),_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Procurar","dark":""},model:{value:(_vm.filters.routeSearch),callback:function ($$v) {_vm.$set(_vm.filters, "routeSearch", $$v)},expression:"filters.routeSearch"}})],1)],1),_c('v-data-table',{attrs:{"dark":"","search":_vm.filters.routeSearch,"sort-by":'descricao',"sort-desc":false,"items-per-page":10,"headers":[
            { text: 'Rota', value: 'descricao' },
            { text: 'Data de Pagamento', value: 'dia_pagamento', width: 200 },
          ],"items":_vm.settings.routes},scopedSlots:_vm._u([{key:`item.dia_pagamento`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center my-1"},[_c('masked-text-field',{attrs:{"label":"Dia","mask":_vm.masks.day,"unmask":"","placeholder":"15","outlined":"","dense":"","hide-details":""},model:{value:(item.dia_pagamento),callback:function ($$v) {_vm.$set(item, "dia_pagamento", _vm._n($$v))},expression:"item.dia_pagamento"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.applyToAllRoutes(item)}}},on),[_c('v-icon',{staticStyle:{"transform":"rotate(90deg)"}},[_vm._v(" multiple_stop ")])],1)]}}],null,true)},[_vm._v(" Aplicar a todos ")])],1)]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"dark":"","color":"transparent"}},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(" Naturezas de Operação "),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":_vm.addOperation}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" add ")])],1)]}}])},[_c('span',[_vm._v("Incluir natureza de operação")])])],1),_c('v-data-table',{attrs:{"dark":"","sort-by":'nome',"sort-desc":false,"items-per-page":15,"headers":[
            { text: 'Descrição', value: 'description', width: 450 },
            { text: 'Padrão', value: 'default', width: 450 },
            { text: '', value: 'actions', width: 10, align: 'end'},
          ],"items":_vm.operations},on:{"click:row":_vm.editOperation},scopedSlots:_vm._u([{key:`item.default`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.getDefault(value))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.removeOperation(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Excluir")])],1)],1)],1)]}}],null,true)})],1)],1)],1),_c('file-upload',{attrs:{"multiselect":false,"extensions":"p12,pfx","accept":".p12,.pfx"},on:{"insertFiles":_vm.onSelectFile},model:{value:(_vm.showFileUpload),callback:function ($$v) {_vm.showFileUpload=$$v},expression:"showFileUpload"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"bottom":"","color":"green mr-5","dark":"","fab":"","fixed":"","large":"","right":""},on:{"click":function($event){$event.stopPropagation();return _vm.saveSettings()}}},on),[_c('v-icon',[_vm._v("save")])],1)]}}])},[_c('span',[_vm._v("Salvar configurações ")])]),_c('numeration-dialog',{attrs:{"edit-id":_vm.numerationDialog.id},on:{"save":_vm.loadNumerations},model:{value:(_vm.numerationDialog.show),callback:function ($$v) {_vm.$set(_vm.numerationDialog, "show", $$v)},expression:"numerationDialog.show"}}),_c('operation-dialog',{attrs:{"edit-id":_vm.dialog.id,"tax-regime":_vm.settings.taxRegime === 3 ? 'NORMAL' : 'SIMPLES'},on:{"save":_vm.loadOperations},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }