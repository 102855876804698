<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Pré validação - Análises
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                md="3"
                class="py-0"
              >
                <v-date-range-picker
                  v-model="filters.dateRange"
                  dark
                  @change="load"
                />
              </v-col>
              <v-col
                md="3"
                class="py-0"
              >
                <v-select
                  v-model="filters.status"
                  dark
                  filled
                  label="Status"
                  hide-details
                  clearable
                  :items="[
                    { text:'Sucesso', value: 'SUCCESS' },
                    { text:'Erro', value: 'ERROR' },
                    { text:'Processado', value: 'PROCESSED' },
                  ]"
                  multiple
                  @change="load"
                  small-chips
                />
              </v-col>
              <v-col
                md="3"
                class="py-0"
              >
                <v-select
                  v-model="filters.type"
                  dark
                  filled
                  label="Tipo"
                  hide-details
                  clearable
                  :items="[
                    { text:'Produtor', value:'PRODUCER' },
                    { text:'Rota', value:'ROUTE' },
                    { text:'Silo', value:'silo' },
                    { text:'Controle Leiteiro', value:'DAIRY_CONTROL' }
                  ]"
                  @change="load"
                />
              </v-col>
              <v-col
                md="3"
                class="py-0"
              >
                <v-text-field
                  v-model="filters.search"
                  filled
                  hide-details
                  prepend-inner-icon="search"
                />
              </v-col>
            </v-row>
          </v-card-title>

          <data-table
            ref="report"
            :headers="headers"
            :items="filteredItems"
            class="elevation-1"
            disable-filtering
            multi-sort
            dark
            item-key="id_pre_analise"
            @click:row="edit"
          >
            <template #[`item.compoe_media`]="{ value }">
              <v-chip small>
                {{ value ? 'Sim' : 'Não' }}
              </v-chip>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-menu>
                <template #activator="{ on }">
                  <v-btn
                    dark
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list v-if="hasEditAnalisisValidationAccess || hasDeleteAnalisisValidationAccess">
                  <v-list-item
                    v-if="hasEditAnalisisValidationAccess"
                    @click="edit(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        edit
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Editar
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="hasDeleteAnalisisValidationAccess"
                    @click="onAnalysisExclude(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        delete
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Excluir
                    </v-list-item-title>
                  </v-list-item>
                </v-list>

                <v-list v-else>
                  <v-list-item>
                    <v-list-item-title disabled>
                      Sem acesso
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template #[`footer.prepend`]>
              <div class="text-caption text-left ml-4">
                Total de
                <v-chip
                  small
                  class="mx-2 mb-0"
                >
                  {{ items.length }}
                </v-chip>
                registros
              </div>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <editing-analysis-dialog
      ref="dialog"
      @onSave="onAnalysisSave"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="deep-green darken-1"
        @click="onAnalysisProcess"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              check
            </v-icon>
          </template>

          Concluir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="deep-purple darken-1"
        @click="showCreatingAnalysisDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Adicionar Analise
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import EditingAnalysisDialog from "@/Domains/Analysis/Producers/Components/EditingAnalysisDialog.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

import moment from "moment-timezone";
import _ from "lodash";

const Status = {
  'SUCCESS': 'Sucesso',
  'ERROR': 'Erro',
};

const Types = {
  'PRODUCER': 'Produtor',
  'ROUTE': 'Rota',
  'SILO': 'Silo',
  'DAIRY_CONTROL': 'Controle Leiteiro',
};

export default {
  components: {
    VDateRangePicker,
    EditingAnalysisDialog,
  },

  data() {
    return {
      filters: {
        search: '',
        dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        type: null,
        status: [
          'SUCCESS',
          'ERROR',
        ],
      },

      headers: [
        { text: 'Código Laticínio', value: 'producer.code', align: 'center' },
        { text: 'Produtor/Rota/Resfriador', value: 'producer.name', align: 'start' },
        { text: 'Data', value: 'collectedAt', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Compõe Média', value: 'compoe_media', align: 'center' },
        { text: 'Tipo', value: 'type', align: 'center', formatter: value => (Types[value]) },
        { text: 'Status', value: 'status', align: 'center', formatter: value => (Status[value]) },
        { text: 'CCS', value: 'ccs', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'CPP', value: 'cbt', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Caseína', value: 'caseina', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Gordura', value: 'gordura', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Uréia', value: 'ureia', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Lactose', value: 'lactose', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Proteína', value: 'proteina', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Crioscopia', value: 'crioscopia', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'ESD', value: 'esd', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'S.T.', value: 'solidos_totais', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Amostra', value: 'numero_amostra', align: 'center' },
        { value: 'actions', sortable: false, align: 'end', width: 20 },
      ],

      items: [],

      // Dialogs
      editing: {
        show: false,
        title: '',
        analysis: {},
      },
    };

  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;
      return this.items.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasSearch;
      });
    },

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    hasEditAnalisisValidationAccess() {
      return this.$store.state.settings.user.id_cargo === 1 || this.userResources.some(o => o.recurso === "edit-analisis-validation" && o.tipo === "COMPONENTE");
    },

    hasDeleteAnalisisValidationAccess() {
      return this.$store.state.settings.user.id_cargo === 1 || this.userResources.some(o => o.recurso === "delete-analisis-validation" && o.tipo === "COMPONENTE");
    },
  },

  methods: {
    showCreatingAnalysisDialog() {
      return this.$refs.dialog.show({
        producer: {},
        analysisAt: moment().format('YYYY-MM-DD'),
        type: 'PRODUCER',
        compoe_media: true,
      });
    },

    async onAnalysisSave(analysis) {
      try {
        this.$root.$progressBar.loading();

        let analysisAt = !_.isEmpty(analysis.analyzedAt) ? moment(analysis.analyzedAt).format('YYYY-MM-DD') : null ;
        let collectedAt = !_.isEmpty(analysis.collectedAt) ? moment(analysis.collectedAt).format('YYYY-MM-DD') : null ;

        const request = {
          id_pre_analise: analysis.id,
          data_coleta: collectedAt,
          data_analise: analysisAt,
          status: 'SUCCESS',
          id_produtor: analysis.producer.id,
          nome: analysis.producer.name,
          cbt: analysis.cbt,
          ccs: analysis.ccs,
          gordura: analysis.gordura,
          caseina: analysis.caseina,
          ureia: analysis.ureia,
          lactose: analysis.lactose,
          proteina: analysis.proteina,
          esd: analysis.esd,
          solidos_totais: analysis.solidos_totais,
          numero_amostra: analysis.numero_amostra,
          crioscopia: analysis.crioscopia,
          compoe_media: analysis.compoe_media,
        };

        if (analysis.id) {
          await this.$axios.put(`/analysis/pre-check/${analysis.id}`, request);

          this.$refs.dialog.close();

          return this.load();
        }

        await this.$axios.post(`/analysis/pre-check`, {
          ...request,
          tipo: 'PRODUCER',
        });

        this.$refs.dialog.close();

        return this.load();
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a analise!", "Atenção");
      } finally {
        this.$refs.dialog.close();

        this.$root.$progressBar.hide();
      }
    },

    async onAnalysisExclude(analysis) {
      try {
        if (!(await this.$root.$confirm(
          'Atenção',
          `Deseja realmente excluir a amostra <b>${analysis.numero_amostra}</b>?<br><br>Esta ação não poderá ser revertida!<br><br>`,
          { color: 'red', token: 'EXCLUIR' }
        ))
        ) {
          return;
        }

        this.$root.$progressBar.loading();

        await this.$axios.delete(`/analysis/pre-check/${analysis.id}`);

        this.$refs.dialog.close();

        await this.load();
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a analise!", "Atenção");
      } finally {
        this.$refs.dialog.close();

        this.$root.$progressBar.hide();
      }
    },

    async load() {
      try {
        this.$root.$progressBar.loading();

        const [dateStart, dateEnd] = this.filters.dateRange;

        let { data } = await this.$axios.get(`/analysis/pre-check`, {
          params: {
            start_date: dateStart,
            end_date: dateEnd,
            status: this.filters.status,
            type: this.filters.type,
          }
        });

        this.items = data.map(row => {
          return {
            id: row.id_pre_analise,
            settings: {
              id: row.id_laticinio_laboratorio,
            },
            producer: {
              id: row.id_produtor,
              name: row.nome,
              code: row.codigo_laticinio,
            },
            analyzedAt: row.data_analise,
            collectedAt: row.data_coleta,
            status: row.status,
            type: row.tipo,
            caseina: row.caseina,
            cbt: row.cbt,
            ccs: row.ccs,
            crioscopia: row.crioscopia,
            esd: row.esd,
            gordura: row.gordura,
            lactose: row.lactose,
            numero_amostra: row.numero_amostra,
            proteina: row.proteina,
            solidos_totais: row.solidos_totais,
            ureia: row.ureia,
            compoe_media: row.compoe_media,
          }
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async onAnalysisProcess() {
      try {
        this.$root.$progressBar.loading();

        const invalidAnalysis = this.items.filter(({ type, producer }) => {
          return type === 'PRODUCER' && !producer.id;
        });

        if (!_.isEmpty(invalidAnalysis)) {
          this.$snotify.error("Oops, Existem analises sem produtor vinculado! Verifique-as e tente novamente.", "Atenção");

          return;
        }

        const analysis = this.items.map(item => {
          return {
            id_pre_analise: item.id,
            id_laticinio_laboratorio: item.settings.id,
            id_produtor: item.producer.id,
            nome: item.producer.name,
            codigo_laticinio: item.producer.code,
            data_analise: item.analyzedAt,
            data_coleta: item.collectedAt,
            status: item.status,
            tipo: item.type,
            caseina: item.caseina,
            cbt: item.cbt,
            ccs: item.ccs,
            crioscopia: item.crioscopia,
            esd: item.esd,
            gordura: item.gordura,
            lactose: item.lactose,
            numero_amostra: item.numero_amostra,
            proteina: item.proteina,
            solidos_totais: item.solidos_totais,
            ureia: item.ureia,
            compoe_media: item.compoe_media,
          }
        });

        await this.$axios.put(`/analysis/pre-check/process`, analysis);

        return this.load();
      } catch (error) {
        console.warn(error);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Análises - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    edit(row) {
      if (!this.hasEditAnalisisValidationAccess) {
        return;
      }

      return this.$refs.dialog.show(_.cloneDeep(row));
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    parseNumber: val => +String(val) || null,
  },

}
</script>
