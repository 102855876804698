<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          v-if="ccs_meta"
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-text>
            <v-row>
              <v-col
                md="11"
              >
                <v-chart
                  type="bar"
                  :options="ccsGraph"
                  :style="{width: '100%', height: `300px !important`}"
                  autoresize
                />
              </v-col>
              <v-col
                v-if="ccs.length > 0 "
                md="1"
                class="pt-12"
              >
                <p>Médias </p>
                <p>Meta: {{ Math.abs(ccs[0].goals).toFixed(2) }} </p>
                <p>Inicial: {{ Math.abs(ccsFirst).toFixed(2) }} </p>
                <p>
                  Final: {{ Math.abs(ccs[ccs.length-1].media).toFixed(2) }}
                  <v-icon
                    right
                    :color="ccs[ccs.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
                  >
                    verified
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="cpp_meta"
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-text>
            <v-row>
              <v-col
                md="11"
              >
                <v-chart
                  type="bar"
                  :options="cbtGraph"
                  :style="{width: '100%', height: `350px !important`}"
                  autoresize
                />
              </v-col>
              <v-col
                v-if="cpp.length > 0 "
                md="1"
                class="pt-12"
              >
                <p>Médias </p>
                <p>Meta: {{ Math.abs(cpp[0].goals).toFixed(2) }} </p>
                <p>Inicial: {{ Math.abs(cppFirst).toFixed(2) }} </p>
                <p>
                  Final: {{ Math.abs(cpp[cpp.length-1].media).toFixed(2) }}
                  <v-icon
                    right
                    :color="cpp[cpp.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
                  >
                    verified
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="gordura_meta"
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-text>
            <v-row>
              <v-col
                md="11"
              >
                <v-chart
                  type="bar"
                  :options="gorduraGraph"
                  :style="{width: '100%', height: `350px !important`}"
                  autoresize
                />
              </v-col>
              <v-col
                v-if="gordura.length > 0 "
                md="1"
                class="pt-12"
              >
                <p>Médias </p>
                <p>Meta: {{ Math.abs(gordura[0].goals).toFixed(2) }} </p>
                <p>Inicial: {{ Math.abs(gorduraFirst).toFixed(2) }} </p>
                <p>
                  Final: {{ Math.abs(gordura[gordura.length-1].media).toFixed(2) }}
                  <v-icon
                    right
                    :color="gordura[gordura.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
                  >
                    verified
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="proteina_meta"
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-text>
            <v-row>
              <v-col
                md="11"
              >
                <v-chart
                  type="bar"
                  :options="proteinaGraph"
                  :style="{width: '100%', height: `350px !important`}"
                  autoresize
                />
              </v-col>
              <v-col
                v-if="proteina.length > 0 "
                md="1"
                class="pt-12"
              >
                <p>Médias </p>
                <p>Meta: {{ Math.abs(proteina[0].goals).toFixed(2) }} </p>
                <p>Inicial: {{ Math.abs(proteinaFirst).toFixed(2) }} </p>
                <p>
                  Final: {{ Math.abs(proteina[proteina.length-1].media).toFixed(2) }}
                  <v-icon
                    right
                    :color="proteina[proteina.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
                  >
                    verified
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="caseina_meta"
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-text>
            <v-row>
              <v-col
                md="11"
              >
                <v-chart
                  type="bar"
                  :options="caseinaGraph"
                  :style="{width: '100%', height: `350px !important`}"
                  autoresize
                />
              </v-col>
              <v-col
                v-if="caseina.length > 0 "
                md="1"
                class="pt-12"
              >
                <p>Médias </p>
                <p>Meta: {{ Math.abs(caseina[0].goals).toFixed(2) }} </p>
                <p>Inicial: {{ Math.abs(caseinaFirst).toFixed(2) }} </p>
                <p>
                  Final: {{ Math.abs(caseina[caseina.length-1].media).toFixed(2) }}
                  <v-icon
                    right
                    :color="caseina[caseina.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
                  >
                    verified
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="solidos_totais_meta"
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-text>
            <v-row>
              <v-col
                md="11"
              >
                <v-chart
                  type="bar"
                  :options="solidosTotaisGraph"
                  :style="{width: '100%', height: `350px !important`}"
                  autoresize
                />
              </v-col>
              <v-col
                v-if="solidos_totais.length > 0 "
                md="1"
                class="pt-12"
              >
                <p>Médias </p>
                <p>Meta: {{ Math.abs(solidos_totais[0].goals).toFixed(2) }} </p>
                <p>Inicial: {{ Math.abs(solidosTotaisFirst).toFixed(2) }} </p>
                <p>
                  Final: {{ Math.abs(solidos_totais[solidos_totais.length-1].media).toFixed(2) }}
                  <v-icon
                    right
                    :color="solidos_totais[solidos_totais.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
                  >
                    verified
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="volume_meta"
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-text>
            <v-row>
              <v-col
                md="11"
              >
                <v-chart
                  type="bar"
                  :options="volumeGraph"
                  :style="{width: '100%', height: `350px !important`}"
                  autoresize
                />
              </v-col>
              <v-col
                v-if="volume.length > 0 "
                md="1"
                class="pt-12"
              >
                <p>Médias </p>
                <p>Meta: {{ Math.abs(volume[0].goals).toFixed(2) }} </p>
                <p>Inicial: {{ Math.abs(volumeFirst).toFixed(2) }} </p>
                <p>
                  Final: {{ Math.abs(volume[volume.length-1].media).toFixed(2) }}
                  <v-icon
                    right
                    :color="volume[volume.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
                  >
                    verified
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

export default {
  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  props: {
    aba: String,
    filter: Object
  },

  data() {
    return {
      loading: false,

      history: {
        headers_quality: [
          { text: 'Mês Início', value: 'mes_ini' },
          { text: 'Mês Fim', value: 'mes_fim' },
          { text: 'Indicador', value: 'indicator' },
          { text: 'Média Inicial', value: 'media_ini' },
          { text: 'Média Final', value: '' },
          { text: 'Meta', value: 'goals' },
          { text: 'Cumprimento de Meta %', value: '' },
        ],
      },

      qualityGoals: {},

      ccsFirst: '',

      ccs: [],

      ccs_meta: [],

      cppFirst: null,

      cpp: [],

      cpp_meta: [],

      gorduraFirst: null,

      gordura: [],

      gordura_meta: [],

      proteinaFirst: null,

      proteina: [],

      proteina_meta: [],

      caseinaFirst: null,

      caseina: [],

      caseina_meta: [],

      solidosTotaisFirst: null,

      solidos_totais: [],

      solidos_totais_meta: [],

      volumeFirst: '',

      volume: [],

      volume_meta: []
    };
  },

  computed: {
    ccsGraph() {
      let acao = this.ccs.map(x => x.indicator === 'CCS') ? this.ccs.map(x => x.acao)[0] : '';
      let percent = this.ccs.map(x => x.goalsPerc)[0];

      return this.generateGraphOptions(this.ccs, 'Média Geométrica CCS * (1000) - ' + acao +  ' ' + percent);
    },

    cbtGraph() {
      let acao = this.cpp.map(x => x.indicator === 'CPP') ? this.cpp.map(x => x.acao)[0] : '';
      let percent = this.cpp.map(x => x.goalsPerc)[0];

      return this.generateGraphOptions(this.cpp, 'Média Geométrica CPP * (1000) - ' + acao +  ' ' + percent);
    },

    gorduraGraph() {
      let acao = this.gordura.map(x => x.indicator === 'Gordura') ? this.gordura.map(x => x.acao)[0] : '';
      let percent = this.gordura.map(x => x.goalsPerc)[0];

      return this.generateGraphOptions(this.gordura, 'Média Aritmética de Gordura - ' + acao + ' ' + percent);
    },

    proteinaGraph() {
      let acao = this.proteina.map(x => x.indicator === 'Proteína') ? this.proteina.map(x => x.acao)[0] : '';
      let percent = this.proteina.map(x => x.goalsPerc)[0];

      return this.generateGraphOptions(this.proteina, 'Média Aritmética de Proteína - ' + acao + ' ' + percent);
    },

    caseinaGraph() {
      let acao = this.caseina.map(x => x.indicator === 'Caseína') ? this.caseina.map(x => x.acao)[0] : '';
      let percent = this.caseina.map(x => x.goalsPerc)[0];

      return this.generateGraphOptions(this.caseina, 'Média Aritmética de Caseína - ' + acao + ' ' + percent);
    },

    solidosTotaisGraph() {
      let acao = this.solidos_totais.map(x => x.indicator === 'Sólidos Totais') ? this.solidos_totais.map(x => x.acao)[0] : '';
      let percent = this.solidos_totais.map(x => x.goalsPerc)[0];

      return this.generateGraphOptions(this.solidos_totais, 'Média Aritmética de Sólidos Totais - ' + acao + ' ' + percent);
    },

    volumeGraph() {
      let acao = this.volume.map(x => x.indicator === 'Volume') ? this.volume.map(x => x.acao)[0] : '';
      let percent = this.volume.map(x => x.goalsPerc)[0];

      return this.generateGraphOptions(this.volume, 'Média de Volume - ' + acao + ' ' + percent);
    },
  },

  watch: {
    filter: {
      handler() {
        this.loadVisits();
      },
      deep: true
    }
  },

  mounted() {
    this.loadVisits();
  },

  methods: {
    async loadVisits() {
      if (this.filter.project) {
        if (this.aba == 'reports_quality') {
          await this.loadCCS();
          await this.loadCPP();
          await this.loadGordura();
          await this.loadProteina();
          await this.loadCaseina();
          await this.loadSolidosTotais();
          await this.loadVolume();
        }
      } else {
        this.ccs = [];
        this.cpp = [],
        this.gordura = [],
        this.proteina = [],
        this.caseina = [],
        this.solidos_totais = [],
        this.volume = []
      }
    },

    async loadCCS() {
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaCcsPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: "PMLS"
          })
        );

        if (!_.isEmpty(data)) {
          let tipo      = 0;
          let meta      = 0;
          let indicator = '';
          let acao      = '';

          if (!_.isEmpty(this.filter.project.quality) ) {
            this.qualityGoals = JSON.parse(this.filter.project.quality).map(q => {
              if ( q.indicador == 'CCS' ) {
                meta = this.formatNumber(q.percentual);
                tipo = q.tipo;
                indicator = q.indicador;
                acao = q.acao;
              }
            })
          }

          //primeira media para ser utilizado como referencia
          if (_.isEmpty(this.ccsFirst)) {
            this.ccsFirst = parseInt(data[0].ccs);
          }

          this.ccs = data.map(o => {
            let icone = 'cancel';
            let metaCalculada;

            if (tipo == "UN") {
              metaCalculada =  meta
            } else {
              if (acao == 'REDUZIR') {
                metaCalculada = parseFloat(this.ccsFirst) - (this.ccsFirst / 100) * meta;
              } else {
                metaCalculada = parseFloat(this.ccsFirst) + (this.ccsFirst / 100) * meta;
              }
            }

            if ((acao == 'AUMENTAR' && parseFloat(o.ccs) > parseFloat(metaCalculada)) ||
                (acao == 'REDUZIR' && parseFloat(o.ccs) < parseFloat(metaCalculada)) ) {
              icone = 'verified';
            }

            return {
              media: o.ccs ? o.ccs : 0,
              data: o.data,
              goals: metaCalculada ? metaCalculada : 0,
              goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta) + ' UN',
              icone: icone,
              indicator: indicator,

              acao: acao,
            }
          });

          this.ccs_meta = this.ccs.find(o => ({ indicator: o.indicador })).indicator;
        }

      } catch (error) {
        console.log(error);
      }
    },

    async loadCPP() {
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaCppPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: "PMLS"
          })
        );

        if (!_.isEmpty(data)) {
          let tipo      = 0;
          let meta      = 0;
          let indicator = '';
          let acao      = '';

          if (!_.isEmpty(this.filter.project.quality) ) {
            this.qualityGoals = JSON.parse(this.filter.project.quality).map(q => {
              if ( q.indicador == 'CPP' ) {
                meta = this.formatNumber(q.percentual);
                tipo = q.tipo;
                indicator = q.indicador;
                acao = q.acao;
              }
            })
          }

          //primeira media para ser utilizado como referencia
          if (_.isEmpty(this.cppFirst)) {
            this.cppFirst = parseInt(data[0].cpp);
          }

          this.cpp = data.map(o => {
            let icone = 'cancel';
            let metaCalculada;

            if (tipo == "UN") {
              metaCalculada =  meta
            } else {
              if (acao == 'REDUZIR') {
                metaCalculada = parseFloat(this.cppFirst) - (this.cppFirst / 100) * meta;
              } else {
                metaCalculada = parseFloat(this.cppFirst) + (this.cppFirst / 100) * meta;
              }
            }

            if ((acao == 'AUMENTAR' && parseFloat(o.cpp) > parseFloat(metaCalculada)) ||
                (acao == 'REDUZIR' && parseFloat(o.cpp) < parseFloat(metaCalculada)) ) {
              icone = 'verified';
            }

            return {
              media: o.cpp,
              data: o.data,
              goals: metaCalculada ? metaCalculada : 0,
              goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta) + ' UN',
              icone: icone,
              indicator: indicator,
              acao: acao
            }
          });

          this.cpp_meta = this.cpp.find(o => ({ indicator: o.indicador })).indicator;
        }

      } catch (error) {
        console.log(error);
      }
    },

    async loadGordura() {
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaAritmeticaIndicadoresPeriodo`,
          this.$qs.stringify({
            indicador: 'gordura',
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: "PMLS"
          })
        );

        if (!_.isEmpty(data)) {
          let tipo      = 0;
          let meta      = 0;
          let acao      = '';
          let indicador = '';

          if (!_.isEmpty(this.filter.project.quality) ) {
            this.qualityGoals = JSON.parse(this.filter.project.quality).map(q => {
              if ( q.indicador == 'Gordura' ) {
                meta = this.formatNumber(q.percentual);
                tipo = q.tipo;
                acao = q.acao;
                indicador = q.indicador;
              }
            })
          }

          //primeira media para ser utilizado como referencia
          if (_.isEmpty(this.gorduraFirst)) {
            this.gorduraFirst = parseInt(data[0].media);
          }

          this.gordura = data.map(o => {
            let icone = 'cancel';
            let metaCalculada;

            if (tipo == "UN") {
              metaCalculada =  meta
            } else {
              if (acao == 'REDUZIR') {
                metaCalculada = parseFloat(this.gorduraFirst) - (this.gorduraFirst / 100) * meta;
              } else {
                metaCalculada = parseFloat(this.gorduraFirst) + (this.gorduraFirst / 100) * meta;
              }
            }

            if ((acao == 'AUMENTAR' && parseFloat(o.media) > parseFloat(metaCalculada)) ||
                (acao == 'REDUZIR' && parseFloat(o.media) < parseFloat(metaCalculada))) {
              icone = 'verified';
            }

            return {
              media: o.media ? o.media : 0,
              data: o.data,
              goals: metaCalculada ? metaCalculada : 0,
              goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta) + ' UN',
              icone: icone,
              acao: acao,
              indicator: indicador
            }
          });

          this.gordura_meta = this.gordura.find(o => ({ indicador: o.indicator })).indicator;
        }

      } catch (error) {
        console.log(error);
      }
    },

    async loadProteina() {
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaAritmeticaIndicadoresPeriodo`,
          this.$qs.stringify({
            indicador: 'proteina',
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: "PMLS"
          })
        );

        if (!_.isEmpty(data)) {
          let tipo = 0;
          let meta = 0;
          let acao = '';
          let indicador = '';

          if (!_.isEmpty(this.filter.project.quality) ) {
            this.qualityGoals = JSON.parse(this.filter.project.quality).map(q => {
              if ( q.indicador == 'Proteína' ) {
                meta = this.formatNumber(q.percentual);
                tipo = q.tipo;
                acao = q.acao;
                indicador = q.indicador;
              }
            })
          }

          //primeira media para ser utilizado como referencia
          if (_.isEmpty(this.proteinaFirst)) {
            this.proteinaFirst = parseInt(data[0].media);
          }

          this.proteina = data.map(o => {
            let icone = 'cancel';
            let metaCalculada;

            if (tipo == "UN") {
              metaCalculada =  meta
            } else {
              if (acao == 'REDUZIR') {
                metaCalculada = parseFloat(this.proteinaFirst) - (this.proteinaFirst / 100) * meta;
              } else {
                metaCalculada = parseFloat(this.proteinaFirst) + (this.proteinaFirst / 100) * meta;
              }
            }

            if ((acao == 'AUMENTAR' && parseFloat(o.media) > parseFloat(metaCalculada)) ||
                (acao == 'REDUZIR' && parseFloat(o.media) < parseFloat(metaCalculada))) {
              icone = 'verified';
            }

            return {
              media: o.media ? o.media : 0,
              data: o.data,
              goals: metaCalculada ? metaCalculada : 0,
              goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta) + ' UN',
              icone: icone,
              indicator: indicador,
              acao: acao
            }
          });

          this.proteina_meta = this.proteina.find(o => ({ indicador: o.indicator })).indicator;
        }

      } catch (error) {
        console.log(error);
      }
    },

    async loadCaseina() {
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaAritmeticaIndicadoresPeriodo`,
          this.$qs.stringify({
            indicador: 'caseina',
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: "PMLS"
          })
        );

        if (!_.isEmpty(data)) {
          let tipo = 0;
          let meta = 0;
          let acao = '';
          let indicador = '';

          if (!_.isEmpty(this.filter.project.quality) ) {
            this.qualityGoals = JSON.parse(this.filter.project.quality).map(q => {
              if ( q.indicador == 'Caseína' ) {
                meta = this.formatNumber(q.percentual);
                tipo = q.tipo;
                acao = q.acao;
                indicador = q.indicador;
              }
            })
          }

          //primeira media para ser utilizado como referencia
          if (_.isEmpty(this.caseinaFirst)) {
            this.caseinaFirst = parseInt(data[0].media);
          }

          this.caseina = data.map(o => {
            let icone = 'cancel';
            let metaCalculada;

            if (tipo == "UN") {
              metaCalculada =  meta
            } else {
              if (acao == 'REDUZIR') {
                metaCalculada = parseFloat(this.caseinaFirst) - (this.caseinaFirst / 100) * meta;
              } else {
                metaCalculada = parseFloat(this.caseinaFirst) + (this.caseinaFirst / 100) * meta;
              }
            }

            if ((acao == 'AUMENTAR' && parseFloat(o.media) > parseFloat(metaCalculada)) ||
                (acao == 'REDUZIR' && parseFloat(o.media) < parseFloat(metaCalculada))) {
              icone = 'verified';
            }

            return {
              media: o.media ? o.media : 0,
              data: o.data,
              goals: metaCalculada ? metaCalculada : 0,
              goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta) + ' UN',
              icone: icone,
              indicator: indicador,
              acao: acao
            }
          });

          this.caseina_meta = this.caseina.find(o => ({ indicador: o.indicator })).indicator;
        }

      } catch (error) {
        console.log(error);
      }
    },

    async loadSolidosTotais() {
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaAritmeticaIndicadoresPeriodo`,
          this.$qs.stringify({
            indicador: 'solidos_totais',
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: "PMLS"
          })
        );

        if (!_.isEmpty(data)) {
          let tipo = 0;
          let meta = 0;
          let acao = '';
          let indicador = '';

          if (!_.isEmpty(this.filter.project.quality) ) {
            this.qualityGoals = JSON.parse(this.filter.project.quality).map(q => {
              if ( q.indicador == 'Sólidos Totais' ) {
                meta = this.formatNumber(q.percentual);
                tipo = q.tipo;
                acao = q.acao;
                indicador = q.indicador
              }
            })
          }

          //primeira media para ser utilizado como referencia
          if (_.isEmpty(this.solidosTotaisFirstFirst)) {
            this.solidosTotaisFirstFirst = parseInt(data[0].media);
          }

          this.solidos_totais = data.map(o => {
            let icone = 'cancel';
            let metaCalculada;

            if (tipo == "UN") {
              metaCalculada =  meta
            } else {
              if (acao == 'REDUZIR') {
                metaCalculada = parseFloat(this.solidosTotaisFirstFirst) - (this.solidosTotaisFirstFirst / 100) * meta;
              } else {
                metaCalculada = parseFloat(this.solidosTotaisFirstFirst) + (this.solidosTotaisFirstFirst / 100) * meta;
              }
            }

            if ((acao == 'AUMENTAR' && parseFloat(o.media) > parseFloat(metaCalculada)) ||
                (acao == 'REDUZIR' && parseFloat(o.media) < parseFloat(metaCalculada))) {
              icone = 'verified';
            }

            return {
              media: o.media ? o.media : 0,
              data: o.data,
              goals: metaCalculada ? metaCalculada : 0,
              goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta) + ' UN',
              icone: icone,
              indicator: indicador,
              acao: acao
            }
          });

          this.solidos_totais_meta = this.solidos_totais.find(o => ({ indicador: o.indicator })).indicator;
        }

      } catch (error) {
        console.log(error);
      }
    },

    async loadVolume() {
      try {
        const { data } = await this.$axios.post(
          `/relatorios/volumeMes`,
          {
            data_inicio: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: "PMLS"
          }
        );

        if (!_.isEmpty(data)) {
          let tipo = 0;
          let meta = 0;
          let acao = '';
          let indicador = '';

          if (!_.isEmpty(this.filter.project.quality) ) {
            this.qualityGoals = JSON.parse(this.filter.project.quality).map(q => {
              if ( q.indicador == 'Volume' ) {
                meta = this.formatNumber(q.percentual);
                tipo = q.tipo;
                acao = q.acao;
                indicador = q.indicador;
              }
            })
          }

          //primeira media para ser utilizado como referencia
          if (_.isEmpty(this.volumeFirst)) {
            this.volumeFirst = parseInt(data[0].total);
          }

          this.volume = data.map(o => {
            let icone = 'cancel';
            let metaCalculada;

            if (tipo == "UN") {
              metaCalculada =  meta
            } else {
              if (acao == 'REDUZIR') {
                metaCalculada = parseFloat(this.volumeFirst) - (this.volumeFirst / 100) * meta;
              } else {
                metaCalculada = parseFloat(this.volumeFirst) + (this.volumeFirst / 100) * meta;
              }
            }

            if ((acao == 'AUMENTAR' && parseFloat(o.total) > parseFloat(metaCalculada)) ||
                (acao == 'REDUZIR' && parseFloat(o.total) < parseFloat(metaCalculada))) {
              icone = 'verified';
            }

            return {
              media: o.total ? o.total : 0,
              data: o.mes + "-01",
              goals: metaCalculada ? metaCalculada : 0,
              goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta) + ' UN',
              icone: icone,
              indicator: indicador,
              acao: acao
            }
          });

          this.volume_meta = this.volume.find(o => ({ indicador: o.indicator })).indicator;
        }

      } catch (error) {
        console.log(error);
      }
    },

    generateGraphOptions(data, title) {
      return {
        color: ['rgba(229, 57, 53, 0.7)',  'rgba(38, 198, 218, 0.7)'],
        title: {
          text: `${title}`,
          textStyle: { color: '#ddd' },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.data)),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          },
        },
        series: [
          {
            name: 'Média',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => Math.abs(o.media).toFixed(2)),
          },
          {
            name: 'Meta',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: o => '  ' + data[o.dataIndex].goalsPerc,
              }
            },
            data: data.map(o =>  Math.abs(o.goals).toFixed(2)),
          }
        ]
      };
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  }
};
</script>
