<template>
  <v-text-field
    ref="field"
    v-bind="$attrs"
    @focus="$emit('focus', $event)"
    @blur="normalizeValue"
    @keyup="$emit('keyup', $event)"
    @click:append="$emit('click:append', $event)"
    @click:clear="$emit('click:clear', $event)"
  >
    <template
      v-for="(_, slotName) in $slots"
      #[slotName]
    >
      <slot :name="slotName" />
    </template>
  </v-text-field>
</template>

<script>
import IMask from 'imask'

export default {
  name: 'MaskedTextField',

  props: {
    value: {
      type: [String, Number],
      required: false,
      default: String()
    },

    mask: {
      type: Object,
      required: true
    },

    unmask: [Boolean, String]
  },

  data() {
    return {
      element: {},
      masked: {},
    }
  },

  watch: {
    value() {
      if (this.maskRef) {
        this.updateValue();
      }
    }
  },

  mounted () {
    if (!this.mask) return;

    this.initMask();
  },

  destroyed() {
    this.destroyMask();
  },

  methods: {
    initMask() {
      this.element = this.$refs.field.$el.querySelector('input')
      this.maskRef = IMask(this.element, this.mask)
        .on('accept', this.onAccept.bind(this));
      this.updateValue();
    },

    destroyMask() {
      if (this.maskRef) {
        this.maskRef.destroy();
        delete this.maskRef;
      }
    },

    updateValue() {
      const value = this.value == null || this.value == undefined ? '' : this.value.toString();
      if (this.unmask === 'typed') {
        this.maskRef.typedValue = value;
      }
      else if (this.unmask) {
        this.maskRef.unmaskedValue = value;
      }
      else {
        this.maskRef.value = value;
      }
      this.$refs.field.internalValue = this.maskRef.value;
      this.maskRef.updateValue()
      this.maskRef.updateControl()
    },

    maskValue() {
      if (this.unmask === 'typed') {
        return this.maskRef.typedValue;
      }

      if (this.unmask) {
        return this.maskRef.unmaskedValue;
      }

      return this.maskRef.value;
    },

    onAccept() {
      const val = this.maskValue();
      if (val != this.value) {
        this.$emit('input', val);
      }
    },

    normalizeValue() {
      this.$refs.field.internalValue = this.maskRef.value;
      this.$emit('blur', this.maskRef.value);
    },

    focus() {
      this.$refs.field?.focus();
    },

    resetValidation() {
      this.$refs.field?.resetValidation();
    },
  },
}
</script>
