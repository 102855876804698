<template>
  <v-dialog
    v-model="show"
    scrollable
    :max-width="1100"
  >
    <v-card>
      <v-card-title>
        Informar lotes {{ type === 'ENTRADA' ? 'e validades' : '' }} de produtos
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <v-form
          ref="form"
          style="max-height: calc(100vh - 250px);"
          @submit="save"
        >
          <v-simple-table
            dense
          >
            <template #default>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th :width="100">
                    Quantidade
                  </th>
                  <th>Lote</th>
                  <th>Fabricação</th>
                  <th>Validade</th>
                  <th>
                    Fornecedor
                  </th>
                  <th>Qtde. do Lote</th>
                  <th :width="30" />
                </tr>
              </thead>

              <tbody>
                <template v-for="(item, itemIdx) in products">
                  <tr
                    v-for="(lote, idx) in item.lotes"
                    :key="`${itemIdx}-${idx}`"
                  >
                    <td>
                      <span v-if="idx === 0">
                        {{ item.nome }}
                        <span
                          v-if="!!item.codigo"
                          class="text-body-2 grey--text d-block"
                        >
                          {{ item.codigo }}
                        </span>
                      </span>
                    </td>
                    <td>
                      <masked-text-field
                        v-if="idx === 0"
                        :value="item.quantidade"
                        :mask="masks.float"
                        unmask
                        readonly
                        dense
                        :suffix="item.unidade"
                        class="mt-4"
                        :rules="getQuantityRules(itemIdx)"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-if="type === 'ENTRADA' || item.lote_manual"
                        v-model="lote.numero"
                        label="Lote"
                        outlined
                        dense
                        class="mt-4"
                        :rules="[rules.required]"
                        @blur="checkIfHasPersistedLots(item.id_item, itemIdx, idx)"
                      />
                      <v-select
                        v-else-if="type === 'SAIDA'"
                        v-model="lote.lote"
                        :items="getPersistedLots(item.id_item, itemIdx, idx)"
                        label="Lote"
                        item-text="text"
                        item-value="numero_lote"
                        return-object
                        outlined
                        dense
                        class="mt-4"
                        :rules="[
                          v => !!v.text || 'Campo obrigatório!',
                          v => v.quantidade >= lote.quantidade || `Quantidade indisponível (${v.quantidade} disp.)`,
                        ]"
                      />
                    </td>
                    <td>
                      <date-text-field
                        v-if="type === 'ENTRADA' || item.lote_manual"
                        v-model="lote.data_fabricacao"
                        label="Fabricação"
                        manual
                        outlined
                        dense
                        class="mt-4"
                        :disabled="lote.lote_existente"
                        :rules="[rules.date]"
                      />
                      <date-text-field
                        v-else-if="type === 'SAIDA'"
                        :value="lote.lote?.data_fabricacao"
                        label="Fabricação"
                        manual
                        max=""
                        readonly
                        dense
                        class="mt-4"
                      />
                    </td>
                    <td>
                      <date-text-field
                        v-if="type === 'ENTRADA' || item.lote_manual"
                        v-model="lote.data_validade"
                        label="Validade"
                        manual
                        max=""
                        outlined
                        dense
                        class="mt-4"
                        :disabled="lote.lote_existente"
                        :rules="!item.lote_manual ? [rules.required, rules.date] : [rules.date]"
                      />
                      <date-text-field
                        v-else-if="type === 'SAIDA'"
                        :value="lote.lote?.data_validade"
                        label="Validade"
                        manual
                        max=""
                        readonly
                        dense
                        class="mt-4"
                      />
                    </td>
                    <td>
                      <template v-if="type === 'ENTRADA' || item.lote_manual">
                        <person-autocomplete-filter
                          v-if="!lote.fornecedor_manual"
                          v-model="lote.fornecedor"
                          label="Fornecedor"
                          type="COMMERCIAL"
                          :prepend-inner-icon="null"
                          :hide-details="false"
                          :filled="false"
                          outlined
                          dense
                          class="mt-4"
                          append-outer-icon="edit"
                          :disabled="lote.lote_existente"
                          @click:append-outer="lote.fornecedor_manual = !lote.fornecedor_manual"
                        />
                        <v-text-field
                          v-else
                          v-model="lote.fornecedor.name"
                          label="Fornecedor"
                          outlined
                          dense
                          class="mt-4"
                          append-outer-icon="edit"
                          :disabled="lote.lote_existente"
                          @click:append-outer="lote.fornecedor_manual = !lote.fornecedor_manual"
                        />
                      </template>
                      <v-text-field
                        v-else-if="type === 'SAIDA'"
                        :value="lote.lote?.nome_fornecedor"
                        label="Fornecedor"
                        readonly
                        dense
                        class="mt-4"
                      />
                    </td>
                    <td>
                      <masked-text-field
                        v-model="lote.quantidade"
                        label="Qtde. do Lote"
                        :mask="masks.float"
                        unmask
                        outlined
                        dense
                        :suffix="item.unidade"
                        class="mt-4"
                        :rules="[rules.required]"
                      />
                    </td>
                    <td>
                      <v-menu
                        bottom
                        right
                      >
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            @click="addLot(itemIdx)"
                          >
                            <v-list-item-icon>
                              <v-icon>
                                add
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Informar outro lote</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="item.lotes.length > 1"
                            @click="removeLot(itemIdx, idx)"
                          >
                            <v-list-item-icon>
                              <v-icon>
                                delete
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Remover lote</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import DateTextField from '@/Support/Components/DateTextField.vue';

import moment from 'moment';
import { get } from 'lodash';

export default {

  name: 'ItemLotDialog',

  components: {
    PersonAutocompleteFilter,
    MaskedTextField,
    DateTextField,
  },

  props: {
    value: Boolean,
    type: {
      type: String,
      default: 'ENTRADA',
      validator: (value) => ['ENTRADA', 'SAIDA'].includes(value)
    },
    items: {
      type: Array,
      default: () => ([])
    },
    warehouseId: String,
  },

  data() {
    return {
      headers: [
        { text: 'Produto', value: 'nome' },
        { text: 'Quantidade', value: 'quantidade', width: 100 },
        { text: 'Lote', value: 'lote' },
        { text: 'Fabricação', value: 'data_fabricacao' },
        { text: 'Validade', value: 'data_validade' },
        { text: 'Qtde. do Lote', value: 'quantidade_lote' },
        { text: '', value: 'action', width: 30 },
      ],

      products: [],
      persistedLots: {},

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        date: v => !v || moment(v, 'YYYY-MM-DD').isValid() || 'Campo Inválido!',
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    quantityDiff() {
      return this.products.map(item => parseFloat(item.quantidade) - item.lotes.reduce((acc, cur) => (acc + (parseFloat(cur.quantidade) || 0)), 0));
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.init()
      }
    }
  },

  methods: {
    async init() {
      this.$refs.form && this.$refs.form.resetValidation();

      this.products = this.items.map(item => ({
        ...item,
        lotes: [
          {
            numero: null,
            data_fabricacao: null,
            data_validade: null,
            quantidade: item.quantidade,
            lote: {}, // Utilizado em saídas
            fornecedor_manual: false,
            fornecedor: {},
            lote_existente: false,
          }
        ]
      }));

      this.persistedLots = {};

      if (this.products.length === 0) {
        this.$snotify.error('Itens não informados para lote');
        this.show = false;
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/stock`, { params: {
          id_deposito: this.warehouseId,
          ids: this.products.map(item => item.id_item),
        } });

        this.persistedLots = data.reduce((acc, cur) => {
          if (!(cur.id_item in acc)) {
            acc[cur.id_item] = [];
          }

          cur.text = cur.numero_lote || 'Sem Lote'

          acc[cur.id_item].push(cur);

          return acc;
        }, {});

        if (this.type === 'SAIDA') {
          for (let product of this.products) {
            if (!product.lote_manual) {
              const lots = this.persistedLots[product.id_item] || []
              // Auto select first
              product.lotes[0].lote = lots[0] || {};
            }
          }
        }
      } catch (error) {
        const message = get(error, 'response.data.message', 'Erro ao carregar estoques');
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }

    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      const products = this.products.map(product => ({
        ...product,
        lotes: product.lotes.map(item => ({
          ...item,
          quantidade: item.quantidade,
          numero: item.lote?.numero_lote || item.numero,
          data_fabricacao: item.lote?.data_fabricacao || item.data_fabricacao,
          data_validade: item.lote?.data_validade || item.data_validade,
          fornecedor: item.fornecedor,
        }))
      }))

      this.$emit('save', products);
      this.close();
    },

    close() {
      this.show = false;
    },

    addLot(itemIdx) {
      this.products[itemIdx].lotes.push({
        numero: null,
        data_fabricacao: null,
        data_validade: null,
        quantidade: null,
        lote: {}, // Utilizado em saídas
      })
    },

    removeLot(itemIdx, idx) {
      this.products[itemIdx].lotes.splice(idx, 1);
    },

    getQuantityRules(idx) {
      const diff = this.quantityDiff[idx];

      if (diff === 0) {
        return [];
      }

      return [
        () => diff < 0 || `${diff} restantes`,
        () => diff > 0 || `Quantidade difere do informado`,
      ];
    },

    getPersistedLots(itemId, itemIdx, idx) {
      const persistedLots = this.persistedLots[itemId] || [];

      const alreadySelected = this.products[itemIdx].lotes
        .map(item => item.lote.numero_lote);

      alreadySelected.splice(idx, 1);

      // Filtra os lotes já selecionados, excluindo o do index atual
      return persistedLots.filter(item => !alreadySelected.includes(item.numero_lote) )
    },

    checkIfHasPersistedLots(itemId, itemIdx, idx) {
      const persistedLots = this.persistedLots[itemId] || [];

      const lotForm = this.products[itemIdx].lotes[idx];
      const lotNumber = lotForm.numero;

      const lot = persistedLots.find(item => item.numero_lote === lotNumber);

      // Insere o lote existente (lot), ou atualiza se for alterado (lote_existente)
      if (lot || lotForm.lote_existente) {
        this.products[itemIdx].lotes[idx].data_fabricacao = lot?.data_fabricacao;
        this.products[itemIdx].lotes[idx].data_validade = lot?.data_validade;
        this.products[itemIdx].lotes[idx].fornecedor = { id: lot?.id_fornecedor, name: lot?.nome_fornecedor }
        this.products[itemIdx].lotes[idx].fornecedor_manual = lot && !lot?.id_fornecedor;
        this.products[itemIdx].lotes[idx].lote_existente = !!lot;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  }
}
</script>
