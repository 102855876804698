<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="800"
    persistent
  >
    <v-card class="invoice-dialog">
      <v-card-title>
        {{ item.descricao }}
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs
          v-model="tab"
        >
          <v-tab href="#tab-produto">
            Dados do produto
          </v-tab>
          <v-tab href="#tab-icms">
            ICMS
          </v-tab>
          <v-tab href="#tab-ipi">
            IPI
          </v-tab>
          <v-tab href="#tab-pis">
            PIS
          </v-tab>
          <v-tab href="#tab-cofins">
            COFINS
          </v-tab>
          <v-tab href="#tab-funrural">
            FUNRURAL
          </v-tab>
          <v-tab href="#tab-outros">
            OUTROS
          </v-tab>

          <v-tab-item
            eager
            value="tab-produto"
          >
            <v-form
              ref="produto"
              lazy-validation
              :disabled="disabled"
              @submit.prevent="save()"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="pb-0"
                >
                  <product-select
                    v-if="issueType === 'PROPRIA'"
                    v-model="item.descricao"
                    label="Descrição *"
                    prepend-inner-icon="inventory"
                    :rules="[rules.required]"
                    @productSelected="onProductSelected"
                  />
                  <v-text-field
                    v-else
                    v-model="item.descricao"
                    label="Descrição *"
                    prepend-inner-icon="inventory"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="item.codigo"
                    label="Código"
                    prepend-inner-icon="qr_code"
                    :disabled="!!item.id"
                  >
                    <template #append>
                      <v-btn
                        v-if="!!item.id"
                        icon
                        small
                        @click.stop="item.codigo = null, item.id = null"
                      >
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="item.unidade_medida"
                    label="Unidade de Medida *"
                    placeholder="UN, KG, LT"
                    prepend-inner-icon="straighten"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="item.codigo_barras"
                    label="GTIN/EAN"
                    prepend-inner-icon="subtitles"
                    persistent-hint
                    placeholder="SEM GTIN"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="item.ncm"
                    label="NCM"
                    prepend-inner-icon="subtitles"
                    :mask="{ mask: '0000.00.00' }"
                    unmask
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="item.cest"
                    label="CEST"
                    prepend-inner-icon="subtitles"
                    :mask="{ mask: '00.000.00' }"
                    unmask
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-select
                    v-model="item.origem"
                    :items="[
                      { value: 0, text: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8' },
                      { value: 1, text: '1 - Estrangeira - Importação direta, exceto a indicada no código 6' },
                      { value: 2, text: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7' },
                      { value: 3, text: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%' },
                      { value: 4, text: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes' },
                      { value: 5, text: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%' },
                      { value: 6, text: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural' },
                      { value: 7, text: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural' },
                      { value: 8, text: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%' },
                    ]"
                    label="Origem da Mercadoria"
                    prepend-inner-icon="place"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="item.quantidade"
                    label="Quantidade *"
                    :mask="masks.float"
                    unmask
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <money-input
                    v-model="item.valor_unitario"
                    label="Preço Unitário *"
                    :precision="4"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <money-input
                    :value="item.quantidade * item.valor_unitario"
                    label="Total"
                    readonly
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pb-0"
                >
                  <v-select
                    v-model="item.rastreabilidade"
                    label="Controlado por lote"
                    :items="[
                      { text: 'Sim', value: true },
                      { text: 'Não', value: false },
                    ]"
                    prepend-inner-icon="pallet"
                    persistent-hint
                    class="mt-0"
                    @change="onLoteChange"
                  />
                </v-col>
              </v-row>
              <template v-if="item.rastreabilidade">
                <div class="d-flex">
                  <span class="flex-grow-1 text-h5 mt-4 black--text">Rastreabilidade/Lote do Produto</span>

                  <div
                    v-if="!disabled"
                    class="d-flex justify-end"
                  >
                    <v-btn
                      outlined
                      color="blue"
                      class="my-2"
                      @click="addLote()"
                    >
                      <v-icon>add</v-icon> Lote
                    </v-btn>
                  </div>
                </div>

                <v-divider class="mb-4" />

                <v-row
                  v-for="(lote, idx) of item.lotes"
                  :key="'LOTE-' + idx"
                >
                  <v-col class="pb-0">
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        class="pb-0"
                      >
                        <v-select
                          v-if="inOut === 'SAIDA' && availableLots.length > 0"
                          v-model="lote.numero"
                          label="Nº Lote *"
                          prepend-inner-icon="subtitles"
                          :items="availableLots"
                          item-text="numero"
                          item-value="numero"
                          :rules="[rules.required]"
                          @change="onLotSelected(idx)"
                        />
                        <v-text-field
                          v-else
                          v-model="lote.numero"
                          label="Nº Lote *"
                          prepend-inner-icon="subtitles"
                          :rules="[rules.required]"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="pb-0"
                      >
                        <masked-text-field
                          v-model="lote.quantidade"
                          label="Quantidade *"
                          :mask="masks.float"
                          unmask
                          :rules="[rules.required]"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="pb-0"
                      >
                        <masked-text-field
                          v-model="lote.data_fabricacao"
                          label="Data de Fabricação *"
                          prepend-inner-icon="event"
                          placeholder="00/00/0000"
                          :mask="{ mask: '00/00/0000' }"
                          :max-length="10"
                          :rules="[rules.required, rules.date]"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="pb-0"
                      >
                        <masked-text-field
                          v-model="lote.data_validade"
                          label="Data de Validade *"
                          prepend-inner-icon="event"
                          placeholder="00/00/0000"
                          :mask="{ mask: '00/00/0000' }"
                          :max-length="10"
                          :rules="[rules.required, rules.date]"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="!disabled && item.lotes.length > 1"
                    class="px-0 pb-0 d-flex flex-column justify-center"
                    style="max-width: 49px;"
                  >
                    <v-btn
                      icon
                      @click.stop="removeLote(idx)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-if="issueType === 'XML'">
                <div class="d-flex">
                  <span class="flex-grow-1 text-h5 mt-4 black--text">Estoque</span>
                </div>

                <v-divider class="mb-4" />

                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <v-select
                      v-model="item.tipo_cadastro"
                      :items="[
                        { text: 'Incluir novo produto', value: 'CADASTRAR' },
                        { text: 'Vincular a produto existente', value: 'VINCULAR' },
                        { text: 'Não cadastrar', value: 'NAO_CADASTRAR' },
                      ]"
                      label="O que fazer com este produto?"
                      prepend-inner-icon="import_export"
                      select-first
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <item-autocomplete-filter
                      v-if="item.tipo_cadastro === 'VINCULAR'"
                      v-model="item.id"
                      label="Produto *"
                      prepend-inner-icon="inventory"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-tab-item>
          <v-tab-item
            eager
            value="tab-icms"
          >
            <v-form
              ref="icms"
              lazy-validation
              :disabled="disabled"
              @submit.prevent="save()"
            >
              <icms-form
                :icms.sync="item.impostos.icms"
                :icms-uf-dest.sync="item.impostos.icms_uf_dest"
                :tax-regime="taxRegime"
                :interstate="interstate"
                :final-consumer="finalConsumer"
                :in-out="inOut"
                :autocalculation="autocalculation"
                show-retido
                show-valores
              />
            </v-form>
          </v-tab-item>
          <v-tab-item
            eager
            value="tab-ipi"
          >
            <v-form
              ref="ipi"
              lazy-validation
              :disabled="disabled"
              @submit.prevent="save()"
            >
              <ipi-form
                :data.sync="item.impostos.ipi"
                :in-out="inOut"
                :autocalculation="autocalculation"
                show-valores
              />
            </v-form>
          </v-tab-item>
          <v-tab-item
            eager
            value="tab-pis"
          >
            <v-form
              ref="pis"
              lazy-validation
              :disabled="disabled"
              @submit.prevent="save()"
            >
              <pis-cofins-form
                :data.sync="item.impostos.pis"
                :autocalculation="autocalculation"
                show-valores
              />
            </v-form>
          </v-tab-item>
          <v-tab-item
            eager
            value="tab-cofins"
          >
            <v-form
              ref="cofins"
              lazy-validation
              :disabled="disabled"
              @submit.prevent="save()"
            >
              <pis-cofins-form
                :data.sync="item.impostos.cofins"
                :autocalculation="autocalculation"
                show-valores
              />
            </v-form>
          </v-tab-item>
          <v-tab-item
            eager
            value="tab-funrural"
          >
            <v-form
              ref="funrural"
              lazy-validation
              :disabled="disabled"
              @submit.prevent="save()"
            >
              <funrural-form
                :data.sync="item.impostos.funrural"
                :autocalculation="autocalculation"
                show-valores
              />
            </v-form>
          </v-tab-item>
          <v-tab-item
            eager
            value="tab-outros"
          >
            <v-form
              ref="outros"
              lazy-validation
              :disabled="disabled"
              @submit.prevent="save()"
            >
              <others-form
                :data.sync="item.impostos.outros"
              />
            </v-form>
          </v-tab-item>
        </v-tabs>
        <v-alert
          v-if="!disabled && autocalculation"
          outlined
          dense
          type="info"
          class="mt-4"
        >
          Os valores dos impostos somente serão recalculados após salvar este produto.
        </v-alert>
        <v-alert
          v-if="!autocalculation"
          outlined
          dense
          type="info"
          class="mt-4"
        >
          O cálculo automático está desabilitado para esta nota.
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="cancel()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!disabled"
          outlined
          color="primary"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import cloneDeep from 'lodash/fp/cloneDeep';

import ItemAutocompleteFilter from "@/Support/Components/Filters/ItemAutocompleteFilter.vue";
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import MoneyInput from '@/Support/Components/MoneyInput.vue';
import ProductSelect from '@/Domains/Fiscal/Components/ProductSelect.vue';

import IcmsForm from '@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/IcmsForm.vue';
import IpiForm from '@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/IpiForm.vue';
import PisCofinsForm from '@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/PisCofinsForm.vue';
import FunruralForm from '@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/FunruralForm.vue';
import OthersForm from '@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/OthersForm.vue';

import { icms, icms_uf_dest, ipi, pis, cofins, funrural, outros } from '@/Domains/Settings/Views/Fiscal/Components/Operation/Services/FiscalOperationDefault.js';
import { getImpostoProduto } from '@/Domains/Settings/Views/Fiscal/Components/Operation/Services/FiscalOperationService.js';

const impostos = {
  icms: { ...icms },
  icms_uf_dest: { ...icms_uf_dest },
  ipi: { ...ipi },
  pis: { ...pis },
  cofins: { ...cofins },
  funrural: { ...funrural },
  outros: { ...outros },
};

export default {

  components: {
    ItemAutocompleteFilter,
    MaskedTextField,
    MoneyInput,
    ProductSelect,
    IcmsForm,
    IpiForm,
    PisCofinsForm,
    FunruralForm,
    OthersForm,
  },

  props: {
    taxRegime: {
      type: String,
      default: 'NORMAL',
      validator: (value) => ['NORMAL', 'SIMPLES'].includes(value)
    },
    inOut: {
      type: String,
      default: 'SAIDA',
      validator: (value) => ['ENTRADA', 'SAIDA'].includes(value)
    },
    issueType: {
      type: String,
      default: 'PROPRIA',
      validator: (value) => ['PROPRIA', 'TERCEIROS', 'XML'].includes(value)
    },
    interstate: Boolean,
    finalConsumer: Boolean,
    disabled: Boolean,
    autocalculation: Boolean,
    customerState: String,
  },

  data() {
    return {
      dialog: false,

      loading: false,
      saving: false,

      tab: null,
      item: {
        lotes: [],
        impostos: cloneDeep(impostos),
      },

      configuredTaxes: null,

      availableLots: [],

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        date: v => this.isDateValid(v, 'DD/MM/YYYY') || 'Data Inválida!',
      },

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
        integer: { mask: Number, min: 0, scale: 0, signed: false },
        percentual: { mask: Number, min: 0, max: 100, scale: 4 },
      }
    }
  },

  methods: {
    show(item, configuredTaxes) {
      this.tab = null;
      this.dialog = true;
      this.item = cloneDeep({
        ...item,
        lotes: item.lotes || [],
        impostos: {
          ...impostos,
          ...item.impostos || {}
        },
      });
      this.configuredTaxes = configuredTaxes;
      this.availableLots = [];
    },

    async validate() {
      if (!await this.$refs['produto'].validate()) {
        this.tab = 'tab-produto';
        return false;
      }
      if (!await this.$refs['icms'].validate()) {
        this.tab = 'tab-icms';
        return false;
      }
      if (!await this.$refs['ipi'].validate()) {
        this.tab = 'tab-ipi';
        return false;
      }
      if (!await this.$refs['pis'].validate()) {
        this.tab = 'tab-pis';
        return false;
      }
      if (!await this.$refs['cofins'].validate()) {
        this.tab = 'tab-cofins';
        return false;
      }
      if (!await this.$refs['funrural'].validate()) {
        this.tab = 'tab-funrural';
        return false;
      }
      if (!await this.$refs['outros'].validate()) {
        this.tab = 'tab-outros';
        return false;
      }

      return true;
    },

    async save() {
      try {
        if (!await this.validate()) {
          return;
        }
        this.saving = true;

        this.$emit('save', this.item);
        this.dialog = false;
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    onProductSelected(product) {
      let impostosAux = this.item.impostos;

      if (this.configuredTaxes) {
        impostosAux = getImpostoProduto(this.configuredTaxes, this.customerState, product.id_item, product.ncm);
      }

      this.item = {
        idx: this.item.idx,
        id: product.id_item,
        descricao: product.nome.trim(),
        codigo: product.cod_item,
        unidade_medida: product.unidade,
        origem: product.origem,
        ncm: product.ncm,
        cest: product.cest,
        codigo_barras: product.codigo_barras,
        valor_unitario: product.valor ? parseFloat(product.valor) : null,
        impostos: cloneDeep(impostosAux),
        rastreabilidade: product.controlar_lotes,
        lotes: [],
      }

      if (product.controlar_lotes) {
        this.addLote();
        if (this.inOut === 'SAIDA') {
          this.loadLots()
        }
      }
    },

    onLoteChange(value) {
      if (value && this.item.lotes.length === 0) {
        this.addLote();
      }
    },

    addLote() {
      this.item.lotes.push({});
    },

    removeLote(idx) {
      this.item.lotes.splice(idx, 1);
    },

    async loadLots() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/stock`, { params: {
          ids: [this.item.id],
          //id_deposito: this.warehouseId
        } });

        this.availableLots = data
          .filter(item => !!item.numero_lote)
          .map(item => ({
            numero: item.numero_lote,
            data_fabricacao: item.data_fabricacao ? moment(item.data_fabricacao).format('DD/MM/YYYY') : null,
            data_validade: item.data_validade ? moment(item.data_validade).format('DD/MM/YYYY') : null,
          }));
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onLotSelected(idx) {
      const lote = this.item.lotes[idx];
      const number = this.item.lotes[idx].numero;

      const selected = this.availableLots.find(lot => lot.numero === number);

      this.item.lotes.splice(idx, 1, {
        ...lote,
        data_fabricacao: selected.data_fabricacao,
        data_validade: selected.data_validade,
      });
    },

    cancel() {
      this.dialog = false;
    },

    isDateValid(value, format) {
      if (!value) {
        return true;
      }

      if (value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },
  }
}
</script>
