<template>
  <v-row>
    <v-col
      v-for="g in goals"
      :key="g.indicator.value"
      md="12"
    >
      <v-card
        color="transparent"
        dark
      >
        <v-card-title class="text-subtitle-2">
          {{ g.indicator.text }}
          <v-spacer />
          {{ g.type }}
          <v-spacer />
          <v-btn
            icon
            color="white"
          >
            <v-icon
              @click="onGoalDelete(g.indicator)"
            >
              delete_outline
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="g.ranges"
          hide-default-footer
          disable-sort
        >
          <template #[`item.class`]="{ item }">
            <v-text-field
              v-model.number="item.class"
              placeholder="A"
              type="text"
              dense
              outlined
              hide-details
            />
          </template>
          <template #[`item.start`]="{ item }">
            <v-text-field
              v-model.number="item.start"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.end`]="{ item }">
            <v-text-field
              v-model.number="item.end"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.type`]="{ item }">
            <v-select
              v-model="item.type"
              label="Tipo"
              :items="g.conditional"
              item-value="value"
              item-text="text"
              outlined
              hide-details
              dense
            />
          </template>
          <template #[`item.visits`]="{ item }">
            <v-text-field
              v-model.number="item.visits"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.period`]="{ item }">
            <v-select
              v-model="item.period"
              :items="periods"
              item-value="value"
              item-text="text"
              dense
              outlined
              hide-details
            />
          </template>
          <template #[`item.questionnaires`]="{ item }">
            <v-select
              v-model="item.questionnaires"
              label="Questionários"
              :items="questionnaires"
              prepend-inner-icon="icon-cadastros"
              item-value="id"
              item-text="title"
              multiple
              outlined
              small-chips
              hide-details
              dense
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-text-field
              v-model.number="item.actions"
              placeholder=""
              type="text"
              dense
              outlined
              hide-details
            />
          </template>
          <template #[`item.show_app`]="{ item }">
            <v-switch
              v-model="item.show_app"
              hide-details
              class="ma-1 pb-1"
              color="teal accent-3"
            />
          </template>

          <template #[`item.type_producer`]="{ item }">
            <!-- so para CPP por enquanto pois precisa ajustar a trigger para os demais indicadores -->
            <div
              v-if="g.indicator.value === 2 "
            >
              <v-select
                v-model="item.type_producer"
                :items="typeProducer"
                item-value="value"
                item-text="text"
                dense
                outlined
                hide-details
              />
            </div>
            <div v-else>
              *
            </div>
          </template>
          <template #[`item.time_init`]="{ item }">
            <v-text-field
              v-if="g.indicator.value === 2 && item.type_producer === 'NOVO' "
              v-model.number="item.time_init"
              placeholder="0"
              type="number"
              suffix="dias"
              dense
              outlined
              hide-details
              :rules="[v => !!v || 'Valor inválido!']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.buttons`]="{ item }">
            <v-btn
              icon
              color="white"
            >
              <v-icon
                @click="onRatingDelete(g.ranges,item.index)"
              >
                delete_outline
              </v-icon>
            </v-btn>
          </template>
          <template #[`body.append`]="{}">
            <tr>
              <td
                :colspan="headers.length"
                align="left"
              >
                <v-btn
                  outlined
                  rounded
                  text
                  @click="onRatingInsert(g.ranges);"
                >
                  <v-icon>
                    add
                  </v-icon>
                  Faixa
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

export default {

  components: {
  },

  props: {
    value: Array,
    typeRange: String,
  },

  data() {
    return {
      periods: [
        { text: "Semanal", value: "semanal" },
        { text: "Quinzenal", value: "quinzenal" },
        { text: "Mensal", value: "mensal" },
        { text: "Bimestral", value: "bimestral" },
        { text: "Trimestral", value: "trimestral" },
        { text: "Quadrimestral", value: "quadrimestral" },
        { text: "Quinquenal", value: "quinquenal" },
        { text: "Semestral", value: "semestral" },
        { text: "Anual", value: "anual" },
      ],
      typeProducer: [
        { text: "Todos", value: "" },
        { text: "Novos", value: "NOVO" },
      ],
      questionnaires: [],

      conditional: [],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    headers() {
      let header = [];
      if (this.typeRange == 'nonconformity') {
        header = [
          { text: "Classe", value: "class", width: 80 },
          { text: "De", value: "start", width: 100  },
          { text: "Até", value: "end", width: 100 },
          { text: "Tipo", value: "type", width: 100 },
          { text: "Visitas", value: "visits", width: 80 },
          { text: 'Período', value: "period", width: 150 },
          { text: "Aplicar Questionário", value: "questionnaires", width: 200 },
          { text: "Ações", value: "actions", },
          { text: "Aplicar Campo ", value: "show_app", },
          { text: "", align: "right", value: "buttons", width: 8 },
        ];
      } else {
        header = [
          { text: "Classe", value: "class", width: 80 },
          { text: "De", value: "start", width: 100  },
          { text: "Até", value: "end", width: 100 },
          { text: "Visitas", value: "visits", width: 80 },
          { text: 'Período', value: "period", width: 150 },
          { text: "Aplicar Questionário", value: "questionnaires", width: 200 },
          { text: "Ações", value: "actions", },
          { text: "Aplicar em campo ", value: "show_app", width: 15 },
          { text: "Produtor", value: "type_producer", width: 15 },
          { text: 'Dias de entrada', value: "time_init", width: 100  },
          { text: "", align: "right", value: "buttons", width: 8 },
        ];
      }
      return header;
    },
  },

  created() {
    this.loadQuestionnaires();
  },
  methods: {

    async loadQuestionnaires() {

      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ['VISITA_BPA'],
            ativo: 1,
          }
        );

        this.questionnaires = data
          //.filter(item => !item.tipo.includes('VISITA_PADRAO'))
          .map(item => {
            let text = item.titulo ? item.titulo : ``;
            text = item.versao ?  ` ${text} versão: ${item.versao} ` : text;
            text = item.codigo ?  ` ${text} - RQ ${item.codigo} ` : text;
            return {
              id: item.id,
              title: text,
              types: item.tipo,
            }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onRatingInsert(range) {

      let lastRange = range[0] ? _.last(range).end : 0;
      let index =  range[0] ? _.last(range).index + 1 : 1;
      let period =  range[0] ? _.last(range).period : 'mensal';

      if ((lastRange == null )) {
        this.$snotify.error("Informe o valor para o Fim da faixa anterior", "Atenção");
        return false;
      }

      range.push({
        id: uuidv4(),
        index: index,
        name: '',
        start: lastRange + 1,
        end: null,
        visits: null,
        period: period,
        questionnaires: [],
        actions: '',
        show_app: true,
        type_producer: '',
        time_init: '',
      })
    },

    onRatingDelete(range, index) {
      // console.log(range,'index', index);
      const idx = range.findIndex(o => o.index === index);
      range.splice(idx, 1);
    },

    onGoalDelete(indicator) {
      const idx = this.goals .findIndex(o => o.indicador === indicator);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
}
</script>
