<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.range"
              label="Data"
              dark
              @change="load"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pt-0"
          >
            <v-select
              v-model="filters.status"
              :items="['PLANEJADO', 'LIBERADO', 'FECHADO', 'CANCELADO']"
              dark
              clearable
              filled
              hide-details
              label="Status"
              prepend-inner-icon="rule"
              multiple
              small-chips
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        v-model="selected"
        :headers="headers"
        :items="filteredItems"
        :search="filters.search"
        show-select
        dark
        item-key="id_ordem_producao"
        mobile-breakpoint="1000"
        @click:row="showReportDialog"
      >
        <template #[`item.status`]="{ value }">
          <v-chip
            x-small
            :color="getStatusColor(value)"
          >
            {{ value }}
          </v-chip>
        </template>

        <template #[`item.etapa`]="{ value }">
          <v-chip
            v-if="!!value"
            x-small
          >
            {{ value }}
          </v-chip>
        </template>

        <template #[`item.operations`]="{ item }">
          <production-order-operations
            :item="item"
            @showOrder="show"
            @reloadOrders="load"
            @addAppointment="$emit('addAppointment', $event)"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasEditAccess && item.status === 'PLANEJADO'"
                @click="changeStatus(item, 'LIBERADO')"
              >
                <v-list-item-icon>
                  <v-icon color="success">
                    check
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Liberar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasEditAccess && item.status === 'PLANEJADO'"
                @click="changeStatus(item, 'CANCELADO')"
              >
                <v-list-item-icon>
                  <v-icon color="error">
                    cancel
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Cancelar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasAttachmentsAccess && !['PLANEJADO', 'CANCELADO'].includes(item.status)"
                @click="showAttachmentsDialog(item.id_ordem_producao)"
              >
                <v-list-item-icon>
                  <v-icon color="purple">
                    receipt
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Laudos
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!['PLANEJADO', 'CANCELADO'].includes(item.status)"
                @click="showReportDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon color="teal">
                    content_paste
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Relatório
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!['PLANEJADO', 'CANCELADO'].includes(item.status)"
                @click="showPrintDialog(item.id_ordem_producao)"
              >
                <v-list-item-icon>
                  <v-icon color="orange darken-3">
                    print
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Imprimir
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!['PLANEJADO', 'CANCELADO'].includes(item.status)"
                @click="productiveControlReport(item.id_ordem_producao)"
              >
                <v-list-item-icon>
                  <v-icon color="blue">
                    assignment
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Controle Produtivo
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasExcludeAccess"
                :disabled="item.status === 'FECHADO'"
                @click="remove(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <production-order-dialog
      v-model="dialog.show"
      :edit-id.sync="dialog.id"
      @save="load"
    />

    <production-order-report-dialog
      v-model="reportDialog.show"
      :order-id="reportDialog.id"
      @productiveControlReport="productiveControlReport"
      @print="showPrintDialog"
    />

    <production-order-attachments-dialog
      v-model="attachmentsDialog.show"
      :order-id="attachmentsDialog.id"
    />

    <print-settings-dialog
      ref="print-settings"
      @print="printOrders"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            @click="add()"
            v-on="on"
          >
            <v-icon>
              add
            </v-icon>
          </v-btn>
        </template>
        Nova Ordem de Produção
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel"
            v-on="on"
          >
            <v-icon>
              backup_table
            </v-icon>
          </v-btn>
        </template>
        Download (Excel)
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print"
            v-on="on"
          >
            <v-icon>
              print
            </v-icon>
          </v-btn>
        </template>
        Imprimir
      </v-tooltip>

      <v-tooltip
        v-if="selected.length > 0"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-3"
            @click="showPrintDialog()"
            v-on="on"
          >
            <v-icon>
              print
            </v-icon>
          </v-btn>
        </template>
        Imprimir Ordem de Produção
      </v-tooltip>

      <v-tooltip
        v-if="selected.length > 0"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue darken-1"
            @click="productiveControlReport()"
            v-on="on"
          >
            <v-icon>
              assignment
            </v-icon>
          </v-btn>
        </template>
        Controle Produtivo
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<script>
import ProductionOrderDialog from '@/Domains/Industry/ProductionOrder/Components/ProductionOrderDialog.vue';
import ProductionOrderOperations from '@/Domains/Industry/ProductionOrder/Components/ProductionOrderOperations.vue';
import ProductionOrderReportDialog from '@/Domains/Industry/ProductionOrder/Components/ProductionOrderReportDialog.vue';
import ProductionOrderAttachmentsDialog from '@/Domains/Industry/ProductionOrder/Components/ProductionOrderAttachmentsDialog.vue';
import PrintSettingsDialog from '@/Support/Components/PrintSettingsDialog.vue';
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';

import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from 'moment-timezone';
import printJS from 'print-js';
import isEmpty from 'lodash/fp/isEmpty';

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

export default {

  components: {
    VDateRangePicker,
    PrintSettingsDialog,
    ProductionOrderDialog,
    ProductionOrderOperations,
    ProductionOrderReportDialog,
    ProductionOrderAttachmentsDialog,
  },

  mixins: [ReportMixin],

  data() {
    return {
      filters: {
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        status: [],
        search: ''
      },

      items: [],

      selected: [],

      dialog: {
        show: false,
        id: null
      },

      reportDialog: {
        show: false,
        id: null,
      },

      attachmentsDialog: {
        show: false,
        id: null,
      },
    }
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasEditAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-editar' && o.tipo === 'COMPONENTE')
    },
    hasAttachmentsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-anexos' && o.tipo === 'COMPONENTE');
    },
    hasExcludeAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-excluir' && o.tipo === 'COMPONENTE');
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasDatesAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-datas' && o.tipo === 'COMPONENTE');
    },

    // Columns
    headers() {
      const headers = [
        { text: 'Código', value: 'codigo' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Produto', value: 'produto.nome' },
        { text: 'Status', value: 'status' },
        { text: 'Etapa', value: 'etapa' },
        { text: 'Data', value: 'data_pedido', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
      ];

      if (this.hasDatesAccess) {
        headers.push(
          { text: 'Data Início', value: 'data_inicio', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Data Venc.', value: 'data_vencimento', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        )
      }

      if (this.hasPlannedQuantityAccess) {
        headers.push({ text: 'Quant. Planejada', value: 'quantidade_planejada', formatter: value => this.formatNumber(value) })
      }

      headers.push(
        { text: '', altText: 'Operações', value: 'operations', align: 'end', width: 30, sortable: false, drag: false },
        { text: '', altText: 'Opções', value: 'actions', align: 'end', width: 30, sortable: false, drag: false },
      )

      return headers
    },
    /**
     * Exibe as ordens com base nos filtros selecionados em tela
     */
    filteredItems() {
      if (isEmpty(this.filters.status) && isEmpty(this.filters.search)) {
        return this.items;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.items.filter(item => {
        const hasStatus = isEmpty(this.filters.status) || this.filters.status.includes(item.status);
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return hasStatus && hasSearch;
      });
    },
  },

  created() {
    this.load()
  },

  methods: {
    show(id) {
      this.dialog.show = true;
      this.dialog.id = id;
    },

    add() {
      this.dialog.show = true;
      this.dialog.id = null;
    },

    async load() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.range;

        this.items = await api.index({
          start_date: startDate,
          end_date: endDate,
          with: 'ITENS'
        });

      } catch (error) {
        this.$snotify.error('Erro ao carregar as ordens de produção', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async remove(order) {
      if (!(await this.$root.$confirm(
        `Remover ordem de produção nº ${order.codigo}?`,
        `Esta ação não pode ser desfeita.`,
        { color: 'red' }
      ))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await api.destroy(order.id_ordem_producao);

        const index = this.items.findIndex(item => item.id_ordem_producao === order.id_ordem_producao);
        this.items.splice(index, 1);

        this.$snotify.success('Ordem de produção removida com sucesso');
      } catch (error) {
        this.$snotify.error(`Erro ao remover a ordem de produção. ${error}`, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async changeStatus(order, status) {
      if (!(await this.$root.$confirm(
        `Alterar ordem de produção nº ${order.codigo}`,
        `Tem certeza que deseja alterar o status da ordem de produção para <b>${status}</b>?`,
        { color: 'blue' }
      ))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await api.update(order.id_ordem_producao, { status });

        const index = this.items.findIndex(item => item.id_ordem_producao === order.id_ordem_producao);

        this.items.splice(index, 1, {
          ...order,
          status
        });
        this.$snotify.success('Ordem de produção alterada com sucesso');
      } catch (error) {
        this.$snotify.error(`Erro ao alterar status de ordem de produção. ${error}`, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    showReportDialog(item) {
      if (['PLANEJADO', 'CANCELADO'].includes(item.status)) {
        return;
      }

      this.reportDialog.show = true
      this.reportDialog.id = item.id_ordem_producao
    },

    showAttachmentsDialog(id) {
      this.attachmentsDialog.show = true
      this.attachmentsDialog.id = id
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.range;
      return `Ordens de Produção - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    showPrintDialog(id = null) {
      this.$refs['print-settings'].show({
        params: [
          { title: 'Movimentações de estoque', key: 'mostra_movimentacoes_estoque', value: true },
          { title: 'Movimentações de silo', key: 'mostra_movimentacoes_silo', value: true },
          { title: 'Apontamentos de produção', key: 'mostra_apontamentos', value: true },
        ],
        item: { id },
      });
    },

    async printOrders({ params, item }) {
      if (this.selected.length === 0 && !item.id) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const ids = item.id ? [item.id] : this.selected.map(item => item.id_ordem_producao);

        const { data } = await this.$axios.post(`/production-order/print`, {
          ids,
          params,
        });

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async productiveControlReport(id = null) {
      if (!this.selected.length === 0 && !id) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const ids = id ? [id] : this.selected.map(item => item.id_ordem_producao);

        const { data } = await this.$axios.get(`/production-order/productive-control`, { params: {
          ids,
        } });

        if (data.length === 0) {
          this.$snotify.warning('Nenhum dado para gerar o relatório');
          return;
        }

        // Transpose the data
        const report = Object.keys(data[0]).map(key => [key, ...data.map(obj => obj[key] || '')]);

        // Add headers
        const headers = ['Tanque', ...data.map((_, idx) => `T${idx + 1}`)];

        const json = report.map(values => Object.fromEntries(headers.map((key, i) => [key, values[i]])));

        this.exportToFile({ report: json, title: 'Controle Produtivo' });

      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getStatusColor(value) {
      const statusColors = {
        'PLANEJADO': 'orange darken-1',
        'LIBERADO': 'blue',
        'FECHADO': 'green darken-1',
        'CANCELADO': 'red darken-1',
      }
      return statusColors[value]
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    parseNumber: val => +String(val) || null,
  }
}
</script>
