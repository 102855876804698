<template>
  <v-dialog
    v-model="dialog.show"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Descarga</span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row class="pb-2">
            <v-col cols="6">
              <date-time-text-field
                v-model="unload.entrancedAt"
                label="Chegada na Portaria"
                icon="access_time"
                input-format="DD/MM/YYYY HH:mm"
                manual
                filled
                @input="validate()"
              />
            </v-col>

            <v-col cols="6">
              <date-time-text-field
                v-model="unload.departureAt"
                label="Saída da Portaria"
                icon="access_time"
                input-format="DD/MM/YYYY HH:mm"
                :rules="[ v => validateDepartureAtTime(v) || 'Data Inválida!' ]"
                manual
                filled
                :disabled="!hasAllProcessesCompleted"
                @input="validate()"
              />
            </v-col>
          </v-row>

          <div
            v-if="isWeighingVisible"
          >
            <span
              class="overline"
              style="line-height: 15px !important; font-size: 10px !important;"
            >
              Pesagem da Carga
            </span>

            <v-divider />

            <v-row
              class="pb-2"
            >
              <v-col cols="6">
                <masked-input
                  v-model.number="unload.inletWeight"
                  label="Peso Cheio (Kg)"
                  prepend-inner-icon="compare"
                  :mask="NumberMask"
                  :max-length="8"
                  :disabled="!hasEditingStartingWeight"
                  filled
                  hide-details
                  return-unmasked
                  type="number"
                  :rules="[
                    v => validateWeight(v, 'inlet'),
                  ]"
                  @input="onWeightChange"
                >
                  <template slot="append">
                    <v-btn
                      icon
                      small
                      @click="getTruckWeigh('inletWeight')"
                    >
                      <v-icon small>
                        autorenew
                      </v-icon>
                    </v-btn>
                  </template>
                </masked-input>
              </v-col>

              <v-col
                cols="6"
              >
                <masked-input
                  v-model.number="unload.outletWeight"
                  label="Peso Vazio (Kg)"
                  prepend-inner-icon="compare"
                  :mask="NumberMask"
                  :max-length="8"
                  :disabled="!hasEditingEndWeight || !hasAllProcessesCompleted"
                  filled
                  hide-details
                  return-unmasked
                  type="number"
                  :rules="[
                    v => validateWeight(v, 'outlet'),
                  ]"
                  @input="onWeightChange"
                >
                  <template slot="append">
                    <v-btn
                      icon
                      small
                      @click="getTruckWeigh('outletWeight')"
                    >
                      <v-icon small>
                        autorenew
                      </v-icon>
                    </v-btn>
                  </template>
                </masked-input>
              </v-col>
            </v-row>
          </div>

          <v-row
            v-if="isWeighingVisible && tanksToMeasureWeight.length"
          >
            <v-col cols="4">
              <div
                class="pa-4 justify-center text-center align-center"
                style="display: flex; height: 100%; background: rgba(0, 0, 0, 0.06);"
              >
                <span class="overline">Inconformidades</span>
              </div>
            </v-col>

            <v-col cols="8">
              <weight-measurement-tank-row
                v-for="tank in tanksToMeasureWeight"
                :key="tank.index"
                :tank="tank"
                @onTankWeightChange="onTankWeightChange"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="grey darken-1"
          text
          @click="hide()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay v-model="loading">
      Carregando...
    </v-overlay>
  </v-dialog>
</template>

<script>
import moment from "moment";
import _ from "lodash";

import DateTimeTextField from '@/Support/Components/DateTimeTextField.vue';
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import WeightMeasurementTankRow from "@/Domains/Platform/Unload/Components/WeightMeasurementTankRow.vue";

export default {

  components: {
    DateTimeTextField,
    WeightMeasurementTankRow,
    MaskedInput,
  },

  props: {
    hasAllProcessesCompleted: Boolean,
  },

  data() {
    return {
      loading: false,

      dialog: {
        show: false,
      },

      unload: {},

      // Mascara usada nos damais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            signed: true,
            scale: 2,
            max: 99000,
          },
        },
      },
    };
  },

  computed: {

    measurementParam() {
      return this.$store.state.settings.plataforma ? this.$store.state.settings.plataforma.medicao_descarga_plataforma : 'balanca';
    },

    tanksToMeasureWeight: {
      get() {
        if (_.isEmpty(this.unload.tanks)) {
          return [];
        }

        return this.unload.tanks.filter(tank => tank.measureTankWeight);
      },
      set(newValue) {
        this.unload.tanks = newValue;
      },
    },

    isWeighingVisible() {
      return !['vale', 'vazao', 'medidor'].includes(this.measurementParam)
    },

    //recupera permissao de usuario
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasEditingStartingWeight() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "concierg-editing-start" && o.tipo === "COMPONENTE");
    },
    hasEditingEndWeight() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "concierg-editing-end" && o.tipo === "COMPONENTE");
    },
  },

  methods: {

    show(unload) {
      this.dialog.show = true;

      const entrancedAt = unload.entrancedAt ? unload.entrancedAt : moment().format('DD/MM/YYYY HH:mm');

      this.unload = {
        ...unload,
        entrancedAt,
      };
    },

    hide() {
      this.dialog.show = false;
      this.unload = {};
    },

    /**
     *  Método busca o peso da balança
     */
    async getTruckWeigh(field) {
      try {
        const settings = this.$store.state.settings;

        if (settings?.user?.system !== 'milkup') {
          const { data } = await this.$ws.post(`/integrator-status`, {
            dairy_id: settings?.laticinio?.id
          });

          if (!data.connected) {
            this.$snotify.warning('Integrador desconectado', 'Desconectado');
            return;
          }
        }

        const { data: { valor } } = await this.$axios.post(`/balanca/listaDadosBalanca`);

        this.unload = {
          ...this.unload,
          [field]: parseInt(valor),
        }

        return this.onWeightChange();
      } catch (e) {
        console.warn(e);
      }
    },

    validate() {
      return this.$refs.form?.validate();
    },

    validateDepartureAtTime() {
      const entrancedAt = this.unload.entrancedAt;
      const departureAt = this.unload.departureAt;

      if (_.isEmpty(departureAt)) {
        return true;
      }

      if (_.isEmpty(entrancedAt) || _.isEmpty(departureAt)) {
        return false;
      }

      return moment(entrancedAt, 'DD/MM/YYYY HH:mm').isBefore(moment(departureAt, 'DD/MM/YYYY HH:mm'));
    },

    validateWeight(v, field) {

      if (_.isEmpty(v)) {
        return true;
      }

      let value = parseInt(v) || null;
      let minValue = null;
      let maxValue = null;

      if (field === 'inlet') {
        minValue = this.unload.outletWeight;
        maxValue = 100000;
      }

      if (field === 'outlet') {

        // Se for carga, preenche-primeiro o campo do Peso Vazio
        if (this.unload.operation === 'CARGA' && _.isEmpty(this.unload.inletWeight)) {
          return true;
        }

        minValue = 0;
        maxValue = this.unload.inletWeight;
      }

      if (_.isNull(value) || !_.isNumber(value)) {
        return false;
      }

      if (_.isNull(minValue) && _.isNull(maxValue)) {
        return _.isNumber(value);
      }

      if (minValue && maxValue) {
        return value > minValue && value < maxValue;
      }

      if (minValue) {
        return value > minValue;
      }

      if (maxValue) {
        return value < maxValue;
      }

      return false;
    },

    onTankWeightChange(updatedTank) {
      this.unload = {
        ...this.unload,
        tanks: this.unload.tanks.map(tank => {

          if (updatedTank.index === tank) {
            return updatedTank;
          }

          return tank;
        }),
      };

      return this.onWeightChange();
    },

    onWeightChange() {
      return this.$emit('onWeightChange', this.unload);
    },

    async save() {
      const isValid = this.$refs.form.validate();

      if (!isValid) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar, verifique os dados informados!",
          "Atenção"
        );

        return;
      }

      this.$emit('onWeightMeasurementSave', this.unload);

      return this.hide();
    },

  },

}
</script>
