<template>
  <v-dialog
    v-model="dialog"
    width="1200"
    @click:outside="clear"
  >
    <v-card
      style="background: #f5f5f5"
    >
      <v-card-text class="pa-0">
        <v-row class="pa-0 ma-0">
          <v-col
            cols="7"
            class="pa-0 ma-0"
          >
            <v-card>
              <route-map
                ref="map"
                height="590px"
                :center="$store.state.settings.coordenadas"
                :markers="markers"
                :value="{color: '#ff6666'}"
                single-route
                @onRouteTraced="onRouteTraced"
              />

              <v-overlay
                absolute
                :opacity="0.5"
                :value="overlay"
              >
                <v-btn
                  v-if="markers.length > 1"
                  @click="onTraceRouteClick"
                >
                  TRAÇAR A ROTA
                </v-btn>
              </v-overlay>
            </v-card>
          </v-col>

          <v-col
            cols="5"
            class="pb-0 pr-0"
          >
            <v-autocomplete
              v-model="technician"
              :items="technicians"
              label="Técnico"
              item-text="name"
              item-value="id"
              hide-details
              return-object
              solo
              class="elevation-2 mb-3 ml-1 mr-4"
              @change="clear"
            />

            <v-card
              :elevation="2"
              class=" ml-1 mr-4"
            >
              <v-card-title
                class="text-overline py-2"
                style="font-size: 12px !important;"
              >
                <span>
                  <b>Distância:</b> {{ info.distance.toFixed(1) }} Km | <b>Duração:</b> {{ info.duration }}
                </span>
              </v-card-title>
            </v-card>

            <div
              class="mt-1"
              style="max-height: 465px; overflow-y: auto"
            >
              <v-timeline
                align-top
                reverse
                dense
                class="pt-3 ml-1"
              >
                <v-timeline-item
                  v-for="(item, idx) of items"
                  :key="idx"
                  color="blue darken-1"
                  fill-dot
                  right
                  large
                >
                  <template v-slot:icon>
                    <span
                      class="text-center"
                      style="color: white; font-size: 12px !important;"
                    >
                      {{ item.start | dateFormat('HH:mm') }}
                    </span>
                  </template>
                  <v-card class="elevation-2">
                    <v-card-title
                      class="text-overline pa-0 pt-1 pl-2 pr-2 pb-2"
                      style="font-size: 12px !important;"
                    >
                      <span>
                        <b>{{ item.name }}</b>
                      </span>
                    </v-card-title>
                    <v-card-text class="py-0 px-0">
                      <v-list>
                        <v-list-item
                          dense
                          style="height: 2rem;"
                        >
                          <v-list-item-action class="mr-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  schedule
                                </v-icon>
                              </template>
                              <span>Horário</span>
                            </v-tooltip>
                          </v-list-item-action>
                          <v-list-item-title>
                            <v-row>
                              <v-col class="d-flex py-0">
                                <span>
                                  {{ item.start | dateFormat('DD/MM/YYYY - HH:mm') }}
                                </span>
                              </v-col>
                              <v-col class="d-flex py-0">
                                <span>
                                  {{ item.end | dateFormat('DD/MM/YYYY - HH:mm') }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-if="enableTypes && item.type"
                          dense
                          style="height: 2rem;"
                        >
                          <v-list-item-action class="mr-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  pending_actions
                                </v-icon>
                              </template>
                              <span>Tipo de visita</span>
                            </v-tooltip>
                          </v-list-item-action>
                          <v-list-item-title>
                            <span
                              v-html="item.type.map(k => types[k]).join(', ')"
                            />
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          dense
                          style="height: 2rem;"
                        >
                          <v-list-item-action class="mr-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  person_outline
                                </v-icon>
                              </template>
                              <span>Produtor</span>
                            </v-tooltip>
                          </v-list-item-action>
                          <v-list-item-title style="overflow: unset;">
                            <span
                              v-html="item.producer.name"
                            />
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-if="!!item.note"
                          dense
                          style="height: 2rem;"
                        >
                          <v-list-item-action class="mr-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  notes
                                </v-icon>
                              </template>
                              <span>Observação</span>
                            </v-tooltip>
                          </v-list-item-action>
                          <v-list-item-title>
                            <span
                              v-html="item.note"
                            />
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";
import * as _ from "lodash";

import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";
import VisitsService from "@/Domains/Visits/Services/VisitsService.js";

const service = new VisitsService();

export default {
  name: "schedule-map-dialog",

  components: {
    RouteMap,
  },

  filters: {
    dateFormat(value, opts) {
      return moment(value).format(opts);
    },
  },

  props: {
    value: {
      type: Boolean,
    },
    date: Date,
    events: Array,
  },

  data() {
    return {
      // Dialogs
      dialog: false,
      overlay: true,

      map: null,

      types: service.types,

      info: {
        distance: 0,
        duration: 0,
      },

      technician: {
        id: '',
        name: '',
      },

      producers: {},

      // Desabilitado até ser decidido os tipos que serão utilizados
      enableTypes: false
    };
  },

  computed: {

    technicians() {
      if (this.events.length == 0) {
        return [];
      }

      return this.events.map(event => event.person);
    },

    items() {
      if (this.events.length == 0 || !this.technician.id) {
        return [];
      }

      return this.events.filter(event => event.person.id == this.technician.id);
    },

    markers() {
      if (this.events.length == 0 || !this.technician.id) {
        return [];
      }

      const markers = this.events
        .map(event => {
          const producer = this.producers[event.producer.id];

          const location = producer ? producer.pos : {};
          return {
            key: event.producer.id,
            location,
            draggable: false,
            visible: true,
            color: 'red',
            icon: 'person_outline',
            technician: event.person,
            richMeta: {
              "Produtor": event.producer.name,
            },
          };
        })
        .filter(marker => marker.technician.id == this.technician.id && marker.location.lat && marker.location.lng);

      // Adiciona o laticínio
      markers.unshift({
        key: `dairy`,
        location: {
          lat: this.$store.state.settings.coordenadas.lat,
          lng: this.$store.state.settings.coordenadas.lng,
        },
        draggable: false,
        label: this.$store.state.settings.laticinio.name,
        color: 'red',
        visible: true,
      });

      return markers;
    },

  },

  watch: {
    value() {
      this.dialog = this.value;
      this.$nextTick(() => {
        this.map = this.$refs.map;
      });
    },
    dialog(value) {
      this.$emit("input", !!value);
    },
    technicians(value) {
      if (value.length > 0) {
        this.technician = value[0];
      }
    }
  },

  created() {
    this.loadProducers();
  },

  methods: {

    /**
     * @event void
     *
     * Evento acionado ao clicar no botão para traçar a rota
     */
    onTraceRouteClick() {
      this.overlay = false;
      this.map.trace();
    },

    async loadProducers() {
      try {
        let { data } = await this.$axios.post(`/produtores/listaJson`);

        if (_.isString(data)) {
          throw data;
        }

        data.forEach(person => {
          this.producers[person.id_pessoa] = {
            id: person.id_pessoa,
            name: person.nome,
            pos: {
              lat: person.end_latitude ? parseFloat(person.end_latitude.replace(/^0+/, '')) : null,
              lng: person.end_longitude ? parseFloat(person.end_longitude.replace(/^0+/, '')) : null
            }
          };
        });
      } catch (error) {
        console.warn(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      }
    },

    onRouteTraced({ distance, duration }) {
      this.info.distance = distance;
      this.info.duration = duration;
    },

    clear() {
      this.overlay = true;
      this.info = { distance: 0, duration: 0 };
      this.map.clearRoute();
    },

  },

}
</script>
