<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Programa Mais Leite Saudável - PMLS
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="tab == 'projeto' || tab == 'producers' || tab == 'reports' || tab == 'evolution'"
    >
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.project"
          :items="pmlsList"
          label="Vigência do Projeto"
          item-value="dateStart"
          item-text="text"
          return-object
          hide-details
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="loadRange"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <month-range-picker
          v-model="filter.range"
          :ranges.sync="filter.ranges"
          :min="filter.min"
          :max="filter.max"
          dark
        />
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
        @change="onTabChange"
      >
        <v-tab href="#producers">
          Produtores
        </v-tab>
        <v-tab href="#reports">
          Relatório de Metas
        </v-tab>
        <v-tab href="#evolution">
          Evolução dos produtores
        </v-tab>
        <v-tab href="#classification">
          Curva ABC
        </v-tab>
        <v-tab href="#history">
          Configuração
        </v-tab>
        <v-tab
          href="#planning"
          :disabled="!planning.selected"
        >
          <v-icon>settings</v-icon>
        </v-tab>
        <v-tab-item value="producers">
          <producers
            ref="producers"
            aba="producers"
            :filter="filter"
          />
        </v-tab-item>

        <v-tab-item value="reports">
          <reports
            ref="reports"
            aba="reports"
            :filter="filter"
          />
        </v-tab-item>

        <v-tab-item value="evolution">
          <evolution
            ref="evolution"
            aba="evolution"
            :filter="filter"
          />
        </v-tab-item>

        <v-tab-item value="classification">
          <producer-rating
            ref="classification"
            view="goal-pmls"
          />
        </v-tab-item>

        <v-tab-item value="history">
          <list-goals
            ref="history"
            aba="history"
            :filter="filter"
            project="PMLS"
            @newPlanning="onNewPlanning"
            @selectPlanning="onSelectPlanning"
          />
        </v-tab-item>

        <v-tab-item value="planning">
          <goals-PMLS
            ref="planning"
            v-model="planning.id"
            aba="planning"
            :filter="filter"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.project-pmls {

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import Producers from '@/Domains/Projects/PMLS/Views/Producers.vue';
import Reports from '@/Domains/Projects/PMLS/Views/Reports.vue';
import Evolution from '@/Domains/Projects/PMLS/Views/Evolution.vue';
import ListGoals from "@/Domains/Projects/ListGoals.vue";
import GoalsPMLS from '@/Domains/Projects/PMLS/Views/GoalsPMLS.vue';
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import ProducerRating from "@/Domains/Projects/PQFL/BPA/Components/ProducerRating.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  //name: "project-pmls",

  components: {
    Producers,
    Reports,
    Evolution,
    ListGoals,
    GoalsPMLS,
    MonthRangePicker,
    ProducerRating,
  },

  props: {
    parent: Object,
    aba: String,
  },

  data() {
    return {
      tab: null,

      planning: {
        selected: false,
        id: null
      },

      filter: {
        project: {
          start: null,
          end: null,
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        producer: [],
      },

      pmlsList: [],

    };
  },

  created() {
    this.loadPmls();
  },

  methods: {
    onTabChange() {
      if (this.tab == 'producers' && this.$refs.producers) {
        //setTimeout(() => {
        this.$refs.producers.loadVisits();
        //}, 1000);
      }
      if (this.tab == 'reports' && this.$refs.reports) {
        this.$refs.reports.loadVisits()
      }
      if (this.tab == 'history' && this.$refs.history) {
        this.$refs.history.loadHistory()
      }
      this.planning.selected = false;
      this.planning.id = null;
    },

    async loadRange() {
      let dateEnd = (moment() < moment(this.filter.project.dateEnd)) ? moment().format('YYYY-MM') : moment(this.filter.project.dateEnd).format('YYYY-MM');

      this.filter.max = dateEnd;
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), dateEnd];
    },

    async loadPmls() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PMLS",
          }
        );

        this.pmlsList = data.map((x) => {
          return {
            dateStart: x.data,
            dateEnd: x.data_fim,
            text: "De " + moment(x.data).format('MM/YYYY') + " até " + moment(x.data_fim).format('MM/YYYY'),
            quality: x.qualidade,
            visit: x.visita,
            //protocolo: x.protocolo,
            //descricao: x.descricao
          }
        });

        if (!_.isEmpty(this.pmlsList)) {
          this.filter.project = this.pmlsList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      }
    },
    onNewPlanning() {
      this.planning.selected = true;
      this.planning.id = null;
      this.tab = 'planning';
    },

    onSelectPlanning(id) {
      this.planning.selected = true;
      this.planning.id = id;
      this.tab = 'planning';
    },

  },
};
</script>
