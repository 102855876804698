<template>
  <v-dialog
    v-model="show"
    scrollable
    width="80%"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        <span class="text-h6">Relatório de Ordem de Produção <span v-if="!!order.code">nº {{ order.code }}</span></span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        <v-chip
          dark
          small
          :color="getStatusColor(order.status)"
        >
          {{ order.status }}
        </v-chip>
      </v-card-subtitle>

      <v-card-text>
        <div>
          <v-form
            disabled
          >
            <v-row>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <v-text-field
                  :value="order.code"
                  label="Código"
                  prepend-inner-icon="subtitles"
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <v-text-field
                  :value="order.item?.description"
                  label="Produto"
                  item-value="id"
                  item-text="description"
                  return-object
                  autofocus
                />
              </v-col>
              <v-col
                v-if="hasPlannedQuantityAccess"
                cols="6"
                md="3"
                class="pt-0"
              >
                <masked-text-field
                  :value="order.quantity"
                  label="Quantidade Planejada"
                  :suffix="order.item?.measurement"
                  prepend-inner-icon="iso"
                  :mask="masks.float"
                  unmask
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <masked-text-field
                  :value="order.accomplishedQuantity"
                  label="Quantidade Realizada"
                  :suffix="order.item?.measurement"
                  prepend-inner-icon="iso"
                  :mask="masks.float"
                  unmask
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <v-text-field
                  :value="order.lotNumber"
                  label="Nº Lote"
                  prepend-inner-icon="subtitles"
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <date-text-field
                  :value="order.date"
                  label="Data"
                  manual
                />
              </v-col>
              <v-col
                v-if="hasDatesAccess"
                cols="6"
                md="3"
                class="pt-0"
              >
                <date-text-field
                  :value="order.startDate"
                  label="Data Início"
                  manual
                  max=""
                />
              </v-col>
              <v-col
                v-if="hasDatesAccess"
                cols="6"
                md="3"
                class="pt-0"
              >
                <date-text-field
                  :value="order.dueDate"
                  label="Data Vencimento"
                />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-text-field
                  :value="order.description"
                  label="Descrição"
                  prepend-inner-icon="drive_file_rename_outline"
                />
              </v-col>
            </v-row>
            <v-tabs
              v-model="tab"
            >
              <v-tab
                v-for="(item, idx) of menus"
                :key="idx"
                :href="`#${item.tab}`"
              >
                <v-icon left>
                  {{ item.icon }}
                </v-icon>
                {{ item.description }}
              </v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="tab"
              touchless
              class="mt-2"
            >
              <v-tab-item
                value="tab-write-off"
              >
                <v-row
                  v-for="(data, idx) of items"
                  :key="idx"
                  style="border-bottom: 1px #eee solid;"
                >
                  <v-col class="pt-1 pb-0">
                    <v-text-field
                      :value="data.item?.description"
                      :label="getItemType(data.item?.type)"
                      disabled
                    />
                  </v-col>
                  <v-col class="pt-1 pb-0">
                    <masked-text-field
                      :value="data.quantity"
                      label="Qtde. Base"
                      unmask
                      :mask="masks.float"
                      :suffix="data.item?.measurement"
                      disabled
                    />
                  </v-col>
                  <v-col
                    v-if="hasPlannedQuantityAccess"
                    class="pt-1 pb-0"
                  >
                    <masked-text-field
                      :value="data.plannedQuantity"
                      label="Qtde. Planejada"
                      unmask
                      :mask="masks.float"
                      :suffix="data.item?.measurement"
                      disabled
                    />
                  </v-col>
                  <v-col class="pt-1 pb-0">
                    <masked-text-field
                      :value="data.accomplishedQuantity"
                      label="Qtde. Realizada"
                      unmask
                      :mask="masks.float"
                      :suffix="data.item?.measurement"
                      disabled
                    />
                  </v-col>
                </v-row>

                <template v-if="rawMaterialWriteOff.items.length > 0">
                  <span class="text-h6 d-block mt-4">Saídas de Produção</span>
                  <v-divider class="mb-4" />
                  <v-data-table
                    :headers="rawMaterialWriteOff.headers"
                    :items="rawMaterialWriteOff.items"
                    disable-pagination
                    disable-sort
                    disable-filtering
                    hide-default-footer
                  >
                    <template #[`item.tinas`]="{ item }">
                      <div
                        v-for="tina in item.tinas"
                        :key="tina.id"
                      >
                        <v-chip
                          v-if="tina.description"
                          x-small
                        >
                          {{ tina.description }}
                        </v-chip>
                      </div>
                    </template>
                    <template #[`item.transferredVol`]="{ value }">
                      {{ formatNumber(value) }}
                    </template>

                    <template #[`item.transferredAt`]="{ value }">
                      {{ formatDate(value, 'DD/MM/YYYY') }}
                    </template>

                    <template #[`item.processedAt`]="{ value }">
                      {{ formatDate(value, 'DD/MM/YYYY') }}
                    </template>
                  </v-data-table>
                </template>

                <template v-if="itemWriteOff.items.length > 0">
                  <span class="text-h6 d-block mt-4">Saídas de Insumos</span>
                  <v-divider class="mb-4" />
                  <v-data-table
                    :headers="itemWriteOff.headers"
                    :items="itemWriteOff.items"
                    disable-pagination
                    disable-sort
                    disable-filtering
                    hide-default-footer
                  >
                    <template #[`item.date`]="{ value }">
                      {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                    </template>
                    <template #[`item.quantity`]="{ item }">
                      {{ formatNumber(item.quantity) }} {{ item.measurement }}
                    </template>
                  </v-data-table>
                </template>
              </v-tab-item>

              <v-tab-item
                value="tab-entry"
              >
                <v-row
                  v-for="(data, idx) of byproducts"
                  :key="idx"
                  style="border-bottom: 1px #eee solid;"
                >
                  <v-col class="pt-1 pb-0">
                    <v-text-field
                      :value="data.item?.description"
                      :label="getItemType(data.item?.type)"
                      disabled
                    />
                  </v-col>
                  <v-col class="pt-1 pb-0">
                    <masked-text-field
                      v-model="data.quantity"
                      label="Qtde. Base"
                      persistent-hint
                      unmask
                      :mask="masks.float"
                      :suffix="data.item?.measurement"
                      disabled
                    />
                  </v-col>
                  <v-col
                    v-if="hasPlannedQuantityAccess"
                    class="pt-1 pb-0"
                  >
                    <masked-text-field
                      :value="data.plannedQuantity"
                      label="Qtde. Planejada"
                      unmask
                      :mask="masks.float"
                      :suffix="data.item?.measurement"
                      disabled
                    />
                  </v-col>
                  <v-col class="pt-1 pb-0">
                    <masked-text-field
                      :value="data.accomplishedQuantity"
                      label="Qtde. Realizada"
                      persistent-hint
                      unmask
                      :mask="masks.float"
                      :suffix="data.item?.measurement"
                      disabled
                    />
                  </v-col>
                </v-row>

                <template v-if="rawMaterialEntry.items.length > 0">
                  <span class="text-h6 d-block mt-4">Entradas de Silo</span>
                  <v-divider class="mb-4" />
                  <v-data-table
                    :headers="rawMaterialEntry.headers"
                    :items="rawMaterialEntry.items"
                    disable-pagination
                    disable-sort
                    disable-filtering
                    hide-default-footer
                  >
                    <template #[`item.transferredVol`]="{ value }">
                      {{ formatNumber(value) }} L
                    </template>

                    <template #[`item.transferredAt`]="{ value }">
                      {{ formatDate(value, 'DD/MM/YYYY') }}
                    </template>
                  </v-data-table>
                </template>

                <template v-if="itemEntry.items.length > 0">
                  <span class="text-h6 d-block mt-4">Entradas de Estoque</span>
                  <v-divider class="mb-4" />
                  <v-data-table
                    :headers="itemEntry.headers"
                    :items="itemEntry.items"
                    disable-pagination
                    disable-sort
                    disable-filtering
                    hide-default-footer
                  >
                    <template #[`item.date`]="{ value }">
                      {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                    </template>
                    <template #[`item.quantity`]="{ item }">
                      {{ formatNumber(item.quantity) }} {{ item.measurement }}
                    </template>
                  </v-data-table>
                </template>
              </v-tab-item>

              <v-tab-item
                value="tab-production"
              >
                <template v-if="lots.length > 0">
                  <span class="text-h6 d-block mt-4">Lotes</span>

                  <v-divider class="mb-4" />

                  <v-row
                    v-for="(data, idx) of lots"
                    :key="idx"
                    style="border-bottom: 1px #eee solid;"
                  >
                    <v-col class="pt-1 pb-0">
                      <v-text-field
                        :value="data.lotNumber"
                        label="Lote"
                        disabled
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <masked-text-field
                        :value="data.quantity"
                        label="Qtde. Física"
                        unmask
                        :mask="masks.float"
                        :suffix="order.item?.measurement"
                        disabled
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <masked-text-field
                        :value="data.reservedQuantity"
                        label="Qtde. Reservada (Shelf Life)"
                        unmask
                        :mask="masks.float"
                        :suffix="order.item?.measurement"
                        disabled
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <masked-text-field
                        :value="data.quantity - data.reservedQuantity"
                        label="Qtde. Disponível"
                        unmask
                        :mask="masks.float"
                        :suffix="order.item?.measurement"
                        disabled
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <date-text-field
                        v-model="data.manufacturingDate"
                        label="Fabricação"
                        manual
                        disabled
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <date-text-field
                        v-model="data.expirationDate"
                        label="Validade"
                        manual
                        disabled
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <v-text-field
                        :value="data.status"
                        label="Status"
                        disabled
                      />
                    </v-col>
                  </v-row>
                </template>

                <span class="text-h6 d-block mt-4">Registros</span>

                <v-divider class="mb-4" />
                <v-data-table
                  v-if="rawMaterialProduction.items.length > 0"
                  :headers="rawMaterialProduction.headers"
                  :items="rawMaterialProduction.items"
                  disable-pagination
                  disable-sort
                  disable-filtering
                  hide-default-footer
                >
                  <template #[`item.transferredVol`]="{ value }">
                    {{ formatNumber(value) }} L
                  </template>
                  <template #[`item.transferredAt`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY') }}
                  </template>
                </v-data-table>
                <v-data-table
                  v-else
                  :headers="itemProduction.headers"
                  :items="itemProduction.items"
                  disable-pagination
                  disable-sort
                  disable-filtering
                  hide-default-footer
                >
                  <template #[`item.date`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                  </template>
                  <template #[`item.quantity`]="{ item }">
                    {{ formatNumber(item.quantity) }} {{ item.measurement }}
                  </template>
                  <template #[`item.manufacturingDate`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY') }}
                  </template>
                  <template #[`item.expirationDate`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY') }}
                  </template>
                </v-data-table>
              </v-tab-item>

              <v-tab-item
                value="tab-appointments"
              >
                <v-tabs v-model="appointmentTab">
                  <v-tab
                    v-for="(item, idx) of appointments"
                    :key="idx"
                    :href="`#${item.tab}`"
                  >
                    {{ item.description }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items
                  v-model="appointmentTab"
                  touchless
                  class="mt-2"
                >
                  <v-tab-item
                    v-for="(item, idx) of appointments"
                    :key="idx"
                    :value="item.tab"
                  >
                    <v-data-table
                      :headers="item.headers"
                      :items="item.items"
                      disable-pagination
                      disable-filtering
                      hide-default-footer
                    >
                      <template #[`item.date`]="{ value }">
                        {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                      </template>
                    </v-data-table>
                    <div class="d-flex mt-4 ">
                      <v-card
                        v-for="(total, id) of item.totals"
                        :key="id"
                        tile
                        class="ma-1"
                      >
                        <div class="d-flex flex-column pa-2">
                          <div>{{ total.label }}</div>
                          <div class="text-h5">
                            {{ formatNumber(total.value) }}
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
                <v-row v-if="appointments.length === 0">
                  <v-col
                    cols="12"
                    class="text-center"
                  >
                    Nenhum apontamento configurado
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>

        <v-spacer />

        <v-btn
          color="blue"
          outlined
          @click="productiveControlReport()"
        >
          Controle Produtivo
          <v-icon
            right
            dark
          >
            assignment
          </v-icon>
        </v-btn>

        <v-btn
          color="green darken-1"
          outlined
          @click="exportExcel()"
        >
          Excel
          <v-icon
            right
            dark
          >
            backup_table
          </v-icon>
        </v-btn>

        <v-btn
          color="orange darken-3"
          outlined
          @click="print()"
        >
          Imprimir
          <v-icon
            right
            dark
          >
            print
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import DateTextField from '@/Support/Components/DateTextField.vue';

import moment from 'moment';
import get from 'lodash/get';

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {

  components: {
    MaskedTextField,
    DateTextField,
  },

  mixins: [ReportMixin],

  props: {
    value: Boolean,
    orderId: String,
  },

  data() {
    return {
      tab: null,

      order: {
        items: [],
        byproducts: [],
      },

      menus: [
        {
          icon: 'conveyor_belt',
          description: 'Saídas',
          tab: 'tab-write-off',
        },
        {
          icon: 'queue',
          description: 'Apontamentos',
          tab: 'tab-appointments',
        },
        {
          icon: 'repartition',
          description: 'Entradas de Subprodutos',
          tab: 'tab-entry',
        },
        {
          icon: 'system_update_alt',
          description: 'Entradas de Produção',
          tab: 'tab-production',
        },
      ],

      items: [],
      byproducts: [],
      lots: [],

      appointments: [],
      appointmentTab: null,

      rawMaterialWriteOff: {
        headers: [
          { text: 'Silo', value: 'silo.description' },
          { text: 'Tinas', value: 'tinas', width: 100, formatter: value => value.map(tina => tina.description).join(', ') },
          { text: 'Baixa', value: 'transferredAt', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Produção', value: 'processedAt', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Pasteurizador', value: 'transferredVol' },
          { text: 'Nº Lote', value: 'batchNumber' },
          { text: 'Matéria Prima', value: 'rawMaterial.description' },
          { text: 'Responsável', value: 'responsible.name' },
          { text: 'Produto', value: 'item.description' },
        ],
        items: [],
      },

      itemWriteOff: {
        headers: [
          { text: 'Produto', value: 'product.name' },
          { text: 'Data e Hora', value: 'date', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
          { text: 'Pessoa', value: 'person' },
          { text: 'Qtde.', value: 'quantity', align: 'center' },
          { text: 'Lote', value: 'lotNumber', align: 'center' },
        ],
        items: [],
      },

      rawMaterialEntry: {
        headers: [
          { text: 'Silo', value: 'silo.description', align: 'start' },
          { text: 'Entrada', value: 'transferredAt', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Volume', value: 'transferredVol' },
          { text: 'Nº Lote', value: 'batchNumber' },
          { text: 'Matéria Prima', value: 'rawMaterial.description' },
        ],
        items: [],
      },

      itemEntry: {
        headers: [
          { text: 'Produto', value: 'product.name' },
          { text: 'Data e Hora', value: 'date', align: 'center' },
          { text: 'Pessoa', value: 'person' },
          { text: 'Qtde.', value: 'quantity', align: 'center' },
          { text: 'Lote', value: 'lotNumber', align: 'center' },
        ],
        items: [],
      },

      rawMaterialProduction: {
        headers: [
          { text: 'Silo', value: 'silo.description' },
          { text: 'Entrada', value: 'transferredAt', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Volume', value: 'transferredVol' },
          { text: 'Nº Lote', value: 'batchNumber' },
          { text: 'Matéria Prima', value: 'rawMaterial.description' },
          { text: 'Status Lote', value: 'lotStatus' },
        ],
        items: [],
      },

      itemProduction: {
        headers: [
          { text: 'Produto', value: 'product.name' },
          { text: 'Data e Hora', value: 'date', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
          { text: 'Pessoa', value: 'person' },
          { text: 'Qtde.', value: 'quantity' },
          { text: 'Nº Lote', value: 'lotNumber' },
          { text: 'Fabricação', value: 'manufacturingDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Validade', value: 'expirationDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        ],
        items: [],
      },

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasDatesAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-datas' && o.tipo === 'COMPONENTE');
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.order = {};
        this.tab = null;
        this.appointments = [];
        this.appointmentTab = null;
        this.loadOrder();
      }
    }
  },

  methods: {
    async loadOrder() {
      try {
        this.$root.$progressBar.loading();

        this.order = await api.show(this.orderId);

        this.items = this.order.items
          .map(item => ({
            ...item,
            plannedQuantity: item.quantity * this.order.quantity,
          }));

        this.byproducts = this.order.byproducts
          .map(item => ({
            ...item,
            plannedQuantity: item.quantity * this.order.quantity,
          }));

        this.lots = this.order.lots || [];

        this.appointments = this.order.item.customs.map(custom => ({
          description: custom.title || custom.step,
          tab: `tab-${custom.id}`,
          headers: [
            ...custom.fields.map(field => ({
              text: field.label,
              value: field.id
            })),
            { text: 'Data e Hora', value: 'date', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
            { text: 'Pessoa', value: 'person' },
          ],
          items: this.order.appointments
            .filter(item => item.id_apontamento === custom.id)
            .map(item => {
              const row = {
                'date': item.data_hora_cadastro,
                'person': item.nome_pessoa_registro,
              };

              return item.apontamentos.reduce((acc, cur) => {
                acc[cur.id_rotulo] = cur.tipo === 'TANK' ? cur.resposta.descricao : cur.resposta;
                return acc;
              }, row)
            }),
          totals: this.order.appointments
            .filter(item => item.id_apontamento === custom.id)
            .reduce((acc, cur) => {
              for (const item of cur.apontamentos) {
                if (item.tipo === 'NUMBER') {
                  if (!(item.id_rotulo in acc)) {
                    acc[item.id_rotulo] = {
                      label: item.rotulo,
                      value: 0,
                    };
                  }
                  acc[item.id_rotulo].value += parseFloat(item.resposta);
                }
              }
              return acc;
            }, {})
        }))

        this.rawMaterialWriteOff.items = this.order.transfers.filter(item => item.type === 'SAIDA_PRODUCAO');
        this.itemWriteOff.items = this.order.records.filter(record => !!record.itemId);

        this.rawMaterialEntry.items = this.order.transfers.filter(transfer => transfer.type === 'ENTRADA_PRODUCAO' && transfer.rawMaterial.id !== this.order.item.id);
        this.itemEntry.items = this.order.records.filter(record => !!record.byproductId &&  record.product.id !== this.order.item.id);

        this.rawMaterialProduction.items = this.order.transfers.filter(transfer => transfer.type === 'ENTRADA_PRODUCAO' && transfer.rawMaterial.id === this.order.item.id);
        this.itemProduction.items = this.order.records.filter(record => record.product.id === this.order.item.id);
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    productiveControlReport() {
      this.$emit('productiveControlReport', this.order.id);
    },

    print() {
      this.$emit('print', this.order.id);
    },

    getStatusColor(value) {
      const statusColors = {
        'PLANEJADO': 'orange darken-1',
        'LIBERADO': 'blue',
        'FECHADO': 'green darken-1',
        'CANCELADO': 'red darken-1',
      }
      return statusColors[value]
    },

    getItemType(value) {
      const types = {
        'PRODUTO_ACABADO': 'Produto Acabado',
        'MATERIA_PRIMA': 'Matéria Prima',
        'INSUMO_PRODUCAO': 'Insumo de Produção',
      };

      return types[value] || 'Produto';
    },

    close() {
      this.show = false;
    },

    exportExcel() {
      const workbook = this.XLSX.utils.book_new();

      // Saídas
      const writeOff = this.items.map(data => ({
        'Produto': data.item?.description,
        'Qtde. Base': data.quantity,
        'Qtde. Planejada': data.plannedQuantity,
        'Qtde. Realizada': data.accomplishedQuantity,
      }));

      const wsWriteOff = this.XLSX.utils.json_to_sheet(writeOff);
      this.applyColsWidth(wsWriteOff, writeOff);
      this.applyStyles(wsWriteOff, writeOff, null, false);
      this.XLSX.utils.book_append_sheet(workbook, wsWriteOff, 'Saídas');

      // Saídas de Produção
      if (this.rawMaterialWriteOff.items.length > 0) {
        const rawMaterialWriteOff = this.getReportJson(this.rawMaterialWriteOff.headers, this.rawMaterialWriteOff.items, true);

        const wsRawMaterialWriteOff = this.XLSX.utils.json_to_sheet(rawMaterialWriteOff);
        this.applyColsWidth(wsRawMaterialWriteOff, rawMaterialWriteOff);
        this.applyStyles(wsRawMaterialWriteOff, rawMaterialWriteOff, null, false);
        this.XLSX.utils.book_append_sheet(workbook, wsRawMaterialWriteOff, 'Saídas de Produção');
      }

      // Saídas de Insumos
      if (this.itemWriteOff.items.length > 0) {
        const itemWriteOff = this.getReportJson(this.itemWriteOff.headers, this.itemWriteOff.items, true);

        const wsItemWriteOff = this.XLSX.utils.json_to_sheet(itemWriteOff);
        this.applyColsWidth(wsItemWriteOff, itemWriteOff);
        this.applyStyles(wsItemWriteOff, itemWriteOff, null, false);
        this.XLSX.utils.book_append_sheet(workbook, wsItemWriteOff, 'Saídas de Insumos');
      }

      // Apontamentos
      for (const appointmentData of this.appointments) {
        if (appointmentData.items.length > 0) {
          const appointment = this.getReportJson(appointmentData.headers, appointmentData.items, true);

          const wsAppointment = this.XLSX.utils.json_to_sheet(appointment);
          this.applyColsWidth(wsAppointment, appointment);
          this.applyStyles(wsAppointment, appointment, null, false);
          this.XLSX.utils.book_append_sheet(workbook, wsAppointment, appointmentData.description);

          // Totais dos apontamentos
          const appointmentTotals = Object.values(appointmentData.totals)
          if (appointmentTotals.length > 0) {
            const currentRow = appointmentData.items.length + 3;

            const totals = [appointmentTotals.reduce((acc, item) => ({
              ...acc,
              [item.label]: item.value,
            }), {})];

            this.XLSX.utils.sheet_add_json(wsAppointment, totals, { origin: currentRow });
            this.applyStyles(wsAppointment, totals, null, false, currentRow);
          }
        }
      }

      // Entradas de Subprodutos
      if (this.byproducts.length > 0) {
        const byproducts = this.byproducts.map(data => ({
          'Produto': data.item?.description,
          'Qtde. Base': data.quantity,
          'Qtde. Planejada': data.plannedQuantity,
          'Qtde. Realizada': data.accomplishedQuantity,
        }));

        const wsByproducts = this.XLSX.utils.json_to_sheet(byproducts);
        this.applyColsWidth(wsByproducts, byproducts);
        this.applyStyles(wsByproducts, byproducts, null, false);
        this.XLSX.utils.book_append_sheet(workbook, wsByproducts, 'Entradas de Subprodutos');
      }

      // Entradas de Silo
      if (this.rawMaterialEntry.items.length > 0) {
        const rawMaterialEntry = this.getReportJson(this.rawMaterialEntry.headers, this.rawMaterialEntry.items, true);

        const wsRawMaterialEntry = this.XLSX.utils.json_to_sheet(rawMaterialEntry);
        this.applyColsWidth(wsRawMaterialEntry, rawMaterialEntry);
        this.applyStyles(wsRawMaterialEntry, rawMaterialEntry, null, false);
        this.XLSX.utils.book_append_sheet(workbook, wsRawMaterialEntry, 'Entradas de Silo');
      }

      // Entradas de Estoque
      if (this.itemEntry.items.length > 0) {
        const itemEntry = this.getReportJson(this.itemEntry.headers, this.itemEntry.items, true);

        const wsItemEntry = this.XLSX.utils.json_to_sheet(itemEntry);
        this.applyColsWidth(wsItemEntry, itemEntry);
        this.applyStyles(wsItemEntry, itemEntry, null, false);
        this.XLSX.utils.book_append_sheet(workbook, wsItemEntry, 'Entradas de Estoque');
      }

      // Entradas de Produção
      if (this.rawMaterialProduction.items.length > 0) {
        const rawMaterialProduction = this.getReportJson(this.rawMaterialProduction.headers, this.rawMaterialProduction.items, true);

        const wsRawMaterialProduction = this.XLSX.utils.json_to_sheet(rawMaterialProduction);
        this.applyColsWidth(wsRawMaterialProduction, rawMaterialProduction);
        this.applyStyles(wsRawMaterialProduction, rawMaterialProduction, null, false);
        this.XLSX.utils.book_append_sheet(workbook, wsRawMaterialProduction, 'Entradas de Produção');
      }
      else if (this.itemProduction.items.length > 0) {
        const itemProduction = this.getReportJson(this.itemProduction.headers, this.itemProduction.items, true);

        const wsItemProduction = this.XLSX.utils.json_to_sheet(itemProduction);
        this.applyColsWidth(wsItemProduction, itemProduction);
        this.applyStyles(wsItemProduction, itemProduction, null, false);
        this.XLSX.utils.book_append_sheet(workbook, wsItemProduction, 'Entradas de Produção');
      }

      // Lotes
      if (this.lots.length > 0) {
        const lots = this.lots.map(data => ({
          'Lote': data.lotNumber,
          'Fabricação': this.formatDate(data.manufacturingDate, 'DD/MM/YYYY'),
          'Validade': this.formatDate(data.expirationDate, 'DD/MM/YYYY'),
          'Qtde. Física': data.quantity,
          'Qtde. Reservada (Shelf Life)': data.reservedQuantity,
          'Qtde. Disponível': data.availableQuantity,
          'Status Lote': data.lotStatus,
        }));

        const wsLots = this.XLSX.utils.json_to_sheet(lots);
        this.applyColsWidth(wsLots, lots);
        this.applyStyles(wsLots, lots, null, false);
        this.XLSX.utils.book_append_sheet(workbook, wsLots, 'Lotes');
      }

      this.XLSX.writeFile(workbook, `Relatório de Ordem de Produção${this.order.code ? ` nº ${this.order.code}` : ''}.xlsx`);
    },

    getReportJson(headers, items, isExcel = false) {
      return items.map(o => {
        return headers
          .filter(header => !!header.text)
          .reduce((acc, header) => ({
            ...acc,
            [header.text]: ('formatter' in header) && !(isExcel && ('mask' in header)) ? header.formatter(get(o, header.value, '')) : get(o, header.value, ''),
          }), {});
      });
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
  }

}
</script>
