<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <v-card
          dark
          class="v-tamanho settings"
          color="transparent"
        >
          <v-card-subtitle
            style="height: 52px;"
          >
            Mensagens
          </v-card-subtitle>
          <v-card-text
            style="background: rgba(0, 0, 0, 0.4);"
          >
            <v-row>
              <v-col>
                <v-responsive
                  class="overflow-y-scroll"
                  max-height="490"
                >
                  <v-list
                    three-line
                    class="transparent text-left"
                  >
                    <div
                      v-for="(msg, index) in messages"
                      :key="index"
                    >
                      <v-divider />
                      <v-list-group
                        prepend-icon="message"
                      >
                        <template #activator>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="msg.notificacao.title || ''"
                            />
                            <v-list-item-subtitle>
                              <v-row>
                                <v-col>
                                  {{ formatDate(msg.data_hora_cadastro, 'DD/MM/YYYY — HH:mm') }}
                                </v-col>
                                <v-col class="text-right">
                                  {{ msg.destinatarios.length }}
                                  {{ pluralize('destinatário', msg.destinatarios.length) }}
                                </v-col>
                              </v-row>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-text="msg.notificacao.body || ''" />
                            <v-list-item-subtitle
                              v-for="(item, idx) in msg.conteudo"
                              :key="`${index}_${idx}`"
                            >
                              <v-img
                                v-if="item.tipo == 'IMAGEM'"
                                max-height="200"
                                :src="item.url"
                                class="my-2"
                              />
                              <div
                                v-else
                                class="my-2"
                                v-text="item.texto"
                              />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </div>
                  </v-list>
                </v-responsive>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>
<style lang="scss">
.overflow-y-scroll {
  overflow-y: scroll
}
</style>

<script>
import moment from "moment-timezone";

export default {
  name: "notifications-tab",

  data() {
    return {
      loading: false,
      messages: []
    };
  },

  async created() {
    this.loadMessages();
  },

  methods: {
    /**
     * Recupera as mensagens do servidor
     */
    async loadMessages() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/messaging`);
        
        this.messages = data;
      } catch (err) {
        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    pluralize: (word, length) => `${word}${length > 1 ? 's' : ''}`
  },
};
</script>
