<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-container>
      <v-card
        class="v-tamanho collecton-city"
        color="transparent"
        dark
      >
        <v-card-title>
          <v-col
            cols="3"
          >
            <v-date-range-picker
              v-model="localDateFilter"
              label="Data"
              class="pt-0 pb-7"
              dark
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <person-autocomplete-filter
              v-model="localProducersFilter"
              label="Produtor"
              type="PRODUCER"
              multiple
              show-groupings
              class="pt-0 pb-7"
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <routes-autocomplete-filter
              v-model="localRoutesFilter"
              class="pt-0 pb-7"
              label="Rota"
              dark
              multiple
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <masked-input
              v-model="filters.horas"
              :mask="{ mask: '00:00' }"
              :max-length="5"
              prepend-icon="access_time"
              placeholder="Filtrar Horas (00:00) Coletas acima de:"
              @change="onTimeFormat"
            />
          </v-col>
        </v-card-title>
        <data-table
          ref="report"
          class="collecton-city-table"
          dark
          :items="relatorio"
          :search="filters.search"
          :headers="[
            { text:'Código', value:'codigo_produtor', align: 'start', width: '10%'},
            { text:'Produtor', value:'nome_produtor', width: '40%', align:'left'},
            { text:'Última Coleta (Hrs)', value:'ultima_coleta', width: '20%', align:'center'},
            { text:'Data Última Coleta', value:'data_ultima_coleta', width: '20%', align:'center'},
            { text:'Volume Coletado', value:'volume_coletado', width: '20%', align:'center'},
          ]"
          item-key="id_produtor"
          show-expand
          single-expand
        >
          <template #expanded-item="{ headers, item }">
            <td
              :colspan="7"
              class="pa-0 text-center"
            >
              <data-table
                :headers="[
                  { text:'Rota', value:'nome_rota', width: '20%', align:'center'},
                  { text:'Data', value:'data', width: '20%', align:'center'},
                  { text:'Coleta (Hrs)', value:'hora', width: '20%', align:'center'},
                  { text:'Volume Coletado', value:'quantidade_coleta', width: '40%', align: 'center'},
                ]"
                :items="item.details"
                :items-per-page="-1"
                dense
                hide-default-footer
                class="elevation-1 ma-3 text-center"
              />
            </td>
          </template>
        </data-table>
      </v-card>
    </v-container>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
          @click="print"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                print
              </v-icon>
            </template>

            Imprimir
          </v-tooltip>
        </v-btn>
    </v-speed-dial>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import XLSX from "xlsx-js-style";
import qs from "qs";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  components: {
    VDateRangePicker,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
    MaskedInput
  },

  props: {
    dateFilter: {
      type: Array
    },
    producersFilter: {
      type: Array
    },
    routesFilter: {
      type: Array
    },
  },

  data() {
    return {
      aba: 'aba-relatorio',
      loading: false,
      filters: {
        search: null,
        horas: "",
        routes: [],
      },
      relatorio: [],
      data: [],
      coletas: [],
      rules: {
        time: v =>  !v || !!v && v.length == 5 || 'Hora Inválida!',
      },
    };
  },

  computed: {
    localDateFilter: {
      get() {
        return this.dateFilter;
      },
      set(newValue) {
        return this.$emit("update:date-filter", newValue);
      },
    },
    localProducersFilter: {
      get() {
        return this.producersFilter;
      },
      set(newValue) {
        return this.$emit("update:producers-filter", newValue);
      },
    },
    localRoutesFilter: {
      get() {
        return this.routesFilter;
      },
      set(newValue) {
        return this.$emit("update:routes-filter", newValue);
      },
    },
  },

  mounted() {
    this.loadRelatorio();
  },

  methods: {
    async loadRelatorio() {
      try {
        this.loading = true;

        let routes = this.routesFilter.map(({ id }) => id)

        const [startDate, endDate] = this.dateFilter;

        const produtores = this.producersFilter.flatMap(p => {
          const producers = [p.id];
          if (p.grouping) {
            producers.push(...p.grouping.map(p => p.id_pessoa))
          }
          return producers;
        });

        const { data } = await this.$axios.post(
          `/relatorios/periodoColetas`,
          qs.stringify({
            data_inicio: startDate,
            data_fim: endDate,
            hora: this.filters.horas,
            rotas: JSON.stringify(routes),
            produtores: JSON.stringify(produtores),
          })
        );

        this.relatorio = data;

      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async onFilter() {
      await this.$nextTick();
      return this.loadRelatorio();
    },

    exportExcel() {
      const report = this.relatorio.flatMap((item) => item.details
        .map((detail) => ({
          'Código': item.codigo_produtor,
          'Produtor': item.nome_produtor,
          'Rota': detail.nome_rota,
          'Data': detail.data,
          'Última Coleta': detail.hora,
          'Último Volume Coletado': detail.quantidade_coleta,
        })));

      const data = XLSX.utils.json_to_sheet(report);

      const workbook = XLSX.utils.book_new();

      const filename = "periodo_entre_coletas";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    onTimeFormat() {

      if (this.filters.horas.length === 0) {
        this.onFilter();
        return;
      }

      let valor = this.filters.horas.replace(/\D/g, "");

      // Adiciona zeros à direita para ter pelo menos 4 caracteres
      while (valor.length < 4) {
        valor += "0";
      }

      // Limita a 4 caracteres
      valor = valor.slice(0, 4);

      // Formata para "00:00"
      valor = valor.replace(/(\d{2})(\d{2})/, "$1:$2");

      // Atualiza o valor do campo de entrada
      this.filters.horas = valor;

      this.onFilter();
    },

    print() {
      const title = `Período entre Coletas`;
      this.$refs.report.print(null, title);
    },
  },
}
</script>
