<template>
  <div class="mx-6 px-16">
    <v-col cols="12">
      <v-card
        color="transparent"
        dark
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="2"
              class="py-0"
            >
              <v-menu
                ref="menu"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateRefFilter"
                    label="Mês de Referência"
                    prepend-inner-icon="event"
                    readonly
                    filled
                    hide-details
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filters.date"
                  :max="maxDateRef"
                  type="month"
                  no-title
                  scrollable
                  @change="loadReport"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0"
            >
              <v-text-field
                :value="formatCurrency(samplePrice)"
                prefix="R$"
                label="Valor por amostra (atual)"
                disabled
                filled
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0"
            >
              <v-text-field
                :value="freeSamples"
                label="Qtde. amostras grátis (atual)"
                disabled
                filled
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0"
            >
              <routes-autocomplete-filter
                v-model="filters.routes"
                label="Rota"
                dark
                multiple
                @change="loadReport"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="py-0"
            >
              <v-text-field
                v-model="filters.search"
                prepend-inner-icon="search"
                label="Procurar"
                filled
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          dark
          :headers="headers"
          :items="filteredItems"
          show-expand
          single-expand
        >
          <template #[`item.amount`]="{ value }">
            <span>R$ {{ formatCurrency(value) }}</span>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0 text-center"
            >
              <v-data-table
                :headers="detailsHeaders"
                :items="item.items"
                :items-per-page="-1"
                dense
                hide-default-footer
                class="elevation-1 ma-3"
              >
                <template #[`item.data`]="{value}">
                  <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
                </template>

                <template #[`item.valor`]="{value, item}">
                  <span>R$ {{ formatCurrency(value) }}</span>

                  <v-tooltip
                    v-if="value"
                    top
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        :disabled="!hasSamplesBillAccess"
                        style="position:relative"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="showBill(item)"
                      >
                        <v-icon>
                          attach_money
                        </v-icon>
                        <v-icon
                          small
                          class="financial-badge"
                          color="green accent-4"
                        >
                          check_circle
                        </v-icon>
                      </v-btn>
                    </template>

                    Exibir conta
                  </v-tooltip>
                  <v-tooltip
                    v-else
                    top
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        :disabled="!hasSamplesBillAccess"
                        style="position:relative"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="generateBill(item)"
                      >
                        <v-icon>
                          attach_money
                        </v-icon>
                        <v-icon
                          small
                          class="financial-badge"
                          color="red accent-3"
                        >
                          remove_circle
                        </v-icon>
                      </v-btn>
                    </template>

                    Gerar conta
                  </v-tooltip>
                </template>
              </v-data-table>
            </td>
          </template>
          <template #[`footer.prepend`]>
            <div class="text-caption text-left ml-4">
              Produtores:
              <v-chip
                small
                class="mx-2 mb-0"
              >
                {{ Math.abs(totalProducers) }}
              </v-chip>
            </div>
            <div class="text-caption text-left ml-4">
              Total Amostra:
              <v-chip
                small
                class="mx-2 mb-0"
              >
                {{ Math.abs(totalSamples) }}
              </v-chip>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      type="ANTIBIOTICO"
      @input="billDialog.id = null"
      @removeBill="loadReport"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        color="blue darken-4"
        fab
        dark
        :disabled="!hasSamplesBillAccess"
        @click="regenerate"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              autorenew
            </v-icon>
          </template>

          Resetar Contas
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<style lang="scss">
  .financial-badge {
    font-size: 16px !important;
    position: absolute !important;
    top: -10px;
    right: 0px;
  }
</style>

<script>
import moment from "moment-timezone";
import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import map from "lodash/fp/map";
import groupBy from "lodash/fp/groupBy";
import sumBy from "lodash/fp/sumBy";
import isEmpty from "lodash/fp/isEmpty";
import flow from "lodash/fp/flow";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

export default {
  components: {
    BillDialog,
    RoutesAutocompleteFilter,
  },

  mixins: [ReportMixin],

  data() {
    return {
      filters: {
        date: moment().format("YYYY-MM"),
        search: null,
        routes: [],
      },

      headers: [
        { text: 'Código', value: 'code', width: 90 },
        { text: 'Produtor', value: 'producer' },
        { text: 'Rota', value: 'rota_principal' },
        { text: 'Amostras', value: 'total', align: 'end' },
        { text: 'Valor', value: 'amount', align: 'end' },
      ],

      detailsHeaders: [
        { text: 'Data', value: 'data' },
        { text: 'Amostra', value: 'numero_amostra' },
        { text: 'Rota', value: 'rota' },
        { text: 'Observação', value: 'observacao' },
        { text: 'Avisado', value: 'avisado' },
        { text: 'Marca', value: 'marca_comercial', show: false },
        { text: 'Principio Ativo', value: 'principio_ativo', show: false },
        { text: 'Resultado', value: 'resultado' },
        { text: 'Valor', value: 'valor', align: 'end' },
      ],

      items: [],

      // Exibe dialogo de financeiro
      billDialog: {
        show: false,
        id: null,
      },
    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = this.filters.search?.toUpperCase()?.trim();
      return this.items.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasSearch;
      });
    },

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format("YYYY-MM");
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, "YYYY-MM").format("MM/YYYY");
    },

    // Price settings
    settings() {
      return this.$store.state.settings.gerais.amostras || {};
    },

    samplePrice() {
      return this.settings.valor_amostra || 0;
    },

    freeSamples() {
      return this.settings.amostras_gratis || 0;
    },

    totalProducers() {
      return this.items.length
    },

    totalSamples() {
      return this.items.reduce((acc, cur) => (acc + (parseInt(cur.total) || 0)), 0);
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasSamplesBillAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "samples-bill-access" && o.tipo === "COMPONENTE");
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {

    async loadReport() {
      this.$root.$progressBar.loading();
      try {
        const { data } = await this.$axios.post(
          `/coleta/relatorioColetaAntibiotico`,
          {
            tipo: 'ANTIBIOTICO',
            data_inicio: moment(this.filters.date).startOf('month').format("YYYY-MM-DD"),
            data_fim: moment(this.filters.date).endOf('month').format("YYYY-MM-DD"),
            rotas: this.filters.routes.map(({ id }) => id),
          }
        );

        this.items = flow(
          groupBy('nome_produtor'),
          map((items) => {
            const [producer] = items;
            return {
              id: producer.id_produtor,
              code: producer.codigo_laticinio,
              producer: producer.nome_produtor,
              amount: sumBy(x => parseFloat(x.valor) || 0, items),
              total: items.length,
              rota_principal: producer.rota,
              items,
            }
          })
        )(data);

      } catch (e) {
        console.warn(e);
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    showBill(item) {
      this.billDialog.id = item.id_titulo;
      this.billDialog.show = true;
    },

    async generateBill(item) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Esta ação irá gerar uma conta no valor de R$ ${ this.formatCurrency(this.samplePrice) }<br>Clique em SIM para Confirmar.`,
        { color: 'orange' }
      ))) {
        return;
      }

      if (!this.samplePrice) {
        this.$snotify.info("Configure um valor para gerar a conta!", "Atenção");
      }

      this.$root.$progressBar.saving();
      try {

        const { data } = await this.$axios.post(
          `/financeiro/gerarContaAntibiotico`,
          {
            id_coletas_amostra: item.id_coletas_amostra,
            valor: this.samplePrice
          }
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Conta gerada com sucesso", "Sucesso");
        this.loadReport();
      } catch (e) {
        console.warn(e);
        this.$snotify.error("Erro ao gerar a conta", "Atenção");
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Action responsável por re-fazer as contas
     * @returns {Promise<void>}
     */
    async regenerate() {
      if (this.items.length > 0) {
        if (!(await this.$root.$confirm(
          'Atenção',
          [
            'Esta ação irá descartar <b>TODAS</b> as contas do período selecionado, independente dos filtros aplicados.',
            `<b>  - Mês referência: ${ this.dateRefFilter }</b>`,
            `<b>  - Valor: R$ ${ this.formatCurrency(this.samplePrice) }</b>`,
            `<b>  - Amostras Grátis: ${ this.freeSamples || 0 }</b>`,
            'Clique em SIM para Confirmar.',
          ].join('<br>'),
          { color: 'orange' }
        ))) {
          return;
        }
      }
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(
          '/financeiro/regerarContasAmostra',
          {
            mes_referencia: this.filters.date,
            tipo: 'ANTIBIOTICO',
          },
        );

        if (!data.codigo) {
          throw data.mensagem;
        }

        this.$snotify.success("Contas geradas com sucesso", "Sucesso");
        this.loadReport();
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");

        console.log(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportJson() {
      return this.filteredItems.map(item => ({
        Código: item.code,
        Produtor: item.producer,
        Rota: item.rota_principal,
        Amostras: item.total,
        Valor: `R$ ${this.formatCurrency(item.amount)}`,
      }));
    },

    getReportTitle() {
      return `Relatório de Coleta de Antibióticos - ${this.dateRefFilter}`;
    },

    exportExcel() {
      this.exportToFile({ report: this.getReportJson(), title: this.getReportTitle() });
    },

    async print() {
      await this.printFromJson(this.getReportJson(), this.getReportTitle());
    },

    onFilter() {
      this.loadReport();
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
}
</script>
