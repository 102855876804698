<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card>
      <v-card-title class="text-h5">
        Acoplar
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="transfers"
          disable-pagination
          disable-sort
          disable-filtering
          hide-default-footer
          @click:row="onItemSelected"
        >
          <template #[`item.volume`]="{ value }">
            {{ value }} L
          </template>
          <template #[`item.data_hora_atualizacao`]="{ value }">
            {{ dateFormat(value, 'DD/MM HH:mm') }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey darken-1"
          outlined
          @click="close"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";
import isArray from "lodash/fp/isArray"
import isObject from "lodash/fp/isObject"

export default {

  data() {
    return {
      dialog: false,

      itineraryId: null,

      vehicle: null,

      transfers: [],

      headers: [
        { text: 'Reboque', value: 'placa_destino', cellClass: 'link' },
        { text: 'Volume', value: 'volume', cellClass: 'link' },
        { text: 'Data', value: 'data_hora_atualizacao', cellClass: 'link', width: 100 },
      ],
    }
  },

  methods: {

    show({ itineraryId, vehicle }) {
      this.itineraryId = itineraryId;
      this.vehicle = vehicle;

      this.dialog = true;

      this.loadOpenTransfers();
    },

    async loadOpenTransfers() {
      try {
        this.$root.$progressBar.loading()

        const { data } = await this.$axios.get(`/itinerario/transferenciasSemAcoplamento`);

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.transfers = data;
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as transferências em aberto!",
          "Atenção"
        );
      } finally {
        this.$root.$progressBar.hide()
      }
    },

    async onItemSelected(transfer) {
      try {
        if (!(await this.$root.$confirm(
          `Deseja realmente acoplar?`,
          `<span class="body-1">Reboque: <b>${transfer.placa_destino}</b><br>Volume: <b>${transfer.volume} L</div>`,
          { color: 'primary' }
        ))
        ) {
          return;
        }

        this.$root.$progressBar.loading()

        const payload = {
          id_itinerario_destino: this.itineraryId,
          id_itinerario: transfer.id_itinerario,
          placa_destino: transfer.placa_destino,
          id: transfer.id,
        };

        const { data } = await this.$axios.post(
          `/itinerario/acoplarReboque`,
          payload
        );

        if (!isObject(data) || !data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$emit('save');
        this.close();

      } catch (error) {
        this.$snotify.error(`Erro ao acoplar equipamento: ${error}`, "Atenção");
        console.error(error);
      } finally {
        this.$root.$progressBar.hide()
      }
    },

    close() {
      this.transfers = [];
      this.dialog = false;
    },

    dateFormat: (value, opts) => moment(value).format(opts),
  },
}
</script>
