<template>
  <div>
    <v-autocomplete
      v-model="input"
      :loading="loading"
      :items="persons"
      item-text="name"
      item-value="id"
      :prepend-inner-icon="prependInnerIcon"
      :label="label"
      :background-color="backgroundColor"
      :return-object="returnObject"
      :hide-details="hideDetails"
      :filled="filled"
      :clearable="clearable"
      :dark="dark"
      :placeholder="placeholder"
      :multiple="multiple"
      :chips="chips"
      :small-chips="chips"
      :deletable-chips="chips"
      :filter="(person, queryText, itemText) => (`${person.code || ''} - ${(person.name || '').toLocaleLowerCase()} - ${person.search}`).indexOf(queryText.toLocaleLowerCase()) > -1"
      v-bind="$attrs"
      @change="onChange"
      @click:append-outer="$emit('click:append-outer')"
    >
      <template
        v-if="!chips && ['PRODUCER', 'CUSTOMER'].includes(type)"
        #selection="data"
      >
        <div
          v-if="data.item.code"
          class="mx-1 font-weight-light"
        >
          {{ data.item.code }} -
        </div>
        {{ data.item.name }}
        <div
          v-if="data.item.status && !['APROVADO', 'ATIVO'].includes(data.item.status)"
          class="mx-1 caption font-weight-light"
        >
          ({{ data.item.status }})
        </div>
      </template>
      <template #item="data">
        <template v-if="!isObject(data.item)">
          <v-list-item-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.name }}
              <v-icon
                v-if="showGroupings && (data.item.groupingId || data.item.grouping)"
                class="ml-2"
              >
                groups
              </v-icon>
            </v-list-item-title>
            <template v-if="showGroupings && data.item.grouping">
              <v-list-item-subtitle
                v-for="person of data.item.grouping"
                :key="person.id_pessoa"
                class="text-body-1 mt-2"
              >
                {{ person.codigo_nome }}
              </v-list-item-subtitle>
            </template>
            <v-list-item-subtitle v-else>
              <span
                v-if="data.item.code"
                class="mx-1"
              >
                {{ data.item.code }}
              </span>
              <v-chip
                v-if="data.item.status && !['APROVADO', 'ATIVO'].includes(data.item.status)"
                x-small
                color="orange"
              >
                {{ data.item.status }}
              </v-chip>
              <span
                v-if="showGroupings && data.item.groupingName"
                class="mx-1"
              >
                {{ data.item.groupingName }}
              </span>
              <v-chip
                v-if="type === 'TECHNICAL'"
                x-small
                color="gray"
              >
                {{ data.item.profile }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  props: {

    value: {
      type: [ Object, Array, String ],
    },

    type: {
      type: String,
    },

    project: {
      type: String,
    },

    label: {
      type: String,
    },

    backgroundColor: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    dark: {
      type: Boolean,
      default: false,
    },

    chips: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    filled: {
      type: Boolean,
      default: true,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    returnObject: {
      type: Boolean,
      default: true,
    },

    prependInnerIcon: {
      type: String,
      default: 'person',
    },

    showGroupings: Boolean,
    showBonusProducers: Boolean,
    showProducersAndGroups: Boolean,
  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // técnicos do Formulário (Autocomplete)
      persons: [],
    };
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  async mounted() {
    switch (this.type) {
      case 'DRIVER':
        await this.loadDrivers();
        break;
      case 'TECHNICAL':
      case 'TECHNICIAN':
        await this.loadTechnicals();
        break;
      case 'ANALYST':
        await this.loadAnalyst();
        break;
      case 'PRODUCER':
        await this.loadProducers();
        break;
      case 'SUPPLIER':
      case 'COMMERCIAL':
        await this.loadSuppliers();
        break;
      case 'CUSTOMER':
        await this.loadCustomers();
        break;
      case 'SELLER':
        await this.loadSellers();
        break;
      case 'DAIRY':
        await this.loadDairies();
        break;
      case 'CARRIER':
        await this.loadCarries();
        break;
    }

    this.input = this.value;
  },

  methods: {

    async loadTechnicals() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaTecnicos`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
            profile: person.cargo,
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadDrivers() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaMotorista`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadAnalyst() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaAnalistas`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
            signature: person.assinatura,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os analistas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadProducers() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/produtores/listaJson`,
          {
            projeto: this.project,
            exibir_produtores_bonificacao: this.showBonusProducers ? 1 : 0,
            exibir_produtores_e_grupos: this.showProducersAndGroups ? 1 : 0
          }
        );

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          const grouping = this.showGroupings && person.tipo_condominio ? JSON.parse(person.condominio) : undefined
          return {
            id: person.id_pessoa,
            name: person.nome,
            code: person.codigo_laticinio,
            status: person.status,
            groupingId: person.id_condominio,
            groupingName: person.nome_condominio,
            grouping,
            search: grouping ? grouping.map(p => p.codigo_nome).join(' - ').toLocaleLowerCase() : '',
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadSuppliers() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/fornecedor/listaJson`);

        if (_.isString(data)) {
          throw data;
        }

        if (this.type === 'COMMERCIAL') {
          data = data.filter(item => item.grupo === 'COMERCIAL');
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os fornecedores!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadCustomers() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/pessoa/listaClientes`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
            code: person.codigo_laticinio,
            status: person.status,
            region: person.id_regiao
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os clientes!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadSellers() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/pessoa/listaVendedoresJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os clientes!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadDairies() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/pessoa/listaLaticinioJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os laticínios!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadCarries() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaTransportadora`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onChange(event) {

      const newValue = event || (this.returnObject ? {} : null);

      // atualiza o v-model do componente
      this.$emit("input", newValue);

      this.$emit("change");
    },

    isObject: item => typeof item === 'object'
  },
};
</script>
