<template>
  <div
    class="mx-6 px-6"
  >
    <v-row class="pa-0 ma-0">
      <v-col
        cols="4"
        md="2"
      >
        <p class="white--text">
          Volume
        </p>
        <v-card
          class="report-card"
          color="transparent"
          dark
          flat
        >
          <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
            {{ Math.abs(volumeTotal) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        md="2"
      >
        <p class="white--text">
          Coletas
        </p>
        <v-card
          class="report-card"
          color="transparent"
          dark
          flat
        >
          <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
            {{ Math.abs(coletasTotal) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        md="2"
      >
        <p class="white--text">
          Produtores
        </p>
        <v-card
          class="report-card"
          color="transparent"
          dark
          flat
        >
          <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
            {{ Math.abs(totalProdutores) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filters.referenceDate"
          :items="['DESCARGA', 'COLETA']"
          label="Data de referência"
          prepend-inner-icon="local_shipping"
          dark
          filled
          hide-details
          @change="onFilter"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-date-range-picker
          v-model="filters.dateRange"
          dark
          hide-details
          @change="onFilter"
        />
      </v-col>
    </v-row>

    <v-card
      dark
      :style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <v-card-text>
        <v-chart
          :options="options"
          :style="{ width: '100%', height: `400px !important` }"
          autoresize
        />
      </v-card-text>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay
      v-model="loading"
      dark
    >
      Carregando
      <v-progress-circular
        indeterminate
        size="20"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss">
.chart-card {
  background: rgba(0, 0, 0, 0.5);
  overflow: none;
}
</style>

<script>
import isArray from "lodash/fp/isArray";
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {
  components: {
    VDateRangePicker,
  },

  data() {
    return {
      loading: false,
      filters: {
        dateRange: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        search: null,
        referenceDate: 'DESCARGA',
      },
      report: [],
      general: [],
    };
  },

  computed: {
    options() {
      return {
        color: "#619CB4",
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.general.map(general => moment(general.data).format("DD")),
          axisLabel: {
            color: "rgba(255, 255, 255, 0.8)",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            margin: 0,
            color: "rgba(255, 255, 255, 0.8)",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: '1%',
          right: '5%',
          top: '50px',
          bottom: '0%',
          containLabel: true,
        },
        series: [
          {
            data: this.general.map(data => data.volume),
            type: "line",
            name: 'Volume',
            areaStyle: {},
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 2,
              formatter: function(f) {
                return parseFloat(f.data);
              },
            }
          },
        ],
        toolbox: {
          right: "10",
          left: "10",
          iconStyle: {
            borderColor: "#fff"
          },
          feature: {
            saveAsImage: {
              title: "Volume geral por ciclo",
              name: "Volume geral por ciclo",
              backgroundColor: '#193D5B',
            },
          },
        },
      };
    },

    volumeTotal() {
      return Math.abs(this.report.reduce((acc, item) => (acc + parseInt(item.total_volume)), 0));
    },

    coletasTotal() {
      return Math.abs(this.report.reduce((acc, item) => (acc + parseInt(item.qtde_coletas)), 0));
    },

    totalProdutores() {
      return this.report.length;
    }
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = this.$options._componentTag;
        this.$store.commit("setFilter", { [filterId]: value });
      },
    },
  },

  created() {
    this.getFiltersFromStore();
  },

  mounted() {
    this.onFilter();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = this.$options._componentTag;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = this.$store.state.settings.filters[filterId];
      }
    },

    async loadReport() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        const [{ data }, { data: general }] = await Promise.all([
          this.$axios.post(
            `/coleta/volumeColeta`,
            {
              data_inicio: startDate,
              data_fim: endDate,
              data_referencia: this.filters.referenceDate
            }
          ),
          this.$axios.post(
            `/coleta/volumeColetaGeral`,
            {
              data_inicio: startDate,
              data_fim: endDate,
              data_referencia: this.filters.referenceDate
            }
          ),
        ]);

        if (!isArray(data)) {
          throw new Error(data);
        }

        if (!isArray(general)) {
          throw new Error(general);
        }

        this.report = data;
        this.general = general.sort((a, b) => moment(a.data).diff(moment(b.data)));
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    onFilter() {
      this.loadReport();
    },

    exportExcel() {
      let records = this.general.map(rel => {
        const cols = [
          {
            key: "Dia",
            value: moment(rel.data).format("DD/MM/YYYY"),
          },
          {
            key: "Volume",
            value: parseFloat(rel.volume).toFixed(2),
          },
        ];

        const mapped = cols.map(item => ({ [item.key]: item.value }));

        return Object.assign({}, ...mapped);
      });

      const data = XLSX.utils.json_to_sheet(records);
      const workbook = XLSX.utils.book_new();
      const filename = `volume_geral_por_ciclo`;

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
};
</script>
