<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="80%"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      scrollable
    >
      <v-card>
        <v-card-title>
          Rastreabilidade - {{ silo.label }}
        </v-card-title>
        <v-card-subtitle>
          <v-chip
            v-if="silo.rawProduct?.name"
            color="teal"
            text-color="white"
            class="mr-2"
          >
            {{ silo.rawProduct?.name }}
          </v-chip>
          <v-chip
            v-if="silo.lotNumber"
            color="red"
            text-color="white"
          >
            <span>Lote: <b>{{ silo.lotNumber }}</b></span>
          </v-chip>
        </v-card-subtitle>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="-1"
            hide-default-footer
            item-key="id"
            show-expand
            single-expand
            dense
          >
            <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
              <v-btn
                v-if="item.inOut === 'IN'"
                icon
                link
                transition="fab-transition"
                @click="expand(!isExpanded)"
              >
                <v-icon>
                  {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                </v-icon>
              </v-btn>
            </template>
            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col
                    v-if="item.movements.length > 0"
                    cols="12"
                  >
                    <v-card>
                      <v-card-title>Movimentos</v-card-title>

                      <v-card-text>
                        <v-data-table
                          :headers="headersMovements"
                          :items="item.movements"
                          :items-per-page="-1"
                          hide-default-footer
                          item-key="id"
                          show-expand
                          single-expand
                          dense
                        >
                          <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
                            <v-btn
                              v-if="item.collects.length > 0 "
                              icon
                              link
                              transition="fab-transition"
                              @click="expand(!isExpanded)"
                            >
                              <v-icon>
                                {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <template #expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                              <v-row>
                                <v-col v-if="item.collects.length > 0">
                                  <v-card>
                                    <v-card-title>Coletas</v-card-title>

                                    <v-card-text>
                                      <v-data-table
                                        :headers="collectsHeaders"
                                        :items="item.collects"
                                        :items-per-page="-1"
                                        hide-default-footer
                                        dense
                                      >
                                        <template #[`item.date`]="{ value }">
                                          {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                                        </template>
                                      </v-data-table>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </td>
                          </template>

                          <template #[`item.transferredAt`]="{ value }">
                            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                          </template>

                          <template #[`item.transferredVol`]="{ item }">
                            <transferred-vol-chip :item="item" />
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    v-if="item.origins.length > 0"
                    cols="12"
                  >
                    <v-card>
                      <v-card-title>Movimentos de origem</v-card-title>

                      <v-card-text>
                        <v-data-table
                          :headers="headersOrigin"
                          :items="item.origins"
                          :items-per-page="-1"
                          hide-default-footer
                          dense
                        >
                          <template #[`item.transferredAt`]="{ value }">
                            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                          </template>

                          <template #[`item.transferredVol`]="{ item }">
                            <transferred-vol-chip :item="item" />
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    v-for="item in item.originLots"
                    :key="item.lotNumber"
                    cols="12"
                  >
                    <v-card>
                      <v-card-title>Lote de Origem: {{ item.lotNumber }}</v-card-title>

                      <v-card-text>
                        <v-data-table
                          :headers="headersOriginsLots"
                          :items="item.entries"
                          :items-per-page="-1"
                          hide-default-footer
                          item-key="id"
                          show-expand
                          single-expand
                          dense
                        >
                          <template #[`item.data-table-expand`]="{ expand, isExpanded }">
                            <v-btn
                              icon
                              link
                              transition="fab-transition"
                              @click="expand(!isExpanded)"
                            >
                              <v-icon>
                                {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <template #expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                              <v-row>
                                <v-col v-if="item.movements.length > 0">
                                  <v-card>
                                    <v-card-title>Movimentos</v-card-title>

                                    <v-card-text>
                                      <v-data-table
                                        :headers="headersOriginsLotsMovements"
                                        :items="item.movements"
                                        :items-per-page="-1"
                                        hide-default-footer
                                        item-key="id"
                                        show-expand
                                        single-expand
                                        dense
                                      >
                                        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
                                          <v-btn
                                            v-if="item.collects.length > 0 "
                                            icon
                                            link
                                            transition="fab-transition"
                                            @click="expand(!isExpanded)"
                                          >
                                            <v-icon>
                                              {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                        <template #expanded-item="{ headers, item }">
                                          <td :colspan="headers.length">
                                            <v-row>
                                              <v-col v-if="item.collects.length > 0">
                                                <v-card>
                                                  <v-card-title>Coletas</v-card-title>

                                                  <v-card-text>
                                                    <v-data-table
                                                      :headers="collectsHeaders"
                                                      :items="item.collects"
                                                      :items-per-page="-1"
                                                      hide-default-footer
                                                      dense
                                                    >
                                                      <template #[`item.date`]="{ value }">
                                                        {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                                                      </template>
                                                    </v-data-table>
                                                  </v-card-text>
                                                </v-card>
                                              </v-col>
                                            </v-row>
                                          </td>
                                        </template>

                                        <template #[`item.transferredAt`]="{ value }">
                                          {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                                        </template>

                                        <template #[`item.transferredVol`]="{ item }">
                                          <transferred-vol-chip :item="item" />
                                        </template>
                                      </v-data-table>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </td>
                          </template>

                          <template #[`item.transferredAt`]="{ value }">
                            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                          </template>

                          <template #[`item.transferredVol`]="{ item }">
                            <transferred-vol-chip :item="item" />
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template #[`item.icon`]="{ item }">
              <v-icon
                v-if="item.type == 'ESTORNO'"
                color="orange accent-3"
              >
                undo
              </v-icon>
              <v-icon
                v-else-if="item.inOut === 'IN'"
                color="green accent-3"
              >
                south_west
              </v-icon>
              <v-icon
                v-else
                color="red accent-3"
              >
                north_east
              </v-icon>
            </template>

            <template #[`item.type`]="{ value }">
              <v-chip
                small
              >
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.transferredAt`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
            </template>

            <template #[`item.transferredVol`]="{ item }">
              <transferred-vol-chip :item="item" />
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TransferredVolChip from "@/Domains/Platform/Silos/Components/TransferredVolChip.vue";
import moment from 'moment';

const TypesEnum = {
  ENTRADA_TANQUE: 'Entrada Coleta',
  ENTRADA_PRODUCAO: 'Entrada Produção',
  SAIDA_PRODUCAO: 'Saída Produção',
  AJUSTE: 'Ajuste',
  CIP: 'CIP',
  ENTRE_SILOS: 'Transferência Entre Silos',
  SAIDA_UNIDADE: 'Saída Unidade',
  ENTRADA_UNIDADE: 'Entrada Unidade',
  SAIDA_SPOT: 'Saída Spot',
  ENTRADA_SPOT: 'Entrada Spot',
  ESTORNO: 'Estorno',
}

export default {
  components: {
    TransferredVolChip
  },

  data() {
    return {
      dialog: false,

      silo: {},

      headers: [
        { altText: 'Ícone', value: 'icon', sortable: false, drag: false },
        { text: 'Tipo', value: 'type' },
        { text: 'OP/Itinerário', value: 'originCode', align: 'center' },
        { text: 'OP/Origem/Destino/Rota', value: 'origin', align: 'center' },
        { text: 'Movimentado Em', value: 'transferredAt' },
        { text: 'Volume', value: 'transferredVol' },
      ],

      headersMovements: [
        { text: 'Origem/Rota', value: 'origin', align: 'center' },
        { text: 'Movimentado Em', value: 'transferredAt' },
        { text: 'Volume', value: 'transferredVol' },
        { text: 'Tanque', value: 'tank' },
        { text: 'Observação', value: 'obs' },
      ],

      headersOrigin: [
        { text: 'Unidade', value: 'origin', align: 'center' },
        { text: 'Silo', value: 'silo.description' },
        { text: 'Movimentado Em', value: 'transferredAt' },
        { text: 'Volume', value: 'transferredVol' },
        { text: 'Nº Lote', value: 'lotNumber' },
        { text: 'Observação', value: 'obs' },
      ],

      headersOriginsLots: [
        { text: 'Tipo', value: 'type' },
        { text: 'OP/Itinerário', value: 'originCode', align: 'center' },
        { text: 'Movimentado Em', value: 'transferredAt' },
        { text: 'Volume', value: 'transferredVol' },
      ],

      headersOriginsLotsMovements: [
        { text: 'Movimentado Em', value: 'transferredAt' },
        { text: 'Volume', value: 'transferredVol' },
        { text: 'Tanque', value: 'tank' },
      ],

      collectsHeaders: [
        { text: 'Produtor', value: 'producer.name' },
        { text: 'Volume', value: 'vol' },
        { text: 'Amostra', value: 'sampleNumber' },
        { text: 'Data Coleta', value: 'date' },
      ],

      items: [],
    };
  },

  methods: {
    show(silo) {
      this.dialog = true;
      this.silo = { ...silo };
      this.getTraceability(silo.id);
    },

    close() {
      this.dialog = false;
      this.items = [];
      this.silo = {};
    },

    async getTraceability(siloId) {
      try {
        this.$root.$progressBar.loading();

        const { data } =  await this.$axios.get(`/industry/silo/${siloId}/traceability`);

        this.items = data.map(history => ({
          id: history.id,
          code: history.code || '',
          originCode: history.origem_codigo || '',
          silo: {
            id: history.id_silo,
            description: history.nome_silo,
          },
          transferredVol: parseInt(history.volume),
          transferredAt: history.data,
          type: this.getTypeName(history.tipo),
          inOut: history.volume > 0 ? 'IN' : 'OUT',
          origin: history.origem || '',
          movements: (history.movimentos || [])
            .map(mov => ({
              id: mov.id_silo_movimento,
              code: mov.cod_itinerario || '',
              transferredVol: parseInt(mov.volume),
              transferredAt: mov.data,
              tank: this.getTankName(mov.tanque),
              obs: mov.observacao || '',
              type: this.getTypeName(mov.tipo),
              inOut: mov.volume > 0 ? 'IN' : 'OUT',
              origin: mov.origem || '',
              collects: (mov.coletas || [])
                .map(collect => ({
                  id: collect.id_descarga_analise,
                  producer: {
                    id: collect.id_produtor,
                    name: collect.nome_produtor,
                  },
                  sampleNumber: collect.numero_amostra,
                  vol: parseInt(collect.volume_liquido),
                  tank: this.getTankName(collect.tanque),
                  date: collect.data_coleta,
                })),
            })),
          origins: (history.movimentos_origem || [])
            .map(mov => ({
              id: mov.id_silo_movimento,
              code: mov.cod_itinerario || '',
              silo: {
                id: mov.id_silo,
                description: mov.nome_silo,
              },
              rawMaterial: {
                id: mov.id_materia_prima,
                description: mov.nome_materia_prima,
              },
              transferredVol: parseInt(mov.volume),
              transferredAt: mov.data,
              obs: mov.observacao || '',
              type: this.getTypeName(mov.tipo),
              inOut: mov.volume > 0 ? 'IN' : 'OUT',
              lotNumber: mov.numero_lote,
              origin: mov.origem || '',
            })),
          originLots: (history.movimentos_lote || [])
            .map(lot => ({
              lotNumber: lot.numero_lote,
              entries: (lot.entradas || [])
                .map(entry => ({
                  id: entry.id,
                  type: this.getTypeName(history.tipo),
                  originCode: entry.origem_codigo || '',
                  transferredVol: parseInt(entry.volume),
                  transferredAt: entry.data,
                  inOut: entry.volume > 0 ? 'IN' : 'OUT',
                  movements: (entry.movimentos || [])
                    .map(mov => ({
                      id: mov.id_silo_movimento,
                      transferredVol: parseInt(mov.volume),
                      transferredAt: mov.data,
                      tank: this.getTankName(mov.tanque),
                      type: this.getTypeName(mov.tipo),
                      inOut: mov.volume > 0 ? 'IN' : 'OUT',
                      collects: (mov.coletas || [])
                        .map(collect => ({
                          id: collect.id_descarga_analise,
                          producer: {
                            id: collect.id_produtor,
                            name: collect.nome_produtor,
                          },
                          sampleNumber: collect.numero_amostra,
                          vol: parseInt(collect.volume_liquido),
                          tank: this.getTankName(collect.tanque),
                          date: collect.data_coleta,
                        })),
                    })),
                })),
            })),
        }))
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao carregar as movimentações!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getTankName: (value) => {
      const tanks = {
        tanque1: 'Tanque 1',
        tanque2: 'Tanque 2',
        tanque3: 'Tanque 3',
        tanque4: 'Tanque 4',
        tanque5: 'Tanque 5',
        tanque6: 'Tanque 6',
        tanque7: 'Tanque 7',
        tanque8: 'Tanque 8'
      }

      return tanks[value] || ''
    },

    getTypeName: (value) => TypesEnum[value] || value,
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
}
</script>
