<template>
  <v-dialog
    v-model="show"
    scrollable
    width="80%"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        <span class="text-h6">Entrada de Subproduto de Ordem de Produção nº {{ order.code }}</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle
        v-if="step?.description"
        class="pb-2"
      >
        Etapa atual: <b>{{ step.description }}</b>
      </v-card-subtitle>

      <div class="mx-6">
        <v-tabs
          v-model="tab"
        >
          <v-tab
            v-for="menuItem of menu"
            :key="menuItem.view"
            :href="`#${menuItem.view}`"
          >
            <v-icon left>
              {{ menuItem.icon }}
            </v-icon>
            {{ menuItem.description }}
          </v-tab>
        </v-tabs>
      </div>

      <v-card-text>
        <div style="min-height:400px">
          <v-tabs-items
            v-model="tab"
            touchless
            class="mt-2"
          >
            <v-tab-item value="byproduct-silo">
              <byproduct-silo-entry
                v-if="show && order.id"
                :order="order"
                :can-edit="canEdit"
                :step="step"
                @onByproductTransferring="onByproductTransferring"
                @close="close"
              />
            </v-tab-item>

            <v-tab-item value="byproduct-entry">
              <byproduct-entry
                v-if="show && order.id"
                ref="byproduct-entry"
                :order="order"
                :can-edit="canEdit"
                :step="step"
                @close="close"
              />
            </v-tab-item>
          </v-tabs-items>
          <v-col
            v-if="menu.length === 0"
            cols="12"
            class="text-center"
          >
            Nenhum subproduto configurado para esta etapa
          </v-col>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-spacer />

        <v-btn
          v-if="tab === 'byproduct-entry'"
          color="primary"
          outlined
          :disabled="!canEdit"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <byproduct-silo-dialog
      v-model="byproductSiloDialog.show"
      :order="order"
      :silo="byproductSiloDialog.silo"
      :raw-materials="byproductSiloDialog.rawMaterials"
      @save="close"
    />
  </v-dialog>
</template>

<script>
import ByproductSiloEntry from '@/Domains/Industry/ProductionOrder/Components/ByproductSiloEntry.vue';
import ByproductEntry from '@/Domains/Industry/ProductionOrder/Components/ByproductEntry.vue';
import ByproductSiloDialog from '@/Domains/Industry/ProductionOrder/Components/ByproductSiloDialog.vue';

import get from 'lodash/get';

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

export default {

  components: {
    ByproductSiloEntry,
    ByproductEntry,
    ByproductSiloDialog,
  },

  props: {
    value: Boolean,
    orderId: String,
    step: Object,
  },

  data() {
    return {
      menu: [],

      tab: null,

      productionMenu: {
        icon: 'repartition',
        description: 'Produção de Subproduto',
        view: 'byproduct-silo',
      },

      byproductMenu: {
        icon: 'move_to_inbox',
        description: 'Entrada de Subproduto',
        view: 'byproduct-entry',
      },

      order: {
        byproducts: [],
        transfers: [],
      },

      byproductSiloDialog: {
        show: false,
        silo: null,
        rawMaterials: [],
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    canEdit() {
      return ['PLANEJADO', 'LIBERADO'].includes(this.order.status)
    },

  },

  watch: {
    show(value) {
      if (value) {
        this.order = {
          byproducts: [],
          transfers: [],
        };

        this.menu = [];

        this.tab = null;

        this.loadOrder();
      }
    }
  },

  methods: {
    async loadOrder() {
      try {
        this.$root.$progressBar.loading();

        this.order = await api.show(this.orderId);

        const hasRawMaterial = this.order.byproducts.find(data => data.item.isRawMaterial && (!data.step || data.step.id === this.step?.id))
        const hasProduct = this.order.byproducts.find(data => !data.item.isRawMaterial && (!data.step || data.step.id === this.step?.id))

        if (hasRawMaterial) {
          this.menu.push(this.productionMenu);
        }

        if (hasProduct) {
          this.menu.push(this.byproductMenu);
        }

      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao carregar a ordem de produção');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onByproductTransferring({ silo, rawMaterials }) {
      this.byproductSiloDialog.show = true;
      this.byproductSiloDialog.silo = silo;
      this.byproductSiloDialog.rawMaterials = rawMaterials;
    },

    close() {
      this.show = false;
    },

    save() {
      this.$refs['byproduct-entry'].save();
    },

  }

}
</script>
