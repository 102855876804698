<template>
  <v-dialog
    v-model="show"
    scrollable
    width="500"
    persistent
  >
    <v-card>
      <v-card-title>
        Numeração da NF-e
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-select
                v-model="form.ambiente"
                :items="[
                  { text: 'Produção', value: 1 },
                  { text: 'Homologação', value: 2 },
                ]"
                label="Ambiente *"
                :disabled="!!form.id"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.serie"
                label="Série *"
                placeholder="1"
                :mask="masks.integer"
                unmask
                prepend-inner-icon="tag"
                :disabled="!!form.id"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.prox_numero"
                label="Próximo número da Nota *"
                placeholder="1"
                :mask="masks.integer"
                unmask
                prepend-inner-icon="tag"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

export default {

  components: {
    MaskedTextField,
  },

  props: {
    value: Boolean,
    editId: String,
  },

  data() {
    return {
      loading: false,
      saving: false,
      form: {},

      masks: {
        integer: { mask: Number, min: 0, scale: 0, signed: false },
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.reset();

        if (this.editId) {
          this.load();
        }
      }
    }
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/fiscal/numeration/${this.editId}`);

        this.form = data;
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        if (!await this.$refs['form'].validate()) {
          return;
        }

        this.saving = true;

        const payload = this.form

        const data = this.editId ? await this.update(payload) : await this.store(payload);

        this.$snotify.success("Salvo com sucesso!", "Sucesso");
        this.show = false;
        this.$emit('save', data.id);
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/fiscal/numeration`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/fiscal/numeration/${this.editId}`, payload);
      return data;
    },

    cancel() {
      this.show = false;
    },

    reset() {
      this.tab = null;
      this.$refs.form && this.$refs.form.resetValidation();
      this.form = {};
    },
  }
}
</script>
