<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="1200px"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="pallet-dialog">
      <v-card-title>
        <span>Pallet<span v-if="!!form.description"> - {{ form.description }}</span></span>
        <v-chip
          v-if="form.barcode"
          small
          color="primary"
          class="ml-2"
        >
          {{ form.barcode }}
        </v-chip>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="products">
        {{ products }}
      </v-card-subtitle>

      <v-divider />

      <v-card-text>
        <v-form
          ref="formRef"
        >
          <v-layout>
            <div
              class="navigation"
            >
              <v-row
                class="mt-4 pr-5"
              >
                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <v-text-field
                    v-model="form.description"
                    label="Pallet *"
                    placeholder="00"
                    prepend-inner-icon="subtitles"
                    outlined
                    :rules="[v => !!v || 'Campo obrigatório']"
                  />
                </v-col>

                <v-col
                  v-if="!form.shippingId"
                  cols="12"
                  class="pt-0"
                >
                  <v-select
                    v-model="form.storageId"
                    label="Armazenamento"
                    :items="storages"
                    item-value="id"
                    item-text="descricao"
                    prepend-inner-icon="storage"
                    :disabled="storagesDisabled"
                    outlined
                    clearable
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <masked-text-field
                    v-model="form.palletTare"
                    label="Tara Pallet"
                    suffix="Kg"
                    :mask="masks.float"
                    unmask
                    inputmode="numeric"
                    prepend-inner-icon="scale"
                    outlined
                    @focus="onWeighingFocus('palletTare')"
                    @blur="onWeighingBlur()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <masked-text-field
                    v-model="form.stretchTare"
                    label="Tara Stretch"
                    suffix="Kg"
                    :mask="masks.float"
                    unmask
                    inputmode="numeric"
                    prepend-inner-icon="scale"
                    outlined
                    @focus="onWeighingFocus('stretchTare')"
                    @blur="onWeighingBlur()"
                  />
                </v-col>
              </v-row>

              <v-divider />

              <v-row
                class="mt-4 pr-5"
              >
                <v-col
                  cols="6"
                  class="pt-0"
                >
                  <span>Qtde de Caixas</span>
                  <masked-text-field
                    :value="form.packings.length"
                    :mask="masks.integer"
                    unmask
                    solo
                    hide-details
                    readonly
                  />
                </v-col>

                <v-col
                  cols="6"
                  class="pt-0"
                >
                  <span>Tara Embalagem</span>
                  <masked-text-field
                    :value="boxTareWeight"
                    suffix="Kg"
                    :mask="masks.float"
                    unmask
                    solo
                    hide-details
                    readonly
                  />
                </v-col>

                <v-col
                  cols="6"
                  class="pt-0"
                >
                  <span>Peso Bruto</span>
                  <masked-text-field
                    :value="grossWeight"
                    suffix="Kg"
                    :mask="masks.float"
                    unmask
                    solo
                    hide-details
                    readonly
                  />
                </v-col>

                <v-col
                  cols="6"
                  class="pt-0"
                >
                  <span>Peso Líquido</span>
                  <masked-text-field
                    :value="netWeight"
                    suffix="Kg"
                    :mask="masks.float"
                    unmask
                    solo
                    hide-details
                    readonly
                  />
                </v-col>

                <v-col
                  v-if="form.sscc"
                  cols="12"
                  class="pt-0"
                >
                  <span>SSCC</span>
                  <v-text-field
                    :value="form.sscc"
                    solo
                    hide-details
                    readonly
                  />
                </v-col>

                <v-col
                  v-if="packingSettings.palletTagModel"
                  cols="12"
                >
                  <v-btn
                    text
                    outlined
                    block
                    color="grey darken-2"
                    @click="printPalletTag()"
                  >
                    Etiqueta pallet
                    <v-icon right>
                      print
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider />

              <v-row
                class="mt-4 pr-5"
              >
                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <v-menu
                    v-model="settings.dialog"
                    :close-on-content-click="false"
                    top
                    right
                    content-class="settings-menu"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        text
                        outlined
                        block
                        color="grey darken-2"
                        @click="openSettings"
                      >
                        Configurações
                        <v-icon right>
                          settings
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Configurações</v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="settings.computerId"
                              label="ID Computador"
                              outlined
                              small
                              hide-details
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="settings.palletTagModel"
                              label="Etiqueta Pallet"
                              :items="[
                                { text: 'Sistema (10x5)', value: 'PALLET_10X5' },
                                { text: 'SIF (10x15)', value: 'PALLET_10X15' },
                              ]"
                              outlined
                              small
                              clearable
                              hide-details
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="settings.tagModel"
                              label="Etiqueta Caixa"
                              :items="[
                                { text: 'Sistema (10x5)', value: 'SYSTEM_10X5' },
                                { text: 'SIF (10x15)', value: 'SIF_10X15' },
                              ]"
                              outlined
                              small
                              clearable
                              hide-details
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="settings.generateSscc"
                              label="Gerar SSCC"
                              :items="[
                                { text: 'Sim', value: true },
                                { text: 'Não', value: false }
                              ]"
                              outlined
                              small
                              hide-details
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              v-model="settings.autoWeighing"
                              label="Pesagem automática"
                              :items="[
                                { text: 'Sim', value: true },
                                { text: 'Não', value: false }
                              ]"
                              outlined
                              small
                              hide-details
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              v-model="settings.autoPrinting"
                              label="Etiqueta automática"
                              :items="[
                                { text: 'Sim', value: true },
                                { text: 'Não', value: false }
                              ]"
                              outlined
                              small
                              hide-details
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="saveSettings"
                        >
                          Salvar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <div class="main-content flex-grow-1 pt-2">
              <div
                v-show="canWeigh"
                class="expand-button"
              >
                <v-btn
                  outlined
                  :text="expandAdd !== 'weighing'"
                  color="purple"
                  class="mr-3"
                  @click="expandAdd !== 'weighing' ? expandAdd = 'weighing' : expandAdd = false"
                >
                  <v-icon left>
                    scale
                  </v-icon>
                  Pesar
                  <v-icon right>
                    {{ expandAdd == 'weighing' ? 'expand_less' : 'expand_more' }}
                  </v-icon>
                </v-btn>
                <v-btn
                  outlined
                  :text="expandAdd !== 'registered'"
                  color="teal"
                  @click="expandAdd !== 'registered' ? expandAdd = 'registered' : expandAdd = false"
                >
                  <v-icon left>
                    inventory_2
                  </v-icon>
                  Incluir
                  <v-icon right>
                    {{ expandAdd == 'registered' ? 'expand_less' : 'expand_more' }}
                  </v-icon>
                </v-btn>
              </div>
              <v-expand-transition>
                <div
                  v-show="expandAdd == 'weighing'"
                  class="ml-4"
                >
                  <div class="text-h5 mt-2 black--text">
                    Pesagem
                  </div>
                  <packing-weighing
                    ref="packingWeighingRef"
                    settings-id="PALLET"
                    :external-validate="validate"
                    :box-number="form.packings.length + 1"
                    :pallet-number="form.description"
                    :load-number="props.loadNumber"
                    :loaded-at="props.loadedAt"
                    :has-weighing-input-selected="!!selectedWeighingInput || expandAdd !== 'weighing'"
                    @weighing="onWeighing"
                    @created="onPackingCreated"
                  />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div
                  v-show="expandAdd == 'registered'"
                  class="ml-4"
                >
                  <div class="d-flex">
                    <div class="text-h5 mt-2 black--text">
                      Cadastradas
                    </div>
                    <div
                      v-if="!form.shippingId"
                      class="d-flex mb-1 ml-4"
                    >
                      <v-date-range-picker
                        v-model="filters.range"
                        :filled="false"
                        dense
                        solo
                        class="mr-4"
                        style="max-width: 225px"
                        @change="reload"
                      />

                      <v-btn
                        icon
                        color="blue"
                        class="mt-1"
                        @click="reload()"
                      >
                        <v-icon>refresh</v-icon>
                      </v-btn>
                    </div>
                  </div>

                  <div class="registered-table">
                    <v-data-table
                      v-model="registeredSelected"
                      :items="packings"
                      :headers="headers"
                      show-select
                      hide-default-footer
                      disable-pagination
                      disable-sort
                      disable-filtering
                    >
                      <template #[`item.quantity`]="{ value, item }">
                        {{ formatNumber(value) }} {{ item.measurement }}
                      </template>
                      <template #[`item.grossWeight`]="{ value }">
                        {{ formatNumber(value) + ' Kg' }}
                      </template>
                      <template #[`item.tareWeight`]="{ value }">
                        {{ formatNumber(value) + ' Kg' }}
                      </template>
                      <template #[`item.netWeight`]="{ value }">
                        {{ formatNumber(value) + ' Kg' }}
                      </template>
                      <template #[`item.createdAt`]="{ value }">
                        {{ formatDate(value, 'DD/MM/YYYY') }}
                      </template>
                      <template #[`item.actions`]="{ item }">
                        <v-btn
                          small
                          color="blue"
                          outlined
                          text
                          @click="save({
                            packingsToAdd: [item]
                          })"
                        >
                          Adicionar
                        </v-btn>
                      </template>
                    </v-data-table>
                  </div>
                  <div style="height: 26px">
                    <v-expand-transition>
                      <div v-show="registeredSelected.length > 0">
                        <div class="d-flex pl-5">
                          <v-icon style="rotate: -90deg">
                            shortcut
                          </v-icon>
                          <div class="text-h6 py-1 px-3">
                            {{ String(registeredSelected.length).padStart(2, '0') }}
                          </div>
                          <v-btn
                            small
                            color="blue"
                            outlined
                            text
                            class="mt-1"
                            @click="save({
                              packingsToAdd: registeredSelected
                            })"
                          >
                            Adicionar selecionados
                          </v-btn>
                        </div>
                      </div>
                    </v-expand-transition>
                  </div>
                </div>
              </v-expand-transition>

              <v-divider
                v-show="expandAdd"
                class="my-4"
              />

              <div class="ml-4">
                <div class="text-h5 mt-2 black--text">
                  Caixas
                </div>

                <div class="packings-table">
                  <v-data-table
                    v-model="addedSelected"
                    :items="form.packings"
                    :headers="headers"
                    show-select
                    hide-default-footer
                    disable-pagination
                    disable-sort
                    disable-filtering
                  >
                    <template #[`item.quantity`]="{ value, item }">
                      {{ formatNumber(value) }} {{ item.measurement }}
                    </template>
                    <template #[`item.grossWeight`]="{ value }">
                      {{ formatNumber(value) + ' Kg' }}
                    </template>
                    <template #[`item.tareWeight`]="{ value }">
                      {{ formatNumber(value) + ' Kg' }}
                    </template>
                    <template #[`item.netWeight`]="{ value }">
                      {{ formatNumber(value) + ' Kg' }}
                    </template>
                    <template #[`item.createdAt`]="{ value }">
                      {{ formatDate(value, 'DD/MM/YYYY') }}
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-menu
                        bottom
                        right
                      >
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            @click="printTags([item])"
                          >
                            <v-list-item-icon>
                              <v-icon>
                                print
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Imprimir etiqueta</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="packingSettings.generateSscc && !item.sscc"
                            @click="generateSscc(item)"
                          >
                            <v-list-item-icon>
                              <v-icon>
                                code
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Gerar SSCC</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="canWeigh"
                            color="error"
                            @click="save({
                              packingsToRemove: [item]
                            })"
                          >
                            <v-list-item-icon>
                              <v-icon color="error">
                                remove
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Remover</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </div>
                <div style="height: 26px">
                  <v-expand-transition>
                    <div v-show="addedSelected.length > 0">
                      <div class="d-flex pl-5">
                        <v-icon style="rotate: -90deg">
                          shortcut
                        </v-icon>
                        <div class="text-h6 py-1 px-3">
                          {{ String(addedSelected.length).padStart(2, '0') }}
                        </div>
                        <v-btn
                          v-if="canWeigh"
                          small
                          color="error"
                          outlined
                          text
                          class="mt-1"
                          @click="save({
                            packingsToRemove: addedSelected
                          })"
                        >
                          Remover selecionados
                        </v-btn>
                        <v-btn
                          small
                          color="secondary"
                          outlined
                          text
                          class="mt-1 ml-3"
                          @click="printTags(addedSelected)"
                        >
                          Imprimir etiquetas
                        </v-btn>
                      </div>
                    </div>
                  </v-expand-transition>
                </div>
              </div>
            </div>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click.native="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click.native="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.pallet-dialog {
  .navigation {
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    height: 100%;
    border-right: thin solid rgba(0, 0, 0, 0.12);

    .v-text-field--outlined {
      margin-bottom: -10px;
    }
  }

  .main-content {
    position: relative;

    .expand-button {
      position: absolute;
      top: 7px;
      right: 0;
      z-index: 100;
    }

    .registered-table {
      max-height: 25vh;
      overflow: auto;
    }

    .packings-table {
      max-height: 25vh;
      overflow: auto;

      .v-input--selection-controls__input {
        margin-right: 0 !important;
      }

      .nowrap {
        white-space: nowrap;
      }
    }
  }
}
</style>

<script setup>
import { ref, nextTick, computed, reactive } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'
import store from '@/Support/Resources/vuex.js'
import moment from 'moment'

import { v4 as uuidv4 } from 'uuid'
import { uniq } from 'lodash'

import { usePacking } from '@/Domains/Shipment/Composable/Packing.js'

import ZebraPrinterGenerator from '@/Domains/Shipment/Api/ZebraPrinterGenerator.js'

import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'

import PackingWeighing from '@/Domains/Shipment/StorageControl/Components/PackingWeighing.vue'

const { progressBar, notify } = useUtils()

const { printTag, packingSettings, savePackingSettings } = usePacking('PALLET')

const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)
const formatDate = (value, format) => !value ? '-' : moment(value).format(format)

// eslint-disable-next-line
const props = defineProps({
  warehouseId: String,
  loadNumber: Number,
  loadedAt: String,
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['save', 'close'])

const dialog = ref(false)

const formRef = ref()

const expandAdd = ref(false)

const registeredSelected = ref([])
const addedSelected = ref([])

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

const form = reactive({
  id: null,
  barcode: null,
  sscc: null,
  storageId: null,
  description: null,
  palletTare: null,
  stretchTare: null,
  shippingId: null,
  packings: [],
})

const storagesDisabled = ref(false);
const storages = ref([]);

const canWeigh = computed(() => {
  // Só pode pesar se for um pallet avulso, ou se estiver editando o pallet dentro da expedição
  return !form.shippingId || (form.shippingId && props.loadNumber)
})

const products = computed(() => {
  return uniq(form.packings.map(box => box.product)).join(', ')
})

const boxTareWeight = computed(() => {
  return form.packings.reduce((acc, box) => acc + parseFloat(box.tareWeight || 0), 0)
})

const boxWeight = computed(() => {
  return form.packings.reduce((acc, box) => acc + parseFloat(box.grossWeight || 0), 0)
})

const netWeight = computed(() => {
  return (boxWeight.value || 0) - (boxTareWeight.value || 0)
})

const grossWeight = computed(() => {
  return (boxWeight.value || 0) + parseFloat(form.palletTare || 0) + parseFloat(form.stretchTare || 0)
})

const packingWeighingRef = ref()

const show = async ({ id = null, storageId = null, shippingId = null, pendingPackings = [], pendingProducts = [] }) => {
  dialog.value = true
  form.id = id || uuidv4()
  form.storageId = storageId
  form.shippingId = shippingId
  form.packings = []
  packings.value = pendingPackings
  expandAdd.value = false

  storagesDisabled.value = !!storageId

  if (id) {
    await load(id)
  }

  if (!form.shippingId) {
    reload()
    loadStorages()
  }

  nextTick(() => {
    packingWeighingRef.value?.init({
      shippingId: form.shippingId,
      pendingProducts
    })
  })
}

const close = () => {
  form.id = null
  form.barcode = null
  form.sscc = null
  form.description = null
  form.palletTare = null
  form.stretchTare = null
  form.shippingId = null
  dialog.value = false
  registeredSelected.value = []
  formRef.value?.reset()
  emit('close')
}

const loadStorages = async () => {
  try {
    const { data } = await axios.get(`/storage`, { params: {
      id_deposito: props.warehouseId,
      apenas_lista: 1,
    } });

    storages.value = data

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar os armazenamentos!', 'Atenção')
  }
}

const load = async (id) => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`pallet/${id}`)

    form.barcode = data.codigo_barras;
    form.storageId = data.id_armazenamento;
    form.description = data.descricao;
    form.palletTare = data.tara_pallet;
    form.stretchTare = data.tara_stretch;
    form.shippingId = data.id_entrega;
    form.sscc = data.sscc;
    form.createdAt = data.data_hora_cadastro;
    form.packings = data.estoques
      .sort((a, b) => (a.numero || Number.MAX_SAFE_INTEGER) - (b.numero || Number.MAX_SAFE_INTEGER))
      .map((packing, index) => ({
        id: packing.id,
        item: {
          id: packing.id_unidade_medida || packing.id_item,
          itemId: packing.id_item,
          measurementId: packing.id_unidade_medida,

          measurement: packing.unidade_medida?.unidade_medida || packing.item.unidade,
          measurementDescription: packing.unidade_medida?.descricao,

          name: packing.item.nome,
          code: packing.unidade_medida?.codigo || packing.item.cod_item,
          barcode: packing.unidade_medida?.codigo_barras || packing.item.codigo_barras,
          defaultBarcode: packing.item.codigo_barras,
          defaultMeasurement: packing.item.unidade,
        },
        stock: {
          id: packing.id_estoque,
          manufacturingDate: packing.estoque?.data_fabricacao,
          expirationDate: packing.estoque?.data_validade,
          lotNumber: packing.estoque?.numero_lote,
        },
        quantity: packing.quantidade,
        measurement: packing.item?.unidade,
        description: packing.unidade_medida ? `${packing.item?.nome} (${packing.unidade_medida.descricao})` : packing.item?.nome,
        product: `${packing.item?.cod_item || ''} - ${packing.item?.nome} - ${packing.estoque?.numero_lote || 'SEM LOTE'}`,
        grossWeight: parseFloat(packing.peso_bruto || 0),
        tareWeight1: parseFloat(packing.peso_tara_1 || 0),
        tareWeight2: parseFloat(packing.peso_tara_2 || 0),
        tareWeight: parseFloat(packing.peso_tara_1 || 0) + parseFloat(packing.peso_tara_2 || 0),
        netWeight: parseFloat(packing.peso_bruto || 0) - parseFloat(packing.peso_tara_1 || 0) - parseFloat(packing.peso_tara_2 || 0),
        createdAt: packing.data_hora_cadastro,
        barcode: packing.codigo_barras,
        sscc: packing.sscc,
        boxNumber: index + 1
      }));
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const filters = reactive({
  range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
})

const packings = ref([])

const headers = [
  { text: '', value: 'boxNumber', width: 1, align: 'center', class: 'pl-0', cellClass: 'pl-0' },
  { text: 'Produto', value: 'description' },
  { text: 'Lote', value: 'stock.lotNumber' },
  { text: 'Qtde', value: 'quantity', cellClass: 'nowrap' },
  { text: 'Peso Bruto', value: 'grossWeight', mask: '#,##0.00', cellClass: 'nowrap' },
  { text: 'Peso Tara', value: 'tareWeight', mask: '#,##0.00', cellClass: 'nowrap' },
  { text: 'Peso Líq.', value: 'netWeight', mask: '#,##0.00', cellClass: 'nowrap' },
  { text: 'Data', value: 'createdAt' },
  { text: 'Código de Barras', value: 'barcode' },
  { text: 'SSCC', value: 'sscc' },
  { text: '', value: 'actions', width: 10 },
]

const reload = async () => {
  try {
    progressBar.loading();

    const [startDate, endDate] = filters.range;

    const { data } = await axios.get(`packing`, { params: {
      warehouse_id: props.warehouseId,
      start_date: startDate,
      end_date: endDate,
      independents: 1,
    } });

    packings.value = data.map(packing => ({
      id: packing.id,
      item: {
        id: packing.id_unidade_medida || packing.id_item,
        itemId: packing.id_item,
        measurementId: packing.id_unidade_medida,

        measurement: packing.unidade_medida?.unidade_medida || packing.item.unidade,
        measurementDescription: packing.unidade_medida?.descricao,

        name: packing.item.nome,
        code: packing.unidade_medida?.codigo || packing.item.cod_item,
        barcode: packing.unidade_medida?.codigo_barras || packing.item.codigo_barras,
        defaultBarcode: packing.item.codigo_barras,
        defaultMeasurement: packing.item.unidade,
      },
      stock: {
        id: packing.id_estoque,
        manufacturingDate: packing.estoque?.data_fabricacao,
        expirationDate: packing.estoque?.data_validade,
        lotNumber: packing.estoque?.numero_lote,
      },
      quantity: packing.quantidade,
      measurement: packing.item?.unidade,
      description: packing.unidade_medida ? `${packing.item?.nome} (${packing.unidade_medida.descricao})` : packing.item?.nome,
      product: `${packing.item?.cod_item || ''} - ${packing.item?.nome} - ${packing.estoque?.numero_lote || 'SEM LOTE'}`,
      grossWeight: parseFloat(packing.peso_bruto || 0),
      tareWeight: parseFloat(packing.peso_tara_1 || 0) + parseFloat(packing.peso_tara_2 || 0),
      netWeight: parseFloat(packing.peso_bruto || 0) - parseFloat(packing.peso_tara_1 || 0) - parseFloat(packing.peso_tara_2 || 0),
      createdAt: packing.data_hora_cadastro,
      barcode: packing.codigo_barras,
      sscc: packing.sscc,
    }));

  } catch (error) {
    notify.error('Erro ao carregar as embalagens', 'Atenção');
    console.warn(error);
  } finally {
    progressBar.hide();
  }
}

const onPackingCreated = (packing) => {
  save({
    packingsToAdd: [packing]
  })
}

const validate = async () => await formRef.value?.validate()

const save = async ({
  packingsToAdd = [],
  packingsToRemove = [],
} = {}) => {
  try {
    if (!await validate()) {
      return
    }

    progressBar?.saving()

    let packingsPayload = form.packings

    if (packingsToAdd.length) {
      packingsPayload.push(...packingsToAdd)
    }

    if (packingsToRemove.length) {
      const packingsToRemoveIds = packingsToRemove.map(packing => packing.id)
      packingsPayload = packingsPayload.filter(packing => !packingsToRemoveIds.includes(packing.id))
    }

    packingsPayload = packingsPayload
      .sort((a, b) => (a.boxNumber || Number.MAX_SAFE_INTEGER) - (b.boxNumber || Number.MAX_SAFE_INTEGER))
      .map((item, index) => ({
        ...item,
        boxNumber: index + 1
      }))

    const packingIds = packingsPayload.map(box => box.id)

    const payload = {
      id: form.id,
      sscc: form.sscc,
      codigo_barras: form.barcode,
      id_armazenamento: form.storageId,
      id_entrega: form.shippingId,
      descricao: form.description,
      tara_pallet: form.palletTare,
      tara_stretch: form.stretchTare,
      tara_embalagem: boxTareWeight.value,
      peso_bruto: grossWeight.value,
      caixas: packingIds
    }

    payload.gerar_sscc = packingSettings.value.generateSscc

    const { data } = await axios.post(`pallet`, payload)

    form.packings = packingsPayload

    registeredSelected.value = []
    addedSelected.value = []

    if (packingsToAdd.length) {
      packings.value = packings.value.filter(packing => !packingIds.includes(packing.id))
    }

    if (packingsToRemove.length) {
      packings.value.push(...packingsToRemove)
    }

    if (!form.barcode) {
      form.barcode = data.barcode
    }

    if (!form.sscc) {
      form.sscc = data.sscc
    }

    if (!form.createdAt) {
      form.createdAt = data.createdAt
    }

    notify.success('Salvo com sucesso!')
    emit('save')
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao salvar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const generateSscc = async (packing) => {
  try {
    progressBar?.saving()

    const { data } = await axios.put(`packing/${packing.id}`, {
      gerar_sscc: true
    })

    packing.sscc = data.sscc

  } catch (error) {
    notify.error('Erro ao gerar o SSCC', 'Atenção')
    console.error(error)
  } finally {
    progressBar?.hide()
  }
}

const settings = reactive({
  dialog: false,
  computerId: null,
  palletTagModel: null,
  tagModel: null,
  generateSscc: false,
})

const openSettings = () => {
  settings.dialog = true
  settings.computerId = packingSettings.value.computerId
  settings.palletTagModel = packingSettings.value.palletTagModel
  settings.tagModel = packingSettings.value.tagModel
  settings.generateSscc = packingSettings.value.generateSscc
  settings.autoWeighing = packingSettings.value.autoWeighing
  settings.autoPrinting = packingSettings.value.autoPrinting
}

const saveSettings = async () => {
  try {
    progressBar?.saving()

    await savePackingSettings({
      computerId: settings.computerId,
      palletTagModel: settings.palletTagModel,
      tagModel: settings.tagModel,
      generateSscc: settings.generateSscc,
      autoWeighing: settings.autoWeighing,
      autoPrinting: settings.autoPrinting,
    })

  } catch (error) {
    notify.error('Erro ao salvar as configurações', 'Atenção')
    console.error(error)
  } finally {
    progressBar?.hide()
    settings.dialog = false
  }
}

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const printTags = async (packings) => {
  progressBar?.loading()

  for (const packing of packings) {
    packingWeighingRef.value?.print(packing)

    await delay(100)
  }

  addedSelected.value = []
  progressBar?.hide()
}

const printPalletTag = () => {
  const tagModel = packingSettings.value.palletTagModel
  if (tagModel === 'PALLET_10X5') {
    const rawData = ZebraPrinterGenerator.systemPalletTag10x5({
      barcode: form.barcode,
      grossWeight: grossWeight.value,
      tareWeight: boxTareWeight.value + parseFloat(form.palletTare || 0) + parseFloat(form.stretchTare || 0),
      netWeight: netWeight.value,
      code: form.description,
    })

    return printTag(rawData)
  }

  if (tagModel === 'PALLET_10X15') {
    const dairy = store.state.settings?.laticinio || {}
    const general = store.state.settings?.gerais || {}

    const rawData = ZebraPrinterGenerator.industryPalletTag10x15({
      dairyName: dairy.name,

      boxTareWeight: boxTareWeight.value,
      palletTare: parseFloat(form.palletTare || 0),
      stretchWeight: parseFloat(form.stretchTare || 0),
      tareWeight: boxTareWeight.value + parseFloat(form.palletTare || 0) + parseFloat(form.stretchTare || 0),
      grossWeight: grossWeight.value,
      netWeight: netWeight.value,

      productName: uniq(form.packings.map(box => box.description)).join(' / '),
      lots: Object.values(form.packings.reduce((acc, cur) => {
        const id = cur.stock.id

        if (!acc[id]) {
          acc[id] = {
            ...cur.stock,
            quantity: 0,
            grossWeight: 0,
            netWeight: 0,
          }
        }

        acc[id].quantity += parseFloat(cur.quantity || 0)
        acc[id].grossWeight += parseFloat(cur.grossWeight || 0)
        acc[id].netWeight += parseFloat(cur.netWeight || 0)

        return acc
      }, {})),
      boxQuantity: form.packings.length,
      loadedAt: props.loadedAt || form.createdAt,

      palletNumber: form.description,
      loadNumber: props.loadNumber,

      sscc: form.sscc,
      sif: general.sif,
    })

    return printTag(rawData)
  }
}

const selectedWeighingInput = ref(null)

const onWeighingFocus = (field) => {
  selectedWeighingInput.value = field
}

const onWeighingBlur = () => {
  selectedWeighingInput.value = null
}

const onWeighing = (weight) => {
  if (selectedWeighingInput.value === 'palletTare') {
    form.palletTare = weight
  }
  else if (selectedWeighingInput.value === 'stretchTare') {
    form.stretchTare = weight
  }
}

// eslint-disable-next-line no-undef
defineExpose({ show, close })
</script>
