<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      max-width="750px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Planejamento de Spot
          </span>
        </v-card-title>

        <v-card-text class="px-0">
          <v-stepper
            v-model="step"
            alt-labels
            class="elevation-0"
          >
            <v-stepper-header>
              <v-stepper-step
                step="1"
                :complete="step > 1"
              >
                Dados principais
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                step="2"
                :complete="step > 2"
              >
                Período
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <company-autocomplete-filter
                        v-model="form.company"
                        label="Empresa *"
                        :hide-details="false"
                        :filled="false"
                        placeholder=" "
                        :rules="[v => !!v && !!v.id || 'Campo obrigatório']"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <raw-material-autocomplete
                        v-model="form.rawMaterialId"
                        label="Matéria Prima *"
                        placeholder=" "
                        :rules="[rules.required]"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <v-date-range-picker
                        v-model="form.period"
                        label="Período"
                        :filled="false"
                        :hide-details="false"
                        :ranges="dateRangeFilters"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0 d-flex"
                    >
                      <v-text-field
                        v-model="form.literPrice"
                        label="Valor do litro"
                        prefix="R$"
                        type="number"
                        placeholder=" "
                        @keypress="disableDot"
                      />
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="align-self-end text-end mb-4"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon
                              color="blue"
                            >
                              help
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Informe o valor do litro negociado para calcular automaticamente o valor total de cada dia</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-alert
                  dense
                  outlined
                  dismissible
                  type="info"
                >
                  Apenas os spots com volume informado serão salvos
                </v-alert>
                <v-form
                  ref="form2"
                  lazy-validation
                >
                  <v-row
                    style="max-height: calc(100vh - 445px); overflow: auto;"
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-row
                        v-for="item in form.spots"
                        :key="item.date"
                        no-gutters
                      >
                        <v-col
                          cols="3"
                          class="pb-0"
                        >
                          <v-text-field
                            :value="dateFormat(item.date, 'DD/MM/YYYY')"
                            :label="dateFormat(item.date, 'dddd')"
                            disabled
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          class="pb-0"
                        >
                          <v-text-field
                            v-model="item.volume"
                            label="Volume (L)"
                            type="number"
                            placeholder=" "
                            prepend-inner-icon="opacity"
                            @keypress="disableDotAndComma"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          class="pb-0"
                        >
                          <v-text-field
                            v-model="item.literPrice"
                            label="Valor do litro"
                            prefix="R$"
                            type="number"
                            placeholder=" "
                            :rules="(item.volume && priceIsRequired) ? [rules.required] : []"
                            @keypress="disableDot"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          class="pb-0"
                        >
                          <v-text-field
                            :value="!!item.literPrice && !!item.volume ? formatCurrency(parseFloat(item.literPrice) * parseFloat(item.volume)) : null"
                            label="Total"
                            prefix="R$"
                            placeholder=" "
                            disabled
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <small class="mx-4">* Campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="step == 1"
            color="grey darken-1"
            outlined
            @click="() => show = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-else
            outlined
            @click="() => step--"
          >
            <v-icon
              left
              dark
            >
              chevron_left
            </v-icon>
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="showDeleteBtn"
            color="red darken-1"
            outlined
            @click="removePlanning"
          >
            Excluir
          </v-btn>
          <v-btn
            v-if="step == 1"
            color="green"
            outlined
            @click="nextStep"
          >
            Continuar
            <v-icon
              right
              dark
            >
              chevron_right
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            outlined
            @click="saveNew"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        v-model="saving"
        absolute
      >
        Salvando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-overlay>

      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import CompanyAutocompleteFilter from '@/Domains/Itineraries/Components/CompanyAutocompleteFilter.vue';
import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue';
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

import _ from "lodash";
import moment from "moment";

export default {

  components: {
    CompanyAutocompleteFilter,
    RawMaterialAutocomplete,
    VDateRangePicker,
  },

  props: {
    value: {
      type: Boolean,
    },
    spot: {
      type: Object,
      default: () => ({
        company: {},
        rawMaterialId: null,
        period: [],
      })
    },

    type: {
      type: String,
      default: 'ENTRADA'
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      step: 1,

      form: {
        company: {},
        rawMaterialId: null,
        literPrice: null,
        period: [],
        spots: []
      },

      rules: {
        required: v => !!v || !_.isEmpty(v) || 'Campo obrigatório!',
        dateTime: v => !v || !!v && v.length == 16 && this.dateValidate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!'
      },
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.reset();
        }
        this.$emit("input", value);
      },
    },
    dateRangeFilters() {
      const today = moment().date();
      let currrentFortnight, previousFortnight, nextFortnight;

      if (today <= 15) {
        currrentFortnight = [moment().startOf("month").format("YYYY-MM-DD"), moment().date(15).format("YYYY-MM-DD")]
        previousFortnight = [moment().subtract(1, "month").date(16).format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")]
        nextFortnight = [moment().date(16).format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")]
      }
      else {
        currrentFortnight = [moment().date(16).format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")]
        previousFortnight = [moment().startOf("month").format("YYYY-MM-DD"), moment().date(15).format("YYYY-MM-DD")]
        nextFortnight = [moment().add(1, "month").startOf("month").format("YYYY-MM-DD"), moment().add(1, "month").date(15).format("YYYY-MM-DD")]
      }

      return {
        'Esta quinzena': currrentFortnight,
        'Quinzena Anterior': previousFortnight,
        'Próxima Quinzena': nextFortnight,
      }
    },
    showDeleteBtn() {
      return this.step == 2 && this.form.spots.some(o => !!o.id);
    },

    priceIsRequired() {
      return !!this.$store.state.settings.gerais.valor_spot_obrigatorio;
    },
  },

  watch: {
    show(value) {
      if (value) {
        if (this.spot && this.spot.company.id && this.spot.rawMaterialId) {
          this.loading = true;

          this.$nextTick(() => {

            this.form.company = { ...this.spot.company };
            this.form.rawMaterialId = this.spot.rawMaterialId;
            this.form.period = [...this.spot.period];

            setTimeout(() => {
              this.nextStep();
            }, 500);
          });

        }
        else {
          this.form.period = this.dateRangeFilters['Esta quinzena'];
        }
      }
    }
  },

  methods: {

    reset() {
      this.$refs.form && this.$refs.form.resetValidation();
      this.step = 1;
      this.form = {
        company: {},
        rawMaterialId: null,
        literPrice: null,
        period: [],
        spots: []
      };
    },

    async nextStep() {
      await this.loadPeriod();
    },

    async loadPeriod() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      try {
        this.form.spots = [];

        const [startDate, endDate] = this.form.period;

        const { data } = await this.$axios.post(
          `/spot/listarPlanejamentos`,
          {
            data_ini: startDate,
            data_fim: endDate,
            id_empresa: this.form.company.id,
            id_materia_prima: this.form.rawMaterialId,
            tipo: this.type
          },
        );

        const store = data.reduce((acc, cur) => ({ ...acc, [cur.data]: cur }), {});

        let dateAux = startDate;

        while (dateAux <= endDate) {
          const date = moment(dateAux);
          const formatedDate = date.format('YYYY-MM-DD');
          this.form.spots.push({
            id: store[formatedDate] ? store[formatedDate].id_spot : null,
            literPrice: store[formatedDate] ? parseFloat(store[formatedDate].valor_litro) : this.form.literPrice,
            volume: store[formatedDate] ? parseFloat(store[formatedDate].volume) : null,
            date: formatedDate
          })
          dateAux = date.add(1, 'day').format('YYYY-MM-DD');
        }

        this.step++;
      } catch (error) {
        this.reset();
        this.show = false;
        this.$snotify.error("Oops, ocorreu um erro ao carregar o período!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },

    async saveNew() {
      if (!this.$refs.form2.validate()) {
        return;
      }

      this.saving = true;

      try {

        const payload = {
          id_empresa: this.form.company.id,
          nome_empresa: this.form.company.name,
          id_materia_prima: this.form.rawMaterialId,
          tipo: this.type,
          spots: this.form.spots
            .filter(o => o.id != null || o.volume != null)
            .map(o => ({
              id_spot: o.id || null,
              data: o.date,
              volume: parseFloat(o.volume),
              valor_litro: o.literPrice || null
            }))
        };

        const { data } = await this.$axios.post(
          `/spot/salva`,
          payload,
        );

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.$emit("change");

        this.reset();
        this.show = false;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar o planejamento!", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async removePlanning() {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este planejamento?', { color: 'red' }))) {
        return;
      }
      this.saving = true;
      try {

        const [startDate, endDate] = this.form.period;

        const { data } = await this.$axios.post(
          `/spot/remove`,
          {
            data_ini: startDate,
            data_fim: endDate,
            id_empresa: this.form.company.id,
            id_materia_prima: this.form.rawMaterialId,
            tipo: this.type,
          },
        );

        if (data.codigo != 1) {
          throw data.mensagem || data;
        }

        this.$emit("change");

        this.reset();
        this.show = false;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao remover o planejamento!", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
        this.getSpots();
      }
    },

    /**
     * Os filtros foram descontinuados na nova versão do vue (v3) por não ser uma boa prática
     * Let's stop using filters and starts to use methods then
     */
    dateValidate: (value, format) => !value ? '' : moment(value, format).isValid(),
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
