<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Novo itinerário de {{ type == 'transferencia' ? 'Transferência' : 'Spot' }}
          </span>
        </v-card-title>

        <v-row
          v-if="type == 'spot' && hasOpenSpot"
          no-gutters
        >
          <v-card-text>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="headline">
                Escolha um spot em aberto
              </div>
            </v-col>
            <v-col cols="12">
              <v-tabs centered>
                <v-tab>
                  ENTRADA
                </v-tab>

                <v-tab>
                  SAÍDA
                </v-tab>

                <v-tab-item>
                  <div class="d-flex justify-space-between align-center">
                    <v-btn
                      icon
                      :disabled="filters.entranceDate === filters.minDate"
                      @click="changeDate('entranceDate', 'PREVIOUS')"
                    >
                      <v-icon>
                        arrow_back_ios
                      </v-icon>
                    </v-btn>
                    <div class="text-h6 dark-grey--text">
                      {{ formatDate(filters.entranceDate, 'DD/MM/YYYY') }}
                    </div>
                    <v-btn
                      icon
                      :disabled="filters.entranceDate === filters.maxDate"
                      @click="changeDate('entranceDate', 'NEXT')"
                    >
                      <v-icon>
                        arrow_forward_ios
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-data-table
                    :headers="plannedSpots.headers"
                    :items="plannedSpotsEntrance"
                    disable-sort
                    hide-default-footer
                    class="elevation-1"
                    @click:row="chooseOpenSpot"
                  >
                    <template #[`item.volume`]="{ value, item }">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-chip
                            color="primary"
                            dark
                            small
                            v-on="on"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                        Planejado: {{ formatNumber(item.plannedVolume) }}
                      </v-tooltip>
                    </template>
                    <template #[`item.date`]="{ value }">
                      {{ formatDate(value, 'DD/MM/YYYY') }}
                    </template>
                  </v-data-table>
                </v-tab-item>

                <v-tab-item>
                  <div class="d-flex justify-space-between align-center">
                    <v-btn
                      icon
                      :disabled="filters.exitDate === filters.minDate"
                      @click="changeDate('exitDate', 'PREVIOUS')"
                    >
                      <v-icon>
                        arrow_back_ios
                      </v-icon>
                    </v-btn>
                    <div class="text-h6 dark-grey--text">
                      {{ formatDate(filters.exitDate, 'DD/MM/YYYY') }}
                    </div>
                    <v-btn
                      icon
                      :disabled="filters.exitDate === filters.maxDate"
                      @click="changeDate('exitDate', 'NEXT')"
                    >
                      <v-icon>
                        arrow_forward_ios
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-data-table
                    :headers="plannedSpots.headers"
                    :items="plannedSpotsExit"
                    disable-sort
                    hide-default-footer
                    class="elevation-1"
                    @click:row="chooseOpenSpot"
                  >
                    <template #[`item.volume`]="{ value, item }">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-chip
                            color="primary"
                            dark
                            small
                            v-on="on"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                        Planejado: {{ formatNumber(item.plannedVolume) }}
                      </v-tooltip>
                    </template>
                    <template #[`item.date`]="{ value }">
                      {{ formatDate(value, 'DD/MM/YYYY') }}
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs>
            </v-col>
            <v-col
              v-if="!onlyPlannedSpotAllowed"
              cols="12"
              class="d-flex justify-center"
            >
              <v-btn
                color="primary"
                outlined
                @click="hasOpenSpot = false"
              >
                Ou inicie um novo
              </v-btn>
            </v-col>
          </v-card-text>
        </v-row>

        <v-card-text
          v-else
        >
          <v-form
            ref="form"
            lazy-validation
            :disabled="form.itineraryId"
            @submit.prevent="saveNew"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0 d-flex"
              >
                <dairy-autocomplete-filter
                  v-if="type == 'transferencia'"
                  v-model="form.transferDairy"
                  label="Destino *"
                  hide-current-dairy
                  :hide-details="false"
                  :filled="false"
                  placeholder=" "
                  :rules="[rules.required]"
                />
                <company-autocomplete-filter
                  v-else-if="type == 'spot'"
                  v-model="form.company"
                  label="Empresa *"
                  :hide-details="false"
                  :filled="false"
                  placeholder=" "
                  :disabled="isPlannedSpot"
                  :rules="[rules.required]"
                />
                <div
                  v-if="type == 'spot'"
                  class="align-self-end text-end ml-4 mb-4"
                >
                  <v-switch
                    v-model="isThird"
                    label="Terceiro"
                    persistent-hint
                    inset
                    dense
                  />
                </div>
              </v-col>
              <v-col
                cols="6"
                class="pb-0"
              >
                <vehicle-autocomplete-filter
                  v-if="!isThird"
                  v-model="form.vehicle"
                  label="Veículo *"
                  cargo="leite"
                  placeholder=" "
                  :hide-details="false"
                  :filled="false"
                  :rules="[v => !!v && !!v.id || 'Informe o veículo']"
                />
                <v-text-field
                  v-else
                  v-model="form.vehicle.plate"
                  prepend-inner-icon="local_shipping"
                  label="Veículo *"
                  placeholder="Digite a placa do veículo"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="6"
                class="pb-0"
              >
                <person-autocomplete-filter
                  v-if="!isThird"
                  v-model="form.driver"
                  label="Motorista *"
                  type="DRIVER"
                  :hide-details="false"
                  :filled="false"
                  placeholder=" "
                  :rules="[v => !!v && !!v.id || 'Informe o motorista']"
                />
                <v-text-field
                  v-else
                  v-model="form.driver.name"
                  prepend-inner-icon="person"
                  label="Motorista *"
                  placeholder="Digite o nome do motorista"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="6"
                class="pb-0"
              >
                <masked-input
                  v-model="form.startedAt"
                  label="Início Itinerário *"
                  prepend-inner-icon="access_time"
                  placeholder=" "
                  :disabled="isPlannedSpot"
                  :mask="{ mask: '00/00/0000 00:00' }"
                  :rules="[rules.required, rules.dateTime]"
                  :max-length="16"
                />
              </v-col>

              <v-col
                cols="6"
                class="pb-0"
              >
                <raw-material-autocomplete
                  v-model="form.rawMaterialId"
                  label="Matéria Prima *"
                  placeholder=" "
                  :disabled="isPlannedSpot"
                  :rules="[rules.required]"
                />
              </v-col>

              <template v-if="type == 'spot'">
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.volume"
                    label="Volume *"
                    placeholder=" "
                    type="number"
                    :rules="volumeRules"
                    @keypress="disableDotAndComma"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="d-inline-flex justify-center align-center pb-0"
                >
                  <span class="pr-4">Entrada</span>
                  <v-switch
                    v-model="form.spotType"
                    inset
                    dense
                    false-value="ENTRADA"
                    true-value="SAIDA"
                    class="mt-0 py-0"
                    :readonly="spotTypeDisabled || isPlannedSpot"
                    @change="form.volume = null"
                  />
                  <span>Saída</span>
                </v-col>
              </template>
            </v-row>
          </v-form>
          <small>* Campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="() => show = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="!hasOpenSpot && !form.itineraryId"
            color="blue darken-1"
            text
            :disabled="loading || saving"
            :loading="saving"
            @click="saveNew"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        v-model="loading"
        absolute
      >
        Carregando...
      </v-overlay>

      <v-overlay
        v-model="saving"
        absolute
      >
        Salvando...
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import DairyAutocompleteFilter from '@/Domains/Itineraries/Components/DairyAutocompleteFilter.vue';
import CompanyAutocompleteFilter from '@/Domains/Itineraries/Components/CompanyAutocompleteFilter.vue';
import VehicleAutocompleteFilter from "@/Support/Components/Filters/VehicleAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue';
import MaskedInput from "@/Support/Components/MaskedInput.vue";

import _ from "lodash";
import moment from "moment";

export default {

  components: {
    DairyAutocompleteFilter,
    CompanyAutocompleteFilter,
    VehicleAutocompleteFilter,
    PersonAutocompleteFilter,
    RawMaterialAutocomplete,
    MaskedInput
  },

  props: {
    value: {
      type: Boolean,
    },
    label: {
      type: String,
      default: 'Itinerário'
    },
    prependInnerIcon: {
      type: String,
      default: 'add_location_alt'
    },
    type: {
      type: String,
      default: 'transferencia',
      validator: (value) => ['transferencia', 'spot'].includes(value)
    },
    spotTypeDisabled: Boolean
  },

  data() {
    return {
      loading: false,

      saving: false,

      input: null,

      hasOpenSpot: false,

      isThird: false,

      filters: {
        minDate: moment().subtract(5, 'day').format('YYYY-MM-DD'),
        maxDate: moment().add(5, 'day').format('YYYY-MM-DD'),
        entranceDate: moment().format('YYYY-MM-DD'),
        exitDate: moment().format('YYYY-MM-DD'),
      },

      form: {
        transferDairy: {},
        company: {},
        vehicle: {},
        driver: {},
        spotType: 'SAIDA',
        volume: null,
        rawMaterialId: null,
      },

      rawProducts: [],

      plannedSpots: {
        headers: [
          { text: 'Empresa', value: 'company.name' },
          { text: 'Matéria Prima', value: 'rawProduct.name' },
          { text: 'Volume', value: 'volume' },
          { text: 'Itinerário', value: 'code' },
        ],
        items: [],
      },

      rules: {
        required: v => !!v || !_.isEmpty(v) || 'Campo obrigatório!',
        dateTime: v => !v || (v.length == 16 && moment(v, 'DD/MM/YYYY HH:mm').isValid()) || 'Data Inválida!'
      },
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$refs.form && this.$refs.form.resetValidation();
          this.form = {
            transferDairy: {},
            company: {},
            vehicle: {},
            driver: {},
            spotType: 'SAIDA',
            volume: null,
            rawMaterialId: null,
            startedAt: null,
          };
        }
        this.$emit("input", value);
      },
    },

    onlyPlannedSpotAllowed() {
      return this.$store.state.settings.gerais.permite_apenas_spot_planejado;
    },

    isPlannedSpot() {
      return !!this.form.spotId;
    },

    plannedSpotsEntrance() {
      return this.plannedSpots.items.filter(spot => spot.type === 'ENTRADA' && spot.date === this.filters.entranceDate)
    },

    plannedSpotsExit() {
      return this.plannedSpots.items.filter(spot => spot.type === 'SAIDA' && spot.date === this.filters.exitDate)
    },

    volumeRules() {
      if (this.form.spotType === 'SAIDA') {
        return [];
      }

      if (this.isPlannedSpot && this.form.availableVol) {
        return [
          this.rules.required,
          //(v) => v <= this.form.availableVol || `Volume maior que o planejado: ${this.form.availableVol} L`
        ]
      }

      return [this.rules.required];
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.form.startedAt = moment().format('DD/MM/YYYY HH:mm');
        this.load();
      }
    }
  },

  methods: {

    async load() {
      if (this.type == 'spot') {
        this.hasOpenSpot = true;
        this.isThird = true;
        await this.loadRawProductsList();
        await this.loadPlannedSpots();
      }
    },

    async saveNew() {
      if (this.form.itineraryId) {
        return;
      }
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      try {

        const payload = {
          tipo: this.type,
          id_itinerario: this.form.itineraryId,
          id_equipamento: this.form.vehicle.id,
          placa: this.form.vehicle.plate,
          veiculo: this.form.vehicle.description,
          id_pessoa: this.form.driver.id || null,
          nome_pessoa: this.form.driver.name || null,
          data_hora_inicio: this.form.startedAt ? moment(this.form.startedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
          data_hora_fim: this.form.startedAt ? moment(this.form.startedAt, 'DD/MM/YYYY HH:mm').add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss') : moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
          id_materia_prima: this.form.rawMaterialId,
          km_inicial: 0,
          km_final: 1,
        };

        if (this.type == 'transferencia') {
          payload.id_laticinio_transferencia = this.form.transferDairy.id;
          payload.nome_laticinio_transferencia = this.form.transferDairy.name;
        }

        if (this.type == 'spot') {
          payload.id_spot = this.form.spotId;
          payload.id_empresa = this.form.company.id;
          payload.nome_empresa = this.form.company.name;
          payload.tipo_spot = this.form.spotType;
          payload.volume_total = this.form.volume;
        }

        const { data } = await this.$axios.post(
          `/itinerario/salva`,
          this.$qs.stringify(payload),
        );

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.$emit("newItinerary", { id: data.id_itinerario, ...this.form });

        this.show = false;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar o itinerário!", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async loadRawProductsList() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: 'TODOS'
        } });

        this.rawProducts = data.map(rawProduct => {
          return {
            id: rawProduct.id_item,
            name: rawProduct.nome,
            type: rawProduct.tipo,
          };
        });
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as matérias primas!",
          "Atenção"
        );
        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    async loadPlannedSpots() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/spot/spotsEmAberto`);

        this.plannedSpots.items = data.map(item => {
          const rawProduct = this.rawProducts.find(rawProduct => rawProduct.id === item.id_materia_prima);

          return {
            id: item.id_spot,
            itineraryId: item.id_itinerario,
            code: item.cod_itinerario,
            type: item.tipo,
            company: {
              id: item.id_empresa,
              name: item.nome_empresa,
            },
            vehicle: {
              id: item.id_equipamento,
              description: item.equipamento,
              plate: item.placa
            },
            driver: {
              id: item.id_motorista,
              name: item.nome_motorista
            },
            rawProduct,
            plannedVolume: parseFloat(item.volume_planejado),
            volume: parseFloat(item.volume),
            date: item.data
          }
        });

        this.hasOpenSpot = this.plannedSpots.items.length > 0 || this.onlyPlannedSpotAllowed;
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os spots!",
          "Atenção"
        );
        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    chooseOpenSpot(item) {
      this.form = {
        spotId: item.id,
        itineraryId: item.itineraryId,
        transferDairy: {},
        company: { ...item.company },
        vehicle: { ...item.vehicle },
        driver: { ...item.driver },
        spotType: item.type,
        availableVol: item.volume,
        volume: item.volume,
        rawMaterialId: item.rawProduct.id,
        startedAt: moment().format('DD/MM/YYYY HH:mm'),
      };
      this.hasOpenSpot = false;
      this.isThird = !item.vehicle.id && !item.driver.id;
    },

    changeDate(field, type) {
      this.filters[field] = type === 'NEXT'
        ? moment(this.filters[field]).add(1, 'day').format('YYYY-MM-DD')
        : moment(this.filters[field]).subtract(1, 'day').format('YYYY-MM-DD');
    },

    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
    formatNumber: (value) => value ? new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value) : '-',
    formatDate: (value, format) => value ? moment(value).format(format) : '-'
  },
};
</script>
