<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="4">
        <v-card
          dark
          color="transparent diff-list"
        >
          <v-card-title>
            <span class="ml-4 font-weight-medium">Dev</span>
          </v-card-title>

          <v-card-text class="pa-4">
            <div class="d-flex">
              <v-btn
                color="green"
                :loading="buttonsLoading.develop_activate"
                :disabled="environmentActive.develop"
                @click="run('develop', 'activate')"
              >
                Ligar
              </v-btn>

              <v-spacer />

              <v-btn
                color="red"
                :loading="buttonsLoading.develop_deactivate"
                :disabled="!environmentActive.develop"
                @click="run('develop', 'deactivate')"
              >
                Desligar
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card
          dark
          color="transparent diff-list"
        >
          <v-card-title>
            <span class="ml-4 font-weight-medium">Staging</span>
          </v-card-title>

          <v-card-text class="pa-4">
            <div class="d-flex">
              <v-btn
                color="green"
                :loading="buttonsLoading.staging_activate"
                :disabled="environmentActive.staging"
                @click="run('staging', 'activate')"
              >
                Ligar
              </v-btn>

              <v-spacer />

              <v-btn
                color="red"
                :loading="buttonsLoading.staging_deactivate"
                :disabled="!environmentActive.staging"
                @click="run('staging', 'deactivate')"
              >
                Desligar
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card
          dark
          color="transparent diff-list"
        >
          <v-card-title>
            <span class="ml-4 font-weight-medium">Demo</span>
          </v-card-title>

          <v-card-text class="pa-4">
            <div class="d-flex">
              <v-btn
                color="green"
                :loading="buttonsLoading.demo_activate"
                :disabled="environmentActive.demo"
                @click="run('demo', 'activate')"
              >
                Ligar
              </v-btn>

              <v-spacer />

              <v-btn
                color="red"
                :loading="buttonsLoading.demo_deactivate"
                :disabled="!environmentActive.demo"
                @click="run('demo', 'deactivate')"
              >
                Desligar
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card
      v-if="jobsList.length > 0"
      dark
      color="transparent diff-list"
      style="margin-top: 20px"
    >
      <v-card-title>
        <span class="ml-4 font-weight-medium">Tarefas</span>
      </v-card-title>

      <data-table
        dark
        dense
        :headers="jobsHeader"
        :items="jobsList"
        group-by="namespace"
        disable-drag
        disable-sort
        disable-pagination
        hide-default-footer
      >
        <template #[`item.run`]="{ item }">
          <v-btn
            small
            class="ma-2"
            @click="run(item)"
          >
            Executar
          </v-btn>
        </template>
      </data-table>
    </v-card>

    <v-card
      dark
      color="transparent diff-list"
      style="margin-top:20px"
    >
      <v-card-title>
        <span class="ml-4 font-weight-medium">Ambientes</span>
      </v-card-title>

      <data-table
        dark
        dense
        :headers="deploymentsHeader"
        :items="deploymentsList"
        group-by="namespace"
        disable-drag
        disable-sort
        disable-pagination
        hide-default-footer
      >
        <template #[`item.readyReplicas`]="{ value }">
          <v-chip
            v-if="value > 0"
            small
            color="green"
            class="ma-2"
          >
            Ativo
          </v-chip>
          <v-chip
            v-else
            small
            color="red"
            class="ma-2"
          >
            Inativo
          </v-chip>
        </template>
      </data-table>
    </v-card>

    <v-overlay
      v-model="loading"
    >
      Carregando...
    </v-overlay>

    <v-btn
      fab
      dark
      color="blue"
      fixed
      right
      bottom
      @click="load()"
    >
      <v-tooltip left>
        <template #activator="{ on }">
          <v-icon v-on="on">
            refresh
          </v-icon>
        </template>

        Recarregar
      </v-tooltip>
    </v-btn>
  </v-container>
</template>

<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

export default {
  data() {
    return {
      loading: false,

      deploymentsList: [],

      jobsList: [],

      jobsHeader: [
        {
          text: "Ambiente",
          value: "namespace",
        },
        {
          text: "Nome",
          value: "name",
        },
        {
          text: '',
          value: 'run',
          align: 'end',
        },
      ],

      deploymentsHeader: [
        {
          text: "Ambiente",
          value: "namespace",
        },
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "",
          value: "readyReplicas",
          align: 'end',
        },
      ],

      buttonsLoading: {
        staging_activate: false,
        staging_deactivate: false,
        develop_activate: false,
        develop_deactivate: false,
        demo_activate: false,
        demo_deactivate: false,
      },

    };
  },

  computed: {
    environmentActive() {
      const namespaceActives = mapValues(groupBy(this.deploymentsList, 'namespace'), (values) => {
        return every(values, ({ readyReplicas }) => readyReplicas);
      });

      if (isEmpty(namespaceActives)) {
        return {
          develop: false,
          staging: false,
        }
      }

      return namespaceActives;
    }
  },

  async mounted() {
    await this.load();
    this.setRefresh();
  },

  methods: {

    async load() {
      try {
        this.loading = true;

        this.fetchLoadings();

        const { data } = await this.$axios.get(`/settings/eks-api`);

        const { jobs = [], deployments = [] } = data;

        this.jobsList = jobs;
        this.deploymentsList = deployments;
      } catch (error) {
        console.error(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de deployments!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async run(namespace, action) {
      try {
        this.loading = true;

        localStorage.setItem(`${namespace}_${action}`, moment().format('YYYY-MM-DD HH:mm:ss'));

        this.fetchLoadings();

        await this.$axios.post(`/settings/eks-api/run`, {
          namespace,
          action
        });
      } catch (error) {
        console.error(error);

        this.$snotify.error("Oops, ocorreu um erro ao executar o job!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    setRefresh() {
      setInterval(this.load, 30000);
    },

    fetchLoadings() {
      this.buttonsLoading = mapValues(this.buttonsLoading, (_, key) => {
        const lastBtnPress = localStorage.getItem(key);

        return moment().diff(moment(lastBtnPress), 'minutes') <= 2;
      });
    }
  },
}
</script>
