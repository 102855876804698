<template>
  <div>
    <v-row no-gutters>
      <v-col
        class="py-2"
        cols="12"
      >
        <card-title-settings class="mb-3">
          Parâmetros da Indústria
        </card-title-settings>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-form
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <masked-text-field
                    v-model="sif"
                    label="SIF/DIPOA"
                    :mask="{ mask: '0000/0000' }"
                    hint="Registro no Ministério da Agricultura"
                    placeholder="####/####"
                    :rules="[() => !sif || sif.length === 9 || 'Campo inválido']"
                    validate-on-blur
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <masked-text-field
                    v-model="gs1"
                    label="Prefixo GS1 da Empresa"
                    :mask="{ mask: Number, scale: 0, signed: false }"
                    unmask
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <masked-text-field
                    v-model="sscc_digito_ext"
                    label="Dígito de Extensão (SSCC)"
                    :mask="{ mask: '0' }"
                    unmask
                    placeholder="0 à 9"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="save()"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>
  </div>
</template>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import CardTitleSettings from '@/Domains/Settings/Components/General/CardTitleSettings.vue'

export default {
  components: {
    MaskedTextField,
    CardTitleSettings,
  },

  data() {
    return {
      sif: null,
      gs1: null,
      sscc_digito_ext: null,
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      const settings = this.$store.state.settings.gerais;

      this.sif = settings?.sif;
      this.gs1 = settings?.gs1;
      this.sscc_digito_ext = settings?.sscc_digito_ext;
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        const dairyId = this.$store.state.settings?.laticinio?.id;

        if (!dairyId) {
          this.$snotify.error('Escolha o laticínio!', 'Atenção');
          return;
        }

        this.$root.$progressBar.saving();

        await this.$axios.put(`/settings/dairy/${dairyId}`, {
          sif: this.sif,
          gs1: this.gs1,
          sscc_digito_ext: this.sscc_digito_ext,
        });

        this.$store.dispatch('updateStoreSetting');
        this.$snotify.success('Configuração salva com sucesso!', 'Sucesso');
        this.show = false;
      } catch (error) {
        const message = error?.response?.data?.message || 'Erro ao salvar configuração'
        this.$snotify.error(message);
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
  },
};
</script>
