<template>
  <v-autocomplete
    v-model="input"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    item-value="id"
    item-text="description"
    :return-object="returnObject"
    :filter="() => true"
    hide-no-data
    v-bind="$attrs"
    @change="onChange"
  >
    <template #item="{ item }">
      <span
        v-if="item.code"
        class="mx-1"
      >
        {{ item.code }} -
      </span>
      {{ item.name }}
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce, isString } from 'lodash';

export default {
  name: 'item-autocomplete-filter',

  props: {
    value: {
      type: [ String, Object, Array ],
    },

    types: {
      type: Array,
      default: () => [ 'PRODUTO_ACABADO' ]
    },

    showOtherMeasurements: Boolean,
    returnObject: Boolean,
  },

  data() {
    return {
      input: null,
      loading: false,
      items: [],
      search: ''
    }
  },

  watch: {
    value() {
      this.input = this.value;
      if (this.value && this.value.name) {
        this.queryProducts(this.value.name);
      }
    },

    search(val) {
      if (val && val !== this.input) {
        this.loading = true;
        this.queryProducts(val);
      }
    },
  },

  created() {
    this.input = this.value;
  },

  mounted() {
    if (this.value) {
      if (isString(this.value)) {
        this.loadProductById(this.value);
      } else if (this.value.name) {
        this.queryProducts(this.value.name);
      }
    }
  },

  methods: {
    queryProducts: debounce(async function(busca) {
      try {
        if (!busca || this.items.find(item => item.description.includes(busca))) {
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          exibir_outras_unidades: this.showOtherMeasurements ? 1 : 0,
          tipos: this.types,
          busca
        } });

        this.items = data.map(o => ({
          id: o.id_item,
          itemId: o.id_item,
          measurementId: null,
          tareWeight1: null,
          grossWeight: o.peso_bruto,
          tareWeight2: o.peso_tara,
          code: o.cod_item,
          name: o.nome.trim(),
          barcode: o.codigo_barras,
          defaultBarcode: o.codigo_barras,
          description: `${o.cod_item} - ${o.nome.trim()}`,
          type: o.tipo,
          measurement: o.unidade,
          defaultMeasurement: o.unidade,
          conversionFactor: 1,
          price: o.valor ? parseFloat(o.valor) : null,
          requiresLot: o.controlar_lotes || false,
          manualLot: o.lote_manual || false,
          lotFormat: o.formato_lote,
          stockControl: o.controle_estoque,
          group: {
            value: o.id_item_grupo,
            text: o.nome_item_grupo,
          },
        }));

        if (this.showOtherMeasurements) {
          const others = data
            .filter(o => o.outras_unidades?.length > 0)
            .flatMap(o => o.outras_unidades.map(other => ({
              id: other.id,
              itemId: o.id_item,
              measurementId: other.id,

              code: other.codigo || o.cod_item,
              name: `${o.nome.trim()} (${other.descricao})`,
              defaultBarcode: o.codigo_barras,
              barcode: other.codigo_barras || o.codigo_barras,
              description: `${other.codigo || o.cod_item} - ${o.nome.trim()} (${other.descricao})`,
              conversionFactor: other.fator_conversao,
              measurement: other.unidade_medida,
              measurementDescription: other.descricao,

              defaultMeasurement: o.unidade,
              grossWeight: ((o.peso_bruto * other.fator_conversao) + (other.peso_tara || 0)) || null,
              tareWeight1: other.peso_tara,
              tareWeight2: (o.peso_tara * other.fator_conversao) || null,

              type: o.tipo,
              price: o.valor ? parseFloat(o.valor) : null,
              requiresLot: o.controlar_lotes || false,
              manualLot: o.lote_manual || false,
              lotFormat: o.formato_lote,
              stockControl: o.controle_estoque,
              group: {
                value: o.id_item_grupo,
                text: o.nome_item_grupo,
              },
            })))
          this.items.push(...others)
        }

      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os produtos!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    }, 1000),

    async loadProductById(id) {
      try {
        const { data } = await this.$axios.get(`/item/${id}`);

        this.items = [
          {
            id: data.id_item,
            code: data.cod_item,
            name: data.nome.trim(),
            description: `${data.cod_item} - ${data.nome.trim()}`,
            type: data.tipo,
            measurement: data.unidade,
            price: data.valor ? parseFloat(data.valor) : null,
            group: {
              value: data.id_item_grupo,
              text: data.nome_item_grupo,
            },
          }
        ];
      } catch (error) {
        console.warn(error);
      }
    },

    onChange(event) {
      // atualiza o v-model do componente
      this.$emit("input", event || {});

      this.$emit("change", event);
    },
  }
}
</script>
