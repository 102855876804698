<template>
  <div>
    <v-row
      v-for="(data, idx) of byproducts"
      :key="idx"
      style="border-bottom: 1px #eee solid;"
    >
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        class="d-flex flex-column justify-center"
        style="max-width: 49px;"
      >
        <v-icon>
          reorder
        </v-icon>
      </v-col>
      <v-col class="pt-1 pb-0">
        <v-text-field
          :value="data.item ? data.item.description : null"
          label="Matéria Prima"
          disabled
        />
      </v-col>
      <v-col
        v-if="hasInputsAccess && !$vuetify.breakpoint.mobile"
        class="pt-1 pb-0"
        cols="6"
        sm="3"
        md
      >
        <masked-text-field
          :value="data.quantity"
          label="Qtde. Base"
          unmask
          :mask="masks.float"
          suffix="LT"
          disabled
        />
      </v-col>
      <v-col
        v-if="hasPlannedQuantityAccess"
        class="pt-1 pb-0"
        cols="6"
        sm="3"
        md
      >
        <masked-text-field
          :value="data.plannedQuantity"
          label="Qtde. Planejada"
          unmask
          :mask="masks.float"
          suffix="LT"
          disabled
        />
      </v-col>
      <v-col
        v-if="hasInputsAccess"
        class="pt-1 pb-0"
        cols="6"
        sm="3"
        md
      >
        <masked-text-field
          :value="data.accomplishedQuantity"
          label="Qtde. Realizada"
          unmask
          :mask="masks.float"
          suffix="LT"
          disabled
        />
      </v-col>
    </v-row>

    <template v-if="canEdit">
      <span class="text-h6 d-block mt-4">Silos</span>

      <v-divider class="mb-4" />

      <v-row>
        <v-col
          v-for="silo in availableSilos"
          :key="silo.label"
          style="max-width:220px"
        >
          <silo-card
            :silo="silo"
            :disabled="!canEdit"
            @click="onSiloClick(silo)"
          />
        </v-col>
        <v-col
          v-if="availableRawMaterials.length === 0"
          cols="12"
          class="text-center"
        >
          Nenhum silo disponível para efetuar entradas
        </v-col>
      </v-row>
    </template>

    <span class="text-h6 d-block mt-4">Registros</span>

    <v-divider class="mb-4" />

    <v-data-table
      :headers="headers"
      :items="transfers"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
    >
      <template #[`item.transferredVol`]="{ value }">
        {{ formatNumber(value) }} L
      </template>

      <template #[`item.transferredAt`]="{ value }">
        <v-chip x-small>
          {{ formatDate(value, 'DD/MM/YYYY') }}
        </v-chip>
      </template>

      <template #[`item.rawMaterial.description`]="{ value }">
        <v-chip
          v-if="value"
          x-small
        >
          {{ value }}
        </v-chip>
      </template>

      <template #[`item.action`]="{ item }">
        <v-tooltip
          v-if="hasChargebackAccess"
          top
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click.stop="removeTransfer(item)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
          Estornar
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import SiloCard from "@/Domains/Industry/ProductionOrder/Components/SiloCard.vue";

import moment from "moment";

export default {

  components: {
    MaskedTextField,
    SiloCard
  },

  props: {
    canEdit: Boolean,
    order: {
      type: Object,
      default: () => ({
        byproducts: [],
        transfers: [],
      })
    },
    step: Object,
  },

  data() {
    return {
      headers: [
        { text: 'Silo', value: 'silo.description', align: 'start' },
        { text: 'Entrada', value: 'transferredAt' },
        { text: 'Volume', value: 'transferredVol' },
        { text: 'Nº Lote', value: 'batchNumber' },
        { text: 'Matéria Prima', value: 'rawMaterial.description' },
        { text: '', value: 'action', width: 30, sortable: false },
      ],

      byproducts: [],
      availableRawMaterials: [],

      silos: [],

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },
    }
  },

  computed: {
    availableSilos() {
      if (this.availableRawMaterials.length === 0) {
        return [];
      }

      return this.silos.filter(silo => !silo.rawProduct.id || this.availableRawMaterials.some(({ item }) => item.id === silo.rawProduct.id));
    },

    transfers() {
      return this.order.transfers.filter(transfer => transfer.type === 'ENTRADA_PRODUCAO' && this.availableRawMaterials.some(({ item }) => item.id === transfer.rawMaterial.id));
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasInputsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-inputs-apontamento' && o.tipo === 'COMPONENTE');
    },
    hasChargebackAccess() {
      if (!this.canEdit) {
        return false
      }
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-exclusao-apontamento' && o.tipo === 'COMPONENTE');
    },
  },

  async mounted() {
    if (this.canEdit) {
      await this.loadSilos();
    }

    this.loadData();
  },

  methods: {
    loadData() {
      this.byproducts = this.order.byproducts
        .filter(data => data.item.isRawMaterial)
        .map(item => {
          const plannedQuantity = item.quantity * this.order.quantity;
          const writeOffQuantity = plannedQuantity - item.accomplishedQuantity;
          return {
            ...item,
            plannedQuantity,
            writeOffQuantity: (this.hasInputsAccess && writeOffQuantity > 0) ? writeOffQuantity : null,
          }
        });

      if (this.step) {
        this.byproducts = this.byproducts
          .filter(item => !item.step || item.step.id === this.step.id)
      }

      this.availableRawMaterials = this.byproducts;
    },

    async loadSilos() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/silo/principal`);

        this.silos = data.silo.map(silo => ({
          index: (silo.descricao || '').replace(/\D/g, ''),
          id: silo.id_silo,
          label: silo.descricao,
          batchNumber: silo.numero_lote,
          vol: parseInt(silo.volume_atual),
          capacity: parseInt(silo.volume_total),
          rawProduct: {
            id: silo.id_materia_prima,
            name: silo.nome_materia_prima,
          },
        })).sort((a, b) => a.index - b.index);
      } catch (e) {
        this.$snotify.error("Erro ao carregar silos", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onSiloClick(silo) {
      return this.$emit('onByproductTransferring', {
        orderId: this.order.id,
        silo,
        rawMaterials: this.availableRawMaterials.map(item => {
          const rawMaterial = item.item;
          const byproduct = this.byproducts.find(byproduct => byproduct.item?.id === rawMaterial.id);
          return {
            ...rawMaterial,
            range: byproduct?.range
          }
        })
      });
    },

    async removeTransfer(item) {
      if (!this.canEdit) {
        return;
      }

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente estornar esta movimentação?<br><br>`, { color: 'red', token: 'ESTORNAR' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.post(`/industry/silo/chargeback-movement`, {
          id: item.movementId,
        });

        this.$snotify.success('Estorno efetuado com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao estornar!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.$emit('close');
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
  }

}
</script>
