<template>
  <div>
    <v-col
      class="px-0"
    >
      <card-title-settings>
        Configurações Compartilhadas (Coleta/Visita)
      </card-title-settings>
    </v-col>
    <v-card
      dark
      outlined
      color="rgba(255, 255, 255, 0.08)"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="4"
          >
            <v-select
              v-model="coleta_amostras_foto"
              :items="rules"
              label="Foto bula/requerimento de análise"
              dense
            />
          </v-col>

          <v-col
            cols="4"
          >
            <v-select
              v-model="coleta_amostras_marca"
              :items="rules"
              label="Marca do Antibiótico/remédio"
              dense
            />
          </v-col>

          <v-col
            cols="4"
          >
            <v-select
              v-model="coleta_amostras_principio_ativo"
              :items="rules"
              label="Grupo de Antibiótico"
              dense
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="tipos_coleta_amostra"
              :items="defaultSampleCollectTypes"
              label="Tipos de coletas de amostras"
              dense
              multiple
              chips
              deletable-chips
              clearable
            />
          </v-col>

          <v-col cols="12">
            <v-combobox
              v-model="marcas_antibiotico_amostra"
              label="Marcas de Antibiótico"
              multiple
              chips
              deletable-chips
              clearable
              dense
            />
          </v-col>

          <v-col cols="12">
            <v-combobox
              v-model="antibiotico_principio_ativo"
              label="Grupos de Antibiótico"
              multiple
              chips
              deletable-chips
              clearable
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-if="isMilkUpUser">
      <v-col
        cols="12"
        class="px-0"
      >
        <card-title-settings>
          Rastreamento
        </card-title-settings>
      </v-col>
      <v-card
        dark
        outlined
        color="rgba(255, 255, 255, 0.08)"
      >
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="prioridade_localizacao"
                :items="[
                  { value: 'HIGH_ACCURACY', text: 'ALTA PRECISÃO (HIGH_ACCURACY)' },
                  { value: 'BALANCED_POWER_ACCURACY', text: 'BALANCEADO (BALANCED_POWER_ACCURACY)' },
                  { value: 'LOW_POWER', text: 'BAIXA PRECISÃO (LOW_POWER)' },
                  { value: 'PASSIVE', text: 'PASSIVO (PASSIVE)' },
                ]"
                label="Prioridade localização"
                dense
                :hint="priorityHint"
                persistent-hint
              />
            </v-col>

            <v-col cols="4">
              <v-text-field
                v-model="distancia_minima_localizacao"
                label="Distância mínima para atualização de coordenadas"
                type="number"
                suffix="metros"
                dense
              />
            </v-col>

            <v-col cols="4">
              <v-select
                v-model="segundos_intervalo_localizacao"
                label="Intervalo entre atualização de coordenadas"
                :items="[
                  { value: 10, text: '10 segundos' },
                  { value: 30, text: '30 segundos' },
                  { value: 60, text: '1 minuto' },
                  { value: 120, text: '2 minutos' },
                ]"
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <v-col
      cols="12"
      class="px-0"
    >
      <card-title-settings>
        Notificações
      </card-title-settings>
    </v-col>
    <v-card
      dark
      outlined
      color="rgba(255, 255, 255, 0.08)"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <v-select
              v-model="notificacoes.tipos_notificacoes"
              :items="notificationTypesList"
              dense
              multiple
              label="Tipos de notificações"
              append-icon
              chips
              deletable-chips
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-text-field
              v-model="notificacoes.temperatura_maxima"
              label="Temperatura máx. de coleta"
              type="number"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="saveQualitySettings();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações do Aplicativo</span>
    </v-tooltip>

    <v-overlay v-model="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

import _ from "lodash";

export default {
  components: {
    CardTitleSettings
  },

  data() {
    return {
      loading: false,

      coleta_amostras_foto: null,
      coleta_amostras_marca: null,
      coleta_amostras_principio_ativo: null,

      tipos_coleta_amostra: null,
      marcas_antibiotico_amostra: null,
      antibiotico_principio_ativo: null,

      defaultSampleCollectTypes: ['ACIDEZ', 'ALIZAROL', 'ANTIBIOTICOS', 'BRUCELOSE', 'CCS', 'CPP', 'CRIOSCOPIA', 'GORDURA'],

      rules: [
        { text: 'Permitido', value: 'PERMITIDO' },
        { text: 'Obrigatório', value: 'OBRIGATORIO' },
        { text: 'Inativo', value: 'NAO_PERMITIDO' },
      ],

      prioridade_localizacao: 'HIGH_ACCURACY',
      distancia_minima_localizacao: 5,
      segundos_intervalo_localizacao: 10,

      notificacoes: {
        tipos_notificacoes: [],
        temperatura_maxima: 4,
      },

      notificationTypesList: [
        { value: 'INCONFORMIDADE_ANALISE', text: 'INCONFORMIDADE DE ANÁLISE' },
        { value: 'RESULTADO_ANTIBIOTICO', text: 'RESULTADO DE ANTIBIÓTICO (PRODUTOR)' },
        { value: 'RESULTADO_ANTIBIOTICO_TECNICO', text: 'RESULTADO DE ANTIBIÓTICO (TÉCNICOS)' },
        { value: 'RESULTADO_ANTIBIOTICO_MOTORISTA', text: 'RESULTADO DE ANTIBIÓTICO (MOTORISTAS)' },
        { value: 'COLETA_REJEITADA', text: 'COLETA REJEITADA' },
        { value: 'COLETA_TEMPERATURA', text: 'TEMPERATURA MÁX. COLETA' },
        { value: 'IMPEDIMENTO_COLETA', text: 'IMPEDIMENTO DE COLETA' },
      ]
    };
  },

  computed: {
    priorityHint() {
      const hints = {
        'HIGH_ACCURACY': 'Requisições de localizações altamente precisas, às custas possíveis do uso adicional de energia.',
        'BALANCED_POWER_ACCURACY': 'Requisições de localização equilibrada entre a precisão da localização e o uso de energia.',
        'LOW_POWER': 'Requisições de localização que favorece o baixo uso de energia em detrimento da precisão da localização.',
        'PASSIVE': 'Nenhuma energia extra será usada. Atuará passivamente e receberá apenas localizações solicitadas por outros aplicativos.',
      }
      return hints[this.prioridade_localizacao] || ''
    },

    userProfile() {
      return this.$store.state.settings.user || {};
    },

    isMilkUpUser() {
      return this.userProfile.system === 'milkup';
    }
  },

  mounted() {
    this.loadQualitySettings();
  },

  methods: {
    /**
     * carregar configuracoes do APP
     */
    async loadQualitySettings() {
      try {
        const { data } = await this.$axios.post(`/configuracao/listaConfiguracoesLaticinio`);

        if (_.isEmpty(data)) {
          return false;
        }

        this.handleData(data);

      } catch (e) {
        console.error(e);
      }
    },

    handleData(data) {
      this.prioridade_localizacao = data.prioridade_localizacao || 'HIGH_ACCURACY';
      this.distancia_minima_localizacao = data.distancia_minima_localizacao || 5;
      this.segundos_intervalo_localizacao = data.segundos_intervalo_localizacao || 10;
      this.notificacoes = { ...this.notificacoes, ...data.notificacoes };
      this.tipos_coleta_amostra = data.tipos_coleta_amostra;
      this.coleta_amostras_foto = data.coleta_amostras_foto;
      this.coleta_amostras_marca = data.coleta_amostras_marca;
      this.coleta_amostras_principio_ativo = data.coleta_amostras_principio_ativo;
      this.marcas_antibiotico_amostra = data.marcas_antibiotico_amostra;
      this.antibiotico_principio_ativo = data.antibiotico_principio_ativo;
    },

    async saveQualitySettings() {

      this.loading = true;

      try {
        const payLoad = {
          configuracaoQualidade: JSON.stringify([
            {
              prioridade_localizacao: this.prioridade_localizacao,
              distancia_minima_localizacao: parseFloat(this.distancia_minima_localizacao),
              segundos_intervalo_localizacao: parseInt(this.segundos_intervalo_localizacao),
              notificacoes: this.notificacoes,
              tipos_coleta_amostra: this.tipos_coleta_amostra,
              coleta_amostras_foto: this.coleta_amostras_foto,
              coleta_amostras_marca: this.coleta_amostras_marca,
              coleta_amostras_principio_ativo: this.coleta_amostras_principio_ativo,
              marcas_antibiotico_amostra: this.marcas_antibiotico_amostra,
              antibiotico_principio_ativo: this.antibiotico_principio_ativo,
            },
          ]),
        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          this.$qs.stringify(payLoad)
        );

        data = _.head(data);

        if (_.get(data, 'codigo') !== 1) {
          throw new Error(data);
        }

        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.warn(error);
      } finally {
        this.$store.dispatch('updateStoreSetting');
        this.loading = false;
      }
    },
  },
};
</script>
