<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :search="filter.search"
              dark
              class="elevation-1 report-table"
            >
              <template #[`item.date`]="{ value }">
                {{ dateFormat(value, 'DD/MM/YYYY HH:mm') }}
              </template>
              <template #[`item.version`]="{ value }">
                <v-chip
                  dark
                >
                  {{ value }}
                </v-chip>
              </template>
              <template #[`item.newVersion`]="{ item }">
                <v-text-field
                  v-model="item.newVersion"
                  label="Nova versão"
                  outlined
                  dense
                  hide-details
                />
              </template>
            </v-data-table>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip
      v-if="showSaveBtn"
      left
    >
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green"
          v-on="on"
          @click="save()"
        >
          <v-icon>send</v-icon>
        </v-btn>
      </template>

      <span>Salvar em lote</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

export default {

  data() {
    return {
      loading: false,

      filter: {
        search: '',
      },

      headers: [
        { text: 'Formulário/Relatório', value: 'title' },
        { text: 'Data da versão', value: 'date' },
        { text: 'Versão', value: 'version' },
        { text: '', value: 'newVersion', width: 250, sortable: false },
      ],
      items: [],
    };
  },

  computed: {
    showSaveBtn() {
      return this.items.some(o => !!o.newVersion && o.newVersion.trim().length > 0)
    }
  },

  mounted() {
    this.loadForms();
  },

  methods: {
    async loadForms() {
      try {
        this.loading = true;

        let { data } = await this.$axios.get(`/relatorios/formulariosRastreabilidade`);

        if (_.isString(data)) {
          throw data;
        }

        this.items = data.map(item => {
          return {
            form: item.formulario,
            title: item.titulo,
            date: item.data_versao,
            version: item.versao
          };
        });
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      const items = this.items
        .flatMap(({ form, newVersion }) => !!newVersion && newVersion.trim().length > 0 ? [{ formulario: form, versao: newVersion }] : []);
        
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/relatorios/salvaVersaoFormularios`, { items });

        if (!data || data.codigo != 1) {
          throw data;
        }
        this.$snotify.success("Versões atualizadas com sucesso", "Sucesso");
        this.loadForms();
      }
      catch (err) {
        this.$snotify.error("Erro ao atualizar as versões", "Atenção");
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    dateFormat: (value, format) => (!value ? "-" : moment(value).format(format)),
  },
};
</script>
