<template>
  <div
    id="mapContainer"
    ref="container"
    :style="`height: ${height}`"
  >
    <GmapMap
      ref="routeMap"
      :center="center"
      :zoom="10"
      map-type-id="roadmap"
      :style="`height: ${height}`"
      :clickable="clickable"
      :options="{ fullscreenControl: !disableFullscreen }"
      @click="onSelectPosition"
    >
      <div v-if="useSimpleMarker">
        <SimpleMarker
          v-for="(marker, idx) in waypoints"
          ref="markers"
          :key="marker.key"
          :location="marker.location"
          :draggable="draggable && marker.draggable"
          :visible="marker.visible"
          :color="marker.color"
          :meta="marker.meta"
          :rich-meta="marker.richMeta"
          :title="marker.title"
          :label="marker.label"
          :icon="marker.icon"
          :marker-data="marker"
          :z-index="idx"
          @onPositionChange="onPositionChange"
          @click:marker="$emit('click:marker', $event)"
        />
      </div>
      <div v-else>
        <RichMarker
          v-for="marker in waypoints"
          ref="markers"
          :key="marker.key"
          :location="marker.location"
          :draggable="draggable && marker.draggable"
          :visible="marker.visible"
          :color="marker.color"
          :meta="marker.meta"
          :rich-meta="marker.richMeta"
          :marker-data="marker"
          @onPositionChange="onPositionChange"
          @click:marker="$emit('click:marker', $event)"
        >
          <span v-if="marker.label">{{ marker.label }}</span>
          <v-icon
            v-if="marker.icon"
            color="white"
            v-text="marker.icon"
          />
        </RichMarker>
      </div>

      <gmap-polygon
        v-for="(polygon, idx) in mapPolygons"
        ref="polygon"
        :key="idx"
        :paths="polygon.paths"
        :editable="polygon.editable || false"
        :options="{
          strokeColor: polygon.color || '#000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: polygon.color || '#000',
          fillOpacity: 0.35
        }"
        @paths_changed="onPolygonChange($event, idx)"
        @click="handlePolygonClick($event, idx)"
        @rightclick="handlePolygonRightClick($event, idx)"
      />

      <gmap-info-window
        :options="{ pixelOffset: { width: 0, height: -35 } }"
        :position="infoWindow.pos"
        :opened="infoWindow.open"
        @closeclick="infoWindow.open=false"
      >
        <div v-html="infoWindow.content" />
      </gmap-info-window>

      <template slot="visible">
        <div class="custom-options">
          <v-menu
            v-if="customOptions"
            open-on-hover
            attach
          >
            <template #activator="{ on }">
              <div
                class="map-custom-points"
                v-on="on"
              >
                Partida/Chegada
              </div>
            </template>

            <v-list>
              <v-list-item
                class="grab"
                draggable="true"
                @dragstart="onMenuItemDragStart($event, 'start')"
                @dragend="onMenuItemDragEnd($event, 'start')"
              >
                <v-list-item-icon>
                  <v-icon>place</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  Ponto de Partida
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="grab"
                draggable="true"
                @dragstart="onMenuItemDragStart($event, 'end')"
                @dragend="onMenuItemDragEnd($event, 'end')"
              >
                <v-list-item-icon>
                  <v-icon>place</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  Ponto de Chegada
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="grab"
                draggable="true"
                @dragstart="onMenuItemDragStart($event, 'stopping_point')"
                @dragend="onMenuItemDragEnd($event, 'stopping_point')"
              >
                <v-list-item-icon>
                  <v-icon>place</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  Ponto de Parada
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <slot name="controls" />
        </div>
      </template>
    </GmapMap>
    <v-overlay
      :value="onLoading"
      :absolute="true"
    >
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<style lang="scss">
  .custom-options {
    display: flex;
    padding-top: 10px;
  }

  .map-custom-points {
    left: 190px;
    direction: ltr;
    overflow: hidden;
    text-align: center;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    position: absolute;
    color: rgb(86, 86, 86);
    font-family: Roboto, Arial, sans-serif;
    user-select: none;
    font-size: 18px;
    background-color: #fff;
    padding: 0px 17px;
    margin: 0px 5px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    min-width: 40px;
  }

  .grab > * {
    cursor: grab !important;
  }
</style>

<script>
import _ from "lodash";
import moment from "moment";
import MomentDuration from "moment-duration-format";

import { gmapApi } from "gmap-vue";
import RichMarker from "@/Domains/Routes/Components/Maps/Marker/RichMarker.vue";
import SimpleMarker from "@/Domains/Routes/Components/Maps/Marker/SimpleMarker.vue";
import MarkerIcon from "@/Assets/marker.png";
import RouteService from "@/Domains/Routes/Services/RouteService.js";

MomentDuration(moment);

const service = new RouteService();

export default {
  components: { RichMarker, SimpleMarker },

  props: {
    value: [ Array, Object ],
    center: Object,
    markers: {
      type: Array,
      default: () => [],
    },
    path: Array,
    polyline: String,
    draggable: Boolean,
    customOptions: Boolean,
    singleRoute: Boolean,
    autoTrace: Boolean,
    traceOnLoad: Boolean,
    height: {
      type: String,
      default: '500px',
    },
    polygons: {
      type: Array,
      default: () => [],
    },
    disableRecenter: Boolean,
    useSimpleMarker: Boolean,
    clickable: Boolean,
    disableFullscreen: Boolean,
  },

  data() {
    return {
      // Overlay (Loader)
      onLoading: false,

      // Usado no pre-load da image do DnD do item do menu
      dragIcon: null,

      // Google Maps API
      overlay: {},

      // Array com todos os renders usados no mapa atual (Pode ser mais de 1 caso a rota possua mais de 23 paradas)
      renders: [],

      // Waypoints
      waypoints: [],

      // Polygons usados no mapa atual
      mapPolygons: [],

      infoWindow: {
        open: false,
        pos: { lat: 0, lng: 0 },
        content: '',
      }
    };
  },

  computed: {
    google: gmapApi
  },

  watch: {
    value: {
      deep: true,

      handler() {
        return this.onRouteChange();
      },
    },

    markers: {
      deep: true,

      handler() {
        return this.onRouteChange();
      },
    },

    path: {
      deep: true,

      handler() {
        this.onRouteChange();
      },
    },

    polyline: {
      deep: true,

      handler() {
        this.onRouteChange();
      },
    },

  },

  async mounted() {
    await this.$refs.routeMap.$mapPromise; // Inicía o mapa

    await this.loadMap();

    this.preloadDragIcon();

    this.onRouteChange();

    this.trace(this.traceOnLoad);

    this.recenter();

    this.setMapPolygons();
  },

  methods: {
    /**
     * Faz o load do mapa com suas definições
     */
    async loadMap() {
      try {
        this.onLoading = true;

        const map = this.$refs.routeMap.$mapObject;

        const overlay = new this.google.maps.OverlayView();
        overlay.setMap(map);
        overlay.draw = function() {};
        this.overlay = overlay;
      } catch (e) {
        console.log(e);
      } finally {
        this.onLoading = false;
      }
    },

    /**
     * Faz o preload da imagem que será usada como icone ao arrastar um elemento do menu
     *
     * @todo Tentar substitur para uma div/canvas + css
     */
    preloadDragIcon() {
      const img = new Image();

      img.src = MarkerIcon;

      this.dragIcon = img;
    },

    onRouteChange() {
      // Limpa os waypoints
      this.waypoints = [];

      let waypointsArray = [];

      return this.$nextTick(function() {
        waypointsArray.push(this.markers);

        if (!_.isEmpty(this.value)) {
          if (this.singleRoute) {
            waypointsArray.push(this.getRouteWaypoints(this.value));
          }
          else {
            waypointsArray.push(...this.value.map(route => this.getRouteWaypoints(route)));
          }
        }

        this.waypoints = _.flatten(waypointsArray);

        // Traça a rota quando alterar o valor
        this.trace(this.autoTrace);

        this.traceFromPath();
        this.traceFromPolyline();

        return this.recenter();
      });
    },

    /**
     * Recupera todos os waypoints de uma rota
     * @param route
     * @returns {Array}
     */
    getRouteWaypoints(route) {

      if (_.isEmpty(route)) {
        return [];
      }

      if (_.isEmpty(route.params)) {
        return [];
      }

      if (_.isEmpty(route.mapPersons)) {
        return [];
      }

      const dairyPosition = {
        lat: this.$store.state.settings.coordenadas.lat,
        lng: this.$store.state.settings.coordenadas.lng,
      };

      const startPosition = {
        lat: _.get(route.params.routeStartPosition, "lat", null),
        lng: _.get(route.params.routeStartPosition, "lng", null),
      };

      const endPosition = {
        lat: _.get(route.params.routeEndPosition, "lat", null),
        lng: _.get(route.params.routeEndPosition, "lng", null),
      };

      return service.getWaypoints(
        route.id,
        dairyPosition,
        startPosition,
        endPosition,
        route.mapPersons,
        route.params.collectTime,
        route.color,
        false,
      );
    },

    traceFromPath() {
      if (!this.path) {
        return;
      }

      this.traceRouteFromPath(this.path);
    },

    traceFromPolyline() {
      if (!this.polyline) {
        return;
      }

      this.traceRouteFromPolyline(this.polyline);
    },

    traceRouteFromPath(path, routeColor = '#3b89f5') {
      const polyline = new this.google.maps.Polyline({
        path,
        geodesic: true,
        strokeColor: routeColor,
        strokeOpacity: 1.0,
        strokeWeight: 3,
        icons: [{
          icon: {
            path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 2,
          },
          offset: '100%',
          repeat: '80px',
        }],
        map: this.$refs.routeMap.$mapObject
      });

      this.renders.push(polyline);
    },

    traceRouteFromPolyline(polyline, routeColor = '#ff6666') {
      if (_.isEmpty(polyline)) {
        return;
      }

      const path = this.google.maps.geometry.encoding.decodePath(polyline);

      this.traceRouteFromPath(path, routeColor);
    },

    async trace(traceFromApi = true, traceFromPolyline = true, option) {
      try {
        this.clearRoute();

        if (_.isEmpty(this.value)) {
          return;
        }

        if (this.singleRoute) {
          if (traceFromPolyline && this.value.params.routePolyline) {
            return this.traceRouteFromPolyline(this.value.params.routePolyline, this.value.color);
          }

          if (traceFromApi) {
            return await this.traceRoute(this.value, option);
          }

          return;
        }

        return await Promise.all(this.value.map(route => {
          if (traceFromPolyline && route.params.routePolyline) {
            return this.traceRouteFromPolyline(route.params.routePolyline, route.color);
          }

          if (traceFromApi) {
            return this.traceRoute(route, option)
          }
        }));
      } catch (e) {

        console.log(e);
      }
    },

    /**
     * Traça a rota passando por todos os waypoints
     */
    async traceRoute(route, option) {
      try {
        this.onLoading = true;

        let routeWaypoints = this.getRouteWaypoints(route);

        if (!route && routeWaypoints.length == 0 && this.waypoints.length > 0) {
          routeWaypoints = this.waypoints;
        }

        let routeDetails = (option === 'OSRM')
          ? await this.traceRouteFromOSRM(routeWaypoints, route.color)
          : await this.traceRouteFromGMaps(routeWaypoints, route.color);

        //Adiciona ao tempo total da rota, o tempo de parada.
        let totalStopoverTime = this.waypoints.reduce(
          (totalStopoverTime, waypoint) => {
            return totalStopoverTime + (waypoint.waiting || 0);
          },
          0
        );

        this.onLoading = false;

        const duration = routeDetails.duration + totalStopoverTime;

        return this.$emit("onRouteTraced", {
          route,
          distance: routeDetails.distance / 1000, // Converte de metros para Km
          duration: moment.duration(duration, "seconds").format("HH:mm"),
          polyline: routeDetails.polyline,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.onLoading = false;
      }
    },

    async traceRouteFromOSRM(routeWaypoints, routeColor) {
      try {
        const routes = await service.generateRoute(routeWaypoints);

        const route = _.first(routes);

        if (!route) {
          return null;
        }

        this.traceRouteFromPolyline(route.geometry, routeColor);

        return {
          distance: route.distance,
          duration: route.duration,
          polyline: route.geometry,
        };
      } catch (err) {
        return;
      }
    },

    async traceRouteFromGMaps(routeWaypoints, routeColor) {
      const waypointsChunks = _.chunk(routeWaypoints, 25);

      const { routeDetails } = await waypointsChunks.reduce(
        (promise, chunk) => {
          return promise.then(async ({ lastLocation, routeDetails }) => {
            let waypoints = chunk;
            let origin = _.isEmpty(lastLocation) ? waypoints.shift() : lastLocation;
            let destination = waypoints.pop();

            const chunkRouteDetails = await this.traceRouteChunk(
              origin,
              destination,
              waypoints,
              routeColor,
            );

            return {
              lastLocation: destination,
              routeDetails: {
                distance: routeDetails.distance + chunkRouteDetails.distance,
                duration: routeDetails.duration + chunkRouteDetails.duration,
                path: [...routeDetails.path, ...chunkRouteDetails.path],
              },
            };
          });
        },
        Promise.resolve({
          lastLocation: {},
          routeDetails: {
            distance: 0,
            duration: 0,
            path: [],
          },
        })
      );

      routeDetails.polyline = this.google.maps.geometry.encoding.encodePath(routeDetails.path);

      return routeDetails;
    },

    /**
     * Traça a rota respeitando o limite do google maps de 25 waypoints
     */
    traceRouteChunk(origin, destination, waypoints, color) {
      return new Promise(async (resolve, reject) => {
        const map = await this.$refs.routeMap.$mapObject;
        const service = new this.google.maps.DirectionsService();
        const renderer = new this.google.maps.DirectionsRenderer({
          polylineOptions: {
            strokeColor: color || '#ff6666',
          },
        });

        renderer.setMap(map);
        renderer.setOptions({
          suppressMarkers: true,
          preserveViewport: true,
        });

        /**
         * É salvo uma cópia de cada render usado para possibilitar remover as rotas do mapa
         */
        this.renders.push(renderer);

        service.route(
          {
            origin: origin.location,
            destination: destination.location,
            waypoints: waypoints.map(({ location }) => ({
              location,
              stopover: true,
            })),
            optimizeWaypoints: false,
            provideRouteAlternatives: false,
            travelMode: "DRIVING",
          },
          (response, status) => {
            if (status !== "OK") {
              return reject();
            }

            renderer.setDirections(response);

            const route = _.first(response.routes);

            const routeInfo = route.legs.reduce(
              (accumulator, leg) => {
                return {
                  distance: accumulator.distance + leg.distance.value,
                  duration: accumulator.duration + leg.duration.value,
                };
              },
              { distance: null, duration: null }
            );

            /**
             * A chave overview_path da API é apenas um caminho geral e ao usar a polyline desse caminho vários pontos ficam retos
             * Por isso as coordenadas agora são obtidas através da chave legs -> steps -> lat_lngs
             * Esse dado é bem detalhado, por isso é feito um reduce para obter a coordenada a cada terceiro elemento do array
             */
            // routeInfo.path = route.overview_path;
            routeInfo.path = route.legs
              .flatMap(leg => leg.steps.flatMap(step => step.lat_lngs))
              .reduce((acc, cur, index, arr) => {
                if (index % 3 === 0 || index === arr.length - 1)
                  acc.push(cur)
                return acc;
              }, []);

            return resolve(routeInfo);
          }
        );
      });
    },

    resize() {
      const map = this.$refs.routeMap.$mapObject;

      if (!map) {
        return;
      }

      this.google.maps.event.trigger(map, "resize");
    },

    recenter() {
      return this.$nextTick(function() {
        if (this.disableRecenter) {
          return;
        }
        const map = this.$refs.routeMap.$mapObject;

        if (!map) {
          return;
        }

        if (_.isEmpty(this.waypoints)) {
          return;
        }
        const bounds = new this.google.maps.LatLngBounds();

        if (!_.isEmpty(this.polylines)) {
          this.polylines.forEach(({ lat, lng }) => {
            bounds.extend(new this.google.maps.LatLng(lat, lng));
          });

          return map.fitBounds(bounds);
        }

        this.waypoints.forEach(({ location }) => {
          bounds.extend(new this.google.maps.LatLng(location.lat, location.lng));
        });

        map.fitBounds(bounds);
      })
    },

    clearRoute() {
      this.renders.forEach(render => render.setMap(null));
      this.renders = [];
    },

    /**
     * @event Object
     *
     * Evento acionado ao iniciar o drag do item do menu (Adicionar ponto no mapa)
     */
    onMenuItemDragStart(event) {
      event.dataTransfer.setDragImage(this.dragIcon, 20, 45);
    },

    /**
     * @event Object
     *
     * Evento acionado no drop do item do menu (Adicionar ponto no mapa)
     */
    onMenuItemDragEnd(event, marker) {
      const offset = this.$refs.container.getBoundingClientRect();

      const X = event.pageX - offset.x;
      const Y = event.pageY - offset.y;

      const point = new this.google.maps.Point(X, Y);

      const position = this.overlay
        .getProjection()
        .fromContainerPixelToLatLng(point);

      return this.onPositionChange(
        {
          lat: position.lat(),
          lng: position.lng(),
        },
        marker
      );
    },

    /**
     * @event Object
     *
     * Evento acionado ao mudar uma marcação de lugar no mapa
     */
    onPositionChange(position, marker) {
      this.$emit("onPositionChange", {
        position,
        marker,
      });
    },

    /**
     * Inicia um poligono para edição
     */
    initPolygons() {
      // obtem os limites (bounds) do mapa atual, para que definir o tamanho do polígono
      var bounds = this.$refs.routeMap.$mapObject.getBounds()
      var northEast = bounds.getNorthEast();
      var southWest = bounds.getSouthWest();
      var center = bounds.getCenter();
      var degree = this.mapPolygons.length + 1;
      var f = Math.pow(0.66, degree);

      var paths = [[
        { lng: center.lng(), lat: (1 - f) * center.lat() + (f) * northEast.lat() },
        { lng: (1 - f) * center.lng() + (f) * southWest.lng(), lat: (1 - f) * center.lat() + (f) * southWest.lat() },
        { lng: (1 - f) * center.lng() + (f) * northEast.lng(), lat: (1 - f) * center.lat() + (f) * southWest.lat() },
      ]]

      this.mapPolygons.push({
        paths,
        mvcPaths: null,
        editable: true
      });
    },

    onPolygonChange(mvcPaths, idx) {
      this.mapPolygons[idx].mvcPaths = mvcPaths;

      if (!mvcPaths) return null;

      const polygon = this.mapPolygons[idx];

      let paths = [];
      for (let i = 0; i < polygon.mvcPaths.getLength(); i++) {
        let path = [];
        for (let j = 0; j < polygon.mvcPaths.getAt(i).getLength(); j++) {
          let point = polygon.mvcPaths.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }
      if (paths) {
        this.mapPolygons[idx].paths = paths;
        this.$emit("onPolygonChanged", { coordinates: polygon.paths[0], idx });
      }
    },

    getPolygonMarkers(idx) {
      if (!(this.$refs.polygon[idx])) {
        return;
      }

      const polygon = this.$refs.polygon[idx].$polygonObject;

      return [...this.waypoints]
        .filter(marker =>
          this.google.maps.geometry.poly.containsLocation(new this.google.maps.LatLng(marker.location.lat, marker.location.lng), polygon)
          && marker.key != 'dairy');
    },

    /**
     * @event Object
     * Ao clicar com o botão esquerdo do mouse no polígono
     */
    handlePolygonClick($event, idx) {

      const markers = this.getPolygonMarkers(idx);

      const totalProducers = markers.length;

      let volume = markers.reduce((acc, marker) => acc + marker.vol, 0);
      let { title } = this.mapPolygons[idx];
      let contentString =
        `<div class="text-left black--text">
          <b>Região: </b> ${title}
          <br>Produtores: ${totalProducers}
          <br>Volume: ${volume} L
        </div>`;

      // Replace the info window's content and position.
      this.infoWindow.content = contentString;
      this.infoWindow.pos = $event.latLng;
      this.infoWindow.open = true;
    },

    /**
     * @event Object
     * Ao clicar com o botão direito do mouse no polígono
     */
    handlePolygonRightClick($event, idx) {
      // Caso seja no vértice, remove-o
      if ($event.vertex) {
        this.$refs.polygon[idx].$polygonObject.getPaths()
          .getAt($event.path)
          .removeAt($event.vertex)
      }
    },

    verifyMarkersInnerPolygons() {
      setTimeout(() => {
        let polygonMarkers = [];
        for (let idx in this.mapPolygons) {
          const markers = this.getPolygonMarkers(idx);
          polygonMarkers.push(markers);
        }
        this.$emit("onPolygonMarkers", polygonMarkers);
      }, 300)
    },

    /**
     * Obtém das props [polygons] as regiões demarcadas
     */
    setMapPolygons() {
      let mapPolygons = [];

      if (_.isEmpty(this.polygons)) {
        return;
      }

      let polygons = this.polygons.filter(({ coordinates }) => !!coordinates && coordinates.length > 0);

      if (polygons.length == 0) {
        return;
      }

      polygons.forEach(({ coordinates, editable, title, color }) => {
        mapPolygons.push({
          paths: [coordinates],
          mvcPaths: null,
          editable,
          title,
          color
        });
      });

      this.mapPolygons = mapPolygons;

      this.verifyMarkersInnerPolygons();
    },

    /**
     * Captura o evento de click no map
     */
    onSelectPosition(location) {
      this.$emit('onSelectPosition', {
        position: {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        },
      });
    },

    /**
     * Calcula a distância entre dois pontos em metros
     */
    calcDistance(p1, p2) {
      return this.google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
    },

    /**
     * Compara uma posição com uma lista de posições
     * e retorna a distância mais próxima em metros
     */
    getNearbyDistance(pos, locations) {
      const position = new this.google.maps.LatLng(pos.lat, pos.lng);

      return locations.reduce((nearest, location) => {
        const locationPos = new this.google.maps.LatLng(location.lat, location.lng);

        const distance = this.calcDistance(position, locationPos);

        if (distance < nearest) {
          return distance;
        }

        return nearest;
      }, Infinity);
    },

    /**
     * Traça a rota respeitando o limite do google maps de 25 waypoints
     */
    async getRoutePolylineFromGMaps(routeWaypoints) {

      const waypointsChunks = _.chunk(routeWaypoints, 25);

      const getRoutePolyline = (origin, destination, waypoints) => new Promise(async (resolve, reject) => {
        const service = new this.google.maps.DirectionsService();

        service.route(
          {
            origin: origin.location,
            destination: destination.location,
            waypoints: waypoints.map(({ location }) => ({
              location,
              stopover: true,
            })),
            optimizeWaypoints: false,
            travelMode: "DRIVING",
          },
          (response, status) => {
            if (status !== "OK") {
              return reject();
            }

            return resolve(response.routes[0].overview_polyline);
          }
        );
      });

      const { polylines } =  await waypointsChunks.reduce(
        (promise, chunk) => {
          return promise.then(async ({ lastLocation, polylines }) => {
            let waypoints = chunk;
            let origin = _.isEmpty(lastLocation) ? waypoints.shift() : lastLocation;
            let destination = waypoints.pop();

            const polyline = await getRoutePolyline(origin, destination, waypoints);

            return {
              lastLocation: destination,
              polylines: [...polylines, polyline],
            };
          });
        },
        Promise.resolve({
          lastLocation: {},
          polylines: [],
        })
      );

      return polylines;
    },

    async getRoutePolylineFromOSRM(routeWaypoints) {
      const routes = await service.generateRoute(routeWaypoints);

      return routes.map(r => this.optimizePolyline(r.geometry));
    },

    /**
     * Gera a a url do mapa estático para ser utilizado em impressões
     * Static Maps images can be returned in any size up to 640 x 640 pixels.
     */
    async getStaticMapFromGMaps(size = "640x640") {
      if (_.isEmpty(this.waypoints)) {
        return;
      }

      /**
       * @todo Adicionar suporte à múltiplas rotas
       */
      if (!this.singleRoute) {
        return;
      }

      const route = this.value;

      try {
        const apiKey = "AIzaSyB8lYX5eAT3-aObP1gw1XvpXhGHx0EUes0";

        let routeWaypoints = this.getRouteWaypoints(route);

        if (routeWaypoints.length == 0 && this.waypoints.length > 0) {
          routeWaypoints = this.waypoints;
        }

        // método para arredondar a lat e log da localização em caso de impressão com muito produtores
        // A API do google estava apresentando erro, ao tentar gerar um map estatico com muito produtores.
        // Os parametros enviados na solicitação eram muitos, gerando um exceção 414
        const roundedLocation = (loc) => (routeWaypoints.length > 120) ? _.round(loc, 3) : loc;

        let polylines = [];

        if (!_.isEmpty(route.params.routePolyline)) {
          polylines.push(this.optimizePolyline(route.params.routePolyline));
        }
        else {
          polylines = await this.getRoutePolylineFromOSRM(routeWaypoints);

          if (_.isEmpty(polylines)) {
            polylines = await this.getRoutePolylineFromGMaps(routeWaypoints);
          }
        }

        const sep = encodeURI("|");

        const markers = routeWaypoints
          .map(({ location: { lat, lng }, color, label }) => {
            const markerColor = (color || 'red').replace('#', '0x');
            const markerLabel = !isNaN(parseInt(label) + 9) ? (parseInt(label) + 9).toString(36).toUpperCase() : '-'
            const waypoint = `${roundedLocation(lat)},${roundedLocation(lng)}`;
            return `markers=color:${markerColor}${sep}label:${markerLabel}${sep}${waypoint}`;
          }).join("&");

        const routeColor = (route.color || 'red').replace('#', '0x');

        const paths = polylines
          .map(polyline => `path=color:${routeColor}${sep}enc:${encodeURI(polyline)}`)
          .join("&");

        return `https://maps.googleapis.com/maps/api/staticmap?size=${size}&maptype=roadmap&${paths}&${markers}&key=${apiKey}`;
      } catch (e) {
        console.warn(e);
      }
    },

    /**
     * Gera a a url do mapa estático para ser utilizado em impressões
     */
    async getStaticMap(size = "640x640") {
      if (_.isEmpty(this.waypoints)) {
        return;
      }

      /**
       * @todo Adicionar suporte à múltiplas rotas
       */
      if (!!this.value && !this.singleRoute) {
        return;
      }

      const route = this.value;

      try {
        const map = this.$refs.routeMap.$mapObject;

        const zoom = map.getZoom() + 1;
        const center = map.getCenter();
        const lat = center.lat();
        const lng = center.lng();

        let routeWaypoints = this.getRouteWaypoints(route);

        if (routeWaypoints.length == 0 && this.waypoints.length > 0) {
          routeWaypoints = this.waypoints;
        }

        // método para arredondar a lat e log da localização em caso de impressão com muito produtores
        // A API do google estava apresentando erro, ao tentar gerar um map estatico com muito produtores.
        // Os parametros enviados na solicitação eram muitos, gerando um exceção 414
        const roundedLocation = (loc) => (routeWaypoints.length > 120) ? _.round(loc, 3) : loc;

        let polylines = [];

        if (route) {
          if (route.params?.routePolyline) {
            polylines.push(this.optimizePolyline(route.params.routePolyline));
          }
          else {
            polylines = await this.getRoutePolylineFromOSRM(routeWaypoints);

            if (_.isEmpty(polylines)) {
              polylines = await this.getRoutePolylineFromGMaps(routeWaypoints);
            }
          }
        }

        const sep = encodeURI("|");

        const markers = routeWaypoints
          .slice(0, 200) // Marker limit
          .map(({ location: { lat, lng }, color, label }) => {
            const markerColor = color?.replace('#', '') || '';
            const markerLabel = !isNaN(parseInt(label) + 9) ? (parseInt(label) + 9).toString(36).toUpperCase() : (label || '')
            const waypoint = `${roundedLocation(lat)},${roundedLocation(lng)}`;
            return `m[]=c:${markerColor}${sep}l:${markerLabel}${sep}${waypoint}`;
          }).join("&");

        const routeColor = route?.color?.replace('#', '') || '';

        const paths = polylines
          .map(polyline => `p[]=c:${routeColor}${sep}enc:${encodeURI(polyline)}`)
          .join("&");

        return `${process.env.VUE_APP_API_BASE_URL}/ws/staticmap?size=${size}&zoom=${zoom}&center=${lat},${lng}&${markers}&${paths}`;
      } catch (e) {
        console.warn(e);
      }
    },

    /**
     * Para gerar o mapa estático pela API do Google, a polyline não pode ser complexa, pois a imagem gerada é pequena.
     * Quando é obtida a polyline pela própria API do Google, ele gera caminhos menores que 250 pontos,
     * por isso a otimização é feita para gerar em torno de 250 pontos
     *
     * @param {string} polyline
     * @returns {string}
     */
    optimizePolyline(polyline) {
      /**
       * Recupera os pontos de passagem em array
       */
      const path = this.google.maps.geometry.encoding.decodePath(polyline);

      if (path.length > 250) {
        const divider = parseInt(path.length / 250);

        if (divider > 1) {
          const reduced = path.reduce((acc, cur, index, arr) => {
            if (index % divider === 0 || index === arr.length - 1)
              acc.push(cur)
            return acc;
          }, []);

          /**
           * Converte novamente para polyline
           */
          polyline = this.google.maps.geometry.encoding.encodePath(reduced);
        }
      }

      return polyline
    },

    getCenterLocation() {
      const map = this.$refs.routeMap.$mapObject;

      if (!map) {
        return;
      }
      var center = map.getCenter();

      return { lng: center.lng(), lat: center.lat() };
    },

    openMarkerInfo(key) {
      const marker = this.$refs.markers[key].marker;
      this.google.maps.event.trigger(marker, 'click');
    },

  },
};
</script>
