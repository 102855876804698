<template>
  <div>
    <v-dialog
      v-model="dialog.show"
      max-width="630px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      scrollable
      @click:outside="closeDialogs"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Médias</span>

          <v-chip
            v-if="silo.lotNumber"
            dark
            color="red lighten-1"
            class="ml-4"
          >
            LOTE {{ silo.lotNumber }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <v-row class="justify-center">
            <v-btn
              v-if="isTransferActionsEnabled"
              outlined
              color="blue"
              class="ma-2"
              large
              @click="onSiloProductionTransferring"
            >
              <v-icon class="mr-1">
                get_app
              </v-icon>

              Produção
            </v-btn>
            <v-btn
              v-if="isTransferActionsEnabled && hasPermissionToSeeBetweenSilos"
              outlined
              color="blue"
              class="ma-2"
              large
              @click="onSiloToSiloTransferring"
            >
              <v-icon class="mr-1">
                get_app
              </v-icon>

              Entre Silos
            </v-btn>
            <v-btn
              outlined
              color="blue"
              class="ma-2"
              large
              @click="onSiloClearing"
            >
              <v-icon class="mr-2">
                published_with_changes
              </v-icon>

              CIP
            </v-btn>
            <v-btn
              v-if="isTransferActionsEnabled"
              outlined
              color="blue"
              class="ma-2"
              large
              @click="onUnitTransferring"
            >
              <v-icon class="mr-2">
                multiple_stop
              </v-icon>
              Unidade
            </v-btn>
            <v-btn
              v-if="isTransferActionsEnabled"
              outlined
              color="blue"
              class="ma-2"
              large
              @click="onSpotTransferring"
            >
              <v-icon class="mr-2">
                business
              </v-icon>
              Spot
            </v-btn>
            <v-btn
              v-if="hasCreatePeriodicSiloAnalysisAccess"
              outlined
              color="blue"
              class="ma-2"
              large
              @click="onSiloPeriodicAnalysis"
            >
              <v-icon class="mr-2">
                biotech
              </v-icon>
              Análise
            </v-btn>
          </v-row>
          <v-data-table
            :items="analysisAvg"
            :headers="[
              { text: 'Parâmetro', value: 'description', align: 'left' },
              { text: 'Média Cargas', value: 'value', align: 'right' },
              { text: 'Média Silo', value: 'value2', align: 'right' },
              { text: 'Média Geral', value: 'value3', align: 'right' },
            ]"
            disable-sort
            disable-pagination
            hide-default-footer
          >
            <template #[`item.value`]="{ item }">
              <v-chip
                v-if="item.value"
                dark
                small
              >
                {{ item.value }} {{ item.unit }}
              </v-chip>
              <span v-else>#</span>
            </template>
            <template #[`item.value2`]="{ item }">
              <v-chip
                v-if="item.value2"
                dark
                small
              >
                {{ item.value2 }} {{ item.unit }}
              </v-chip>
              <span v-else>#</span>
            </template>
            <template #[`item.value3`]="{ item }">
              <v-chip
                v-if="item.value3"
                dark
                small
              >
                {{ item.value3 }} {{ item.unit }}
              </v-chip>
              <span v-else>#</span>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialogs()"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          class="mb-0"
          color="white"
          indeterminate
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  data() {
    return {

      loading: false,

      // Dialog data
      dialog: {
        show: false,
      },

      analysisAvg: [],

      silo: {},

      // Mascara usada nos campos numéricos inteiros
      IntNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: '.',
            min: -999999,
            max: 999999,
          },
        },
      },

      // Mascara usada nos campos numéricos float
      FloatNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },
    };
  },

  computed: {

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    hasCreatePeriodicSiloAnalysisAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "periodic-silo-analysis-insert" && o.tipo === "COMPONENTE");
    },

    validateSiloVol() {
      return this.$store.state.settings.gerais.validar_saldo_silo;
    },

    isTransferActionsEnabled() {
      return (!this.validateSiloVol || this.silo.vol > 0) && this.silo.rawProduct.id;
    },

    hasPermissionToSeeBetweenSilos() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "see-between-silo" && o.tipo === "COMPONENTE");
    },
  },

  methods: {

    async show(silo) {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/industry/silo/${silo.id}/analysis-average`);

        this.silo = silo;

        const parameters = this.getParameters();

        this.analysisAvg = parameters.map(parameter => {

          let value = _.get(data, parameter.field);
          let value2 = _.get(data, parameter.field + '2');
          let value3 = _.get(data, parameter.field + '3');

          if (parameter.type === 'float') {
            value = parseFloat(value);
            value2 = parseFloat(value2);
            value3 = parseFloat(value3);
          }

          return {
            ...parameter,
            value: value ? value.toFixed(parameter.precision) : null,
            value2: value2 ? value2.toFixed(parameter.precision) : null,
            value3: value3 ? value3.toFixed(parameter.precision) : null,
          };
        });

        this.dialog.show = true;
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as médias do silo!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    getParameters() {
      const defaultParams = [
        {
          field: "temperatura",
          description: "Temperatura",
          unit: "ºC",
          type: "float",
          precision: 1,
        },
        {
          field: "acidez",
          description: "Acidez",
          unit: "ºD",
          type: "float",
          precision: 2,
        },
        {
          field: "alizarol",
          description: "Alizarol",
          unit: "°GL",
          type: "float",
          precision: 0,
        },
        {
          field: "crioscopia",
          description: "Crioscopia",
          unit: "°C",
          type: "float",
          precision: 3,
        },
        {
          field: "densidade",
          description: "Densidade",
          unit: "g/ml",
          type: "float",
          precision: 1,
        },
        {
          field: "esd",
          description: "E.S.D",
          unit: "g/100g",
          type: "float",
          precision: 2,
        },
        {
          field: "gordura",
          description: "Gordura",
          unit: "%/100g",
          type: "float",
          precision: 3,
        },
        {
          field: "proteina",
          description: "Proteína",
          unit: "g/100g",
          type: "float",
          precision: 2,
        },
        {
          field: "ph",
          description: "pH",
          unit: "",
          type: "float",
          precision: 2,
        },
        {
          field: "redutase",
          description: "Redutase",
          unit: "",
          type: "float",
          precision: 0,
        }
      ];

      if (!this.silo.rawProduct) {
        return defaultParams;
      }

      let analysisParams = this.$store.state.settings?.plataforma?.parametros_analise?.materia_prima?.[this.silo.rawProduct.id];

      if (_.isEmpty(analysisParams)) {
        return defaultParams;
      }

      analysisParams = _.keyBy(analysisParams, 'parametro');

      return defaultParams
        .map(param => {
          const settings = analysisParams[param.field];

          if (!settings) {
            return null;
          }

          return {
            ...param,
            unit: settings?.unidade_medida?.unidade || param.unit,
          }
        })
        .filter(o => o);
    },

    onSiloProductionTransferring() {
      this.closeDialogs();

      return this.$emit('onSiloProductionTransferring', this.silo);
    },

    onSiloToSiloTransferring() {
      this.closeDialogs();

      return this.$emit('onSiloToSiloTransferring', this.silo);
    },

    onSiloClearing() {
      this.closeDialogs();

      return this.$emit('onSiloClearing', this.silo);
    },

    onUnitTransferring() {
      this.closeDialogs();

      return this.$emit('onUnitTransferring', this.silo);
    },

    onSpotTransferring() {
      this.closeDialogs();

      return this.$emit('onSpotTransferring', this.silo);
    },

    onSiloPeriodicAnalysis() {
      this.closeDialogs();

      return this.$emit('onSiloPeriodicAnalysis', this.silo);
    },

    /**
     * Fecha todos os dialogos abertos na tela
     */
    closeDialogs() {
      this.dialog.show = false;
      this.analysisAvg = [];
    },

  },

}
</script>

