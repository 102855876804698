<template>
  <div v-if="tanks.length">
    <span
      :class="{
        'overline': true,
        [`${color}--text`]: !!color,
      }"
    >
      {{ title }}
    </span>

    <v-divider
      class="mb-2"
      style="margin-top: -8px;"
    />

    <v-row
      v-for="tank of tanks"
      :key="tank.index"
      :class="{
        'pa-1': true,
        'ma-0': true,
        'mb-2': true,
        'text-uppercase': true,
        [`${color} lighten-5`]: !!color,
        'orange--text': ['TO_UNLOAD_RESTRICT', 'UNLOADED_RESTRICT'].includes(tank.status),
        'blue--text': tank.status === 'UNLOADED',
      }"
      style="border: 1px solid #ccc;"
    >
      <v-col
        cols="5"
        class="pa-0 ma-0"
      >
        Tanque {{ tank.index }}
      </v-col>
      <v-col
        cols="7"
        class="pa-0 ma-0 text-right"
      >
        {{ tank.vol }} L
      </v-col>
      <template v-if="showMeasured">
        <v-col
          cols="5"
          class="pa-0 ma-0"
        >
          &nbsp;- Medidos
        </v-col>
        <v-col
          cols="7"
          class="pa-0 ma-0 text-right"
        >
          <v-text-field
            v-model.number="tank.measured"
            :disabled="measuredDisabled"
            flat
            solo
            dense
            hide-details
            reverse
            class="measured-input"
            background-color="transparent"
            @input="onTankMeasuredInput($event, tank)"
          >
            <template slot="append-outer">
              <span>L</span>
            </template>
          </v-text-field>
        </v-col>
      </template>
      <v-col
        v-if="tank.difference"
        cols="5"
        class="pa-0 ma-0"
      >
        &nbsp;- Diferença
      </v-col>
      <v-col
        v-if="tank.difference"
        cols="7"
        class="pa-0 ma-0 text-right"
      >
        <span
          v-if="tank.difference > 0"
          class="green--text text--darken-4"
        >
          <span>+{{ tank.difference }} L</span>
        </span>
        <span
          v-if="tank.difference < 0"
          class="red--text"
        >
          <span>{{ tank.difference }} L</span>
        </span>
      </v-col>
      <v-col
        cols="6"
        class="pa-0 ma-0"
      >
        &nbsp;- Disponível
      </v-col>
      <v-col
        cols="6"
        class="pa-0 ma-0 text-right"
      >
        {{ tank.availableVolToTransfer || 0 }} L
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    color: String,
    tanks: Array,
    showMeasured: Boolean,
    measuredDisabled: Boolean,
  },

  methods: {
    onTankMeasuredInput(measured, updatedTank) {
      this.$emit('onTankMeasuredInput', measured, updatedTank);
    }
  }
}
</script>
