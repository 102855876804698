<template>
  <v-dialog
    v-model="show"
    width="800"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">
          Exame Sanitário
        </span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-stepper
        v-model="step"
        alt-labels
        class="elevation-0"
      >
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="step > 1"
          >
            Dados
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="2">
            Laudos
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            step="1"
            class="pa-0"
          >
            <v-card-text>
              <v-form
                ref="form"
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <person-autocomplete-filter
                      v-model="form.producer"
                      label="Produtor *"
                      type="PRODUCER"
                      :filled="false"
                      :hide-details="false"
                      :rules="[v => !!v || 'Campo obrigatório!']"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <veterinarian-select
                      v-model="form.veterinarian"
                      label="Veterinário *"
                      prepend-inner-icon="manage_accounts"
                      persistent-hint
                      return-object
                      :rules="[v => !!v || 'Campo obrigatório!']"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <v-autocomplete
                      :value="availableExams"
                      :items="types"
                      label="Tipos de exame *"
                      prepend-inner-icon="biotech"
                      placeholder=" "
                      multiple
                      small-chips
                      deletable-chips
                      return-object
                      dense
                      :rules="[v => v.length > 0 || 'Campo obrigatório!']"
                    >
                      <template #item="{ item }">
                        <v-list-item-content
                          @click.stop="onSelectExam(item)"
                          v-text="item.text"
                        />
                      </template>

                      <template #selection="{ item, index }">
                        <v-chip x-small>
                          <span>{{ item.text }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col
                    v-if="availableExams.length > 0"
                    cols="12"
                    class="subtitle-1"
                  >
                    Exames
                    <v-divider />
                  </v-col>

                  <transition-group name="scroll-y-transition">
                    <v-row
                      v-for="(exam, idx) in availableExams"
                      :key="exam.value"
                      class="mt-0"
                      no-gutters
                    >
                      <v-col
                        class="py-0 flex-grow-1"
                      >
                        <v-row>
                          <v-col
                            cols="3"
                            class="pb-0"
                          >
                            <v-text-field
                              :value="exam.text"
                              label="Tipo de Exame"
                              prepend-inner-icon="biotech"
                              filled
                              dense
                              disabled
                              hide-details
                            />
                          </v-col>
                          <v-col
                            cols="3"
                            class="pb-0"
                          >
                            <masked-text-field
                              v-model="exam.date"
                              label="Data do Exame *"
                              prepend-inner-icon="event"
                              placeholder="dd/mm/aaaa"
                              :mask="{
                                mask: Date,
                                pattern: 'd/m/00000',
                                lazy: true,
                              }"
                              :rules="[v => {
                                if(!v) {
                                  return 'Campo obrigatório!';
                                } else if(v.trim().length < 10) {
                                  return 'Data inválida!';
                                } else {
                                  return true;
                                }
                              }]"
                              @input="onDateChange($event, idx)"
                            />
                          </v-col>
                          <v-col
                            cols="3"
                            class="pb-0"
                          >
                            <masked-text-field
                              v-model="exam.dateDue"
                              label="Data de Vencimento"
                              prepend-inner-icon="event"
                              placeholder="dd/mm/aaaa"
                              :mask="{
                                mask: Date,
                                pattern: 'd/m/00000',
                                lazy: true,
                              }"
                              :rules="[v => {
                                if(v && (v.trim().length > 0 && v.trim().length < 10)) {
                                  return 'Data inválida!';
                                } else {
                                  return true;
                                }
                              }]"
                              @input="onDateDueChange($event, idx)"
                            />
                          </v-col>
                          <v-col
                            cols="3"
                            class="pb-0"
                          >
                            <masked-text-field
                              v-model="exam.quantity"
                              label="Quant. de Animais *"
                              prepend-inner-icon="icon-sanidade"
                              :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
                              :rules="[v => !!v || 'Campo obrigatório!']"
                              @input="onQuantityChange($event, idx)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        class="flex-grow-0 align-self-center text-center pl-0 d-flex"
                      >
                        <v-btn
                          icon
                          color="red"
                          @click="onSelectExam(exam)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </transition-group>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <v-text-field
                      v-model="form.observations"
                      label="Observações"
                      type="text"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-row>
              <v-col
                v-for="(attachment, idx) in form.attachments"
                :key="idx"
              >
                <file-viewer
                  :value="attachment"
                  @removeFile="onRemoveFile(idx)"
                />
              </v-col>
              <v-col
                v-if="form.attachments.length < 3"
                class="py-0 text-center align-self-center flex-column justify-center"
              >
                <v-btn
                  outlined
                  class="mb-5"
                  @click="showFileUpload = !showFileUpload"
                >
                  <v-icon>attach_file</v-icon>
                  <span class="ml-2">Adicionar</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-divider />
      <v-card-actions>
        <v-btn
          v-if="step == 1"
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-btn
          v-else
          outlined
          @click="step = step - 1"
        >
          <v-icon
            left
            dark
          >
            chevron_left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="step == 1"
          color="green"
          outlined
          @click="nextStep()"
        >
          Continuar
          <v-icon
            right
            dark
          >
            chevron_right
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <file-upload
      v-model="showFileUpload"
      extensions="pdf,xml,gif,jpe,jpeg,jpg,tiff,png,webp,bmp"
      accept="application/pdf,text/xml,image/*"
      :multiselect="false"
      @insertFiles="onSelectFiles"
    />
  </v-dialog>
</template>

<script>
import VeterinarianSelect from "@/Domains/Projects/HealthExamination/Components/VeterinarianSelect.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";

import moment from "moment/moment";

export default {
  components: {
    PersonAutocompleteFilter,
    VeterinarianSelect,
    MaskedTextField,
    FileUpload,
    FileViewer
  },

  props: {
    value: Boolean,
    editId: String,
  },

  data() {
    return {
      showFileUpload: false,
      step: 1,
      form: {
        exams: [],
        attachments: []
      },
      types: [
        { text: 'Brucelose', value: 'BRUCELOSE' },
        { text: 'Tuberculose', value: 'TUBERCULOSE' },
      ]
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    availableExams() {
      return this.form.exams.filter(exam => exam.status === 'ACTIVE');
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.step = 1;
        this.form = {
          exams: [],
          attachments: []
        };

        if (this.editId) {
          this.loadExam();
        }
        else {
          // Seleciona automaticamente os exames em um novo cadastro
          this.types.forEach(type => this.onSelectExam(type));
        }
      }
    }
  },

  methods: {
    async loadExam() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam/${this.editId}`);

        this.form = {
          producer: data.produtor ? {
            id: data.produtor.id_pessoa,
            name: data.produtor.nome,
          } : null,
          veterinarian: data.veterinario ? {
            id: data.veterinario.id_pessoa,
            name: data.veterinario.nome,
          } : null,
          observations: data.observacoes,
          exams: data.exames.map(exam => {
            const type = this.types.find(type => type.value === exam.tipo)
            return {
              ...type,
              id: exam.id,
              date: moment(exam.data, "YYYY-MM-DD").format("DD-MM-YYYY"),
              dateDue: exam.data_vencimento ? moment(exam.data_vencimento, "YYYY-MM-DD").format("DD-MM-YYYY") : null,
              quantity: exam.quantidade_animais,
              pending: false,
              status: exam.data_hora_apagado ? 'INACTIVE' : 'ACTIVE',
              dueEvent: false,
              dateEvent: false
            }
          }),
          attachments: data.anexos || []
        };
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_produtor: this.form.producer.id,
          id_veterinario: this.form.veterinarian.id,
          observacoes: this.form.observations,
          exames: this.form.exams.map(exam => ({
            id: exam.id || null,
            tipo: exam.value,
            data: moment(exam.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            data_vencimento: exam.dateDue ? moment(exam.dateDue, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
            quantidade_animais: exam.quantity,
            ativo: exam.status === 'ACTIVE'
          })),
          anexos: this.form.attachments
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.ids[0];

        this.$emit("update:editId", editId);
        this.$emit('save', editId);
        this.close();
        this.$snotify.success("Registro salvo com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar registro", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/projects/health-exam`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/projects/health-exam/${this.editId}`, payload);
      return data;
    },

    async nextStep() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      this.step++;
    },

    onSelectFiles(files) {
      for (let file of files) {
        if (!this.form.attachments.find(o => o.name == file.name)) {
          this.form.attachments.push(file);
        }
      }
    },

    async onRemoveFile(idx) {
      try {
        const fileUrl = this.form.attachments[idx].url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('exames')) {
          if (!(await this.$root.$confirm('Remover anexo', 'Tem certeza que deseja excluir este anexo?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.attachments.splice(idx, 1);
          await this.save();
        }
        else {
          this.form.attachments.splice(idx, 1);
        }
      } catch (err) {
        console.warn(err);
      }
    },

    onSelectExam(exam) {
      const hasTypeInList = this.form.exams.find(item => item.value === exam.value);

      if (!hasTypeInList) {
        this.form.exams.push({
          ...exam,
          date: moment().format("DD-MM-YYYY"),
          dateDue: null,
          pending: true,
          status: 'ACTIVE',
        });

        return;
      }

      if (hasTypeInList.status === 'INACTIVE') {
        this.form.exams = this.form.exams.map(item => {

          if (item.value === exam.value) {
            return {
              ...item,
              status: 'ACTIVE',
            }
          }

          return item;
        });

        return;
      }

      if (hasTypeInList.pending) {
        const removedIndex = this.form.exams.findIndex(item => item.value === exam.value);

        this.form.exams.splice(removedIndex, 1);

        return;
      }

      this.form.exams = this.form.exams.map(item => {

        if (item.value === exam.value) {
          return {
            ...item,
            status: 'INACTIVE',
          }
        }

        return item;
      });
    },

    onDateChange(date, idx) {
      if (idx > 0) return;

      this.form.exams = this.form.exams.map((item, index) => {
        if (index === 0) {
          return item;
        }

        if (!item.dateEvent) {
          item.dateEvent = true;
          return item;
        };

        return {
          ...item,
          date,
        }
      });
    },

    onDateDueChange(dateDue, idx) {
      if (idx > 0) return;

      this.form.exams = this.form.exams.map((item, index) => {
        if (index === 0) {
          return item;
        }

        if (!item.dueEvent) {
          item.dueEvent = true;
          return item;
        };

        return {
          ...item,
          dateDue,
        }
      });
    },

    onQuantityChange(quantity, idx) {
      if (idx > 0) return;

      this.form.exams = this.form.exams.map((item, index) => {
        if (index === 0) {
          return item;
        }

        return {
          ...item,
          quantity,
        }
      });
    },

    close() {
      this.show = false;
    }
  }
}
</script>
