<template>
  <div>
    <v-row>
      <v-spacer />
      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <v-date-range-picker
          v-model="filter.period"
          :ranges="dateRangeFilters"
          label="Período"
          dark
          @change="getSpots"
        />
      </v-col>

      <v-col
        v-if="plannings.length == 0"
        cols="12"
      >
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title class="d-flex justify-center py-12">
            Nenhuma programação encontrada para o período selecionado
          </v-card-title>
        </v-card>
      </v-col>

      <v-col
        v-for="(items, idx) in plannings"
        :key="idx"
        cols="12"
      >
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Programação de {{ type.toLowerCase() }} de {{ items.rawMaterial }}
            <v-spacer />
            <div class="hidden-xs-only">
              <div class="d-flex">
                <div
                  class="d-flex flex-column justify-end px-14"
                >
                  <span class="body-2 font-weight-light">
                    Volume Total
                  </span>
                  <span
                    class="body-1 font-weight-bold cyan--text text--accent-3"
                  >{{ formatNumber(items.totals.total) }}</span>
                </div>
              </div>
            </div>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items.items"
            :loading="loading"
            dark
            dense
            disable-sort
            hide-default-footer
            class="elevation-1 report-table"
          >
            <template
              #[`body.prepend`]="{}"
            >
              <tr class="hidden-xs-only">
                <td />
                <td
                  v-for="(date, idx) in dates"
                  :key="idx"
                  class="text-start text-truncate"
                >
                  {{ dateFormat(date.date, 'dddd') }}
                </td>
                <td />
                <td />
              </tr>
            </template>

            <template
              v-for="(date, idx) in dates"
              #[`item.${date.column}`]="{ value }"
            >
              <span :key="idx">
                {{ formatNumber(value) }}
              </span>
            </template>

            <template
              #[`item.total`]="{ value }"
            >
              <b>{{ formatNumber(value) }}</b>
            </template>

            <template
              #[`body.append`]="{}"
            >
              <tr
                class="hidden-xs-only"
              >
                <td class="text-start">
                  <b>Total {{ items.rawMaterial }}</b>
                </td>
                <td
                  v-for="(date, idx) in dates"
                  :key="idx"
                  class="text-start"
                >
                  {{ formatNumber(items.totals[date.column]) }}
                </td>
                <td class="text-end">
                  <b>{{ formatNumber(items.totals.total) }}</b>
                </td>
                <td />
              </tr>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-menu>
                <template #activator="{ on }">
                  <v-btn
                    dark
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="editPlanning(item)">
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Editar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="removePlanning(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Excluir
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <v-card-title />
        </v-card>
      </v-col>
    </v-row>

    <spot-dialog
      v-model="planningDialog.show"
      :spot="planningDialog.spot"
      :type="type"
      @change="getSpots"
    />

    <confirm ref="confirm" />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="blue darken-1"
        @click="newPlanning"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Novo spot
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import Confirm from "@/Support/Components/Confirm.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import SpotDialog from '@/Domains/Platform/Spots/Components/SpotDialog.vue';
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import _ from "lodash";
import moment from "moment-timezone";

moment.locale('pt');

export default {
  name: "spot-planning",

  components: {
    VDateRangePicker,
    SpotDialog,
    Confirm,
  },

  mixins: [ReportMixin],

  props: {
    type: {
      type: String,
      default: 'ENTRADA'
    },
  },

  data() {
    return {
      loading: false,

      filter: {
        period: [],
      },

      rawMaterials: {},

      plannings: [],

      planningDialog: {
        show: false,
        spot: null
      }
    };
  },

  computed: {
    dates() {
      let period = [];

      const [startDate, endDate] = this.filter.period;

      let dateAux = startDate;

      while (dateAux <= endDate) {
        const date = moment(dateAux);
        period.push({
          date,
          column: date.format('YYYY_MM_DD')
        });
        dateAux = date.clone().add(1, 'day').format('YYYY-MM-DD');
      }

      return period;
    },
    headers() {
      let headers = [
        { text: 'Empresa', value: 'company.name' },
      ];

      for (let date of this.dates) {
        headers.push({
          text: date.date.format('DD/MM/YYYY'),
          value: date.column
        })
      }

      headers.push(
        { text: 'Total', value: 'total', align: 'end' },
        { value: "actions", align: 'end', width: 40 }
      );

      return headers;
    },
    dateRangeFilters() {
      const today = moment().date();
      let currrentFortnight, previousFortnight, nextFortnight;

      if (today <= 15) {
        currrentFortnight = [moment().startOf("month").format("YYYY-MM-DD"), moment().date(15).format("YYYY-MM-DD")]
        previousFortnight = [moment().subtract(1, "month").date(16).format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")]
        nextFortnight = [moment().date(16).format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")]
      }
      else {
        currrentFortnight = [moment().date(16).format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")]
        previousFortnight = [moment().startOf("month").format("YYYY-MM-DD"), moment().date(15).format("YYYY-MM-DD")]
        nextFortnight = [moment().add(1, "month").startOf("month").format("YYYY-MM-DD"), moment().add(1, "month").date(15).format("YYYY-MM-DD")]
      }

      return {
        'Esta quinzena': currrentFortnight,
        'Quinzena Anterior': previousFortnight,
        'Próxima Quinzena': nextFortnight,
      }
    }
  },

  async mounted() {
    await this.loadRawMaterials();
    this.filter.period = this.dateRangeFilters['Esta quinzena'];
    this.getSpots();
  },

  methods: {
    async loadRawMaterials() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: 'TODOS'
        } });

        this.rawMaterials = data.reduce((acc, cur) => ({ ...acc, [cur.id_item]: cur.nome }), {});

      } catch (error) {
        console.warn(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as matérias primas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },
    async getSpots() {
      this.loading = true;

      try {
        const [startDate, endDate] = this.filter.period;

        const { data } = await this.$axios.post(`/spot/relatorio`, {
          data_inicio: startDate,
          data_fim: endDate,
          tipo: this.type
        });

        this.plannings = _(data)
          .map(o => ({
            key: `${o.id_empresa}_${o.id_materia_prima}`,
            ...o,
          }))
          .groupBy('key')
          .map((items) => {
            const { id_empresa, nome_empresa, id_materia_prima } = items[0];
            return {
              company: {
                id: id_empresa,
                name: nome_empresa
              },
              rawMaterial: {
                id: id_materia_prima,
                name: this.rawMaterials[id_materia_prima]
              },
              ...items.reduce((acc, cur) => ({
                ...acc,
                [moment(cur.data).format('YYYY_MM_DD')]: parseFloat(cur.volume),
              }), {}),
              total: items.reduce((acc, cur) => (acc + parseFloat(cur.volume)), 0),
              items
            }
          })
          .groupBy('rawMaterial.name')
          .map((items, rawMaterial) => {
            return {
              rawMaterial,
              items,
              totals: items.reduce((acc, cur) => {
                cur.items.forEach(item => {
                  const date = moment(item.data).format('YYYY_MM_DD');
                  if (!(date in acc)) {
                    acc[date] = 0;
                  }
                  acc[date] += parseFloat(item.volume);
                  acc.total += parseFloat(item.volume);
                });
                return acc;
              }, { total: 0 })
            }
          }).value();

      } catch (error) {
        console.warn(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os spots!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    newPlanning() {
      this.planningDialog = {
        show: true,
        spot: null
      }
    },

    editPlanning(item) {
      this.planningDialog = {
        show: true,
        spot: {
          company: item.company,
          rawMaterialId: item.rawMaterial.id,
          period: this.filter.period,
        }
      }
    },

    async removePlanning(item) {
      if (!(await this.$refs.confirm.open('Remover registro', 'Tem certeza que deseja remover este planejamento?', { color: 'red' }))) {
        return;
      }
      this.loading = true;
      try {

        const [startDate, endDate] = this.filter.period;

        const { data } = await this.$axios.post(
          `/spot/remove`,
          {
            data_ini: startDate,
            data_fim: endDate,
            id_empresa: item.company.id,
            id_materia_prima: item.rawMaterial.id,
            tipo: this.type
          },
        );

        if (data.codigo != 1) {
          throw data.mensagem || data;
        }

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao remover o planejamento!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
        this.getSpots();
      }
    },

    getReportJson({ items, totals, rawMaterial }, isExcel = false) {
      const formatNumber = isExcel ? this.formatNumberExcel : this.formatNumber;
      return [
        // Dias da semana
        {
          Empresa: '',
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: cur.date.format('dddd')
          }), {}),
          Total: '',
        },
        // Spots
        ...items.map(item => ({
          Empresa: item.company.name,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(item[cur.column])
          }), {}),
          Total: formatNumber(item.total),
        })),
        // Totais por dia
        {
          Empresa: `Total ${rawMaterial}`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(totals[cur.column])
          }), {}),
          Total: formatNumber(totals.total),
        }
      ];
    },

    getReportTitle() {
      const [startDate = moment(), endDate = moment()] = this.filter.period;
      return `Programação de Spot - ${ this.type } - ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      const title = this.getReportTitle();
      const plannings = [...this.plannings];

      const first = plannings.shift();

      const worksheet = this.XLSX.utils.json_to_sheet(this.getReportJson(first, true), { origin: 'A3' });

      worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: this.dates.length + 1 } }];

      worksheet['A1'] = { v: `Programação de ${ this.type.toLowerCase() } de ${ first.rawMaterial }` };

      let startRow = first.items.length;
      plannings.forEach(items => {
        startRow += 6;
        this.XLSX.utils.sheet_add_json(worksheet, this.getReportJson(items, true), { skipHeader: true, origin: startRow + 2 });

        worksheet["!merges"].push({ s: { r: startRow, c: 0 }, e: { r: startRow, c: this.dates.length + 1 } });
        worksheet[`A${startRow + 1}`] = { v: `Programação de ${ this.type.toLowerCase() } de ${ items.rawMaterial }` };
        startRow += items.items.length;
      })

      worksheet['!cols'] = [{ wch: 35 }];

      this.dates.forEach(() => {
        worksheet['!cols'].push({ wch: 12 });
      })

      worksheet['!cols'].push({ wch: 12 });

      const workbook = this.XLSX.utils.book_new();

      this.XLSX.utils.book_append_sheet(workbook, worksheet, _.truncate(title, { length: 31 }));
      this.XLSX.writeFile(workbook, `${title}.xlsx`);
    },

    /**
     * Imprime o relatório
     */
    print() {
      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      const pages = this.plannings
        .map(items => {
          const title = `Programação de ${ this.type.toLowerCase() } de ${ items.rawMaterial }`;
          const json = this.getReportJson(items);

          const body = [{
            tag: 'section',
            class: 'row',
            children: [{
              tag: 'datatable',
              headers: Object.keys(json[0])
                .map(header => ({
                  key: header,
                  label: header,
                  class: 'text-left'
                })),
              items: json
            }]
          }];

          return { title, header, body };
        });

      this.printPdf({ pages, landscape: true });
    },

    /**
     * Os filtros foram descontinuados na nova versão do vue (v3) por não ser uma boa prática
     * Let's stop using filters and starts to use methods then
     */
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => value ? new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value) : '-',
    formatNumberExcel: (value) => value == null || isNaN(value) ? '' : parseFloat(value),
  },
};
</script>
